import makeStyles from '@mui/styles/makeStyles';
import { FONT } from '@constants/style';


const useStyles = makeStyles((theme) => ({
  lightTooltip: {
    paddingBottom: '8px',
    '& >div': {
      backgroundColor: '#F2F2F2',
      color: '#404040',
      margin: '0',
      fontWeight: FONT.WEIGHT.REGULAR,
      padding: '4px',
      boxShadow: '0px 2px 3px rgba(29, 29, 29, 0.2);',
      borderRadius: '0px',
    }
  },
}));

export default useStyles;

