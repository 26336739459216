import { naicsCodes } from 'skyeye-common-const/dist/reference/naicsCodes';
import { DRAWER, BUSINESS_LINES, DATA } from '@constants/actions';
import { isEmpty } from 'lodash';
import { populateBusinessLines, searchBusinessLinesByKeyword } from '@constants/utils';
import createAction from './actionCreator';


export const initBusinessLines = () => {
  return async function (dispatch: any) {
    const forest = populateBusinessLines();
    dispatch(createAction(BUSINESS_LINES.INIT, {
      name: forest.name,
      tree: forest.tree,
      parent: forest.parent,
    }));

    dispatch(createAction(DATA.BUSINESS_LINES.NAME, {
      data: forest.name,
    }));

    dispatch(createAction(DATA.BUSINESS_LINES.TREE, {
      data: forest.tree,
    }));

    dispatch(createAction(DATA.BUSINESS_LINES.PARENT, {
      data: forest.parent,
    }));
  };
};

export const searchDrawerMenuBusinessLines = (keyword?: string, reset?: boolean) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.IN_PROGRESS));
    let forest = getState().getIn(['BusinessLines']).toJS();
    let tree = forest?.tree || {};
    let name = forest?.name || {};
    let parent = forest?.parent || {};

    /**
     * If tree strcture is empty,
     * load the entire business lines forest
     */
    if (isEmpty(tree)) {
      forest = populateBusinessLines();
      tree = forest.tree;
      name = forest.name;
      parent = forest.parent;
    }

    /**
     * Force reset with initial result
     */
    if (isEmpty(keyword) || reset) {
      dispatch(createAction(DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.NOT_IN_PROGRESS));
      dispatch(createAction(DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.RESULT, {
        name,
        tree,
        parent,
        search: {
          keyword: '',
          result: [],
        },
      }));

      return;
    }

    const result = searchBusinessLinesByKeyword(keyword).map((scoreItem) => {
      const { code, description, matchLabels } = scoreItem;
      return {
        code,
        description,
        uniqueIdentifier: code,
        displayName: description,
        matchLabels,
      };
    });

    dispatch(createAction(DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.NOT_IN_PROGRESS));
    dispatch(createAction(DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.RESULT, {
      name,
      tree,
      parent,
      search: {
        keyword,
        result,
      },
    }));
  };
};
