import { useLocation } from 'react-router-dom';
import { filter, isEmpty, isEqual, isNumber, keyBy } from 'lodash';
import { COMPANY_CONSTANT, LOCAL_STORAGE, FINANCIAL_UNIT, EMPTY_STRING, ARTICLE_PUBLISHER, CURRENCY, GUID_REGEX, ONBOARDING_TYPE, GRAPH_HEIGHT, STORE_QUERY, ONBOARDING_ONE_COUNTRY_SELECTED, SOURCE_TYPE, KEYWORD_IN_COMPANY_FIELDS_PARAMS, FINANCIAL_ADDITIONAL_INFO_LABEL, FILTER_FINANCIAL_EMPTY_DATA, UI_ELEMENT, SWAGGER_ENV_MAP, NEWS_REPORT_PUBLISHER, ARAIRegx, ExpertConnectAsiaRegx, CHINA_REGIONS, COUNTRIES, GLINRegx, getUrlForGlinRegex, DASH, NIKKEI_PUBLISHERS } from '@constants/common';
import { IFinancialProps, IFinancialItemProps } from '@react/components/props/common/finanical.props';
import { NumberEmployee } from '@react/components/props/common/numberEmployee.props';
import moment from 'moment';
import { convertUnixToLocalTime } from 'skyeye-fe-common-util';
import { SearchQuery } from '@models/searchQuery';
import { FinancialUnitType } from '@models/financialUnit';
import IAnnouncement from '@models/announcement';
import ILocalStorageAnnouncement from '@models/localStorageAnnouncement';
import 'core-js/features/url-search-params';
import { newsConst } from 'skyeye-common-const';
import { ILocalStorageFinancialUnits } from '@models/localStorageFinancialUnits';
import { ICompanyOfficer } from '@models/company';
import { executive, board } from '@constants/peopleGroupTemplate';
import { IOidcConfiguration } from '@models/oidcConfiguration';
import jwt from 'jsonwebtoken';
import { LOCALIZATION } from '@constants/localization';
import { IArticle, IStandardArticle } from '@models/article';
import { Parser } from 'json2csv';
import { SITE_URLS } from '@constants/routes';
import TagManager from 'react-gtm-module';
import CompanyListReq from '@models/companyListReq';
import ArticleListReq from '@models/articleListReq';
import { seerBoard, seerExecutive } from '@constants/peopleGroupScoutAsiaTemplate';
import { IAtlasTracking } from '@models/atlasTracking';
import { RelationshipOptions } from '@constants/component';


const specialCharacterPattern = '([\\\\+]?[\\\\!]?[\\\\#]?[\\\\$]?[\\\\%]?[\\\\&]?[\\\\\']?[\\\\*]?[\\\\/]?[\\\\=]?[\\\\?]?[\\\\^]?[\\\\_]?[\\\\`]?[\\\\{]?[\\\\|]?[\\\\}]?[\\\\~]?)*';

export const encodeToJwt = (data) => jwt.sign(data, process.env.JWT_SECRET);

export const localStorageReady = () => {
  try {
    return typeof localStorage !== 'undefined';
  } catch {
    return false;
  }
};

export const usePathName = () => {
  return useLocation().pathname;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const isListDialogErrorType = (tab: string) => {
  if (tab) {
    return !!tab.match(/Lists/g);
  }
  return false;
};

export const useQueryStore = (key) => {
  return {
    remove: () => { localStorage.removeItem(key); },
    get: () => localStorage.getItem(key),
    has: () => !!localStorage.getItem(key),
    set: (value) => {
      localStorage.setItem(key, value);
      return value;
    },
  };
};

export const goToPage = (history: any, url: string, isPushMethod?: boolean, state?: unknown) => {
  if (isPushMethod) {
    history.push(url, state);
  } else {
    history.replace(url, state);
  }
};

export const checkIsQueryReloadPage = () => {
  return window.location.search.includes('?t=');
};

export const clearAllQueryStore = () => {
  Object.values(STORE_QUERY).forEach(key => localStorage.removeItem(key));
};

export const getCurrentTime = () => {
  return new Date().getTime();
};

export const getFromLocationState = (stateProp: string) => {
  const location = useLocation() as any;
  return location && location.state && location.state[stateProp];
};

export const getHostDomain = (url: string = '', includePrefixDot: boolean = false): string => {
  const hostDomainRegex = new RegExp('[-\\w]+\\.(?:[-\\w]+\\.--[-\\w]+|[-\\w]{3,}|[-\\w]+\.[-\\w]{2})$', 'g');
  const matches = url.match(hostDomainRegex);
  if (matches) {
    const firstMatch = matches[0];
    return includePrefixDot ? `.${firstMatch}` : firstMatch;
  }
  return url;
};

export const formatUrls = (articleList: IArticle[]) => {
  return articleList && articleList.map((article) => {
    return formatUrl(article);
  });
};

export const formatUrl = (article: IArticle) => {
  let formattedUrl = article.url;
  let articleUuid = EMPTY_STRING;

  if (!isEmpty(article.url) && article.source === COMPANY_CONSTANT.SOURCE.FINANCIAL_TIMES.SHORT) {
    articleUuid = article.url.split('/').pop();

    if (!isEmpty(articleUuid) && articleUuid.match(GUID_REGEX)) {
      formattedUrl = `https://www.ft.com/cms/${articleUuid}.html?FTCamp=engage/CAPI/website/Channel_ScoutAsia//B2B`;
    }
  }
  return {
    ...article,
    url: formattedUrl,
  };
};

export const getSearchQueryString = (queryObject: SearchQuery) => {
  const queryString = encodeURIComponent(JSON.stringify(queryObject));
  return btoa(queryString);
};

export const getSearchQueryObject = (queryString: string = ''): SearchQuery => {
  if (isEmpty(queryString)) {
    return {
      data: {},
      descriptor: {},
    };
  }

  const queryObject = atob(queryString);
  return JSON.parse(decodeURIComponent(queryObject));
};


export const getPublisherLabel = (publisherCode: string, providerTitle: string): string => {
  /**
   * Show codes for the following publishers - EMIS, SGX and HKEX.
   */
  if (publisherCode === ARTICLE_PUBLISHER.NAVIGA && providerTitle) {
    return providerTitle;
  }

  if (ARTICLE_PUBLISHER[publisherCode] && ARTICLE_PUBLISHER[publisherCode] !== ARTICLE_PUBLISHER.ARAI) {
    return ARTICLE_PUBLISHER[publisherCode];
  }

  const publisherMap = keyBy(Object.values(newsConst), 'code');
  if (publisherCode && publisherMap[publisherCode]) {
    return publisherMap[publisherCode].label.replace(/ *\([^)]*\) */g, '');
  }
  return EMPTY_STRING;
};

export const isValidEmail = (email: string = '') => {
  const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
  const match = email ? email.match(emailRegex) : false;

  return !!match;
};

/**
 * Split a string which may contain `/`
 *
 * If the string does not contain any '/', it will return an array of the default string.
 *
 * @param val A non-empty string
 *
 * @returns An array of string.
 */
export const splitByForwardSlash = (val: string) => {
  if (isEmpty(val)) {
    return [];
  }
  return val.split('/');
};

export const isNumberNullOrUndefined = (value: number) => {
  return value === null || value === undefined;
};

export const isCompanySourceCritical = (source: string) => {
  return (!isEmpty(source) &&
    (source.toLowerCase().includes(COMPANY_CONSTANT.SOURCE.FACTSET.toLowerCase()) ||
      source.toLowerCase().includes(COMPANY_CONSTANT.SOURCE.FINANCIAL_TIMES.LONG.toLowerCase()) ||
      source.toLowerCase().includes(COMPANY_CONSTANT.SOURCE.FINANCIAL_TIMES.SHORT.toLowerCase())
    ));
};

export const isTheSameString = (textA: string = EMPTY_STRING, textB: string = EMPTY_STRING) => {
  if ((!textA && textA !== EMPTY_STRING) || (!textB && textB !== EMPTY_STRING)) {
    return false;
  }
  return textA.toLowerCase().trim() === textB.toLowerCase().trim();
};

export const stringClamp = (text: string, maxLength: number) => {
  if (!text || (text && text.length <= maxLength)) {
    return text;
  }

  return `${text.substring(0, maxLength)}...`;
};

export const isBlank = (text: string) => {
  return !text.trim();
};

export const isEqualCaseInsensitive = (a: string = EMPTY_STRING, b: string = EMPTY_STRING) => {
  if (isEmpty(a) || isEmpty(b)) {
    return false;
  }
  return a.toLowerCase() === b.toLowerCase();
};

export const saveLocalStorageAnnouncement = (announcement: IAnnouncement, isClosed: boolean = false) => {
  const currentDate = new Date();
  const newStorageValue = new ILocalStorageAnnouncement();

  const current: ILocalStorageAnnouncement = getLocalStorageAnnouncement();
  if (isEmpty(current) || current?.announcement?.guid !== announcement.guid) {

    if (moment(announcement.endDate).isSameOrAfter(moment(currentDate))) {
      newStorageValue.announcement = announcement;
      newStorageValue.isClosed = false;
    }

  } else if (moment(announcement.endDate).isSameOrAfter(moment(currentDate))) {
    newStorageValue.announcement = announcement;
    newStorageValue.isClosed = isClosed || current.isClosed;
  }

  newStorageValue.lastSearchDate = currentDate.toISOString();

  localStorage.setItem(LOCAL_STORAGE.ANNOUNCEMENT, JSON.stringify(newStorageValue));
};

export const saveLocalStorageConnexAcceptTermOfUse = (accept: boolean) => {
  localStorage.setItem(LOCAL_STORAGE.CONNEX_ACCEPT_TERM_OF_USE, `${accept ? 1 : 0}`);
};

export const saveLocalStorageConnexButtonClicks = (numberClick: number) => {
  localStorage.setItem(LOCAL_STORAGE.CONNEX_BUTTON_NUMBER_CLICKS, `${numberClick}`);
};

export const saveLocalStorageTryFeatureNow = (clicked: boolean) => {
  localStorage.setItem(LOCAL_STORAGE.CONNEX_TRY_FEATURE_NOW, `${clicked ? 1 : 0}`);
};

export const saveLocalStorageConnexModalDoNotShowAgain = (doNowShowAgain: boolean) => {
  localStorage.setItem(LOCAL_STORAGE.CONNEX_MODAL_DO_NOT_SHOW_AGAIN, `${doNowShowAgain ? 1 : 0}`);
};

export const saveLocalStorageLeftDrawerTabSelected = (tabIndex: number) => {
  localStorage.setItem(LOCAL_STORAGE.LEFT_DRAWER_TAB_SELECTED, `${tabIndex}`);
};

export const saveLocalStorageOnboarding = (type, list) => {
  const onboardingObject = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.ONBOARDING) : EMPTY_STRING;
  const templateObject = {
    [ONBOARDING_TYPE.GOAL]: [],
    [ONBOARDING_TYPE.OTHERGOAL]: String,
    [ONBOARDING_TYPE.COUNTRY]: [],
    [ONBOARDING_TYPE.SECTOR]: [],
  };
  const newObject = isEmpty(onboardingObject) ? templateObject : JSON.parse(onboardingObject);
  newObject[type] = list;
  localStorage.setItem(LOCAL_STORAGE.ONBOARDING, JSON.stringify(newObject));
};

export const cleanLocalStorageOnboarding = () => {
  localStorage.removeItem(LOCAL_STORAGE.ONBOARDING);
};

export const saveLocalStorageOneCountrySelected = (doNowSelectedAgain: boolean) => {
  localStorage.setItem(ONBOARDING_ONE_COUNTRY_SELECTED, `${doNowSelectedAgain ? 1 : 0}`);
};

export const getLocalStorageOneCountrySelected = () => {
  return localStorage.getItem(ONBOARDING_ONE_COUNTRY_SELECTED);
};

export const getLocalStorageOnboardingGoal = () => {
  const goalList = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.ONBOARDING) : EMPTY_STRING;
  return isEmpty(goalList) || goalList === JSON.stringify({}) ? [] : JSON.parse(goalList)[ONBOARDING_TYPE.GOAL];
};

export const getLocalStorageOnboardingOtherGoal = () => {
  const otherGoal = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.ONBOARDING) : EMPTY_STRING;
  return isEmpty(otherGoal) || otherGoal === JSON.stringify({}) ? '' : JSON.parse(otherGoal)[ONBOARDING_TYPE.OTHERGOAL];
};

export const getLocalStorageOnboardingCountry = () => {
  const countryList = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.ONBOARDING) : EMPTY_STRING;
  return isEmpty(countryList) || countryList === JSON.stringify({}) ? [] : JSON.parse(countryList)[ONBOARDING_TYPE.COUNTRY];
};

export const getLocalStorageOnboardingSector = () => {
  const sectorList = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.ONBOARDING) : EMPTY_STRING;
  return isEmpty(sectorList) || sectorList === JSON.stringify({}) ? [] : JSON.parse(sectorList)[ONBOARDING_TYPE.SECTOR];
};

export const getLocalStorageAnnouncement = (): ILocalStorageAnnouncement => {
  const announcement = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.ANNOUNCEMENT) : EMPTY_STRING;
  const instance = new ILocalStorageAnnouncement();
  if (isEmpty(announcement)) {
    return instance;
  }

  try {
    const announcementObj = JSON.parse(announcement);

    instance.announcement = announcementObj.announcement;
    instance.isClosed = announcementObj.isClosed;
    instance.lastSearchDate = announcementObj.lastSearchDate;
    return instance;
  } catch (_) {
    return instance;
  }
};

export const getLocalStorageConnexAcceptTermOfUse = () => {
  return parseInt(localStorage.getItem(LOCAL_STORAGE.CONNEX_ACCEPT_TERM_OF_USE) || '0', 10) === 1;
};

export const getLocalStorageConnexButtonClicks = () => {
  return parseInt(localStorage.getItem(LOCAL_STORAGE.CONNEX_BUTTON_NUMBER_CLICKS) || '0', 10);
};

export const getLocalStorageConnexModalDoNotShowAgain = () => {
  return parseInt(localStorage.getItem(LOCAL_STORAGE.CONNEX_MODAL_DO_NOT_SHOW_AGAIN) || '0', 10) === 1;
};

export const getLocalStorageTryFeatureNow = () => {
  return parseInt(localStorage.getItem(LOCAL_STORAGE.CONNEX_TRY_FEATURE_NOW) || '0', 10) === 1;
};

export const getLocalStorageFinancialUnits = (): ILocalStorageFinancialUnits => {
  const localStorageUnits = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.FINANCIAL_UNITS) : EMPTY_STRING;
  const defaultUnits = {
    operatingRevenue: {
      from: FinancialUnitType.none.toString(),
      to: FinancialUnitType.none.toString(),
    },
    marketCapitalization: {
      from: FinancialUnitType.none.toString(),
      to: FinancialUnitType.none.toString(),
    },
    totalAssets: {
      from: FinancialUnitType.none.toString(),
      to: FinancialUnitType.none.toString(),
    },
    shareholdersFunds: {
      from: FinancialUnitType.none.toString(),
      to: FinancialUnitType.none.toString(),
    },
    plBeforeTax: {
      from: FinancialUnitType.none.toString(),
      to: FinancialUnitType.none.toString(),
    },
  };
  if (isEmpty(localStorageUnits)) {
    return defaultUnits;
  }

  try {
    return JSON.parse(localStorageUnits);
  } catch {
    return defaultUnits;
  }
};

export const saveLocalStorageRecentSearch = (recentSearch: string) => {
  localStorage.setItem(LOCAL_STORAGE.RECENT_SEARCHES, JSON.stringify(recentSearch));
};

export const getLocalStorageRecentSearch = () => {
  const mostRecentSearch = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.RECENT_SEARCHES) : EMPTY_STRING;
  return isEmpty(mostRecentSearch) ? [] : JSON.parse(mostRecentSearch);
};

export const cleanLocalStorageRecentSearch = () => {
  localStorage.removeItem(LOCAL_STORAGE.RECENT_SEARCHES);
};

export const saveLocalStorageMostRecentSearch = (mostRecentSearchArray: []) => {
  localStorage.setItem(LOCAL_STORAGE.MOST_RECENT_SEARCHES, JSON.stringify(mostRecentSearchArray));
};

export const getLocalStorageMostRecentSearch = () => {
  const mostRecentSearch = localStorageReady() ? localStorage.getItem(LOCAL_STORAGE.MOST_RECENT_SEARCHES) : EMPTY_STRING;
  return isEmpty(mostRecentSearch) ? [] : JSON.parse(mostRecentSearch);
};

export const cleanLocalStorageMostRecentSearch = () => {
  localStorage.removeItem(LOCAL_STORAGE.MOST_RECENT_SEARCHES);
};

export const getLocalStorageLeftDrawerTabSelected = () => {
  return parseInt(localStorage.getItem(LOCAL_STORAGE.LEFT_DRAWER_TAB_SELECTED) || '0', 10);
};

export const saveLocalStorageDefaultLanguage = (language) => {
  localStorage.setItem(`${LOCAL_STORAGE.DEFAULT_LANGUAGE}`, language);
};

export const getLocalStorageDefaultLanguage = () => {
  return localStorageReady() ? (localStorage.getItem(`${LOCAL_STORAGE.DEFAULT_LANGUAGE}`) || LOCALIZATION.DEFAULT_LOCALE) : LOCALIZATION.DEFAULT_LOCALE;
};

export const getLocalStorageDefaultLanguageValue = () => {
  return localStorageReady() ? (localStorage.getItem(`${LOCAL_STORAGE.DEFAULT_LANGUAGE}`)) : null;
};

export const saveLocalStorageChangedLanguage = (value) => {
  return localStorage.setItem(LOCAL_STORAGE.FIRST_SET_LANGUAGE, value);
}

export const getLocalStorageChangedLanguage = () => {
  return localStorage.getItem(LOCAL_STORAGE.FIRST_SET_LANGUAGE);
}

export const isAnnouncementShown = () => {
  const storedAnnouncement = getLocalStorageAnnouncement();
  return (storedAnnouncement?.announcement !== undefined && !storedAnnouncement?.isClosed);
};

export const saveLocalStorageTrendingTopic = (filteredCountry, userGuid: string) => {
  localStorage.setItem(`${LOCAL_STORAGE.TRENDING_TOPICS}-${userGuid}`, filteredCountry);
};

export const getLocalStorageTrendingTopic = (userGuid: string) => {
  const filteredCountry = localStorageReady() ? localStorage.getItem(`${LOCAL_STORAGE.TRENDING_TOPICS}-${userGuid}`) : '';
  return isEmpty(filteredCountry) ? '' : filteredCountry;
};

export const cleanTrendingTopic = (userGuid: string) => {
  localStorage.removeItem(`${LOCAL_STORAGE.TRENDING_TOPICS}-${userGuid}`);
};

export const getStartEnd = (timestamp: number) => {
  let now = new Date();
  if (timestamp != null) {
    now = new Date(timestamp);
  }
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  return {
    start: start.toISOString(),
    end: end.toISOString(),
  };
};

export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  return offset / -60;
};

export function convertStringToFinancialUnitType(type: string): FinancialUnitType {
  if (isEmpty(type)) {
    return FinancialUnitType.none;
  }
  switch (type) {
    case 'T': {
      return FinancialUnitType.trillion;
    }
    case 'B': {
      return FinancialUnitType.billion;
    }
    case 'M': {
      return FinancialUnitType.million;
    }
    default:
      return FinancialUnitType.none;
  }
}

export function convertToMoneyFormat(value: number, predefinedSuffix: string = '', toFixed: number = 0, currency?: string) {
  if (isEmpty(predefinedSuffix)) {
    const absValue = Math.abs(value);

    if (absValue >= 1.0e+12) {
      return {
        value: toFixed ? Number(value / 1.0e+12).toFixed(toFixed) : Number(value / 1.0e+12),
        suffix: 'T',
      };
    }

    if (absValue >= 1.0e+9) {
      return {
        value: toFixed ? Number(value / 1.0e+9).toFixed(toFixed) : Number(value / 1.0e+9),
        suffix: 'B',
      };
    }

    if (absValue >= 1.0e+6) {
      return {
        value: toFixed ? Number(value / 1.0e+6).toFixed(toFixed) : Number(value / 1.0e+6),
        suffix: 'M',
      };
    }

    if (currency && (currency === CURRENCY.JPY || currency === CURRENCY.INR || currency === CURRENCY.KRW)) {
      if (absValue >= 1.0e+5) {
        return {
          value: toFixed ? Number(value / 1.0e+3).toFixed(toFixed) : Number(value / 1.0e+3),
          suffix: 'K',
        };
      }
      return {
        value,
        suffix: '',
      };
    }

    if (absValue >= 1.0e+3) {
      return {
        value: toFixed ? Number(value / 1.0e+3).toFixed(toFixed) : Number(value / 1.0e+3),
        suffix: 'K',
      };
    }

    return {
      value,
      suffix: '',
    };

  }

  switch (predefinedSuffix) {
    case 'M': {
      return {
        value: toFixed ? Number(value / 1.0e+6).toFixed(toFixed) : Number(value / 1.0e+6),
        suffix: 'M',
      };
    }
    case 'B': {
      return {
        value: toFixed ? Number(value / 1.0e+9).toFixed(toFixed) : Number(value / 1.0e+9),
        suffix: 'B',
      };
    }
    case 'T': {
      return {
        value: toFixed ? Number(value / 1.0e+12).toFixed(toFixed) : Number(value / 1.0e+12),
        suffix: 'T',
      };
    }
    default: {
      return {
        value,
        suffix: 'N',
      };
    }
  }
}

export const formatCurrency = (
  intl,
  currencyDisplayed,
  value = 0,
) => {
  return intl.formatNumber(value, {
    style: 'currency',
    currency: currencyDisplayed,
    maximumFractionDigits: 2,
  });

};

const hasFinancialItemChange = (financialItem: IFinancialItemProps) => {
  if (!financialItem) {
    return false;
  }

  if (financialItem.fromValue === null || financialItem.fromValue === undefined &&
    financialItem.toValue === null || financialItem.toValue === undefined) {
    return false;
  }
  return FINANCIAL_UNIT.getValueWithUnit(financialItem.fromValue, financialItem.fromUnit) !== financialItem.minValue ||
    FINANCIAL_UNIT.getValueWithUnit(financialItem.toValue, financialItem.toUnit) !== financialItem.maxValue;
};

export function convertFinancialsToQuery(financials: IFinancialProps) {
  const obj = {};

  const operatingRevenue = financials.operatingRevenue;
  if (hasFinancialItemChange(operatingRevenue)) {
    obj.operatingRevenueFrom = FINANCIAL_UNIT.getValueWithUnit(operatingRevenue.fromValue, operatingRevenue.fromUnit);
    obj.operatingRevenueTo = FINANCIAL_UNIT.getValueWithUnit(operatingRevenue.toValue, operatingRevenue.toUnit);
  }


  const marketCapitalization = financials.marketCapitalization;
  if (hasFinancialItemChange(marketCapitalization)) {
    obj.marketCapitalizationFrom = FINANCIAL_UNIT.getValueWithUnit(marketCapitalization.fromValue, marketCapitalization.fromUnit);
    obj.marketCapitalizationTo = FINANCIAL_UNIT.getValueWithUnit(marketCapitalization.toValue, marketCapitalization.toUnit);
  }

  const totalAssets = financials.totalAssets;
  if (hasFinancialItemChange(totalAssets)) {
    obj.totalAssetsFrom = FINANCIAL_UNIT.getValueWithUnit(totalAssets.fromValue, totalAssets.fromUnit);
    obj.totalAssetsTo = FINANCIAL_UNIT.getValueWithUnit(totalAssets.toValue, totalAssets.toUnit);
  }

  const shareholdersFunds = financials.shareholdersFunds;
  if (hasFinancialItemChange(shareholdersFunds)) {
    obj.shareholdersFundsFrom = FINANCIAL_UNIT.getValueWithUnit(shareholdersFunds.fromValue, shareholdersFunds.fromUnit);
    obj.shareholdersFundsTo = FINANCIAL_UNIT.getValueWithUnit(shareholdersFunds.toValue, shareholdersFunds.toUnit);
  }

  const plBeforeTax = financials.plBeforeTax;
  if (hasFinancialItemChange(plBeforeTax)) {
    obj.plBeforeTaxFrom = FINANCIAL_UNIT.getValueWithUnit(plBeforeTax.fromValue, plBeforeTax.fromUnit);
    obj.plBeforeTaxTo = FINANCIAL_UNIT.getValueWithUnit(plBeforeTax.toValue, plBeforeTax.toUnit);
  }

  return obj;
}

export function convertNumberEmpoyeesToQuery(numberEmployee: NumberEmployee) {
  if (isEmpty(numberEmployee)) {
    return {};
  }

  if ((numberEmployee.fromValue === null || numberEmployee.fromValue === numberEmployee.minValue) &&
    (numberEmployee.toValue === null || numberEmployee.toValue === numberEmployee.maxValue)) {
    return {};
  }

  return {
    numOfEmployeesFrom: numberEmployee.fromValue,
    numOfEmployeesTo: numberEmployee.toValue,
  };
}

export function publishedUnderFiveMinutesAgo(publishedDate: number) {
  const minuteFromNow = moment(Date.now()).diff(convertUnixToLocalTime(publishedDate), 'minutes');
  return minuteFromNow < 5;
}

export const stripTag = (text: string = '') => {
  return text.replace(/(<([^>]+)>)/ig, '');
};

export const sortCompanyOfficerByGroup = (officers: ICompanyOfficer[], groupType: 'executive' | 'board' | 'founder', source) => {

  const executiveTemplate = source === SOURCE_TYPE.FACTSET ? executive : seerExecutive;
  const boardTemplate = source === SOURCE_TYPE.FACTSET ? board : seerBoard;
  
  const convertGroupObjectToMapTypeIndex = (obj) => {
    const mapIndex = {};
    Object.keys(obj).forEach((key, index) => {
      mapIndex[key] = index;
    });
    return mapIndex;
  };

  if (groupType === 'executive') {
    const mapExecutiveGroupTypeIndex = convertGroupObjectToMapTypeIndex(executiveTemplate);
    return officers.sort((a, b) => {
      return mapExecutiveGroupTypeIndex[a.type] - mapExecutiveGroupTypeIndex[b.type];
    });
  }

  if (groupType === 'board') {
    const mapBoardGroupTypeIndex = convertGroupObjectToMapTypeIndex(boardTemplate);
    return officers.sort((a, b) => {
      return mapBoardGroupTypeIndex[a.type] - mapBoardGroupTypeIndex[b.type];
    });
  }

  // For now, since we do not sort officers by name
  // founders will return the default founders array
  return officers;
};

export const decodeOidcConfiguration = (oidcConfigurationString: string): IOidcConfiguration => {
  if (isEmpty(oidcConfigurationString)) {
    return {
      authority: '',
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      scope: '',
      silentRedirectUri: '',
    };
  }

  const configObject = Buffer.from(oidcConfigurationString, 'base64').toString('ascii');
  return JSON.parse(decodeURIComponent(configObject));
};

export const encodeOidcConfiguration = (oidcConfiguration: IOidcConfiguration): string => {
  const encodedString = encodeURIComponent(JSON.stringify(oidcConfiguration));
  return Buffer.from(encodedString).toString('base64');
};

export const removeHttpFromUrl = (rawHttpUrl: string) => {
  if (isEmpty(rawHttpUrl)) {
    return EMPTY_STRING;
  }

  return rawHttpUrl.replace(/https?:\/\//g, EMPTY_STRING);
};

export const convertFirstCharacterToUpperCase = (str) => {
  return String(str).toLowerCase().replace(/(^| )(\w)/g, (x) => {
    return x.toUpperCase();
  });
};

export const getInnerText = (title) => {
  const tempElement = document.createElement('span');
  tempElement.innerHTML = title;
  return tempElement.innerText;
};

const caculateDifference = (difference, publishTime, format) => {
  const date = new Date(publishTime * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (format === 'FULLCAP') {
    if (difference < 3600) {
      return `${Math.round(difference / 60)} ${Math.round(difference / 60) === 1 ? 'MINUTE' : 'MINUTES'} AGO`;
    }
    if (difference >= 3600 && difference < 86400) {
      return `${Math.round(difference / 3600)} ${Math.round(difference / 3600) === 1 ? 'HOUR' : 'HOURS'}  AGO`;
    }
    return `${date.getDate()} ${months[date.getMonth()].toUpperCase()} ${date.getFullYear()}`;
  }
  if (format === 'CAP') {
    if (difference < 3600) {
      return `${Math.round(difference / 60)}M AGO`;
    }
    if (difference >= 3600 && difference < 86400) {
      return `${Math.round(difference / 3600)}H AGO`;
    }
    return `${date.getDate()} ${months[date.getMonth()].toUpperCase()} ${date.getFullYear()}`;
  }
  if (difference < 3600) {
    return `${Math.round(difference / 60)} ${Math.round(difference / 60) === 1 ? 'minute' : 'minutes'} ago`;
  }
  if (difference >= 3600 && difference < 86400) {
    return `${Math.round(difference / 3600)} ${Math.round(difference / 3600) === 1 ? 'hour' : 'hours'}  ago`;
  }
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getTimeDifference = (publishTime, format) => {
  const currentTime = new Date().getTime();
  const currentTimestamp = currentTime / 1000;
  const difference = currentTimestamp - publishTime;
  return caculateDifference(difference, publishTime, format);
};

export const openNewPage = (url, features:string = '_self') => {
  window.open(url, features);
};

export const handleDownloadEmiReport = (fileName: string, downloadEmiReport) => {
  const downUrl = fileName.split('/').filter((a) => !!a).join('/');
  downloadEmiReport && downloadEmiReport(downUrl).then((link) => {
    if (isEmpty(link)) {
      return;
    }
    openNewPage(link);
  });
};

export const goToExternalUrl = (url:string) => {
  window.open(url, '_blank');
};

export const downloadCsv = (exportObj, fileName: string = 'csv-export') => {
  const json2csvParser = new Parser({ withBOM: true });
  const csvString = json2csvParser.parse(exportObj);
  const filename = fileName;
  const blob = new Blob([csvString], { type: 'text/plain' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const a = window.document.createElement('a');

    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const getNumValue = (value) => {
  return value <= 0 ? '' : value;
};

export const isOnboarding = () => {
  return usePathName().includes(SITE_URLS.ACCOUNT_ACTIVATION);
};

export const hasAppBar = () => {
  return [SITE_URLS.ACCOUNT_ACTIVATION, SITE_URLS.PARTNER_USER_ACCOUNT_ACTIVATION, SITE_URLS.ACCOUNT_REGISTRATION, SITE_URLS.SERVER_ERROR].filter(x => usePathName().includes(x)).length > 0;
};

export const hideMenu = () => {
  return [SITE_URLS.PARTNER_USER_ACCOUNT_ACTIVATION, SITE_URLS.ACCOUNT_REGISTRATION, SITE_URLS.SERVER_ERROR].filter(x => usePathName().includes(x)).length > 0;
};

/**
 *  Google Tag Manager (GTM):
 *  Sends page_view transaction to GTM.
 *  The title is the text shown on the browser's tab.
 *  The pathname is the url of the current page.
 * 
 * @param {string} title
 * @param {string} location.pathname
 */
export const sendPageViewToGTM = (title, pathname) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: pathname,
      pageTitle: title,
    },
  });
};

export const toTitleCase = (word: string) => {
  return word.replace(
    /\w\S*/g,
    (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    },
  );
};

export const getGraphHeight = (isDownloading: boolean) => {
  if (isDownloading) {
    return GRAPH_HEIGHT.DOWNLOAD;
  }

  return GRAPH_HEIGHT.NORMAL;
};

export const transformQuery = (searchQuery, additionalInfo, type) => {
  let filters = {};
  if (type === 'article') {
    filters = {
      ...generateNewsSearchQuery.interval(searchQuery),
      ...generateNewsSearchQuery.publisher(searchQuery),
      ...generateNewsSearchQuery.sectors(searchQuery),
      ...generateNewsSearchQuery.scoutAIs(searchQuery),
      ...generateNewsSearchQuery.locations(searchQuery),
      ...generateNewsSearchQuery.companies(searchQuery),
      ...generateNewsSearchQuery.scoutAiTopics(searchQuery),
    };
  } else {
    filters = {
      ...generateCompanySearchQuery.countries(searchQuery),
      ...generateCompanySearchQuery.businessLines(searchQuery),
      ...generateCompanySearchQuery.financial(searchQuery),
      ...generateCompanySearchQuery.sectors(searchQuery),
      ...generateCompanySearchQuery.ownership(searchQuery),
      ...generateCompanySearchQuery.keywordIn(searchQuery),
      ...generateCompanySearchQuery.numberEmployee(searchQuery),
      ...generateCompanySearchQuery.relationship(searchQuery),
      ...generateCompanySearchQuery.relationshipCountries(searchQuery),
    };
  }

  const { searchStr } = searchQuery;

  const updatedNewsQueryObj: ArticleListReq | CompanyListReq = {
    searchStr,
    ...filters,
  };

  const information = {
    ...additionalInfo,
  };

  const updatedSearchQuery: SearchQuery = {
    data: updatedNewsQueryObj,
    descriptor: information,
  };

  return updatedSearchQuery;
};

const generateCompanySearchQuery = {
  businessLines: (selects?: any) => {
    return selects
      && !isEmpty(selects.businessLines)
      && selects.businessLines.length > 0 ?
      {
        naics: selects.businessLines,
      } : {};
  },
  countries: (selects?: any) => {
    return selects
      && !isEmpty(selects.countries)
      && selects.countries.length > 0 ?
      {
        countries: selects.countries,
      } : {};
  },
  financial: (selects?: any) => {
    return selects && !isEmpty(selects.financials) ?
      convertFinancialsToQuery(selects.financials) : {};
  },
  numberEmployee: (selects?: any) => {
    return selects && !isEmpty(selects.numberEmployees) ?
      convertNumberEmpoyeesToQuery(selects.numberEmployees) : {};
  },
  keywordIn: (selects?: any) => {
    const { keywordIn } = selects;
    let search = {};
    if (!isEmpty(keywordIn)) {
      switch (keywordIn) {
        case COMPANY_CONSTANT.KEYWORD_IN.NAME:
          search = {
            searchFields: KEYWORD_IN_COMPANY_FIELDS_PARAMS.NAME.split(','),
          };
          break;

        case COMPANY_CONSTANT.KEYWORD_IN.DESCRIPTION:
          search = {
            searchFields: KEYWORD_IN_COMPANY_FIELDS_PARAMS.DESCRIPTION.split(','),
          };
          break;

        case COMPANY_CONSTANT.KEYWORD_IN.NAMEDESCRIPTION:
          search = {
            searchFields: KEYWORD_IN_COMPANY_FIELDS_PARAMS.NAMEDESCRIPTION.split(','),
          };
          break;
      }
    } else {
      search = {
        searchFields: KEYWORD_IN_COMPANY_FIELDS_PARAMS.NAME.split(','),
      };
    }
    
    return { keywordIn: selects.keywordIn, ...search };
  },
  ownership: (selects?: any) => {
    return selects && isEmpty(selects.ownership) ?
      {} :
      {
        listingStatus: selects.ownership,
      };
  },
  sectors: (selects?: any) => {
    return selects
      && !isEmpty(selects.sectors)
      && selects.sectors.length > 0 ?
      {
        sectors: selects.sectors,
      } : {};
  },
  relationship: (selects?: any) => {
    return selects 
      && !isEmpty(selects.relationship) ? 
      {
        relationship: selects.relationship,
      }: {};
  },
  relationshipCountries: (selects?: any) => {
    return selects 
      && !isEmpty(selects.relationshipCountries) ? 
      {
        relationshipCountries: selects.relationshipCountries,
      }: {};
  },
};

export const generateNewsSearchQuery = {
  interval: (selects?: any) => {
    return selects
      && !isEmpty(selects.calendar)
      && selects.calendar?.start
      && selects.calendar?.end ?
      {
        start: selects.calendar.start,
        end: selects.calendar.end,
      } : {};
  },
  publisher: (selects?: any) => {
    return selects
      && !isEmpty(selects.sources)
      && selects.sources.length > 0 ?
      {
        publishers: selects.sources,
      } : {};
  },
  sectors: (selects?: any) => {
    return selects
      && !isEmpty(selects.sectors)
      && selects.sectors.length > 0 ?
      {
        sectors: selects.sectors,
      } : {};
  },
  scoutAIs: (selects?: any) => {
    return selects
      && !isEmpty(selects.scoutAis)
      && selects.scoutAis.length > 0 ?
      {
        scoutAIs: selects.scoutAis,
      } : {};
  },
  scoutAiTopics: (selects?: any) => {
    return selects
      && !isEmpty(selects.scoutAiTopics)
      && selects.scoutAiTopics.length > 0 ?
      {
        scoutAiTopics: selects.scoutAiTopics,
      } : {};
  },
  locations: (selects?: any) => {
    return selects
      && !isEmpty(selects.countries)
      && selects.countries.length > 0 ?
      {
        mentionedLocations: selects.countries,
      } : {};
  },
  companies: (selects?: any) => {
    return selects
      && !isEmpty(selects.companies)
      && selects.companies.length > 0 ?
      {
        mentionedCompanies: selects.companies,
      } : {};
  },
};

export const restrictedPublisher = (publisher: string) => {
  if (isTheSameString(publisher, ARTICLE_PUBLISHER.SGX) || 
    isTheSameString(publisher, ARTICLE_PUBLISHER.HKEX) || 
    isTheSameString(publisher, ARTICLE_PUBLISHER.EMIS) ||
    isTheSameString(publisher, ARTICLE_PUBLISHER.AUSTRALIAN_FINANCIAL_REVIEW) ||
    isTheSameString(publisher, ARTICLE_PUBLISHER.FINANCIAL_TIMES) ||
    isTheSameString(publisher, ARTICLE_PUBLISHER.ARAI) || 
    isTheSameString(publisher, ARTICLE_PUBLISHER.EXPERT_CONNECT_ASIA) || 
    isTheSameString(publisher, ARTICLE_PUBLISHER.VIETNAM_SECTOR_REPORT)
  ) {
    return true;
  }
  return false;
};

export const getOtherLanguagesData = (article) => {
  const defaultLanguage = getLocalStorageDefaultLanguage();
  if (!isEmpty(article.otherLanguages)) {
    if (defaultLanguage !== LOCALIZATION.DEFAULT_LOCALE) {
      return article.otherLanguages[0];
    }
    return article.otherLanguages.find((language)=>language.language === defaultLanguage);
  }
  return null;
};

export const toDisplayDate = (date: Date | number | string) => {
  return moment(date).format('D MMM YYYY');
};

export const trackingSelectedGuid = (beforeGuid: string[] | number[], afterGuid: string[] | number[], isFromTo?: boolean) => {
  return {
    [isFromTo ? 'from' : 'current']: beforeGuid,
    [isFromTo ? 'to' : 'changed']: afterGuid,
  };
};

export const generateModalAtlasTracking = (trackData: IAtlasTracking, restConfirmObj, restCancelObj) => {
  return {
    confirm: {
      ...trackData,
      ...restConfirmObj,
    },
    cancel: {
      ...trackData,
      ...restCancelObj,
    },
  };
};

/**
 * a simple string array converts the value to case
 * @param list string[]
 * @param lowercase boolean  true:lowercase false:uppercase default: true
 * @returns string[]
 */
export const arrayValueConvertToCase = (list: string[], lowercase: boolean = true) : string[] => {
  return list ? list.map((value) => lowercase ? value.toLocaleLowerCase() : value.toUpperCase()) : [];
};

export const fuzzyFind = (list: string[], value: string): boolean => {
  return !!list.find(item => isTheSameString(item, value));
};

export const getRadarSearchQuery = (radarGuid: string) => {
  const radarQueryStore = useQueryStore(radarGuid);
  return radarQueryStore.get();
};

export const setRadarSearchQuery = (radarGuid: string, searchCriteria: string) => {
  const radarQueryStore = useQueryStore(radarGuid);
  radarQueryStore.set(searchCriteria);
};

export const isCurrentFeed = (query: string) => {
  const location = useLocation() as any;
  return (location.state && location.state[query]?.data && location.state?.isRadarFeedCard);
};

export const isSameArrayItems = (arr1, arr2, key?: string): boolean => {
  const copyArr2 = JSON.parse(JSON.stringify(arr2));
  let result = true;
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (const a1 of arr1) {
    const index = copyArr2.findIndex((a2)=> { return JSON.stringify(key ? a2[key] : a2) === JSON.stringify(key ? a1[key] : a1); });
    if (index === -1) {
      result = false;
      break;
    } else {
      copyArr2.splice(index, 1);
    }   
  }
  return result;
};

export const verticalLineReg = /\|/ig; 

export const orderBy = <T extends string | number >(data: T[], orderByValues: T[]) => {
  let result = [];
  orderByValues.forEach(value => {
    result = result.concat(data.filter(d => d === value));
  });
  return result;
};

export const abortPrevPromise = (data: any) => {
  if (!isEmpty(global['globalAbortPromise'])) {
   
    global['globalAbortPromise'].abort(data);
  }  
};

export const generateAndAbortController = (name: string) : AbortController => {
  const controllerName = `${name  }Controller`;
  const controller: AbortController | undefined = window[controllerName];
  controller && controller.abort();
  window[controllerName] = new AbortController();
  return window[controllerName];
};

export const transformedDateFilterLabel = (start: Date | string, end: Date | string) => {
  return `${toDisplayDate(start)} - ${toDisplayDate(end)}`;
};

const hasFinancialChanged = (financialItem: IFinancialItemProps, aggregate) => {
  return (
    financialItem.fromValue !== undefined && financialItem.toValue !== undefined && (
      (financialItem.fromValue !== financialItem.maxValue && financialItem.fromValue !== aggregate.min) ||
      (financialItem.toValue !== financialItem.maxValue && financialItem.toValue !== aggregate.max)
    )
  );
};

export const constructFinancials = (financials, aggregateFinancial) => {
  const {
    marketCapitalization,
    operatingRevenue,
    totalAssets,
    shareholdersFunds,
    plBeforeTax
  } = financials;

  const localisedTitle = {
    operatingRevenue: FINANCIAL_ADDITIONAL_INFO_LABEL.OPERATING_REVENUE,
    plBeforeTax: FINANCIAL_ADDITIONAL_INFO_LABEL.PL_BEFORE_TAX,
    marketCapitalization: FINANCIAL_ADDITIONAL_INFO_LABEL.MARKET_CAPITALIZATION,
    shareholdersFunds: FINANCIAL_ADDITIONAL_INFO_LABEL.SHAREHOLDERS_FUNDS,
    totalAssets: FINANCIAL_ADDITIONAL_INFO_LABEL.TOTAL_ASSETS,
  };

  let values = [
    constructFinancialFromTo('operatingRevenue', operatingRevenue, localisedTitle.operatingRevenue || EMPTY_STRING, aggregateFinancial),
    constructFinancialFromTo('marketCapitalization', marketCapitalization, localisedTitle.marketCapitalization || EMPTY_STRING, aggregateFinancial),
    constructFinancialFromTo('totalAssets', totalAssets, localisedTitle.totalAssets || EMPTY_STRING, aggregateFinancial),
    constructFinancialFromTo('shareholdersFunds', shareholdersFunds, localisedTitle.shareholdersFunds || EMPTY_STRING, aggregateFinancial),
    constructFinancialFromTo('plBeforeTax', plBeforeTax, localisedTitle.plBeforeTax || EMPTY_STRING, aggregateFinancial),
  ];

  values = values.filter((v) => !isEmpty(v));

  let info = EMPTY_STRING;
  if (isEmpty(values)) {
    return info;
  }

  info = values.length === 1 ? values[0] : `${values.length} selected`;

  return info;
};

const constructFinancialFromTo = (key, financialObj, title: string, aggregateFinancial) => {

  if (!isEmpty(aggregateFinancial) && !hasFinancialChanged(financialObj, aggregateFinancial[key])) {
    return EMPTY_STRING;
  }

  const newFromUnit = financialObj.fromUnit;
  const newToUnit = financialObj.toUnit;
  const fromValue = financialObj.fromValue;
  const toValue = financialObj.toValue;

  const roundNumber = (value: number) => {
    return Math.round(value);
  };
  if (isNaN(fromValue) && isNaN(toValue)) {
    return EMPTY_STRING;
  }
  if (fromValue === null || toValue === null) {
    return EMPTY_STRING;
  }

  const formattedFromValue = FINANCIAL_UNIT.getValueWithUnit(fromValue, newFromUnit);
  const formattedToValue = FINANCIAL_UNIT.getValueWithUnit(toValue, newToUnit);

  return `${title} \$${roundNumber(formattedFromValue).toLocaleString()} - \$${roundNumber(formattedToValue).toLocaleString()}`;
};

export const convertToFinancialItem = (key: string, from: number, to: number) => {

  if (from === undefined && to === undefined) {
    return {
      [key]: FILTER_FINANCIAL_EMPTY_DATA(),
    };
  }
  const localStorageFinancialUnits = getLocalStorageFinancialUnits();
  let fromObject = {};

  if (isNumber(from)) {
    const fromResult = convertToMoneyFormat(
      from,
      localStorageFinancialUnits[key].from,
    );
    fromObject = {
      fromValue: fromResult.value,
      fromUnit: convertStringToFinancialUnitType(fromResult.suffix),
    };
  }

  let toObject = {};
  if (isNumber(to)) {
    const toResult = convertToMoneyFormat(
      to,
      localStorageFinancialUnits[key].to,
    );
    toObject = {
      toValue: toResult.value,
      toUnit: convertStringToFinancialUnitType(toResult.suffix),
    };
  }

  return {
    [key]: {
      ...fromObject,
      ...toObject,
    },
  };
};

export const constructOwnership = (listingStatus, isShowEmptyString) => {
  if (isEmpty(listingStatus)) {
    return isShowEmptyString ? EMPTY_STRING : DASH;
  }
  return listingStatus === COMPANY_CONSTANT.LISTING.STATUS.LISTED
    ? COMPANY_CONSTANT.LISTING.WORDING.LISTED
    : COMPANY_CONSTANT.LISTING.WORDING.UNLISTED;
};

export const constructNumberEmployee = (selectedNumOfEmployees) => {
  const { fromValue, toValue, maxValue, minValue } = selectedNumOfEmployees;
  if (isNaN(fromValue) && isNaN(toValue)) {
    return EMPTY_STRING;
  }
  if (fromValue === null || toValue === null) {
    return EMPTY_STRING;
  }

  if (fromValue === minValue && toValue === maxValue) {
    return EMPTY_STRING;
  }
  
  return `${fromValue.toLocaleString()} - ${toValue.toLocaleString()}`;
};

export const keywordInIsDescriptionOrNameDescription = (query: any) => {
  const { keywordIn, searchStr } = query?.data || {};
  return (keywordIn === COMPANY_CONSTANT.KEYWORD_IN.DESCRIPTION || keywordIn === COMPANY_CONSTANT.KEYWORD_IN.NAMEDESCRIPTION) && !isEmpty(searchStr);
};

export const translationDataTracking = (atlasData) => {
  const dataTracking = {
    targetAttribute: atlasData.targetAttribute,
    atlastracking: {
      uiElement: UI_ELEMENT.LANGUAGE_SWITCH,
      ...atlasData.atlastracking
    },
  };
  return dataTracking;
};

export const getRelationshipLabel = (relationship) => {
  return RelationshipOptions.find(item => item.value === relationship)?.label;
};

export const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || EMPTY_STRING;
};

export const getSwaggerDocUrl = () => {
  const env = window.location.hostname.split('.')[0];
  const docEnv = SWAGGER_ENV_MAP[env];
  return `https://${docEnv !== undefined ? docEnv : `${env  }-`}swagger.scout.asia/api-doc/`;
};

export const getBookmarkCompaniesList = (list) => {
  const bookmarkCompanyListItemsEntityGuid = list?.bookmarkCompanyListItems.map(listItem => {
    return listItem.entityGuid;
  }); 
  return bookmarkCompanyListItemsEntityGuid;
};

/**
 * splitByCharacter
 * Split a string by the provided character and trim the items if needed `/`
 *
 * If the string does not contain the provided character, it will return an array of the default string.
 * If empty string is provided, an empty array will be returned
 * 
 * @param {string} text - A non-empty string to be splitted
 * @param {string} char - A non-empty string that `text` will be splitted by
 * @param {boolean} trim - An optional boolean to indicate if trim operation is needed
 *
 * @returns An array of string.
 */
export const splitByCharacter = (text: string, char: string, trim = false) => {
  if (isEmpty(text)) {
    return [];
  }
  let splittedText = text.split(char);
  if (trim) {
    splittedText = splittedText.map(txt => txt.trim());
  }

  return splittedText;
};

/**
 * openLinkInNewTab
 * Open a provided url in a new tab with window.open and reset the opener safely.\
 * If no url is provided, return void
 * 
 * @param {string} url - A non-empty string of the url to be opened in the new tab
 *
 * @returns void.
 */
export const openLinkInNewTab = (url: string) => {
  if (isEmpty(url)) {
    return;
  }

  window.open(url, '_blank');
  window.opener = null;
};
 
export const getSubcriptionFeatureReport = (subcriptionFeaturePermissionModel = []) => {
  const newsReportPublisher = filter(NEWS_REPORT_PUBLISHER, (key) => subcriptionFeaturePermissionModel.includes(key));
  return newsReportPublisher;
};

export const getReportSource = (url) => {
  if(ARAIRegx.test(url)) {
    return 'Ashu Research';
  }
  if (ExpertConnectAsiaRegx.test(url)) {
    return 'ExpertConnect Asia';
  }
  if (GLINRegx.test(url)) {
    return 'Vietnam Sector Report';
  }
  return 'Emis Insight Industry reports';
};

export const subscriptionReportCode = (publisher) => {
  if (publisher?.toLowerCase() === ARTICLE_PUBLISHER.EMIS.toLowerCase()) {
    return newsConst.emis?.code;
  }
  if (publisher?.toLowerCase() === ARTICLE_PUBLISHER.ARAI.toLowerCase()) {
    return newsConst.arai?.code;
  }
  if (publisher?.toLowerCase() === ARTICLE_PUBLISHER.VIETNAM_SECTOR_REPORT.toLowerCase()) {
    return newsConst.glin?.code;
  }
  return newsConst.expertConnectAsia?.code;
};

export const getReportGuid = (url, isGLINReport) => {
  if (isGLINReport) {
    const match = url?.match(getUrlForGlinRegex);
    return match[1]; 
  }
  return `${url.split('/')[2]}`;
};


export const isUserFromChina = () => {
  return !!JSON.parse(localStorage.getItem('userCountry') || '[]').find(x => x.toUpperCase() === COUNTRIES.CHINA);
};

export const replaceChinaRegionName = (value: string) => {
  if(value && isUserFromChina()) {
    return value
      .replace(new RegExp(CHINA_REGIONS.HongKong, 'g'), `${CHINA_REGIONS.HongKong}, SAR`)
      .replace(new RegExp(CHINA_REGIONS.Macau, 'g'), `${CHINA_REGIONS.Macau}, SAR`)
      .replace(new RegExp(CHINA_REGIONS.Taiwan, 'g'), `${CHINA_REGIONS.Taiwan}, China`);
  }
  return value;
};

export const getNewsFilterSearchCriteria = (filterArray, guid: string) => {
  const currentFilterItem = filterArray.filter(item => {
    return item.guid.toLowerCase() === guid.toLowerCase();
  });
  const searchQuery = JSON.parse(!isEmpty(currentFilterItem) ? currentFilterItem[0]?.searchTerm: '{}');
  
  return {
    newsQuery: {
      data: searchQuery
    },
    companyQuery: {
      data: {
        searchStr: searchQuery?.searchStr
      }
    },
    isFromNewsSearch: true,
  };
  
};

export const getCompanyListForNewsSearchCriteria = (filterArray, guid: string) => {
  const currentFilterItem = filterArray.filter(item => {
    return item.guid.toLowerCase() === guid.toLowerCase();
  });

  if (!isEmpty(currentFilterItem) && isEmpty(currentFilterItem[0]?.bookmarkCompanyListItems)) {
    return {};
  }
  
  const companyGuids = !isEmpty(currentFilterItem) ? currentFilterItem[0]?.bookmarkCompanyListItems.map(listItem => listItem.entityGuid) : [];       
  return {
    newsQuery: {
      data: {
        mentionedCompanies: companyGuids?.map((guids) => guids?.toLowerCase()),
      },
    },
    isFromCompanyList: true,
  };
};

export const isNikkeiPublisher = (publisher) => {
  return !!NIKKEI_PUBLISHERS.find(x => isTheSameString(x, publisher));
}

export const shouldTranslation = (articles: IStandardArticle[]) => {
    return articles.length > 0 && articles.find(x => x?.translatedContents?.length > 0);
}

export const shouldTranslationArticle = (article: IStandardArticle, defaultLanguage: string, language = 'ja') => {
  if(defaultLanguage !== language) {
     return null;
  }
  if(article?.translatedContents?.length > 0) {
    return article.translatedContents.find(x => x.language === language);
  };
  return null;
}