import { SCOUT_AI, DATA } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState, sendPostRequestWithKey } from 'skyeye-fe-common-util';
import { SCOUT_AI_TYPE, ZERO, LOCAL_STORAGE, getScoutAiFromJson, SCOUT_AI_STATUS, SCOUT_AI_CUSTOM_CODE, SCOUT_AI_TOPICS_CREATE_ITEM } from '@constants/common';
import { mapValues, keyBy } from 'lodash';
import { isEqualCaseInsensitive } from '@react/utils/common.util';
import createAction from './actionCreator';
import { increaseProgress, decreaseProgress } from './progress.actions';
import { defaultPromise } from './base.action';

interface IScoutDataStorage {
  list: any[],
  expire: number,
  userGuid: string,
}

const saveToLocalStorage = (data: any[], userGuid: string) => {
  const expiredDate = new Date();
  expiredDate.setHours(expiredDate.getHours() + 2);
  const scoutData: IScoutDataStorage = {
    list: [...data],
    expire: expiredDate.getTime(),
    userGuid,
  };
  localStorage.setItem(LOCAL_STORAGE.SCOUT_AI_CACHE, JSON.stringify(scoutData));
};

const setScoutDataToStore = (data, dispatch) => {
  const standardsFromJson = getScoutAiFromJson();
  const customs = data.filter((s) => s.scoutType === SCOUT_AI_TYPE.CUSTOM);
  const customMappings = mapValues(keyBy(customs || [], (s) => s.guid), (s) => s);
  const standards = data.filter((s) => s.scoutType === SCOUT_AI_TYPE.STANDARD);
  const standardMappings = mapValues(keyBy(standards || [], (s) => s.guid), (s) => s);

  const CUSTOM_LEVEL = 2;
  const standardData = standardsFromJson.filter(x => standards.find(s => s.guid.toLowerCase() === x.code.toLowerCase()) ).map((item) => {
    const _standards = standards.find((x) => x.guid == item.code);

    return {
      ...item,
      count: 0,
      scoutType: SCOUT_AI_TYPE.STANDARD,
      description: _standards?.description,
      label: _standards?.name,
      name: _standards?.name
    };
  });

  const customGraduated = customs
    .filter((scoutAi) => scoutAi.isPublic && scoutAi.status === SCOUT_AI_STATUS.GRADUATE)
    .map((item) => {
      return {
        level: CUSTOM_LEVEL,
        count: 0,
        parent: SCOUT_AI_CUSTOM_CODE,
        code: item.guid,
        description: item.description,
        label: item.name,
        scoutType: item.scoutType
      };
    });

  const allList = [{ ...SCOUT_AI_TOPICS_CREATE_ITEM }, ...standardData, ...customGraduated];
  const allMappings = mapValues(keyBy(allList || [], (s) => s.code), (s) => s);

  dispatch(createAction(DATA.SCOUTAI.STANDARD.LIST, {
    customs,
    customMappings,
    standards,
    standardMappings,
    allList,
    allMappings
  }));
};

export const initScoutAis = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const userGuid = getState().getIn(['Account', 'accountInfo', 'userGuid']);

    let isSuccess = false;
    let responseFromServer = {};
    const scoutDataFromStorage: IScoutDataStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SCOUT_AI_CACHE));
    const isNotExpired = scoutDataFromStorage ? (scoutDataFromStorage.expire - (new Date().getTime()) > ZERO) : false;

    if (
      scoutDataFromStorage &&
      isEqualCaseInsensitive(scoutDataFromStorage.userGuid, userGuid) &&
      isNotExpired
    ) {
      setScoutDataToStore(scoutDataFromStorage.list, dispatch);
      isSuccess = true;
    } else {
      dispatch(createAction(DATA.SCOUTAI.IN_PROGRESS, { inProgress: true }));

      let response = null;
      let request = {};
      if (userGuid) {
        request = {
          userGuid,
          scoutType: [SCOUT_AI_TYPE.CUSTOM, SCOUT_AI_TYPE.STANDARD],
          isPagination: false,
        };
        response = await sendPostRequestWithState(API_ENDPOINT.LIST_SCOUT_AI, request, getState);
      } else {
        request = {
          scoutType: [SCOUT_AI_TYPE.STANDARD],
          isPagination: false,
        };
        response = await sendPostRequestWithKey(API_ENDPOINT.DATA_SCOUT_AI, request);
      }

      if (response && response.isSuccess) {
        const data = response.data || [];
        const onlyPublicData = data.filter(scout => scout.isPublic || scout.scoutType === SCOUT_AI_TYPE.CUSTOM);
        saveToLocalStorage(onlyPublicData, userGuid);
        setScoutDataToStore(onlyPublicData, dispatch);
        isSuccess = true;
      } else {
        isSuccess = false;
        responseFromServer = response;
      }
    }

    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(DATA.SCOUTAI.IN_PROGRESS, { inProgress: false }));
      if (isSuccess) {
        resolve(null);
      } else {
        reject(responseFromServer);
      }
    });
  };
};

export const listScoutAI = (userGuid: string) => {
  const request = {
    userGuid,
    scoutType: [SCOUT_AI_TYPE.CUSTOM, SCOUT_AI_TYPE.STANDARD],
    isPagination: true,
    orderBy: 'createdDate',
    order: 'DESC',
    select: 200,
  };
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.LIST_SCOUT_AI, request, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        const data = response.data || [];
        const onlyPublicData = data.filter(scout => scout.isPublic || scout.scoutType === SCOUT_AI_TYPE.CUSTOM);
        dispatch(createAction(SCOUT_AI.SCOUT_AI_LIST, { data: onlyPublicData }));
        saveToLocalStorage(onlyPublicData, userGuid);
        setScoutDataToStore(onlyPublicData, dispatch);
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const searchStandardScoutAIs = (scoutAIReq) => {
  const { userGuid, searchStr, select } = scoutAIReq;
  const request = {
    userGuid,
    scoutType: [SCOUT_AI_TYPE.STANDARD],
    isPagination: false,
    orderBy: 'name',
    order: 'ASC',
    select,
    name: searchStr,
  };
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(
      API_ENDPOINT.LIST_SCOUT_AI, request, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.SCOUT_AI_STANDARD_LIST,
          { data: response.data }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const getScoutAIs = (scoutAIReq = {}) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_SCOUT_AI_BY_IDS, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.SCOUT_AI_LIST, { data: response.data }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const getScoutAI = (scoutAIReq = {}) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_SCOUT_AI, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.SCOUT_AI_RETRIEVE, { data: response.data }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const getScoutAIResult = (scoutAIReq = {}) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_SCOUTAI_RESULT, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.SCOUT_AI_RESULT_RETRIEVE, { data: response.data }));
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  };
};

export const getScoutAIResultWithContent = (scoutAIReq = {}) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_SCOUTAI_RESULT_WITH_CONTENT, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.SCOUT_AI_RESULT_RETRIEVE, { data: response.data, totalCount: response.totalCount }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const getArticleUntrainedScout = (scoutAIReq = {}) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_ARTICLE_UNTRAINED_SCOUT, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.SCOUT_AI_ARTICLE_UNTRAINED, { data: response.data }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const addScoutAI = (scoutAIReq = {}) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.ADD_SCOUT_AI, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        resolve({
          data: response.data,
        });
      } else {
        reject(response);
      }
    });
  };
};

export const updateScoutAI = (scoutAIReq = {}) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.UPDATE_SCOUT_AI, scoutAIReq, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.TRAINING_AI_UPDATE, { scoutAIReq }));
        resolve('');
      } else {
        reject(response);
      }
    });
  };
};

export const deleteScoutAI = (scoutAIReq = {}) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.DELETE_SCOUT_AI, scoutAIReq, getState);
    return defaultPromise(response);
  };
};


export const trainScoutAI = (scoutAIReq) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.BULK_TRAIN_SCOUTAI, scoutAIReq, getState);
    return defaultPromise(response);
  };
};

export const initialTrainScout = (scoutAIReq) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.INITIAL_TRAIN_SCOUTAI, scoutAIReq, getState);
    return defaultPromise(response);
  };
};

export const continuousTrainScout = (scoutAIReq) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.CONTINUOUS_TRAIN_SCOUTAI, scoutAIReq, getState);
    return defaultPromise(response);
  };
};


export const searchScoutArticles = (newsListReq) => {
  const request = {
    ...newsListReq,
    aggregation: true,
  };
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_TRAINING_ARTICLE, request, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(SCOUT_AI.TRAINING_ARTICLE_LIST, { data: response.data, totalCount: response.totalCount }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const getScoutVisualisation = (guid) => {
  const request = {
    guid,
    dim: 2,
  };
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.PUBLIC_VISUALISATION, request, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(DATA.SCOUTAI.VISUALISATION.LIST, { data: response.data }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};
