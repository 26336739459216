import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';

export const getJWTToken = (userToken: string, accountInfo: any) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.JWT_TOKEN, { userToken, accountInfo }, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};
