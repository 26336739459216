import { TOP_PAGE } from '@constants/actions';
import { FilterProgressStatus } from '@constants/common';
import { Map } from 'immutable';

const initialState = Map({
  companyMention: {
    list: [],
  },
  filter: {
    sector: null,
    country: null,
    progress: FilterProgressStatus.None
  },
});

const TopPage = (state = initialState, action) => {
  switch (action.type) {
    case TOP_PAGE.COMPANY_MENTION.GET_RECENT_COMPANY_MENTION:
      return state.setIn(['companyMention', 'list'], action.payload.data);
    case TOP_PAGE.FILTER.UPDATE_FILTER:
      return state.setIn(['filter'], action.payload);
    case TOP_PAGE.FILTER.UPDATE_FILTER_PROGRESS:
      return state.setIn(['filter', 'progress'], action.payload);
    default:
      return state;
  }
};

export default TopPage;