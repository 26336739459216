import { LIBRARY, PROGRESS } from '@constants/actions';
import { ENTITY_NAVBAR_TAB } from '@constants/common';
import { Map } from 'immutable';

const initialState = Map({
  currentTab: ENTITY_NAVBAR_TAB.OVERVIEW,
  overview: {
    counts: [],
    totalCompanyCount: 0,
    totalNewsCount: 0,
    totalReportsCount: 0,
  },
  inProgress: false,
});

const Library = (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS.INCREASE_PROGRESS: {
      return state.set('inProgress', true);
    }
    case PROGRESS.DECREASE_PROGRESS: {
      return state.set('inProgress', false);
    }
    case LIBRARY.OVERVIEW_COUNT:
      return state.set('overview', action.payload.data);
    case LIBRARY.CURRENT_TAB:
      return state.set('currentTab', action.payload.currentTab);
    default:
      return state;
  }
};

export default Library;