import { ACTION } from '@constants/actions';
import { EMPTY_STRING } from '@constants/common';
import { convertToFinancialItem, transformQuery } from '@react/utils/common.util';
import createAction from '../actionCreator';
import { updateSearchKeyword } from '../quickSearch.actions';
import { resetCompanyFilters, updateCompanySelectedCountriesRegions, updateRelationship, updateSelectedBusinessLines, updateSelectedCompanySectors, updateSelectedEmployeeNum, updateSelectedFinancials, updateSelectedKeywordIn, updateSelectedOwnership } from './companyFilters.actions';
import {
  resetNewsFilters,
  updateNewsSelectedCountriesRegions,
  updateSelectedMentionedCompanies,
  updateSelectedNewsSectors,
  updateSelectedPublicationDate,
  updateSelectedScoutAIs,
  updateSelectedScoutAIsTopics,
  updateSelectedSource,
} from './newsFilters.actions';

export const updateSearchQueryData = () => {
  return (dispatch, getState) => {
    const searchFlag = getState().getIn(['FilterBar', 'searchFlag']);
    dispatch(updateNewsQueryData());
    dispatch(updateCompanyQueryData());
    dispatch(createAction(ACTION.FILTER_BAR.UPDATE_SEARCH_FLAG, !searchFlag));
  };
};

export const updateNewsQueryData = () => {
  return (dispatch, getState) => {

    const additionalInfo = {};
    const publicationDateFilter = getState().getIn([
      'NewsFilter',
      'selectedCalendar',
    ]);
    const selectedSectors = getState().getIn(['NewsFilter', 'selectedNewsSectors']);
    const selectedSources = getState().getIn(['NewsFilter', 'selectedSources']);
    const selectedScoutAIs = getState().getIn(['NewsFilter', 'selectedScoutAIs']);
    const selectedScoutAiTopics = getState().getIn(['NewsFilter', 'selectedNewScoutAiTopics']);
    const selectedMentionedCompanies = getState().getIn(['NewsFilter', 'selectedMentionedCompanies']);
    const selectedCountriesRegions = getState().getIn(['NewsFilter', 'selectedCountriesRegions']);
    const selectedFilters = {
      calendar: publicationDateFilter,
      sectors: selectedSectors,
      scoutAis: selectedScoutAIs,
      scoutAiTopics: selectedScoutAiTopics,
      companies: selectedMentionedCompanies,
      sources: selectedSources,
      countries: selectedCountriesRegions,
    };
    const searchKeyword = getState().getIn(['QuickSearch', 'keyword']);

    const searchQuery = {
      searchStr: searchKeyword,
      ...selectedFilters,
    };
    const newsQueryObj = transformQuery(searchQuery, additionalInfo, 'article');
    dispatch(createAction(ACTION.FILTER_BAR.UPDATE_NEWS_QUERY, newsQueryObj));
    return newsQueryObj;
  };
};

export const updateCompanyQueryData = () => {
  return (dispatch, getState) => {
    const additionalInfo = {};
    const searchKeyword = getState().getIn(['QuickSearch', 'keyword']);
    const selectedSectors = getState().getIn(['CompanyFilter', 'selectedSectors']);
    const selectedBusinessLines = getState().getIn(['CompanyFilter', 'selectedBusinessLines']);
    const selectedCountriesRegions = getState().getIn(['CompanyFilter', 'selectedCountriesRegions']);
    const selectedFinancials = getState().getIn(['CompanyFilter', 'selectedFinancials']);
    const selectedOwnership = getState().getIn(['CompanyFilter', 'selectedOwnership']);
    const selectedNumOfEmployees = getState().getIn(['CompanyFilter', 'selectedNumOfEmployees']);
    const selectedKeywordIn = getState().getIn(['CompanyFilter', 'selectedKeywordIn']);
    const selectedRelationshipSearch = getState().getIn(['CompanyFilter', 'selectedRelationshipSearch']);
    const selectedFilters = {
      sectors: selectedSectors,
      businessLines: selectedBusinessLines,
      countries: selectedCountriesRegions,
      financials: selectedFinancials,
      ownership: selectedOwnership,
      numberEmployees: selectedNumOfEmployees,
      keywordIn: selectedKeywordIn?.value || EMPTY_STRING,
      relationship: selectedRelationshipSearch.relationship,
      relationshipCountries: selectedRelationshipSearch.countriesRegions,
    };
    const searchQuery = {
      searchStr: searchKeyword,
      ...selectedFilters,
    };
    const companyQueryObj = transformQuery(
      searchQuery,
      additionalInfo,
      'company'
    );
    dispatch(
      createAction(ACTION.FILTER_BAR.UPDATE_COMPANY_QUERY, companyQueryObj)
    );
    return companyQueryObj;
  };
};

export const updateNewsQueryDataFromLocationState = (newsQuery) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER_BAR.UPDATE_NEWS_QUERY, newsQuery));
    const { data = {} } = newsQuery || {};

    const {
      searchStr = EMPTY_STRING,
      start = null,
      end = null,
      publishers = [],
      sectors = [],
      scoutAIs = [],
      scoutAiTopics = [],
      mentionedLocations = [],
      mentionedCompanies = [],
    } = data;

    dispatch(updateSearchKeyword(searchStr));

    dispatch(updateSelectedPublicationDate({ start, end }));

    dispatch(updateSelectedSource(publishers));

    dispatch(updateSelectedNewsSectors(sectors));

    dispatch(updateSelectedScoutAIs(scoutAIs));

    dispatch(updateSelectedScoutAIsTopics(scoutAiTopics));

    dispatch(updateSelectedMentionedCompanies(mentionedCompanies));

    dispatch(updateNewsSelectedCountriesRegions(mentionedLocations));

  };
};

export const updateCompanyQueryDataFromLocationState = (companyQuery) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER_BAR.UPDATE_COMPANY_QUERY, companyQuery));
    const { data = {} } = companyQuery || {};

    const {
      searchStr = EMPTY_STRING,
      naics = [], // businessLines
      countries = [],
      sectors = [],
      listingStatus = EMPTY_STRING, // ownership
      numOfEmployeesFrom = null,
      numOfEmployeesTo = null,
      operatingRevenueFrom,
      operatingRevenueTo,
      marketCapitalizationFrom,
      marketCapitalizationTo,
      totalAssetsFrom,
      totalAssetsTo,
      shareholdersFundsFrom,
      shareholdersFundsTo,
      plBeforeTaxFrom,
      plBeforeTaxTo,
      keywordIn = {},
      relationship = '',
      relationshipCountries=[],
    } = data;

    dispatch(updateSearchKeyword(searchStr));

    dispatch(updateSelectedCompanySectors(sectors));

    dispatch(updateSelectedBusinessLines(naics));

    dispatch(updateCompanySelectedCountriesRegions(countries));

    const financials = {
      ...convertToFinancialItem('marketCapitalization', marketCapitalizationFrom, marketCapitalizationTo),
      ...convertToFinancialItem('operatingRevenue', operatingRevenueFrom, operatingRevenueTo),
      ...convertToFinancialItem('totalAssets', totalAssetsFrom, totalAssetsTo),
      ...convertToFinancialItem('shareholdersFunds', shareholdersFundsFrom, shareholdersFundsTo),
      ...convertToFinancialItem('plBeforeTax', plBeforeTaxFrom, plBeforeTaxTo)
    };

    dispatch(updateSelectedFinancials(financials));

    dispatch(updateSelectedOwnership(listingStatus));

    dispatch(updateSelectedEmployeeNum({ fromValue: numOfEmployeesFrom, toValue: numOfEmployeesTo }));

    dispatch(updateSelectedKeywordIn(keywordIn));

    const relationshipPrams = {
      relationship,
      countriesRegions: relationshipCountries,
    };
    dispatch(updateRelationship(relationshipPrams));

  };
};

export const resetFilters = () => {
  return (dispatch, getState) => {
    const queryObj = {
      data: {},
    };
    dispatch(createAction(ACTION.FILTER_BAR.UPDATE_NEWS_QUERY, queryObj));
    dispatch(createAction(ACTION.FILTER_BAR.UPDATE_COMPANY_QUERY, queryObj));
    dispatch(resetNewsFilters());
    dispatch(resetCompanyFilters());
    dispatch(updateSearchKeyword(EMPTY_STRING));
  };
};
