import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { BORDER, FONT, NON_ENGLISH_FONT_STACK, QUICK_SEARCH } from '@constants/style';

const TITLE_LINE_HEIGHT = 30;
const TITLE_LINE_CLAMP = 2;
export const TITLE_MAX_HEIGHT = TITLE_LINE_HEIGHT * TITLE_LINE_CLAMP;
const SUMMARY_LINE_HEIGHT = 20;
const SUMMARY_LINE_CLAMP = 3;
export const SUMMARY_MAX_HEIGHT = SUMMARY_LINE_HEIGHT * SUMMARY_LINE_CLAMP;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 170,
      display: 'flex',
      borderRadius: 0,
      boxShadow: '0 2px 4px 0 rgba(100,109,130,.14), 0 0 1px 0 rgba(100,109,130,.1)',
      '&:hover': {
        boxShadow: '0 0 2px 0 rgba(100, 109, 130, 0.12), 0 8px 14px 0 rgba(100, 109, 130, 0.16)',
        transition: 'all 0.3s ease-out',
      },
      '@media print': {
        position: 'relative',
        pageBreakInside: 'avoid',
      },
    },
    downloadRoot: {
      border: '1px solid #f0f0f0',
      padding: '12px 24px !important',
    },
    recentArticle: {
      paddingLeft: '12px',
      borderLeft: `${theme.spacing(0.5)} solid ${FONT.COLOR.PRIMARY.MAIN}`,
    },
    article: {
      width: '60%',
      padding: '10px 16px',
      cursor: 'pointer',
    },
    sources: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: theme.spacing(1),
      },
      '& span': {
        color: FONT.COLOR.CAPTION.MAIN,
        fontSize: 12,
        fontWeight: FONT.WEIGHT.MEDIUM,
        lineHeight: '14px',
        letterSpacing: 1,
        textTransform: 'uppercase',
      },
    },
    titleOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxOrient: 'vertical',
      display: '-webkit-box',
      lineClamp: TITLE_LINE_CLAMP,
    },
    titleNONEnglish: {
      fontSize: '20px',
      fontFamily: NON_ENGLISH_FONT_STACK,
    },
    titleEnglish: {
      fontSize: '22px',
    },
    title: {
      margin: '9px 0px',
      fontWeight: FONT.WEIGHT.REGULAR,
      lineHeight: '24px',
      position: 'relative',
      maxHeight: `${TITLE_MAX_HEIGHT}px`,
    },
    titleLink: {
      color: `${FONT.COLOR.TEXT.MAIN} !important`,
      textDecoration: 'none',
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
      '&:visited': {
        color: `${FONT.COLOR.CAPTION.MAIN} !important`,
      },
    },
    titleText: {
      color: `${FONT.COLOR.TEXT.MAIN} !important`,
      textDecoration: 'none !important',
    },
    downloadTitleText: {
      color: `${FONT.COLOR.TEXT.MAIN} !important`,
      textDecoration: 'none !important',
      fontSize: 18,
    },
    titleEmi: {
      color: `${FONT.COLOR.TEXT.MAIN} !important`,
      textDecoration: 'none !important',
      '&:hover': {
        color: '#0A7A73 !important',
        textDecoration: 'underline !important',
        cursor: 'pointer',
      },
      '&:visited': {
        color: '#878787 !important',
      },      
    },
    summaryContainerOverflow: {
      overflow: 'hidden',
      '&:after': {
        content: '"..."',
        display: 'inline-block',
        position: 'absolute',
        height: `${SUMMARY_LINE_HEIGHT}px`,
        background: 'white',
        right: 0,
        bottom: 0,
        padding: '0 4px',
      },
    },
    summaryContainer: {
      lineHeight: `${SUMMARY_LINE_HEIGHT}px`,
      position: 'relative',
      maxHeight: `${SUMMARY_MAX_HEIGHT}px`,
    },
    summaryJapanese: {
      fontSize: 14,
    },
    summaryEnglish: {
      fontSize: 16,
    },
    summary: {
      color: '#707070',
      lineHeight: `${SUMMARY_LINE_HEIGHT}px`,
    },
    summaryNotAvailable: {
      textTransform: 'uppercase',
      fontWeight: FONT.WEIGHT.MEDIUM,
      color: '#a1a1a1',
      fontSize: '14px',
      paddingTop: '2px',
      lineHeight: `${SUMMARY_LINE_HEIGHT}px`,
    },
    mentions: {
      width: '40%',
      display: 'inline-block',
      borderLeft: `1px solid ${BORDER.COLOR}`,
      padding: '10px 16px',
      position: 'relative',
      '&:last-child': {
        paddingBottom: 10,
      },
      '&:after, &:before': {
        right: '100%',
        top: '50%',
        border: 'solid transparent',
        content: '" "',
      	height: 0,
      	width: 0,
      	position: 'absolute',
      	pointerEvents: 'none',
      },
      '&:after': {
      	borderColor: 'rgba(136, 183, 213, 0)',
      	borderRightColor: 'white',
      	borderWidth: '10px',
      	marginTop: '-10px',
      	marginRight: '-1px',
      },
      '&:before': {
      	borderColor: `transparent ${BORDER.COLOR} transparent transparent`,
      	borderRightColor: BORDER.COLOR,
      	borderWidth: '10px',
        marginTop: '-10px',
      },
    },
    scoutAI: {
      borderBottom: `1px solid ${BORDER.COLOR}`,
      height: '50%',
      overflow: 'hidden',
    },
    mentionHeader: {
      fontSize: '0.875rem',
      fontWeight: FONT.WEIGHT.MEDIUM,
      textTransform: 'uppercase',
      '& > div': {
        lineHeight: 1.43,
      },
    },
    scoutAITags: {
      height: '75%',
      overflow: 'auto',
    },
    scoutAITag: {
      fontSize: 12,
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      borderRadius: 12,
      textTransform: 'none',
      marginRight: 6,
      marginBottom: 6,
      backgroundColor: '#f0f0f0 !important',
      color: '#404040 !important',
      padding: '0 12px',
      boxShadow: 'none',
    },
    companies: {
      marginTop: 5,
    },
    companiesLink: {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    companiesLabel: {
      color: '#5c5c5c',
    },
    translationIcon: {
      verticalAlign: 'middle',
      marginLeft: '10px',
    },
    translationIconFixed: {
      position: 'absolute',
      right: 0,
      top: '41px',
    },
    titleContainerOverflow: {
      marginRight: theme.spacing(3),
      '&:after': {
        content: '"..."',
        display: 'inline-block',
        position: 'absolute',
        height: `${TITLE_LINE_HEIGHT}px`,
        right: 0,
        bottom: 0,
        padding: '0 8px',
        marginRight: theme.spacing(2),
      },
      '@supports (-webkit-line-clamp: 1)': {
        marginRight: theme.spacing(2),
        '&:after': {
          display: 'none !important',
        },
      },
    },
    downloadListItem: {
      border: '1px solid #f0f0f0',
      '& p': {
        fontSize: '0.875rem',
      },
    },
    downloadTitle: {
      '& a': {
        fontSize: '1.125rem',
      },
    },
    translationIconDownload: {
      marginLeft: '2px',
      width: '2rem',
    },
    compactCard: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
      },
    },
    card: {
      background: '#EEF4F6',
      boxShadow: 'none !important',
    },
    cardContent: {
      padding: '8px 16px !important',
      '& > div': {
        alignItems: 'center',
      },
    },
    titleGrid: {
      display: 'flex',
      alignItems: 'center',
      '& > button': {
        marginRight: 8,
      },
    },
    titleBox: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& > div': {
        lineClamp: 1,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#404040',
        margin: '4px 0',
        '& > div > span > a': {
          textDecoration: 'none !important',
          cursor: 'pointer',
        },
      },
    },
    titleStyle: {
      '& > div > div > span > a': {
        textDecoration: 'none !important',
        cursor: 'pointer',
      },
    },
    formatFont: {
      '& > *': {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#707070',
      },
    },
    ExternalsTagContent: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '12px',
      cursor: 'pointer',
      fontSize: '10px',
      marginTop: '4px',
      color: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
      '&:hover >span, &:hover > svg': {
        color: QUICK_SEARCH.COLOR.FONT.DARK,
        fill: QUICK_SEARCH.COLOR.FONT.DARK,
      }
    },
    FTexternalLink: {
      marginRight: '6px',
    },
    openInNewIcon: {
      width: '12px',
      height: '12px',
      fill: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
    },
    publisherGird: {
      paddingLeft: '16px',
    },
    dataGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',
        '&:first-child': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          '& > span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export default useStyles;