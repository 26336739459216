import { BOOKMARK, RADAR } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import {
  BOOKMARK_ENTITY_TYPE,
  BOOKMARK_LISTS_TYPE,
  BOOKMARK_LIST_ITEM_TYPE,
  BOOKMARK_NAVBAR_TAB,
  RADAR_SEARCH_TYPE,
} from '@constants/common';
import {
  IContentListItem,
  IBookmarkNews,
  IBookmarkSnackBar,
  IAddNewsToBookmarkRequest,
  IBookmarkCompany,
  IBookmarkFilter,
} from '@models/bookmark';
import { getGuid, getUserGuid } from '@redux/selectors/account.selector';
import { isEmpty } from 'lodash';
import {
  sendPostRequestWithKey,
  sendPostRequestWithState,
} from 'skyeye-fe-common-util';
import createAction from './actionCreator';
import { radarEmailAlertsList } from './radar.actions';

export const setBookmarkDetail = (
  bookmark: IBookmarkNews | IBookmarkCompany | IBookmarkFilter,
  tab
) => {
  return createAction(BOOKMARK.SET_BOOKMARK_DETAIL, { bookmark, tab });
};

export const setBookmarkListProgress = (tab: string, pending: boolean) => {
  return createAction(BOOKMARK.SET_BOOKMARK_LIST_IN_PROGRESS, {
    bookmarkListInprogress: pending,
    tab,
  });
};

export const setSelectedBookmarkItems = (bookmarkItems: IContentListItem[]) => {
  return createAction(BOOKMARK.SET_BOOKMARK_SELECTED_ITEMS, { bookmarkItems });
};

export const selectBookmarkAll = (checked: boolean) => {
  return createAction(BOOKMARK.SELECT_BOOKMARK_ALL, { checked });
};

export const setBookmarkSnackBar = (snackBar: IBookmarkSnackBar) => {
  return createAction(BOOKMARK.SET_BOOKMARK_SNACK_BAR, { snackBar });
};

export const getContentList = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(setBookmarkListProgress(BOOKMARK_NAVBAR_TAB.NEWS, true));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_CONTENTLIST_LIST,
      { select: 1000 },
      getState
    );
    return new Promise(async (resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.GET_BOOKMARK_LISTS, {
            lists: response.data,
            tab: BOOKMARK_LIST_ITEM_TYPE.NEWS,
          })
        );
        dispatch(setBookmarkListProgress(BOOKMARK_NAVBAR_TAB.NEWS, false));
        resolve(response.data);
      } else {
        dispatch(setBookmarkListProgress(BOOKMARK_NAVBAR_TAB.NEWS, false));
        reject(response);
      }
    });
  };
};

export const getCompanyLists = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(setBookmarkListProgress(BOOKMARK_NAVBAR_TAB.COMPANIES, true));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_LIST,
      { select: 1000 },
      getState
    );
    return new Promise(async (resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.GET_BOOKMARK_LISTS, {
            lists: response.data,
            tab: BOOKMARK_LIST_ITEM_TYPE.COMPANIES,
          })
        );
        dispatch(setBookmarkListProgress(BOOKMARK_NAVBAR_TAB.COMPANIES, false));
        resolve(response.data);
      } else {
        dispatch(setBookmarkListProgress(BOOKMARK_NAVBAR_TAB.COMPANIES, false));
        reject(response);
      }
    });
  };
};

export const getFilterList = (type?: string) => {
  const tab =
    type === BOOKMARK_ENTITY_TYPE.ARTICLE
      ? BOOKMARK_NAVBAR_TAB.NEWSFILTERS
      : BOOKMARK_NAVBAR_TAB.COMPANIESFILTER;
  return async function (dispatch: any, getState: any) {
    dispatch(setBookmarkListProgress(tab, true));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_FILTERLIST_LIST,
      { userGuid: getUserGuid(getState()), searchType: type },
      getState
    );
    return new Promise(async (resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.GET_BOOKMARK_FILTERS_LIST, {
            lists: response.data,
            type,
          })
        );
        dispatch(setBookmarkListProgress(tab, false));
        resolve(response);
      } else {
        dispatch(setBookmarkListProgress(tab, false));
        reject(response);
      }
    });
  };
};

export const createBookmarkList = (data, noSetDetail: boolean = false) => {
  return async function (dispatch, getState) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_CONTENTLIST_CREATE,
      data,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.CREATE_BOOKMARK_LIST, {
            list: response.data,
            tab: BOOKMARK_LIST_ITEM_TYPE.NEWS,
          })
        );
        !noSetDetail &&
          dispatch(
            setBookmarkDetail(response.data, BOOKMARK_LIST_ITEM_TYPE.NEWS)
          );
        if (response.data.isDefault) {
          dispatch(
            createAction(BOOKMARK.SET_DEFAULT_VALUE, {
              guid: response.data.guid,
              type: BOOKMARK_LIST_ITEM_TYPE.NEWS,
              currentDefault: true,
            })
          );
        }
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  };
};

export const createCompanyList = (req, noSetDetail: boolean = false) => {
  return async function (dispatch, getState) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_CREATE,
      req,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(radarEmailAlertsList());
        dispatch(
          createAction(BOOKMARK.CREATE_BOOKMARK_LIST, {
            list: response.data,
            tab: BOOKMARK_LIST_ITEM_TYPE.COMPANIES,
          })
        );
        !noSetDetail &&
          dispatch(
            setBookmarkDetail(response.data, BOOKMARK_LIST_ITEM_TYPE.COMPANIES)
          );
        if (req.isDefault) {
          dispatch(
            createAction(BOOKMARK.SET_DEFAULT_VALUE, {
              guid: response.data.guid,
              type: BOOKMARK_LIST_ITEM_TYPE.COMPANIES,
              currentDefault: true,
            })
          );
        }
        if (!isEmpty(req.entities)) {
          const companyListItemIds = req.entities.map((item) => ({
            bookmarkCompanyListGuid: response.data.guid,
            entityGuid: item.entityGuid,
          }));
          dispatch(
            createAction(BOOKMARK.ADD_COMPANY_LIST_ITEM, { companyListItemIds })
          );
        }
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  };
};

export const deleteNewsList = (bookmark: IBookmarkNews) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_CONTENTLIST_DELETE,
      { contentListGuids: [bookmark.guid] },
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.DELETE_BOOKMARK_LIST, {
            deletedBookmark: bookmark,
            tab: BOOKMARK_LIST_ITEM_TYPE.NEWS,
          })
        );
        resolve(true);
      } else {
        reject(response);
      }
    });
  };
};

export const deleteCompanyList = (bookmark: IBookmarkCompany) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_DELETE,
      { guid: bookmark.guid },
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.DELETE_BOOKMARK_LIST, {
            deletedBookmark: bookmark,
            tab: BOOKMARK_LIST_ITEM_TYPE.COMPANIES,
          })
        );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const updateBookmarkList = (data) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_CONTENTLIST_UPDATE,
      data,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const list = {
          guid: data.contentListGuid,
          description: data.description,
          name: data.name,
        };
        dispatch(
          createAction(BOOKMARK.UPDATE_BOOKMARK_LIST, {
            list,
            type: BOOKMARK_LIST_ITEM_TYPE.NEWS,
          })
        );
        resolve(true);
      } else {
        reject(response);
      }
    });
  };
};

export const updateCompanyList = (data) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_UPDATE,
      data,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const list = {
          guid: data.guid,
          description: data.description,
          name: data.name,
          isEnabledForEmail: data.isEnabledForEmail,
        };
        dispatch(
          createAction(BOOKMARK.UPDATE_BOOKMARK_LIST, {
            list,
            type: BOOKMARK_LIST_ITEM_TYPE.COMPANIES,
          })
        );
        resolve(true);
      } else {
        reject(response);
      }
    });
  };
};

export const updateUserDefaultBookmark = (data, currentDefault: boolean) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_USER_DEFAULT_BOOKMARK_UPDATE,
      data,
      getState
    );
    return new Promise(async (resolve, reject) => {
      if (response && response.isSuccess) {
        const type =
          data.resourceType === BOOKMARK_LISTS_TYPE.CONTENT_LIST
            ? BOOKMARK_LIST_ITEM_TYPE.NEWS
            : BOOKMARK_LIST_ITEM_TYPE.COMPANIES;
        dispatch(
          createAction(BOOKMARK.SET_DEFAULT_VALUE, {
            guid: response.data.resourceGuid,
            type,
            currentDefault,
          })
        );
        resolve(true);
      } else {
        reject(response);
      }
    });
  };
};

export const getBookmark = (contentListGuid: string) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_CONTENTLIST_RETRIEVE,
      { contentListGuid },
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          setBookmarkDetail(response.data[0], BOOKMARK_LIST_ITEM_TYPE.NEWS)
        );
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const retrieveCompanyList = (guid: string) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_RETRIEVE,
      { guid },
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          setBookmarkDetail(response.data, BOOKMARK_LIST_ITEM_TYPE.COMPANIES)
        );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

const getContentListItemIds = (req: IAddNewsToBookmarkRequest) => {
  const contentListItemIds = [];
  for (const contentListId of req.contentListIds) {
    for (const entity of req.entities) {
      contentListItemIds.push({
        contentListId,
        entityGuid: entity?.entityGuid,
      });
    }
  }
  return contentListItemIds;
};

export const addContentListItem = (req: IAddNewsToBookmarkRequest) => {
  const currentRequest = { ...req };
  const contentListItemIds = getContentListItemIds(req);
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_ADD_CONTENT_LIST_ITEM,
      currentRequest,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.ADD_CONTENT_LIST_ITEMS, { contentListItemIds })
        );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

const getCompanyListItems = (req) => {
  const companyListItemIds = [];
  for (const bookmarkCompanyListGuid of req.bookmarkCompanyListGuids) {
    for (const entity of req.entities) {
      companyListItemIds.push({
        bookmarkCompanyListGuid,
        entityGuid: entity?.entityGuid,
      });
    }
  }
  return companyListItemIds;
};

export const addCompanyListItem = (req) => {
  const companyListItemIds = getCompanyListItems(req);
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_ITEM_ADD,
      req,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.ADD_COMPANY_LIST_ITEM, { companyListItemIds })
        );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const deleteContentListItem = (req: any) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_DELETE_CONTENT_LIST_ITEM,
      req,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.DELETE_CONTENT_LIST_ITEMS, {
            contentListIds: req.contentListIds,
            entityGuid: req.entityGuids,
          })
        );
        dispatch(
          createAction(BOOKMARK.DELETE_BOOKMARK_DETAIL, {
            contentListIds: req.contentListIds,
            entityGuids: req.entityGuids,
          })
        );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};
interface IDeleteCompanyListProps {
  bookmarkCompanyListGuids: string[];
  entityGuids: string[];
  fromBookmark?: boolean;
}

export const deleteCompanyListItem = (req: IDeleteCompanyListProps) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_ITEM_DELETE,
      req,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(BOOKMARK.DELETE_COMPANY_LIST_ITEM, req));
        dispatch(createAction(RADAR.UPDATE_STARRED_COMPANY_LIST, req));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const retrieveNewsListItems = () => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_RETRIEVE_CONTENT_LIST_ITEM,
      {},
      getState
    );
    return new Promise((resolve) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.GET_BOOKMARK_LIST_ITEMS, {
            bookmarkListItemIds: response.data,
            type: BOOKMARK_LIST_ITEM_TYPE.NEWS,
          })
        );
        resolve(response.data);
      }
    });
  };
};

export const retrieveCompanyListItem = (userGuid) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_ITEM_RETRIEVE,
      { userGuid },
      getState
    );
    return new Promise((resolve) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.GET_BOOKMARK_LIST_ITEMS, {
            bookmarkListItemIds: response.data,
            type: BOOKMARK_LIST_ITEM_TYPE.COMPANIES,
          })
        );
        resolve(response.data);
      }
    });
  };
};

export const bindFilterToRadar = (req: any) => {
  const currentRequest = {
    bookmarkFilterGuid: req.bookmarkFilterGuid,
    radarGuids: req.radarGuids,
  };
  const radarSearchCriterias = req?.addLinkedRadarsSearchCriterias;
  const tab = req?.tab;
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_FILTERLIST_BIND,
      currentRequest,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        radarSearchCriterias &&
          dispatch(
            createAction(BOOKMARK.UPDATE_FILTERS_LIST_TO_RADAR, {
              radarSearchCriterias,
              tab,
              guid: req.bookmarkFilterGuid,
              isUnBind: false,
            })
          );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const unBindFilterFromRadar = (req: any) => {
  const tabMap = {
    [BOOKMARK_LIST_ITEM_TYPE.NEWS_FILTERS]: RADAR_SEARCH_TYPE.ARTICLE,
    [BOOKMARK_LIST_ITEM_TYPE.COMPANIES_FILTERS]: RADAR_SEARCH_TYPE.COMPANY,
  };

  const currentRequest = {
    bookmarkFilterGuid: req.bookmarkFilterGuid,
    radarGuids: req.radarGuids,
  };
  const radarSearchCriterias = req?.filterLinkedRadarsSearchCriterias;
  const tab = req?.tab;
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_FILTERLIST_UNBIND,
      currentRequest,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        if (radarSearchCriterias) {
          dispatch(
            createAction(BOOKMARK.UPDATE_FILTERS_LIST_TO_RADAR, {
              radarSearchCriterias,
              tab,
              guid: req.bookmarkFilterGuid,
              isUnBind: true,
            })
          );

          req?.filterName &&
            dispatch(
              createAction(RADAR.UNBIND_FILTER_FROM_RADAR, {
                filterName: req.filterName,
                type: tabMap[req.tab],
              })
            );
        }
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const bindCompanyListToRadar = (req: any) => {
  const currentRequest = { ...req };
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_BIND,
      currentRequest,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        req.addLinkedRadars &&
          dispatch(
            createAction(BOOKMARK.BIND_COMPANY_LIST_TO_RADAR, {
              addLinkedRadars: req.addLinkedRadars,
            })
          );
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const unBindCompanyListFromRadar = (req: any) => {
  const currentRequest = { ...req };
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_COMPANYLIST_UNBIND,
      currentRequest,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        if (req?.filterLinkedRadars) {
          dispatch(
            createAction(BOOKMARK.UNBIND_COMPANY_LIST_FROM_RADAR, {
              filterLinkedRadars: req.filterLinkedRadars,
            })
          );
          dispatch(createAction(RADAR.UNBIND_COMPANY_LIST, {}));
        }
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const createBookmarkFilter = (data, withAPIKey: boolean = false) => {
  return async function (dispatch, getState) {
    const payload = {
      ...data,
      userGuid: withAPIKey ? getGuid(getState()) : getUserGuid(getState()),
    };
    const response = withAPIKey
      ? await sendPostRequestWithKey(
        API_ENDPOINT.BOOKMARK_FILTERLIST_CREATE,
        payload,
        process.env.API_KEY
      )
      : await sendPostRequestWithState(
        API_ENDPOINT.BOOKMARK_FILTERLIST_CREATE,
        payload,
        getState
      );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.CREATE_BOOKMARK_FILTERS_LIST, {
            createData: response.data,
          })
        );
        dispatch(radarEmailAlertsList());
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const deleteBookmarkFilter = (req) => {
  return async function (dispatch, getState) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_FILTERLIST_DELETE,
      req.params,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.DELETE_BOOKMARK_FILTERS_LIST, {
            deleteData: response.data,
            tab: req.tab,
          })
        );
        dispatch(radarEmailAlertsList());
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const updateBookmarkFilter = (req) => {
  const { params, tab, isRadarChange } = req;
  return async function (dispatch, getState) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_FILTERLIST_UPDATE,
      params,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.UPDATE_BOOKMARK_FILTERS_LIST, {
            updateData: response.data,
            tab,
          })
        );
        if (isRadarChange) {
          dispatch(
            createAction(RADAR.UPDATE_SELECT_BOOKMARK_FILTERS, response.data)
          );
        }
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const getBookmarkFilterRetrieveByIds = (guid: string) => {
  return async function (dispatch, getState) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_FILTERLIST_RETRIEVE,
      { guids: [guid] },
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const tab =
          response.data[0].searchType === BOOKMARK_ENTITY_TYPE.ARTICLE
            ? BOOKMARK_LIST_ITEM_TYPE.NEWS_FILTERS
            : BOOKMARK_LIST_ITEM_TYPE.COMPANIES_FILTERS;
        dispatch(setBookmarkDetail(response.data[0], tab));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const companySelectionUpdate = (selection) => {
  return createAction(BOOKMARK.SET_COMPANIES_SELECTED_ITEMS, { selection });
};
