import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FONT } from '@constants/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitleRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    paper: {
      width: '560px',
      borderRadius: 0,
      [theme.breakpoints.down(560)]: {
        width: '100%',
      },
    },
    titleRoot: {
      display: 'flex',
      fontSize: theme.typography.h5.fontSize,
      justifyContent: 'center',
      '& h5': {
        color: theme.palette.primary.main,
        fontWeight: FONT.WEIGHT.MEDIUM,
      },
      '& h5:first-child': {
        marginRight: theme.spacing(0.5),
      },
      paddingBottom: 24,
    },
    actionRoot: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      '& button': {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export default useStyles;
