import { Map, fromJS, List } from 'immutable';
import { COUNTRY } from '@constants/actions';

const initialState = Map({
  overview: {
    inProgress: false,
    title: '',
    summary: '',
  },
  detail: {
    inProgress: false,
    /**
     * Shape of [ICountryDetail]
     */
    data: {},
    sectorParent: {
      code: '',
      label: '',
    },
    company: {
      inProgress: false,
      data: [],
      aggregate: {
        sector: [],
      },
    },
    article: {
      inProgress: false,
      data: [],
    },
  },
  emis:{
    inProgress: false,
    data:[],
    download: {
      inProgress: false,
      link: '',
    },
  },
  drawerMenu: Map({
    countries: List([]),
  }),
});

const Country = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY.OVERVIEW.IN_PROGRESS: {
      return state.setIn(['overview'], {
        inProgress: true,
      });
    }
    case COUNTRY.OVERVIEW.RESULT: {
      const overview = action.payload.data;
      return state.setIn(['overview'], {
        inProgress: false,
        title: overview.title,
        summary: overview.summary,
      });
    }
    case COUNTRY.DETAILS.IN_PROGRESS: {
      return state.setIn(['detail', 'inProgress'], true);
    }
    case COUNTRY.DETAILS.NOT_IN_PROGRESS: {
      return state.setIn(['detail', 'inProgress'], false);
    }
    case COUNTRY.DETAILS.RESULT: {
      const { data, sectorParent }  = action.payload;
      return state
        .setIn(['detail', 'data'], data)
        .setIn(['detail', 'sectorParent'], sectorParent);
    }
    case COUNTRY.DETAILS.ARTICLE.LIST.IN_PROGRESS: {
      return state.setIn(['detail', 'article', 'inProgress'], true);
    }
    case COUNTRY.DETAILS.ARTICLE.LIST.NOT_IN_PROGRESS: {
      return state.setIn(['detail', 'article', 'inProgress'], false);
    }
    case COUNTRY.DETAILS.ARTICLE.LIST.RESULT: {
      const companies = action.payload.data;
      return state.setIn(['detail', 'article', 'data'], companies);
    }
    case COUNTRY.DETAILS.COMPANY.LIST.IN_PROGRESS: {
      return state.setIn(['detail', 'company', 'inProgress'], true);
    }
    case COUNTRY.DETAILS.COMPANY.LIST.NOT_IN_PROGRESS: {
      return state.setIn(['detail', 'company', 'inProgress'], false);
    }
    case COUNTRY.DETAILS.COMPANY.LIST.RESULT: {
      const companies = action.payload.data;
      const aggregateSectors = action.payload.aggregateSectors;
      return state
        .setIn(['detail', 'company', 'data'], companies)
        .setIn(['detail', 'company', 'aggregate', 'sector'], aggregateSectors);
    }
    case COUNTRY.EMIS.LIST.IN_PROGRESS: {
      return state.setIn(['emis', 'inProgress'], true);
    }
    case COUNTRY.EMIS.LIST.NOT_IN_PROGRESS: {
      return state.setIn(['emis', 'inProgress'], false);
    }
    case COUNTRY.EMIS.LIST.RESULT: {
      return state.setIn(['emis', 'data'], action.payload.data);
    }
    case COUNTRY.EMIS.DOWNLOAD.IN_PROGRESS: {
      return state.setIn(['emis', 'download', 'inProgress'], true);
    }
    case COUNTRY.EMIS.DOWNLOAD.NOT_IN_PROGRESS: {
      return state.setIn(['emis', 'download', 'inProgress'], false);
    }
    case COUNTRY.EMIS.DOWNLOAD.RESULT: {
      return state.setIn(['emis', 'download', 'link'], action.payload.data);
    }
    case COUNTRY.DRAWER_MENU_COUNTRY_lIST: {
      const countries = fromJS(action.payload);
      return state.setIn(['drawerMenu', 'countries'], countries);
    }
    default:
      return state;
  }
};

export default Country;