import makeStyles from '@mui/styles/makeStyles';
import { FONT, BUTTON } from '@constants/style';

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
    height: '40px',
    letterSpacing: '1.3px',
    fontWeight: FONT.WEIGHT.SEMIBOLD,
    '& span': {
      lineHeight: '16px',
    },
  },
  primaryContainedRoot: {
    color: FONT.COLOR.TEXT.MAIN,
    background: BUTTON.COLOR.PRIMARY.MAIN,
    border: 'none',
    '&:hover': {
      backgroundColor: BUTTON.COLOR.PRIMARY.HOVER,
    },
  },
  primaryContainedDisabledRoot: {
    color: `${FONT.COLOR.TEXT.DISABLED} !important`,
    backgroundColor: `${BUTTON.COLOR.PRIMARY.DISABLED} !important`,
    border: 'none',
  },
  secondaryOutlinedRoot: {
    border: '1px solid #404040',
    '&:hover': {
      border: `1px solid ${FONT.COLOR.PRIMARY.MAIN}`,
      color: FONT.COLOR.PRIMARY.MAIN,
      backgroundColor: BUTTON.COLOR.SECONDARY.HOVER,
    },
  },
  secondaryInvertedRoot: {
    backgroundColor: 'transparent',
    border: `1px solid ${BUTTON.COLOR.SECONDARY.INVERTED.MAIN}`,
    borderRadius: 0,
    color: FONT.COLOR.SECONDARY.INVERTED.MAIN,
    '&:hover': {
      border: `1px solid ${BUTTON.COLOR.SECONDARY.INVERTED.HOVER}`,
      color: FONT.COLOR.SECONDARY.INVERTED.HOVER,
    },
  },
}));