import loadable from '@loadable/component';
import React from 'react';

const options = { fallback : (<div />) };

export const StandardLayout = loadable(
  () => import(/* webpackPreload: true */'./standard.layout.container'),
  options,
);

export const NonStandardLayout = loadable(
  () => import(/* webpackPreload: true */'./nonStandard.container'),
  options,
);