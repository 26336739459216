import { UserManager } from 'oidc-client';
import { Store } from 'redux';
import React, { useState, ReactNode, createContext, useContext, useEffect } from 'react';
import { OidcProvider } from 'redux-oidc';

export interface ICustomOidcProps {
  children?: ReactNode;
  userManager?: UserManager;
  store?: Store;
}

const CustomOidcContext = createContext<ICustomOidcProps>({});

export const useCustomOidc = () => useContext(CustomOidcContext);

function CustomOidcProvider(props: ICustomOidcProps) {
  const { children, userManager, store } = props;
  const [userManagerInstance, setUserManagerInstance] = useState(userManager);

  useEffect(() => {
    setUserManagerInstance(userManager);
  }, [userManager]);
  
  return (
    <CustomOidcContext.Provider value={{
      store,
      userManager,
    }}
    >
      <OidcProvider store={store} userManager={userManagerInstance}>
        {children}
      </OidcProvider>
    </CustomOidcContext.Provider>
    
  );
}

export default CustomOidcProvider;