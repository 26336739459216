import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { BORDER, FONT } from '@constants/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    compactRoot: {
        padding: 12,
        borderBottom: `1px solid ${BORDER.COLOR}`,
    },
    metadata: {
        fontSize: 14,
        color: '#5c5c5c',
    },
    title: {
        fontSize: theme.spacing(2),
        lineHeight: theme.spacing(2),
    },
    translationIcon: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(0.5),
    },
    widgetArticleCardRoot: {
        boxShadow: 'none !important',
        padding: '16px 12px !important',
        borderBottom: `1px solid ${BORDER.COLOR}`,
        '&:hover': {
            boxShadow: 'none !important',
            transition: 'unset !important',
        },
    },
}));

export default useStyles;