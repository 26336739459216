import { Map, fromJS } from 'immutable';
import { USER_SESSION_STATUS } from '@constants/state';

const isValidUser = (user: any) => {
  if (user && (user.expires_at > (Date.now() / 1000)))
    return true;
  if (user) {
    console.log('expiration', user.expires_at, 'now', Date.now() / 1000);
  }
  return false;
};

const isValidUserWidget = (user: any, userGuid: string = '') => {
  if (user === null) {
    return USER_SESSION_STATUS.EMPTY;
  }
  if (user && (user.expires_at > (Date.now() / 1000))) {
    if (userGuid && user.profile.sub.includes(userGuid.toLowerCase())) {
      return USER_SESSION_STATUS.VALID;
    } else {
      return USER_SESSION_STATUS.MISMATCH;
    }
  }
  if (user) {
    console.log('expiration', user.expires_at, 'now', Date.now() / 1000);
  }
  return USER_SESSION_STATUS.EXPIRED;
};

const getAccountState = (user: any): any => {
  if (user) {
    return fromJS(user);
  }
  return Map({});
};

const checkPasswordUpper = (password: string): boolean => {
  const upper = new RegExp('[A-Z]+');
  if (password.match(upper)) {
    return true;
  }
  return false;
};

const checkPasswordLower = (password: string): boolean => {
  const lower = new RegExp('[a-z]+');
  if (password.match(lower)) {
    return true;
  }
  return false;
};

const checkPasswordNumber = (password: string): boolean => {
  const number = new RegExp(/\d+/);
  if (password.match(number)) {
    return true;
  }
  return false;
};

const checkPasswordSpecialCharacter = (password: string) : boolean => {
  const special = new RegExp('[^A-Za-z0-9]+');
  if (password.match(special)) {
    return true;
  }
  return false;
};

const validPassword = (password: string): boolean => {
  const hasUpper = checkPasswordUpper(password);
  const hasLower = checkPasswordLower(password);
  const hasNumber = checkPasswordNumber(password);
  const hasSpecial = checkPasswordSpecialCharacter(password);
  const validLength = password.length >= 6;

  return hasUpper && hasLower && hasNumber && hasSpecial && validLength;
};

export { getAccountState, isValidUser, validPassword, checkPasswordNumber, checkPasswordSpecialCharacter,
  checkPasswordUpper, checkPasswordLower, isValidUserWidget };
