import { getAllCountries, getMenuCountries } from '@constants/common';
import { COUNTRY, DATA } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';
import { isEmpty, mapValues, keyBy } from 'lodash';
import createAction from './actionCreator';
import { increaseProgress, decreaseProgress } from './progress.actions';
import { replaceChinaRegionName } from '@react/utils/common.util';

/**
 * Get all countries
 * and shape the array of countries into
 * a dictionary of {`alpha3`: `country`}
 */
export const initCountries = () => {
  return async function (dispatch: any) {
    const allCountries = getAllCountries();
    const mapAllByAlpha2 = mapValues(keyBy(allCountries, (c) => c.alpha2), (c) => c);
    const mapAllByAlpha3 = mapValues(keyBy(allCountries, (c) => c.alpha3), (c) => c);
    const countries = getMenuCountries(allCountries);
    const mapCountries = mapValues(keyBy(countries, (c) => c.alpha3), (c) => c);
    dispatch(createAction(DATA.COUNTRIES_ALL.LIST, {
      allCountries: {
        list: allCountries,
        mapping: {
          alpha2: mapAllByAlpha2,
          alpha3: mapAllByAlpha3,
        },
      },
      countries: {
        list: countries,
        mapping: mapCountries,
      },
    }));
  };
};

export const searchCountriesOverview = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(COUNTRY.OVERVIEW.IN_PROGRESS));
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_COUNTRY_OVERVIEW, {}, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());

      if (response && response.isSuccess) {
        /**
         * Return the following format
         * {
         *   isSuccess: true,
         *   data: {
         *     title: "Macoeconomic Overview",
         *     summary: "<html content here>"
         *   }
         * }
         */
        dispatch(createAction(COUNTRY.OVERVIEW.RESULT, { data: JSON.parse(replaceChinaRegionName(JSON.stringify(response.data))) }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const searchCountrySectorDetails = (searchContryReq: any) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(COUNTRY.DETAILS.IN_PROGRESS));
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_COUNTRY_DETAIL, searchContryReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(COUNTRY.DETAILS.NOT_IN_PROGRESS));
      if (response && response.isSuccess) {
        /**
         * Shape of response.data should follow [ICountryDetail] 
         */
        const data = response.data;
        const sectorMapping = getState().getIn(['Data', 'sectors', 'mapping']);
        let sectorParent = { code: '', label: '' };

        if (data?.sector) {
          const selectedSector = sectorMapping[data.sector];
          if (!isEmpty(selectedSector) && selectedSector.parent) {
            sectorParent = { code: selectedSector.parent, label: sectorMapping[selectedSector.parent].label };
          }
        }

        dispatch(createAction(COUNTRY.DETAILS.RESULT, {
          data,
          sectorParent,
        }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const searchEmisReport = (searchEmisReportReq: any) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(COUNTRY.EMIS.LIST.IN_PROGRESS));
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_EMIS_LIST, searchEmisReportReq, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(COUNTRY.EMIS.LIST.NOT_IN_PROGRESS));
      if (response && response.isSuccess) {
        dispatch(createAction(COUNTRY.EMIS.LIST.RESULT, { data: response.data }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const searchEmisDownload = (fileName: string) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(COUNTRY.EMIS.DOWNLOAD.IN_PROGRESS));

    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_EMIS_DOWNLOAD, { fileName }, getState);

    return new Promise((resolve, reject) => {
      dispatch(createAction(COUNTRY.EMIS.DOWNLOAD.NOT_IN_PROGRESS));

      if (response && response.isSuccess) {
        dispatch(createAction(COUNTRY.EMIS.DOWNLOAD.RESULT, { data: response.data }));
        // `response.data` is the link string
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  };
};