export const FONT = {
  FAMILY: ['MetricWeb', 'sans-serif'].join(','),
  COLOR: {
    PRIMARY: {
      MAIN: '#007B78',
      LIGHT: '#0c948b',
      DARK: '#0a7a73',
    },
    SECONDARY: {
      MAIN: '#414248',
      CONTRAST_TEXT: '#ffffff',
      LIGHT: '#a8a9ad',
      INVERTED: {
        MAIN: '#ffffff',
        HOVER: '#10d0c3',
      },
    },
    TAB: {
      MAIN: '#414248',
      CONTRAST_TEXT: '#b0eee9',
    },
    TEXT: {
      MAIN: '#404040',
      DISABLED: '#A7AAAE',
      PLACEHOLDER: '#757575',
      BLACK: '#000000',
    },
    CAPTION: {
      MAIN: '#878787',
      LIGHT: '#707070',
    },
    ERROR: {
      DARK: '#b51225',
    },
    WARN: {
      LIGHT: '#FF6D6B',
    },
    DRAWER: {
      BACKGROUND: 'rgb(64, 67, 72)',
      FOOTER: '#404040',
      BORDER1: '#5c5c5c',
      BORDER2: '#4c4f56',
    },
    INPUT: {
      LABEL: {
        MAIN: '#878787',
        FOCUS: '#575757',
      },
    },
  },
  WEIGHT: {
    BOLD: 700,
    LIGHT: 300,
    MEDIUM: 500,
    REGULAR: 400,
    SEMIBOLD: 600,
  },
};

export const BORDER = {
  COLOR: '#dcdddf',
};

export const SECTION_BORDER = {
  COLOR: '#D9D9D9',
};

export const BUTTON = {
  COLOR: {
    PRIMARY: {
      DISABLED: '#adefea',
      MAIN: '#10d0c3',
      HOVER: '#b0eee9',
    },
    SECONDARY: {
      HOVER: '#e7faf9',
      INVERTED: {
        MAIN: 'white',
        HOVER: '#10d0c3',
      },
    },
    ERROR: {
      MAIN: '#b51225',
      HOVER: '',
    },
    CONFIRM: {
      MAIN: '#0a7a73',
    },
  },
};

export const QUICK_SEARCH = {
  COLOR: {
    BACKGROUND: {
      SELECTED: '#B0EEE9',
      LIGHT: '#E6F2F1',
      LIGHTEST: '#ADEDE9',
      DARK: '#0A7A73',
    },
    FONT: {
      MAIN_DARK: '#707070',
      MAIN_LIGHT: '#E2E2E2',
      DARK: '#0A7A73',
      SELECTED: '#B0EEE9',
    },
    BORDER: {
      MAIN: '#404040',
    },
  },
};

export const TOGGLE_BUTTON = {
  COLOR: {
    PRIMARY: {
      MAIN: '#0c6460',
    },
  },
};

export const ANNOUNCEMENT = {
  HEIGHT: 52,
  COLOR: {
    INFO: '#00572c',
    WARNING: '#b30000',
  },
};

export const APPBAR = {
  HEIGHT: 60,
};

export const LASTSEARCH = {
  HEIGHT: 70,
};

export const BREAKPOINT = {
  MAX_WIDTH_MOBILE: 600,
};

/**
 * The maximum content width.
 */
export const CONTENT_WIDTH = 1180;
/**
 * Horizontal left and right padding - 20px each
 */
export const CONTENT_PADDING_HORIZONTAL = 40;

export const GRAPH = {
  BAR: {
    COLOR: {
      PRIMARY: {
        ACTIVE: FONT.COLOR.PRIMARY.MAIN,
        INACTIVE: '#ff918f',
        HOVER: '#918fff',
      },
    },
  },
};

export const MARKET_GRAPH = {
  BAR: {
    COLOR: {
      PRIMARY: '#0c6460',
    },
  },
  AREA: {
    COLOR: {
      STROKE: '#10d0c3',
      STOP_PRICE: '#10d0c3',
      STOP_CAP: '#d4001a',
    },
  },
  DOT: {
    COLOR: {
      ACTIVE: '#0c6460',
    },
  },
};

export const MENU = {
  HEIGHT: {
    SOURCE: 392,
    SECTOR: {
      DEFAULT: 396,
      TOOLBAR: 506,
      COUNTRY_REGION: 542,
    },
  },
};

export const SEARCH_DRAWER_WIDTH = 270;
export const SEARCH_DRAWER_TOP = 135;
export const SEARCH_DRAWER_TOP_MOBILE = 109;
export const SEARCH_HEADER_HEIGHT = 155;
export const SEARCH_HEADER_HEIGHT_MOBILE = 85;
export const SCOUT_ASIA_FOOTER_HEIGHT = 60;

/**
 * Implement additional custom z-index values
 * to ensure it does not conflict with existing MUI z-indexes.
 * Refer to https://material-ui.com/customization/z-index/
 */
export const ZINDEX = {
  ANNOUNCEMENT: 1201,
  RIGHT_DRAWER: 1203,
  LEFT_DRAWER_POPPER: 1200,
};

export const TABCONTROL = {
  HOVER: '#ECEDF1',
  INDICATOR: {
    HOVER: BORDER.COLOR,
  },
  APPBAR: {
    BORDER: '#ECEDF1',
  },
};

export const PAGE = {
  BG: '#f8f9fb',
  BLANK: '#383B3F',
};

export const NAVBAR = {
  MOBLIE_MENU_WIDTH: 1190,
  ICON_ONLY_WIDTH: 1510,
  MOBLIE_DROPDOWN_WIDTH: 537,
  MOBILE_MENU_ITEM: 45,
};

export const TOOLTIP = {
  BACKGROUND_COLOR: '#404040',
  TEXT_COLOR: 'white',
};

export const NON_ENGLISH_FONT_STACK = 'Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Source Han Sans, Hiragino Sans, Yu Gothic, PingFang TC, PingFang SC, Microsoft Jhenghei, Microsoft YaHei, Apple SD Gothic Neo, Malgun Gothic, sans-serif';