import { createSelector } from 'reselect';
import { Country } from 'skyeye-common-const/dist/interfaces/country';
import { subcriptionFeatureSelector } from '@redux/selectors/account.selector';
import { companyCountryApplicationFeature } from 'skyeye-common-const';

export const getMenuCountries = (state): Country[] => state.getIn(['Data', 'countries', 'list']);

export const getMenuCountriesByFeature = createSelector(
  [getMenuCountries, subcriptionFeatureSelector], (countries, userOwnedFeature: Array<string>) => {
    // filter userFeature in company country feature by intersection
    const userCompanyCountryFeature =
      userOwnedFeature.filter(uf => companyCountryApplicationFeature.features.some(
        cf => uf === cf.featureCode,
      ));
    return countries.filter(c => {
      const find = userCompanyCountryFeature.find(uf => {
        return c.alpha3 === uf;
      });
      return find !== undefined;
    });
  },
);
