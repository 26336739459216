import { Map } from 'immutable';
import { PROGRESS } from '@constants/actions';

const initialState = Map({
  currentProgress: 0,
});

const Progress = (state = initialState, action: any) => {
  const currentProgress: number = state.get('currentProgress');
  switch (action.type) {
    case PROGRESS.INCREASE_PROGRESS:
      return state.set('currentProgress', currentProgress + 1);
    case PROGRESS.DECREASE_PROGRESS:
      return state.set('currentProgress', currentProgress - 1);
    default:
      return state;
  }
};

export default Progress;