import { COMPANY } from '@constants/actions';
import { COMPANY_RESULT_PER_PAGE, EMPTY_STRING } from '@constants/common';
import { Map } from 'immutable';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  companyList: [],
  totalCount: 0,
  selectedCompany: {},
  drawerMenu: {
    companies: [],
    totalCount: 0,
  },
  searchStr: EMPTY_STRING,
  searchInProgress: false,
  retrieveInProgress: {
    base: false,
    addition: false,
  },
  listProps: {
    page: 1,
    resultPerPage: COMPANY_RESULT_PER_PAGE,
  },
  officersList: {
    source: EMPTY_STRING,
    officers: [],
  },
});

const Company = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY.COMPANY_LIST:
      return state.set('totalCount', action.payload.totalCount)
        .set('companyList', action.payload.data)
        .set('searchInProgress', false);
    case COMPANY.STORE_SEARCH_STR:
      return state.set('searchStr', action.payload.searchStr);
    case COMPANY.COMPANY_RETRIEVE:
      return state.set('selectedCompany', action.payload.data);
    case COMPANY.SEARCH_COMPANY_DRAWER_MENU:
      return state.set('drawerMenu', {
        companies: action.payload.data,
        totalCount: action.payload.totalCount,
      });
    case COMPANY.SEARCH_IN_PROGRESS:
      return state.set('searchInProgress', action.payload.inProgress);
    case COMPANY.RETRIEVE_IN_PROGRESS:
      return state.set('retrieveInProgress', action.payload);
    case COMPANY.UPDATE_LIST_PROPS: {
      const currentProps = state.get('listProps') as object;
      const updatedProps = { ...currentProps, ...action.payload };
      return state.set('listProps', updatedProps);
    }
    default:
      return state;
  }
};

export default Company;