import React from 'react';
import Scroll from '@react/utils/animation/scroll.class';
import { useHistory, useLocation } from 'react-router';
import { SITE_URLS } from '@constants/routes';
import { SCROLL_POSITION } from '@constants/common';

interface IProps {
  children: React.ReactNode;
}

export const AutoResetScroll = ({ children }: IProps) => {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (!location.pathname.includes(SITE_URLS.TRENDING_TOPIC_CLUSTER)) {
      sessionStorage.removeItem(SCROLL_POSITION.DASHBOARD_SCROLL_TOP);
    }
  }, [history.location.pathname]);

  React.useEffect(() => {
    return history.listen(() => {
      Scroll.scrollTo(0, 1000);
    });
  }, [history.location.key]);

  return <div>{children}</div>;
};
