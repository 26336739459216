import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router';
import WidgetCallbackRoute from '@react/components/route/widgetCallbackRoute.component';
import { callbackRoute, logOutRedirect, silentRoute } from '@react/routes';
import { EMPTY_STRING, LOCAL_STORAGE } from '@constants/common';
import { IPartnerInfo } from '@models/common';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import WidgetErrorPage from './widgetErrorPage.component';
import useStyles from './styles';
import SSOTest from './ssoTest';
import WidgetWrapper from './widgetWrapper.component';
import messages from './messages';
import { pluginInit as initAtlas, trackIframePage, initialized as atlasInitialized } from '@react/utils/analytics/atlaswrapper.util';

interface WidgetAppOwnProps {
  path?: string;
  exact?: boolean;
  component?;
  render?;
  partnerInfoString?: string;
}

export const WidgetContext = React.createContext({
  partnerInfo: {
    partnerCode: EMPTY_STRING,
    partnerUserId: EMPTY_STRING,
    validToken: false,
    createMode: 0,
    userGuid: EMPTY_STRING,
  } as IPartnerInfo,
});

type IWidgetContext = {
  children: any;
  partnerInfo: IPartnerInfo;
};

export const WidgetContextProvider = ({ children, partnerInfo }: IWidgetContext) => {
  return (
    <WidgetContext.Provider value={{ partnerInfo }}>
      {children}
    </WidgetContext.Provider>
  );
};
 
const WidgetApp: React.FC<WidgetAppOwnProps> = (props) => {
  const history = useHistory();
  const location: any = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  const [analyticsInit, setAnalyticsInit] = useState(false);
  let partnerInfo: IPartnerInfo = props.partnerInfoString ? JSON.parse(props.partnerInfoString) : {};
  if ((isEmpty(partnerInfo) || isEmpty(partnerInfo?.partnerCode)) && location.state && !!location.state.partnerInfo) {
    partnerInfo = JSON.parse(location.state.partnerInfo);
  }

  useEffect(() => {
    if (partnerInfo.partnerUserId && !analyticsInit) {
      setAnalyticsInit(true);
      const analyticsObj = {
        affiliateUserId: partnerInfo.partnerUserId,
        userLevel: 'starter',
        affiliateCode: partnerInfo.partnerCode,
      };
      sessionStorage.setItem(LOCAL_STORAGE.WIDGET_DATA, JSON.stringify(analyticsObj));
      initAtlas(partnerInfo.partnerUserId, analyticsObj);
    }

    if (analyticsInit) {
      const detailAnalyticModel = {
        'data-trackable': 'widget',
      };
      const pageTrackingData = {
        ...detailAnalyticModel,
        content: { asset_type: 'story' },
      };  
      trackIframePage(pageTrackingData);
    }
  }, [analyticsInit, partnerInfo]);
  
  return (
    <div className={classes.appWidget}>
      <WidgetContextProvider partnerInfo={partnerInfo}>
        <Switch>
          <Route 
            path="/widget/test-sso"
            exact={true}
            component={SSOTest}
          />
          <WidgetCallbackRoute
            path={`/widget${callbackRoute.path}`}
            exact={!!callbackRoute.exact}
            component={callbackRoute.component}
          />
          <Route 
            path={`/widget${silentRoute.path}`}
            exact={!!silentRoute.exact}
            component={silentRoute.component}
          />
          <Route 
            path={logOutRedirect.path}
            exact={!!logOutRedirect.exact}
            component={logOutRedirect.component}
          />
          <Route
            path="*"
            render={() => {
              if (partnerInfo?.invalidUrl === true) {
                return <WidgetErrorPage
                        errorCode={400}
                        errorHeader={intl.formatMessage(messages.invalidUrlHeader)}
                        errorMessage={intl.formatMessage(messages.invalidUrlMessage)}
                />;
              }
              if (partnerInfo.validToken) {
                return <WidgetWrapper />;
              }
                if (partnerInfo?.validToken === false) {
                  return <WidgetErrorPage errorCode={400} errorHeader={intl.formatMessage(messages.badToken)} errorMessage={intl.formatMessage(messages.errorProcessingRequest)} showSupportButton={true} supportEmail={`support@scout.asia?subject=400%20Bad%20token`}/>;
                }

                if (partnerInfo?.isSuccess === false) {
                  return <WidgetErrorPage errorMessage={partnerInfo.errorMessage} />;
                }
              return <></>;
            }}
          />
        </Switch>
      </WidgetContextProvider>
    </div>
  );
};

export default WidgetApp;

