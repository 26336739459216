import { Box } from '@mui/material';
import React, { FC } from 'react';
import PrimaryContainedButtonBase from '../button/base/primaryContainedButtonBase.component';
import { sendPostRequestWithKey } from 'skyeye-fe-common-util';
import { useCustomOidc } from '../oidc/customOidcProvider.component';
import { EMPTY_STRING } from '@constants/common';

const SSOTest:FC = (props) => {
    const oidc = useCustomOidc();
    const sendSSORequest = () => {
        oidc.userManager.createSigninRequest()
        .then(res => {
            const returnUrl = res.url.replace(process.env.OIDC_AUTHORITY, EMPTY_STRING);
            const stateId = new URLSearchParams(returnUrl).get('state');
            //store in session storage the session queries.
            sendPostRequestWithKey('/api/account/sign-token', {
                partnerCode: 'CTOS',
                partnerUserGuid: '1111-2222-3333',
                returnUrl: returnUrl
            }, EMPTY_STRING, {'Access-Control-Allow-Origin': '*'}).then(tokenRes => {
                const data = new FormData();
                data.append('validateToken', tokenRes.data);
                (document.getElementById("validate-token") as HTMLInputElement).value = tokenRes.data;
                (document.getElementById('test-the-form') as HTMLFormElement).submit();
            });
        });
    };

    return (
        <Box>
            <form action={process.env.LOGIN_BY_PARTNER_SSO_ENDPOINT} method="POST" id="test-the-form">
            <input type="hidden" id='validate-token' name="validateToken" />
            </form>
            <PrimaryContainedButtonBase
                onClick={sendSSORequest}
            >
                Test SSO form
            </PrimaryContainedButtonBase>
        </Box>
    )
};

export default SSOTest;

