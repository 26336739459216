import { getExtendedMenuSectors, ENTITY_TYPE_TEXT } from '@constants/common';
import { DATA } from '@constants/actions';
import { mapValues, keyBy } from 'lodash';
import createAction from './actionCreator';

/**
 * Get all sectors
 * and shape the array of sectors into
 * a dictionary of {`code`: `sector`}
 */
export const initSectors = () => {
  return async function (dispatch: any) {
    const sectors = getExtendedMenuSectors(ENTITY_TYPE_TEXT.ALL);
    const mapSectors = mapValues(keyBy(sectors, (s) => s.code), (s) => s);
    dispatch(createAction(DATA.SECTORS.LIST, {
      sectors,
      mapSectors,
    }));
  };
};
