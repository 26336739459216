import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FONT, NON_ENGLISH_FONT_STACK, QUICK_SEARCH } from '@constants/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content:{
      display: 'flex',
      marginTop: 28,
      '@media (max-width: 600px)': {
        flexDirection: 'column-reverse',
      },
      '@media (min-width: 600px)': {
        '& > div:first-child': {
          maxWidth: '900px',
          padding: '0 16px',
        },
        '& > div:last-child': {
          padding: '0 16px',
          marginTop: 56,
        },
      },
    },
    root: {
      width: '100%',
      display: 'flex',
      borderRadius: 0,
      boxShadow: '0 2px 4px 0 rgba(100,109,130,.14), 0 0 1px 0 rgba(100,109,130,.1)',
      position: 'relative',
      flexDirection: 'column',
      padding: '24px',
      pageBreakInside: 'avoid',
      '&:hover': {
        boxShadow: '0 0 2px 0 rgba(100, 109, 130, 0.12), 0 8px 14px 0 rgba(100, 109, 130, 0.16)',
        transition: 'all 0.3s ease-out',
      },
      '@media (max-width: 600px)': {
        padding: '20px 16px 28px',
      },
    },
    checkedRoot: {
      background: '#ebf5f5',
    },
    sources: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: theme.spacing(1),
      },
      '& span': {
        color: '#575757',
        fontSize: 14,
        fontWeight: FONT.WEIGHT.MEDIUM,
        lineHeight: '14px',
        letterSpacing: 1,
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        '& > *': {
          marginBottom: theme.spacing(1),
        },
      },
    },
    articleList: {
      padding: '0px 1px',
      '& > *': {
        marginBottom: '20px',
      },
      '& .highlight--keyword': {
        boxShadow: 'inset 0 0px 0 white, inset 0 -1px 0 #bd0016',
      },
    },
    searchSourceContent: {
      display: 'flex',
      alignItems: 'center',
      '& > p': {
        fontSize: 14,
      },
    },
    container: {
      padding: '25px 0 0 0',
      maxWidth: 1200,
      '@media (max-width: 600px)': {
        padding: '25px 24px 0',
      },
    },
    emptyIcon: {
      width: 210,
      height: 156,
    },
    addFeedTitle: {
      color: FONT.COLOR.PRIMARY.LIGHT,
      fontSize: '32px',
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      marginTop: 32,
    },
    description: {
      fontSize: '20px',
      maxWidth: 382,
      textAlign: 'center',
      marginTop: 12,
    },
    descriptionBold: {
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      fontSize: '22px',
    },
    descriptionSubText: {
      fontSize: '18px',
    },
    button: {
      marginTop: 24,
    },
    selectGrid: {
      padding: '0 16px',
    },
    selectContainer: {
      '@media (min-width: 600px)': {
        maxWidth: 868,
      },
    },
    viewMoreButton: {
      width: theme.spacing(14),
    },
    runSearchBox: {
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      padding: '0',
      '& > svg': {
        width: 12,
        height: 12,
      },
    },
    runSearch: {
      fontWeight: FONT.WEIGHT.MEDIUM,
      cursor: 'pointer',
      marginRight: 4,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
      '& > button': {
        marginLeft: 8,
      },
    },
    topSection: {
      borderBottom: '1px solid #dcdddf',
      padding: ' 0 0 8px 0',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        color: '#707070',
        letterSpacing: 0,
        lineHeight: '16px',
      },
      '& p': {
        color: '#707070',
        fontWeight: FONT.WEIGHT.MEDIUM,
        marginLeft: 2,
        lineHeight: '16px',
      },
    },
    titleText: {
      '& > div': {
        margin: '13px 0 6px 0',
        maxHeight: 'unset',
        lineHeight: '33px',
        textAlign: 'left',
        '@media (max-width: 600px)': {
          display: 'block',
          lineHeight: '24px',
        },
      },
    },
    title: {
      '& > div': {
        fontSize: '28px',
        '@media (max-width: 600px)': {
          fontSize: '22px',
        },
      },
    },
    nonEnglishTitle: {
      '& > div': {
        fontSize: '26px',
        fontFamily: NON_ENGLISH_FONT_STACK,
        '@media (max-width: 600px)': {
          fontSize: '20px',
        },
      },
    },
    tag: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        fontSize: '12px',
        textTransform: 'uppercase',
        paddingRight: theme.spacing(1),
        borderRight: '1px solid #dcdddf',
        marginRight: theme.spacing(1),
      },
      '& > *:first-child': {
        paddingLeft: 0,
      },
      '& > *:last-child': {
        borderRight: 0,
        paddingRight: 0,
        marginRight: 0,
      },
    },
    bottomSection: {
      padding: '0px !important',
    },
    summarizeUl: {
      fontSize: 14,
      marginBlockEnd: 'auto',
      paddingInlineStart: '13px',
      marginBlockStart: '12px',
      '& > li': {
        lineHeight: '16px',
        marginBottom: '12px',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    summarizeIcon: {
      width: 16,
      height: 16,
      '@media (min-width: 600px)': {
        marginRight: 4,
      },
    },
    tagText: {
      display: 'flex',
      alignItems: 'center',
    },
    transIcon: {
      width: 24,
      height: 14,
    },
    TextStyle: {
      fontSize: 14,
      fontWeight: FONT.WEIGHT.MEDIUM,
      color: '#707070',
    },
    textColor: {
      color: '#0a7a73',
      fontSize: 'inherit',
      textTransform: 'uppercase',
    },
    popper: {
      zIndex: 1300,
      '& >.MuiTooltip-tooltip > .MuiTooltip-arrow': {
        marginTop: '-0.67em',
        marginBottom: '-0.67em',
      },
    },
    companyToolTip: {
      textTransform: 'capitalize',
    },
    contentBody: {
      fontSize: '14px',
      lineHeight: '1.14',
      marginTop: '12px',
      lineClamp: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    },
    nonEnglishContent: {
      fontFamily: NON_ENGLISH_FONT_STACK,
      fontSize: '12px',
    },
    newestCard: {
      borderLeft: '4px solid #0a7a73',
    },
    countBox: {
      marginBottom: 36,
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 600px)': {
        marginBottom: 24,
      },
    },
    filterBar: {
      margin: '12px 0',
    },
    ExternalsTagContent: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '12px',
      cursor: 'pointer',
      fontSize: '10px',
      marginTop: '4px',
      color: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
      '&:hover >span, &:hover > svg': {
        color: QUICK_SEARCH.COLOR.FONT.DARK,
        fill: QUICK_SEARCH.COLOR.FONT.DARK,
      }
    },
    FTexternalLink: {
      marginRight: '6px',
    },
    openInNewIcon: {
      width: '12px',
      height: '12px',
      fill: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
    },
  }),
);

export default useStyles;