import { ARTICLE_PUBLISHER } from '@constants/common';
import { IArticle } from '@models/article';
import { RadarArticle } from '@models/radar';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatUnixToDate, timeFromNow } from 'skyeye-fe-common-util';
import messages from './messages';

interface IArticleListItemDateProps {
  article: RadarArticle | IArticle;
  isFromRadar?: boolean;
}

const ArticleListItemDate = (props: IArticleListItemDateProps) => {
  const { article, isFromRadar } = props;
  const intl = useIntl();
  const pubDate = isFromRadar
    ? new Date(article.publishedDate).getTime()
    : (article.publishedDate as number) * 1000;
  const isPublisherEmis =
    article.publisher?.toLowerCase() === ARTICLE_PUBLISHER.EMIS.toLowerCase();

  const publishDate = new Date(pubDate);
  const currentTime = new Date();
  const publishHour = publishDate.getHours();
  const currentHour = currentTime.getHours();
  const daysDifference =
    (currentTime.getTime() - publishDate.getTime()) / (1000 * 3600 * 24);

  if (isPublisherEmis) {
    return <span>{formatUnixToDate(pubDate / 1000, 'D MMM YYYY')}</span>;
  }

  if (daysDifference < 1) {
    if (currentHour >= publishHour) {
      return <span>{timeFromNow(pubDate / 1000)}</span>;
    }
    return <span>{intl.formatMessage(messages.yesterday)}</span>;
  }

  if (daysDifference >= 1 && daysDifference < 2) {
    return <span>{intl.formatMessage(messages.yesterday)}</span>;
  }

  return <span>{formatUnixToDate(pubDate / 1000, 'D MMM YYYY')}</span>;
};

export default ArticleListItemDate;
