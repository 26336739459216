import { QUICK_SEARCH } from '@constants/actions';
import { Map } from 'immutable';

const initialState = Map({
  keyword: '',
  companies: [],
  articles: [],
  businessLines: [],
  scoutAIs: [],
  resetSearch: false,
});

const QuickSearch = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_SEARCH.KEYWORD.UPDATE: {
      const { keyword } = action.payload;
      return state.set('keyword', keyword);
    }
    case QUICK_SEARCH.RESULT.COMPANY: {
      const { companies } = action.payload;
      return state.set('companies', companies);
    }
    case QUICK_SEARCH.RESULT.ARTICLE: {
      const { articles } = action.payload;
      return state.set('articles', articles);
    }
    case QUICK_SEARCH.RESULT.BUSINESS_LINES: {
      const { businessLines } = action.payload;
      return state.set('businessLines', businessLines);
    }
    case QUICK_SEARCH.RESULT.SCOUT_AI: {
      const { scoutAIs } = action.payload;
      return state.set('scoutAIs', scoutAIs);
    }
    case QUICK_SEARCH.SEARCH.RESET: {
      const { resetSearch } = action.payload;
      return state.set('resetSearch', resetSearch);
    }
    default:
      return state;
  }
};

export default QuickSearch;