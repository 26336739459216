import { ARTICLE, COMPANY, DRAWER, QUICK_SEARCH } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { SCOUT_AI_STATUS, SCOUT_AI_TYPE } from '@constants/common';
import { searchBusinessLinesByKeyword } from '@constants/utils';
import ArticleListReq from '@models/articleListReq';
import { ICompany } from '@models/company';
import CompanyListReq from '@models/companyListReq';
import { sortBy } from 'lodash';
import { sendPostRequestWithKey, sendPostRequestWithState } from 'skyeye-fe-common-util';
import createAction from './actionCreator';
import { updateSearchQueryData } from './filters/filterBar.actions';

export const updateSearchKeyword = (keyword, updateQueryObject = false) => {
  return async function (dispatch: any, _getState: any) {
    dispatch(createAction(QUICK_SEARCH.KEYWORD.UPDATE, { keyword }));
    dispatch(createAction(QUICK_SEARCH.SEARCH.RESET, { resetSearch: true }));
    dispatch(createAction(DRAWER.COMPANY.SEARCH.KEYWORD.UPDATE, { keyword }));
    dispatch(createAction(DRAWER.ARTICLE.SEARCH.KEYWORD.UPDATE, { keyword }));
    dispatch(createAction(ARTICLE.STORE_ARTICLE_SEARCH_STR, { searchStr: keyword })); // update searched for label in article detail page
    dispatch(createAction(COMPANY.STORE_SEARCH_STR, { searchStr: keyword })); // update searched for label in company detail page

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const resetSearch = (resetSearch) => {
  return async function (dispatch: any, _getState: any) {
    dispatch(createAction(QUICK_SEARCH.SEARCH.RESET, { resetSearch }));
  };
};

// add search articles, business lines, scoutAIs, etc. for quick search so quick search result is persistent without affecting by filter

export const searchCompanies = (keyword, size, withAPIKey: boolean = false) => {
  return async function (dispatch: any, getState: any) {
    const request: CompanyListReq = {
      searchStr: keyword,
      select: size,
      order: 'desc',
      orderBy: 'operatingRevenue',
      aggregation: false,
      isPrimary: true,
    };
    const response = withAPIKey ? await sendPostRequestWithKey(API_ENDPOINT.SEARCH_COMPANY, request, process.env.API_KEY) :
      await sendPostRequestWithState(API_ENDPOINT.SEARCH_COMPANY, request, getState);
    return new Promise<void>((resolve, reject) => {
      if (response && response.isSuccess) {
        const companies: ICompany[] = response.data;
        dispatch(createAction(QUICK_SEARCH.RESULT.COMPANY, { companies }));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const searchNews = (keyword: string, size: number, withAPIKey: boolean = false) => {
  const request: ArticleListReq = {
    searchStr: keyword,
    select: size,
  };
  return async function (dispatch: any, getState: any) {
    const response = withAPIKey ? await sendPostRequestWithKey(API_ENDPOINT.SEARCH_NEWS, request, process.env.API_KEY) :
      await sendPostRequestWithState(API_ENDPOINT.SEARCH_NEWS, request, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(QUICK_SEARCH.RESULT.ARTICLE, { articles: response.data }));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const searchBusinessLines = (keyword: string, size: number) => {
  return async function (dispatch: any, _getState: any) {
    const result = searchBusinessLinesByKeyword(keyword).map((scoreItem) => {
      const { code, description, matchLabels } = scoreItem;
      return {
        code,
        description,
        uniqueIdentifier: code,
        displayName: description,
        matchLabels,
      };
    });
    const sortedResult = sortBy(result, (businessLine) => {
      return businessLine.displayName;
    });
    dispatch(createAction(QUICK_SEARCH.RESULT.BUSINESS_LINES, { businessLines: sortedResult.slice(0, size) }));
  };
};

export const searchStandardScoutAIs = (keyword: string, size: number) => {
  const request = {
    scoutType: [SCOUT_AI_TYPE.STANDARD],
    isPagination: true,
    orderBy: 'name',
    order: 'ASC',
    select: size,
    name: keyword,
    status: [SCOUT_AI_STATUS.GRADUATE]
  };
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.LIST_SCOUT_AI, request, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(QUICK_SEARCH.RESULT.SCOUT_AI,
          { scoutAIs: response.data.slice(0, size) }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};
