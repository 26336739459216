import { getScoutAiFromJson } from '@constants/common';
import { DATA } from '@constants/actions';
import { mapValues, keyBy } from 'lodash';
import createAction from './actionCreator';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';
import { defaultPromise } from './base.action';
import { API_ENDPOINT } from '@constants/api';

export const initScoutAiTopics = () => {
  return async function (dispatch: any) {
    const scoutAIs = getScoutAiFromJson();
    const mapScoutAI = mapValues(
      keyBy(scoutAIs, (s) => s.code),
      (s) => s
    );
    dispatch(
      createAction(DATA.SCOUTAI_TOPICS.LIST, {
        scoutAIs,
        mapScoutAI,
      })
    );
  };
};

export const submitCreateCustomScoutAI = (
  describe: string
) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.CUSTOM_SUPPORT,
      { topic: describe },
      getState
    );
    return defaultPromise(response);
  };
};
