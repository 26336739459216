export const seerExecutive = {
  CEO: 'CEO',
  CFO: 'CFO',
  PSD: 'President',
  MD: 'Managing Director',
  GM: 'General Manager',
  EMP: 'Employee',
  COO: 'COO',
  CIO: 'Chief Investment Officer',
  CTO: 'CTO',
  SAM: 'Sales & Marketing',
  TRE: 'Treasurer',
  LAW: 'Legal Counsel',
  INV: 'Director of Investments',
  IRC: 'Investor Relations',
  HRO: 'HR Director',
  ADM: 'Chief Admin Officer',
  CMP: 'Compliance Officer',
  PRN: 'Corporate Officer',
  AUD: 'Auditor',
};

export const seerBoard = {
  DIR: 'Director',
  ED: 'Executive Director',
  NED: 'Non-Executive Director',
  ID: 'Independent Director',
  LID: 'Lead Independent Director',
  AD: 'Alternate Director',
  CHM: 'Chairman',
  EC: 'Executive Chairman',
  NEC: 'Non-Executive Chairman',
  MP: 'Managing Partner',
  PTR: 'Partner',
  BRD: 'Board Member',
  IND: 'Independent Board Member',
  SHA: 'Shariah Board Member',
  SEC: 'Corporate Secretary',
};

export const seerFounder = { FOU: 'Founder' };
