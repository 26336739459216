import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { ARTICLE_CARD_VIEW, OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import { FormatListBulleted, ViewStream } from '@mui/icons-material';
import useStyles from './styles';
import AtlasDataTrackable from '../atlas/atlas.component';
import classNames from 'classnames';

interface HsToggleButtonProps {
  handleChange: (_, value: string | null) => void;
  view: string;
  customClasses?: {
    toggleGroup?: string,
    toggleButton?: string,
  }
  listViewAtlasTracking: any,
  cardViewAtlasTracking: any,
}

const HsToggleButton = (props: HsToggleButtonProps) => {
  const { handleChange, view, customClasses, listViewAtlasTracking, cardViewAtlasTracking } = props;
  const classes = useStyles();

  return (
    <Box className={classNames(classes.toggleButtonGroup, {
      [customClasses?.toggleGroup]: !!customClasses && customClasses.toggleGroup
    })}>
      <ToggleButtonGroup size='small' value={view} exclusive onChange={handleChange}>
        <ToggleButton 
          className={classNames(classes.toggleButton, {
            [customClasses?.toggleButton]: !!customClasses && !!customClasses.toggleButton
          })}
          value={ARTICLE_CARD_VIEW.COMPACT} 
          aria-label="list"
          {...AtlasDataTrackable(listViewAtlasTracking)}
        >
          <FormatListBulleted />
        </ToggleButton>
        <ToggleButton 
          className={classNames(classes.toggleButton, {
            [customClasses?.toggleButton]: !!customClasses && !!customClasses.toggleButton
          })} 
          value={ARTICLE_CARD_VIEW.DETAILED} 
          aria-label="card"
          {...AtlasDataTrackable(cardViewAtlasTracking)}
        >
          <ViewStream />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default HsToggleButton;