import { SOURCE_TYPE } from '@constants/common';

export interface ITabsContentProps {
  renderTableFoot: (text: string) => JSX.Element;
  companyGuid: string;
}

export interface IRelationshipFactsetContent {
  countryName?: string;
  fromEntity?: string;
  fromName?: string;
  fromRevenue?: string;
  fromSector?: string | null;
  recordFound?: boolean;
  types: any[];
}

export interface IRelationshipOwnershipContent {
  countryName: string;
  fromEntity: string;
  fromName: string;
  ownershipPercentageOwnership: string;
  ownershipPosition: string;
  ownershipPositionChangeSixMonth: string | null;
  ownershipReportDate: string;
  recordFound: string;
}

export interface IRelationshipListParamsProps {
  page: number;
  resultPerPage: number;
}

export interface IGetRelationshipDataParamsProps {
  companyGuid: string;
  skip: number;
  select: number;
  order: string;
  orderBy: string;
  types?: string[];
  source: SOURCE_TYPE;
}

export enum CompanyDetailHeaderFactsetOwnershipID {
  Holder = 'fromName',
  CountryName = 'fromCountryName',
  Country = 'fromCountry',
  Ownership = 'ownershipPercentageOwnership',
  Position = 'ownershipPosition',
  PositionChange = 'ownershipPositionChangeSixMonth',
  ReportDate = 'ownershipReportDate',
}

export enum CompanyDetailTableHeaderFactsetOwnershipDisplay {
  Holder = 'Holder',
  Country = 'Country or region',
  Ownership = '% Ownership',
  Position = 'Position',
  PositionChange = 'Position Change (6M)',
  ReportDate = 'Report Date',
}

export enum CompanyDetailHeaderFactsetCompetitorsID {
  Company = 'fromName',
  Revenue = 'fromRevenue',
  CountryName = 'fromCountryName',
  Sector = 'fromSector',
  Country = 'fromCountry',
}

export enum CompanyDetailHeaderFactsetCompetitorsDisplay {
  Company = 'Company',
  Revenue = 'Revenue (USD)',
  Country = 'Country or region',
  Sector = 'Sector',
}

export enum CompanyDetailHeaderFactsetSupplyChainID {
  Company = 'fromName',
  Revenue = 'fromRevenue',
  Country = 'fromCountry',
  CountryName = 'fromCountryName',
  Sector = 'fromSector',
  Relationship = 'types',
}

export enum CompanyDetailHeaderFactsetSupplyChainDisplay {
  Company = 'Company',
  Revenue = 'Revenue (USD)',
  Country = 'Country or region',
  Sector = 'Sector',
  Relationship = 'Relationship',
}

export enum RelationshipHeaderDisplay {
  Company = 'Company',
  Relationship = 'Relationship',
  MentionedArticles = 'No. of Mentioned Articles',
  LastMentionedArticleHeadline = 'Last Mentioned Article Headline',
  LastMentioned = 'Last Mentioned',
}

export enum RelationshipHeaderID {
  Company = 'fromName',
  Relationship = 'types',
  MentionedArticles = 'articlesCount',
  LastMentionedArticleHeadline = 'latestArticleTitle',
  LastMentioned = 'latestArticlePublishedDate',
}
