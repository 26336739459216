import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import HsDialog from '../dialog/hsDialog/hsDialog.component';
import { Typography } from '@mui/material';
import messages from './messages';
import PrimaryContainedButtonBase from '../button/base/primaryContainedButtonBase.component';
import useModalStyle from '@react/components/modal/styles';
import useStyle from './styles';
import { useDispatch } from 'react-redux';
import { getAgreementStatus as getAgreementStatusAction, acceptAgreement as acceptAgreementAction} from '@redux/actions/widget.actions';
import { ACCOUNT } from '@constants/actions';

const AgreementDialog = (props) => {
    const [open, setOpen] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const modalStyle = useModalStyle();
    const classes = useStyle();
    const intl = useIntl();
    const reduxDispatch = useDispatch();

    const getAgreementStatus = async () => {
        const response: any = await reduxDispatch(getAgreementStatusAction());
        if (response.isSuccess && response.data) {
            const accepted = response.data.accepted;
            if (accepted) {
                setOpen(false);
                localStorage.setItem('agreement-accepted', '1');
            } else {
                setOpen(true);
                localStorage.setItem('agreement-accepted', '0');
            }
            setAccepted(accepted);
            reduxDispatch({type: ACCOUNT.SET_AGREEMENT_ACCEPTED, payload: { accepted }});
        } else {
            setOpen(true);
            localStorage.setItem('agreement-accepted', '0');
        }
    };

    useEffect(() => {
        const storageListener = (event) => {
            if (event.key === 'agreement-accepted' && event.newValue === '1' && !accepted) {
                getAgreementStatus();
            }
        };
        window.addEventListener("storage", storageListener);
        getAgreementStatus();

        return () => {
            window.removeEventListener("storage", storageListener);
        }
    }, []);

    const handleOnAccept = async () => {
        const response: any = await reduxDispatch(acceptAgreementAction());
        if (response.isSuccess) {
            setOpen(false);
            localStorage.setItem('agreement-accepted', '1');
            reduxDispatch({type: ACCOUNT.SET_AGREEMENT_ACCEPTED, payload: { accepted: true }});
        }
    };

    const DialogTitle = () => {
        return <Typography className={modalStyle.title}>
            {intl.formatMessage(messages.agreementDialogTitle)}
        </Typography>
    };

    const DialogContent = () => {
        return <Typography className={modalStyle.contentText}>
            {intl.formatMessage(messages.agreementText)}
        </Typography>
    };

    const DialogAction = () => {
        return <PrimaryContainedButtonBase
            className={classes.agreementButton}
            onClick={handleOnAccept}
        >
            {intl.formatMessage(messages.accept)}
        </PrimaryContainedButtonBase>
    };

    return (
        <HsDialog
          content={<DialogContent />}
          open={open}
          onClose={() => {}}
          title={<DialogTitle />}
          action={<DialogAction />}
          disableEscapeKey
          customClasses={{
            content: modalStyle.dialogContent,
            root: modalStyle.dialogRoot,
            title: modalStyle.titleRoot,
            action: modalStyle.actionRoot,
          }}
        />
      );
};

export default AgreementDialog;