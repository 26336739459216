import { FAQ } from '@constants/actions';
import { IHelpByTopicList } from '@models/faq';
import { Map } from 'immutable';

const initialState = Map({
  summary: '',
  title: '',
  inProgress: false,
  helpTopicList: [] as IHelpByTopicList[],
});

const Faq = (state = initialState, action) => {
  switch (action.type) {
    case FAQ.IN_PROGRESS: {
      return state.set('inProgress', true);
    }
    case FAQ.NOT_IN_PROGRESS: {
      return state.set('inProgress', false);
    }
    case FAQ.RESULT: {
      const { summary, title } = action.payload.data;
      return state.set('summary', summary)
        .set('title', title)
        .set('inProgress', false);
    }
    default:
      return state;
  }
};

export default Faq;