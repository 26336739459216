import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { loadableReady } from '@loadable/component';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import LanguageProvider from '@react/components/language/languageProvider.component';
import { CookiesProvider } from 'react-cookie';
import { IOidcConfiguration } from '@models/oidcConfiguration';
import { UserManager } from 'oidc-client';
import { OIDC } from '@constants/common';
import theme, { widgetTheme } from './theme/theme';
import configureStore from '../redux/configureStore';
import App from './app';
import userManager from './utils/oidcConfig.util';
import { decodeOidcConfiguration } from './utils/common.util';
import CustomOidcProvider from './components/oidc/customOidcProvider.component';
import IFrame from './components/iframe/iframe.component';
import { getFeatureJSON } from './utils/featureToggle.util';
import { featureDb } from './utils/indexedDb.util';
import WidgetApp from './components/widget/widgetApp.component';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const history = createBrowserHistory();
const rootElement =
  document.getElementById('react-app') || document.getElementById('iframe-app');
const featureJSON = getFeatureJSON();

const oidcConfigurationElement = document.querySelector(
  `#${OIDC.CONFIGURATION.HTML_ID}`
);
let oidcConfiguration: IOidcConfiguration = {};
if (oidcConfigurationElement) {
  oidcConfiguration = decodeOidcConfiguration(
    oidcConfigurationElement.innerHTML
  );
  oidcConfigurationElement.parentElement.removeChild(oidcConfigurationElement);
}

if (document.getElementById('iframe-app')) {
  loadableReady(() => {
    hydrate(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <IFrame requestUrl={window.location.href} />
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  });
} else if (document.getElementById('widget-app')) {
  loadableReady(() => {
    const userManagerInstance: UserManager = userManager(oidcConfiguration);
    const store = configureStore(undefined, history, userManagerInstance);
    hydrate(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={widgetTheme}>
          <Provider store={store}>
            <CustomOidcProvider store={store} userManager={userManagerInstance}>
              <LanguageProvider>
                <CookiesProvider>
                  <ConnectedRouter history={history}>
                    <CssBaseline />
                    <WidgetApp partnerInfoString={document.querySelector('#partner-info').innerHTML} />
                  </ConnectedRouter>
                </CookiesProvider>
              </LanguageProvider>
            </CustomOidcProvider>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>,
      document.getElementById('widget-app')
    );
  });
} else {
  loadableReady(() => {
    const userManagerInstance: UserManager = userManager(oidcConfiguration);
    const store = configureStore(undefined, history, userManagerInstance);
    hydrate(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <CustomOidcProvider store={store} userManager={userManagerInstance}>
              <LanguageProvider>
                <CookiesProvider>
                  <ConnectedRouter history={history}>
                    <CssBaseline />
                    <App featureJSON={featureJSON} featureDb={featureDb} />
                  </ConnectedRouter>
                </CookiesProvider>
              </LanguageProvider>
            </CustomOidcProvider>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  });
}
