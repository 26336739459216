import { CONTENT } from '@constants/actions';
import { Map } from 'immutable';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  companyNews: {
    inProgress: false,
    startDate: '',
    endDate: '',
  },
  companyMentionedCount: {
    inProgress: false,
  },
  companyTabNews: {
    inProgress: false,
  },
});

const Content = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT.GET_LIST.IN_PROGRESS:
      return state.set('companyNews', {
        inProgress: true,
      });
    case CONTENT.GET_LIST.NOT_IN_PROGRESS:
      return state.set('companyNews', {
        inProgress: false,
      });
    case CONTENT.GET_LIST.RESULT: {
      const { data, startDate, endDate } = action.payload;
      return state.set('companyNews', {
        inProgress: false,
        [action.payload.guid]: data,
        startDate,
        endDate,
      });
    }
    case CONTENT.GET_MENTIONED_COUNT.IN_PROGRESS:
      return state.set('companyMentionedCount', {
        inProgress: true,
      });
    case CONTENT.GET_MENTIONED_COUNT.NOT_IN_PROGRESS:
      return state.set('companyMentionedCount', {
        inProgress: false,
      });
    case CONTENT.GET_MENTIONED_COUNT.RESULT:
      return state.set('companyMentionedCount', {
        inProgress: false,
        [action.payload.guid]: action.payload.data,
      });
    case CONTENT.GET_TAB_NEWS.IN_PROGRESS:
      return state.set('companyTabNews', {
        inProgress: true,
      });
    case CONTENT.GET_TAB_NEWS.NOT_IN_PROGRESS:
      return state.set('companyTabNews', {
        inProgress: false,
      });
    case CONTENT.GET_TAB_NEWS.RESULT:
      return state.set('companyTabNews', {
        inProgress: false,
        [action.payload.guid]: action.payload.data,
      });
    default:
      return state;
  }
};

export default Content;