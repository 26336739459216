import moment from 'moment';
import { isEmpty } from 'lodash';
import IAnnouncement from './announcement';

export default class ILocalStorageAnnouncement {
  announcement?: IAnnouncement;

  /**
   * Date of which it last query for announcement.
   * Date format in ISO.
   */
  lastSearchDate?: string;

  isClosed?: boolean;

  constructor() {
    this.lastSearchDate = new Date().toISOString();
    this.isClosed = false;
  }

  /**
   * Return `true` if the last fetched date is within one day (24 hours)
   */
  hasFetched(): boolean {
    if (isEmpty(this.announcement)) {
      return false;
    }
    const diff = moment(new Date()).diff(
      moment(this.lastSearchDate),
      'minutes'
    );
    return diff <= 240;
  }

  hasExpired(): boolean {
    if (isEmpty(this.announcement)) {
      return true;
    }
    return moment(new Date()).isAfter(moment(this.announcement.endDate));
  }
}
