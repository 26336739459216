import React from 'react';
import classnames from 'classnames';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    height: '30px',
    width: '170px',
  },
}));

function ScoutAsiaIcon(props) {
  const classes = useStyles();
  const svg = (
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Template-/-Header" transform="translate(-20.000000, -15.000000)">
        <g id="Corporate-/-scoutAsia-logo" transform="translate(20.000000, 15.000000)">
          <g id="scoutAsia-logo">
            <g id="Group-6" transform="translate(0.000000, 8.653846)">
              <g id="Clip-2" />
              <path d="M0.0871794872,12.9807692 C1.96153846,14.4951923 5.01282051,15.4471154 7.01794872,15.4471154 C8.36923077,15.4471154 9.24102564,15.1442308 9.24102564,14.2788462 C9.24102564,13.5 8.63076923,13.1971154 7.45439103,12.9375 L5.92820513,12.5480769 C2.31025641,11.7692308 0,10.0817308 0,6.57692308 C0,2.50961538 3.4,0.215805288 8.02051282,0.215805288 C10.8102564,0.215805288 12.9466987,0.778305288 14.8646474,1.94711538 L14.8646474,7.52830529 C12.9902885,6.18695913 10.8974359,5.27884615 8.67435897,5.27884615 C7.4974359,5.27884615 6.71282051,5.66772837 6.71282051,6.44657452 C6.71282051,7.18269231 7.27948718,7.44230769 8.23900641,7.70192308 L10.025641,8.22115385 C14.2979808,9.34615385 15.9979808,11.0769231 15.9979808,14.3653846 C15.9979808,18.5192308 12.3800321,20.5528846 7.54102564,20.5528846 C4.79487179,20.5528846 1.91794872,19.9038462 0.0871794872,18.6923077 L0.0871794872,12.9807692" id="Fill-1" fill="#0A0B09" />
              <path d="M28.0276603,0.215805288 C30.0327885,0.215805288 31.9076923,0.605228365 33.3025641,1.60042067 L33.3025641,7.3125 C32.0379167,6.40384615 30.6866346,5.92734375 29.0738141,5.92734375 C26.545609,5.92734375 24.5404808,7.44230769 24.5404808,10.3846154 C24.5404808,13.3269231 26.545609,14.8413462 29.0738141,14.8413462 C30.6866346,14.8413462 32.0379167,14.3653846 33.3025641,13.4567308 L33.3025641,19.1682692 C31.9076923,20.1634615 30.0327885,20.5528846 28.0276603,20.5528846 C22.1430449,20.5528846 17.7840705,16.6586538 17.7840705,10.3846154 C17.7840705,4.11057692 22.1430449,0.215805288 28.0276603,0.215805288" id="Fill-3" fill="#0A0B09" mask="url(#scoutAsiaIcon-logo-2)" />
              <path d="M45.245609,5.79807692 C43.1533013,5.79807692 41.9763782,7.70192308 41.9763782,10.3846154 C41.9763782,13.0673077 43.1533013,14.9278846 45.245609,14.9278846 C47.3815064,14.9278846 48.5584295,13.0673077 48.5584295,10.3846154 C48.5584295,7.70192308 47.3815064,5.79807692 45.245609,5.79807692 Z M45.245609,20.5528846 C39.3174038,20.5528846 35.2194231,16.3990385 35.2194231,10.3846154 C35.2194231,4.37019231 39.3174038,0.215805288 45.245609,0.215805288 C51.2174038,0.215805288 55.27125,4.37019231 55.27125,10.3846154 C55.27125,16.3990385 51.2174038,20.5528846 45.245609,20.5528846 L45.245609,20.5528846 Z" id="Fill-4" fill="#0A0B09" mask="url(#scoutAsiaIcon-logo-3)" />
              <path d="M76.8040385,20.1201923 L69.9604487,20.1201923 L69.9604487,18.4326923 C68.9142949,19.6442308 66.9527564,20.5528846 64.7296795,20.5528846 C60.4142949,20.5528846 57.8425,17.7403846 57.8425,13.4134615 L57.8425,0.648497596 L64.6425,0.648497596 L64.6425,11.8125 C64.6425,13.4567308 65.4271154,14.625 67.1707051,14.625 C68.4348077,14.625 69.6117308,13.8028846 69.9604487,12.4615385 L69.9604487,0.648497596 L76.8040385,0.648497596 L76.8040385,20.1201923" id="Fill-5" fill="#0A0B09" />
            </g>
            <polyline id="Fill-7" fill="#0A0B09" points="89.5322436 28.7740385 82.7316987 28.7740385 82.7316987 14.5811899 79.3316987 14.5811899 79.3316987 9.30234375 82.7316987 9.30234375 82.7316987 3.93695913 89.5322436 3.93695913 89.5322436 9.30234375 93.368141 9.30234375 93.368141 14.5811899 89.5322436 14.5811899 89.5322436 28.7740385" />
            <g id="Group-11" transform="translate(94.262821, 2.163462)">
              <g id="Clip-9" />
              <path d="M11.0483654,15.75 L16.5406731,15.75 L13.7945192,8.26388221 L11.0483654,15.75 Z M17.4560577,0.0427283654 L27.6560577,26.6105769 L20.5509295,26.6105769 L18.6329808,21.4615385 L8.91192308,21.4615385 L7.03810897,26.6105769 L0.106794872,26.6105769 L10.3073397,0.0427283654 L17.4560577,0.0427283654 L17.4560577,0.0427283654 Z" id="Fill-8" fill="#007B78" />
              <path d="M29.0939744,19.4711538 C30.9677885,20.9855769 34.0196154,21.9375 36.0247436,21.9375 C37.3760256,21.9375 38.2478205,21.6346154 38.2478205,20.7692308 C38.2478205,19.9903846 37.6375641,19.6875 36.4611859,19.4278846 L34.935,19.0384615 C31.3170513,18.2596154 29.0067949,16.5721154 29.0067949,13.0673077 C29.0067949,9 32.4067949,6.7061899 37.0278526,6.7061899 C39.8175962,6.7061899 41.9534936,7.2686899 43.8708974,8.4375 L43.8708974,14.0186899 C41.9970833,12.6773437 39.9042308,11.7692308 37.6811538,11.7692308 C36.5042308,11.7692308 35.7196154,12.158113 35.7196154,12.9369591 C35.7196154,13.6730769 36.2862821,13.9326923 37.2452564,14.1923077 L39.0324359,14.7115385 C43.3042308,15.8365385 45.0042308,17.5673077 45.0042308,20.8557692 C45.0042308,25.0096154 41.3868269,27.0432692 36.5478205,27.0432692 C33.8016667,27.0432692 30.9241987,26.3942308 29.0939744,25.1826923 L29.0939744,19.4711538" id="Fill-10" fill="#007B78" />
            </g>
            <path d="M141.969071,28.7740385 L148.813205,28.7740385 L148.813205,9.30234375 L141.969071,9.30234375 L141.969071,28.7740385 Z M145.41266,7.65811298 C143.320897,7.65811298 141.533173,6.23022837 141.533173,4.0234976 C141.533173,1.7734976 143.320897,0.345612981 145.41266,0.345612981 C147.505513,0.345612981 149.292692,1.7734976 149.292692,4.0234976 C149.292692,6.23022837 147.505513,7.65811298 145.41266,7.65811298 L145.41266,7.65811298 Z" id="Fill-12" fill="#007B78" />
            <path d="M163.284455,22.1971154 C162.848558,21.375 161.758814,20.8557692 160.538301,20.8557692 C159.187564,20.8557692 157.835737,21.4182692 157.835737,22.7163462 C157.835737,24.0576923 159.187564,24.6201923 160.538301,24.6201923 C161.758814,24.6201923 162.848558,24.1009615 163.284455,23.3221154 L163.284455,22.1971154 Z M163.284455,17.0480769 C163.284455,15.5336538 161.890128,14.4519231 159.100385,14.4519231 C157.225481,14.4519231 155.307532,14.9278846 153.738301,15.8365385 L153.738301,10.3840745 C155.351122,9.51923077 157.967051,8.86965144 160.756795,8.86965144 C166.553686,8.86965144 169.866506,11.595613 169.866506,16.7446514 L169.866506,28.7740385 L163.284455,28.7740385 L163.284455,27.7788462 C162.630609,28.4278846 160.756795,29.1201923 158.663942,29.1201923 C154.784455,29.1201923 151.471635,26.9567308 151.471635,22.8461538 C151.471635,19.0817308 154.784455,16.6586538 159.01266,16.6586538 C160.669071,16.6586538 162.500385,17.1778846 163.284455,17.7403846 L163.284455,17.0480769 L163.284455,17.0480769 Z" id="Fill-13" fill="#007B78" />
          </g>
        </g>
      </g>
    </g>

  );
  return (
    <SvgIcon viewBox="0 0 170 30" className={classnames(classes.root, props.className)}>
      {svg}
    </SvgIcon>
  );
}

export default ScoutAsiaIcon;
