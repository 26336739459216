import React from 'react';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import OpenInNewIcon from '../icons/openInNew.component';
import messages from './messages';
import useStyles from './style';

export interface IExternalLinkProps {
  goToExternalUrl: () => void;
}

function ExternalLink(props: IExternalLinkProps) {
  const { goToExternalUrl } = props;
  const intl = useIntl();
  const classes = useStyles();
  return (
    <>
      <Typography
        component="div"
        className={classes.ExternalsTagContent}
        onClick={() => {goToExternalUrl();}}
      >
        <span
          className={classes.FTexternalLink}
        >
          {intl.formatMessage(messages.externalLinkTag)}
        </span>
        <OpenInNewIcon className={classes.openInNewIcon} />
      </Typography>
    </>
  );
}


export default ExternalLink;
