import { RADAR_SEARCH_TYPE } from '@constants/common';
import { Sector } from 'skyeye-common-const';
import { IBookmarkCompany, IBookmarkFilter } from './bookmark';
import { ICompany } from './company';

export interface RadarStats  {
  radarGuid?: string;
  activeCompanyListCount?: number;
  activeMatchedCompanyCount?: number;
  articleCount?: number;
  companyListCount?: number;
  matchedCompanyListCount?: number;
}
export interface Radar {
  guid?: string;
  name?: string;
  description?: string;
  userGuid?: string;
  createdDate?: string;
  lastModifiedDate?: string;
  deletedStatus?: number;
  lastArticleReadTime?: string;
  lastCompanyReadTime?: string;
  lastFilingReadTime?: string;
  searchCriteria?: RadarSearchCriteria[];
  status?: STATUS_FOR_RADAR;
  counts?: {
    articleSearchCriteria: number;
    companySearchCriteria: number;
    favoriteCompany: number;
    reportFileSearchCriteria: number;
  };
  linkedBookmark?: {
    bookmarkCompanyList?: IBookmarkCompany;
    bookmarkFilters?: IBookmarkFilter[];
  };
  emailAlerts?: RadarEmailAlerts;
  quickAccess?: boolean;
}

export type RadarListItem = Radar & RadarStats;

export interface RadarSearchCriteria {
  guid?: string;
  name?: string;
  type?: string;
  radarGuid?: string;
  searchCriteria?: string;
  status?: string;
  deletedStatus?: number;
  isAllRegions?: boolean;
  isAllPublishers?: boolean;
  lastModifiedDate?: string;
  color?: string;
  description?: string;
  searchTerm?: string;
  searchType?: string;
  filter?: string;
}

export interface RadarSearchCriteriaSetting {
  color?: string;
  updated?: string;
  searchTerm?: string;
  filter?: string;
}

export interface RadarArticle {
  articleGuid?: string;
  mentionedCompanyDetails?: MentionedCompanyDetail[];
  publishedDate?: string;
  providerTitle?: string;
  publisher?: string;
  scoutAi?: string[];
  searchCriteria?: RadarSearchCriteria[];
  status?: string;
  title?: string;
  url?: string;
  summaries?: string[];
  contentBody?: string;
}

export interface RadarArticleOverview {
  radarGuid: string;
  articles: RadarArticle[];
  totalCount?: number;
}

interface MentionedCompanyDetail {
  entityId: string;
  entityType: string;
  preferredName: string;
  primaryEntityId: string;
  primaryScope: string;
  scope: string;
  scoutMasterId: string;
}

export interface RadarCompaniesOverview {
  companyGuid?: string;
  listingStatus?: string;
  name?: string;
  countryName?: string;
  sectorDetails?: Sector[];
  operatingRevenue?: number;
  plBeforeTax?: number;
  marketCapitalization?: number;
  totalAssets?: number;
  shareholdersFunds?: number;
  numOfEmployees?: number;
  mentionCount?: number;
  createdDate?: string;
  modifiedDate?: string;
  description?: string;
}

export interface RadarEmailAlerts {
  cron?: string;
  deletedStatus?: number;
  digestType?: string;
  digestId?: number;
  nextExecution?: string;
  resourceGuid?: string;
  userGuid?: string;
  id?: number;
}

export interface UpdateRadarEmailAlerts {
  req: RadarEmailAlerts;
  isEmailAlertsEmpty?: boolean;
  digestNotActive?: boolean;
}

export interface RadarMentionedCompany {
  companyName?: string;
  count?: number;
  guid?: string;
}

export interface RadarActiveFeeds {
  activeNewsFeed?: ActiveRadarFeed;
}

export interface ActiveRadarFeed {
  type?: 'ALL' | 'INDIVIDUAL' | 'RADARCOMPANIES' | 'COMPANY_FILTER';
  searchCriteriaGuid?: string;
  radarGuid?: string;
  radarSearchType?: RADAR_SEARCH_TYPE;
}

export enum STATUS_FOR_RADAR {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface RadarFavCompany extends Omit<ICompany, 'lastModifiedDate'> {
  id?: string;
  companyGuid?: string;
  radarGuid?: string;
  mentionedCount?: number;
  createdDate?: string;
  lastModifiedDate?: string;
}

export interface RadarMentionCompanyCountByInterval {
  start: string;
  end: string;
  count: number;
}

export interface ITop100MostMentioned {
  companyName: string;
  mentionCount: number;
  companyGuid?: string;
}

export interface IIntervals {
  start: string;
  end: string;
}