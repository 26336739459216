import { DASHBOARD } from '@constants/actions';
import { LOCALIZATION } from '@constants/localization';
import { getLocalStorageDefaultLanguage } from '@react/utils/common.util';
import { Map } from 'immutable';

const initialState = Map({
  articles: {
    inProgress: false,
    data: [],
  },
  targetLists: {
    mentions: {
      inProgress: false,
      data: [],
    },
    myNews: {
      inProgress: false,
      data: [],
    },
  },
  trending: {
    inProgress: false,
    filteredCountry: '',
    selectedCluster: '',
    data: [],
    scrollHeight:'',
  },
  topPageTrending: {
    topPage: [] as string[],
    latestNews: [] as string[]
  },
  defaultLanguage: getLocalStorageDefaultLanguage() || LOCALIZATION.DEFAULT_LOCALE,
  showTranslationLanguage: false,
  myAlerts:{
    inProgress: true,
  },
});

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.ARTICLES.LIST.IN_PROGRESS:
      return state.setIn(['articles', 'inProgress'], true);
    case DASHBOARD.ARTICLES.LIST.NOT_IN_PROGRESS:
      return state.setIn(['articles', 'inProgress'], false);
    case DASHBOARD.ARTICLES.LIST.RESULT: {
      const { news } = action.payload;
      return state.setIn(['articles', 'data'], news);
    }
    case DASHBOARD.DEFAULT_LANGUAGE:{
      const language = action.payload.language;
      return state.setIn(['defaultLanguage'], language);
    }
    case DASHBOARD.SHOW_TRANSLATION_LANGUAGE:
      return state.setIn(['showTranslationLanguage'], action.payload.showTranslationLanguage);
    case DASHBOARD.TRENDING_TOPICS.FILTERED_COUNTRY:{
      return state.setIn(['trending', 'filteredCountry'], action.payload.filteredCountry);
    }
    case DASHBOARD.TRENDING_TOPICS.LIST.IN_PROGRESS:
      return state.setIn(['trending', 'inProgress'], true);
    case DASHBOARD.TRENDING_TOPICS.LIST.NOT_IN_PROGRESS:
      return state.setIn(['trending', 'inProgress'], false);
    case DASHBOARD.TRENDING_TOPICS.LIST.RESULT: {
      return state.setIn(['trending', 'data'], action.payload.trending);
    }
    case DASHBOARD.TRENDING_TOPICS.SELECTED_CLUSTER:{
      return state.setIn(['trending', 'selectedCluster'], action.payload.clusterId);
    }
    case  DASHBOARD.TRENDING_TOPICS.SELECTED_CLUSTER_SCROLL_HEIGHT:{
      return state.setIn(['trending', 'scrollHeight'], action.payload.scrollHeight);
    }
    case DASHBOARD.TOP_PAGE_TRENDING_TOPICS.ALL: {
      return state.setIn(['topPageTrending', 'all'], action.payload.all);
    }
    case DASHBOARD.TOP_PAGE_TRENDING_TOPICS.LATEST_NEWS: {
      return state.setIn(['topPageTrending', 'latestNews'], action.payload.latestNews);
    }
    case DASHBOARD.MY_ALERTS_LOADING.IN_PROGRESS:
      return state.setIn(['myAlerts', 'inProgress'], action.payload.inProgress);
    default:
      return state;
  }
};

export default Dashboard;