import { SOURCE, DRAWER, DATA } from '@constants/actions';
import { mapValues, keyBy } from 'lodash';
import { sources } from '@models/source';
import createAction from './actionCreator';

export const initSources = () => {
  return async function (dispatch: any) {
    const mapSources = mapValues(keyBy(sources, (s) => s.code), (s) => s);
    dispatch(createAction(DATA.SOURCES.LIST, {
      sources,
      mapSources,
    }));
  };
};

export const getDrawerMenuSources = () => {
  return async function (dispatch: any) {
    return new Promise<void>((resolve, _reject) => {
      dispatch(createAction(DRAWER.ARTICLE.DATA.LIST_SOURCES, sources));
      resolve();
    });
  };
};

export const getSources = () => {
  return async function (dispatch: any) {
    return new Promise<void>((resolve, _reject) => {
      const enhancedSources = sources.map((source) => {
        return {
          ...source,
          count: 100,
        };
      });

      dispatch(createAction(SOURCE.SOURCE_LIST, enhancedSources));
      resolve();
    });
  };
};
