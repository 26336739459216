import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.radar.detail.news';

export default defineMessages({
  tags: {
    id: `${scope}.tags`,
    defaultMessage: 'TAGS',
  },
  searchSource: {
    id: `${scope}.searchSource`,
    defaultMessage: 'SEARCH SOURCE',
  },
  yesterday: {
    id: `${scope}.yesterday`,
    defaultMessage: 'YESTERDAY',
  },
  oneMonthAgo: {
    id: `${scope}.oneMonthAgo`,
    defaultMessage: '1 month ago',
  },
  allNews: {
    id: `${scope}.allNews`,
    defaultMessage: 'View {count} articles from the past one year.',
  },
  individualNewsFilter: {
    id: `${scope}.individualNewsFilter`,
    defaultMessage: 'View {count} articles from the past one year, based on your News Filter.',
  },
  newsOnCompany: {
    id: `${scope}.newsOnCompany`,
    defaultMessage: 'View {count} articles from the past one year, mentioning companies matching your Company {type}.',
  },
  viewMore: {
    id: `${scope}.viewMore`,
    defaultMessage: 'View More',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: '{count, plural, one {# COMPANY MENTIONED} other {# COMPANIES MENTIONED}}',
  },
  searchFeedDescription: {
    id: `${scope}.searchFeedDescription`,
    defaultMessage: 'Receive news updates and insights on topics of your interest by syncing a News Filter.',
  },
  noArticlesFoundDescription: {
    id: `${scope}.noArticlesFoundDescription`,
    defaultMessage: 'No News found from the past year matching your News Filter {isEmptyName, select, true {"Company List"} other {"{name}"}}',
  },
  companyFilterNoArticlesDescription: {
    id: `${scope}.companyFilterNoArticlesDescription`,
    defaultMessage: 'No News found mentioning companies matching Company Filter "{name}"'
  },
  companyFilterNoMatchDescription: {
    id: `${scope}.companyListNoMatchDescription`,
    defaultMessage: 'No companies found matching Company Filter "{name}"'
  },
  companyListNoArticlesDescription: {
    id: `${scope}.companyListNoArticlesDescription`,
    defaultMessage: 'No News found mentioning companies matching Company List "{name}"'
  },
  companyListNoMatchDescription: {
    id: `${scope}.companyFilterNoMatchDescription`,
    defaultMessage: 'You have no Saved Companies in this list. Add companies to the list from Company Search or a Company Profile Page.'
  },
  addButton: {
    id: `${scope}.addButton`,
    defaultMessage: 'ADD SEARCH FEED',
  },
  syncFilter: {
    id: `${scope}.syncFilter`,
    defaultMessage: 'SYNC FILTER',
  },
  editSearchFeed: {
    id: `${scope}.editSearchFeed`,
    defaultMessage: 'Edit SEARCH FEEDS',
  },
  editSettings: {
    id: `${scope}.editSettings`,
    defaultMessage: 'Edit SETTINGS',
  },
  scanningNewsFeed: {
    id: `${scope}.scanningNewsFeed`,
    defaultMessage: 'Scanning for news',
  },
  multipleFeeds: {
    id: `${scope}.multipleFeeds`,
    defaultMessage: 'Multiple Search Feeds',
  },
  caughtUp: {
    id: `${scope}.caughtUp`,
    defaultMessage: 'You’re all caught up',
  },
  mentionTitle: {
    id: `${scope}.mentionTitle`,
    defaultMessage: 'Most Mentioned Companies',
  },
  runSearch: {
    id: `${scope}.runSearch`,
    defaultMessage: 'RUN SEARCH',
  },
  autoSummarised: {
    id: `${scope}.autoSummarise`,
    defaultMessage: 'Auto-summarised by ScoutAsia',
  },
  autoTranslate: {
    id: `${scope}.autoTranslate`,
    defaultMessage: 'Auto-translated to English',
  },
  autoTranslateJapanese: {
    id: `${scope}.autoTranslateJapanese`,
    defaultMessage: 'Auto-translated to Japanese',
  },
  companyList: {
    id: `${scope}.companyList`,
    defaultMessage: 'Company List',
  },
  companyFilter: {
    id: `${scope}.companyFilter`,
    defaultMessage: 'Company Filter',
  },
  viewStarredCompanies: {
    id: `${scope}.viewStarredCompanies`,
    defaultMessage: 'View Starred Companies',
  },
  viewMatchedCompanies: {
    id: `${scope}.viewMatchedCompanies`,
    defaultMessage: 'View Matched Companies',
  },
  viewFilter: {
    id: `${scope}.viewFilter`,
    defaultMessage: 'VIEW FILTER',
  },
  viewList: {
    id: `${scope}.viewList`,
    defaultMessage: 'VIEW LIST',
  },
  viewCompanyType: {
    id: `${scope}.viewCompanyType`,
    defaultMessage: 'View Companies matching Company {type}'
  },
  externalLinkTag:{
    id: `${scope}.financialTimesExternalLinkTag`,
    defaultMessage: 'View article in publisher website'
  },
  somethingWrong:{
    id: `${scope}.somethingWrong`,
    defaultMessage: 'Something went wrong'
  },
  errorLoadingNews:{
    id: `${scope}.errorLoadingNews`,
    defaultMessage: 'There was an error loading news. Please try again.'
  },
  retry:{
    id: `${scope}.retry`,
    defaultMessage: 'Retry'
  }
});