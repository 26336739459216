import { ENTITY_TYPE_TEXT } from '@constants/common';
import { IMenuTwoFoldItem } from '@react/components/menu/menuTwoFoldListSelectionHelper';
import { sortBy } from 'lodash';
import { NewsSource } from 'skyeye-common-const/dist/interfaces/newsSource';
import {
  newsCategorySourceMapping,
  reportsCategorySourceMapping,
  exchangeFilingsCategorySourceMapping,
  pressReleaseSourceMapping,
  transcriptsCategorySourceMapping,
  webContentSourceMapping,
  dataMiningSourceMapping,
} from 'skyeye-common-const/dist/reference/newsSource';

const codeSelector = (newSources: NewsSource[]) =>
  newSources.map((source) => source.code);

export function articlesSourceCodes(): Array<string> {
  return [
    ...codeSelector(newsCategorySourceMapping),
    ...codeSelector(pressReleaseSourceMapping),
    ...codeSelector(transcriptsCategorySourceMapping),
    ...codeSelector(webContentSourceMapping),
    ...codeSelector(dataMiningSourceMapping),
  ];
}

export function reportsAndFilingsSourceCodes(): Array<string> {
  return [
    ...codeSelector(exchangeFilingsCategorySourceMapping),
    ...codeSelector(reportsCategorySourceMapping),
  ];
}

function getSources(): Array<ExtendedMenuSource> {
  const mainCategoryLevel = 1;
  const display = [ENTITY_TYPE_TEXT.ARTICLE];

  const mainCategory = {
    news: {
      parent: null,
      code: '1',
      label: 'Articles',
      level: mainCategoryLevel,
      order: 1,
      display,
    },
    exchangeFilings: {
      parent: null,
      code: '2',
      label: 'Exchange Filings',
      level: mainCategoryLevel,
      order: 1,
      display,
    },
    pressReleases: {
      parent: null,
      code: '4',
      label: 'Press Releases',
      level: mainCategoryLevel,
      order: 1,
      display,
    },
    reports: {
      parent: null,
      code: '5',
      label: 'Reports',
      level: mainCategoryLevel,
      order: 1,
      display,
    },
    transcripts: {
      parent: null,
      code: '6',
      label: 'Transcripts',
      level: mainCategoryLevel,
      order: 1,
      display,
    },
    webContent: {
      parent: null,
      code: '7',
      label: 'Web Content',
      level: mainCategoryLevel,
      order: 1,
      display,
    },
  };

  const bindMainCategoryToSubcategory = (
    rawSources: NewsSource[],
    parent: ExtendedMenuSource
  ) => {
    return rawSources
      .map((source, index) => {
        return {
          parent: parent.code,
          level: 2,
          order: parent.order * 1000 + (index + 1),
          display: [...parent.display],
          ...source,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const articleCategorySourceMapping = newsCategorySourceMapping.concat(
    dataMiningSourceMapping
  );

  return [
    mainCategory.news,
    ...bindMainCategoryToSubcategory(
      sortBy(articleCategorySourceMapping, 'code'),
      mainCategory.news
    ),
    mainCategory.exchangeFilings,
    ...bindMainCategoryToSubcategory(
      exchangeFilingsCategorySourceMapping,
      mainCategory.exchangeFilings
    ),
    mainCategory.pressReleases,
    ...bindMainCategoryToSubcategory(
      pressReleaseSourceMapping,
      mainCategory.pressReleases
    ),
    mainCategory.reports,
    ...bindMainCategoryToSubcategory(
      reportsCategorySourceMapping,
      mainCategory.reports
    ),
    mainCategory.transcripts,
    ...bindMainCategoryToSubcategory(
      transcriptsCategorySourceMapping,
      mainCategory.transcripts
    ),
    mainCategory.webContent,
    ...bindMainCategoryToSubcategory(
      webContentSourceMapping,
      mainCategory.webContent
    ),
  ];
}

export interface ExtendedMenuSource extends NewsSource, IMenuTwoFoldItem {
  order: number;
  display: Array<string>;
}

export const sources = getSources();
