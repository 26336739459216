import { CAMPAIGN } from '@constants/actions';
import { Map } from 'immutable';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  selectedCampaign: {},
});

const Campaign = (state = initialState, action) => {
  if (action.type === CAMPAIGN.CAMPAIGN_RETRIEVE) {
    return state.set('selectedCampaign', action.payload.data);
  }
  return state;
};

export default Campaign;