import { DATA } from '@constants/actions';
import { Map } from 'immutable';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  businessLines: {
    name: {},
    tree: {},
  },
  countries: {
    list: [],
    mapping: {},
  },
  allCountries: {
    list: [],
    mapping: {
      alpha2: {},
      alpha3: {},
    },
  },
  sectors: {
    list: [],
    mapping: {},
  },
  sources: {
    list: [],
    mapping: {},
  },
  scoutAIs: {
    inProgress: false,
    custom: {
      list: [],
      mapping: {},
    },
    standard: {
      list: [],
      mapping: {},
    },
    standardFromJson: {
      list: [],
      mapping: {},
    },
    allList: [],
    allMapping: {},
    campaign: {
      list: [],
    },
  },
  scoutAiTopics: {
    list: [],
    mapping: {},
  },
  visualisation: {},
});

const Data = (state = initialState, action) => {
  switch (action.type) {
    case DATA.BUSINESS_LINES.NAME: {
      return state.setIn(['businessLines', 'name'], action.payload.data);
    }
    case DATA.BUSINESS_LINES.TREE: {
      return state.setIn(['businessLines', 'tree'], action.payload.data);
    }
    case DATA.BUSINESS_LINES.PARENT: {
      return state.setIn(['businessLines', 'parent'], action.payload.data);
    }
    case DATA.COUNTRIES_ALL.LIST: {
      return state.setIn(['allCountries'], action.payload.allCountries)
        .setIn(['countries'], action.payload.countries);
    }
    case DATA.SECTORS.LIST: {
      return state
        .setIn(['sectors', 'list'], action.payload.sectors)
        .setIn(['sectors', 'mapping'], action.payload.mapSectors);
    }
    case DATA.SCOUTAI_TOPICS.LIST: {
      return state
        .setIn(['scoutAiTopics', 'list'], action.payload.scoutAIs)
        .setIn(['scoutAiTopics', 'mapping'], action.payload.mapScoutAI);
    }
    case DATA.SOURCES.LIST: {
      return state
        .setIn(['sources', 'list'], action.payload.sources)
        .setIn(['sources', 'mapping'], action.payload.mapSources);
    }
    case DATA.SCOUTAI.IN_PROGRESS: {
      return state.setIn(['scoutAIs', 'inProgress'], action.payload.inProgress); 
    }
    case DATA.SCOUTAI.STANDARD.LIST: {
      const { standards, standardMappings, customs, customMappings, allList, allMappings } = action.payload;
      return state
        .setIn(['scoutAIs', 'standard', 'list'], standards)
        .setIn(['scoutAIs', 'standard', 'mapping'], standardMappings)
        .setIn(['scoutAIs', 'custom', 'list'], customs)
        .setIn(['scoutAIs', 'custom', 'mapping'], customMappings)
        .setIn(['scoutAIs', 'allList'], allList)
        .setIn(['scoutAIs', 'allMapping'], allMappings);
    }
    case DATA.SCOUTAI.VISUALISATION.LIST: {
      return state.setIn(['scoutAIs', 'visualisation'], action.payload.data);
    }
    default:
      return state;
  }
};

export default Data;
