const AGREEMENT_ENDPOINTS = {
  AGREEMENT_STATUS: '/api/account/agreement-status',
  AGREEMENT_ACCEPT: '/api/account/agreement-accept'
};

const ANNOUNCEMENT_ENDPOINTS = {
  ANNOUNCEMENT_LIST: '/api/announcement/list',
};

const SEARCH_ENDPOINTS = {
  AGGREGATION_COMPANY: '/api/search/company/aggregation',
  SEARCH_COMPANY: '/api/search/company',
  SEARCH_COMPANY_ONBOARDING: '/api/search/onboarding/company',
  SEARCH_COMPANY_WITH_CONTENT_COUNT: '/api/search/company/count',
  AGGREGATION_CONTENT: '/api/search/content/aggregation',
  SEARCH_CONTENT: '/api/search/content',
  SEARCH_CONTENT_MENTIONED_COUNT: '/api/search/content/mentionedCount',
  SEARCH_CONTENT_TAB_NEWS: '/api/search/content/tabNews',
  SEARCH_COUNTRY_OVERVIEW: '/api/search/country/overview',
  SEARCH_COUNTRY_DETAIL: '/api/search/country/detail',
  SEARCH_EMIS_LIST: '/api/search/emis',
  SEARCH_EMIS_DOWNLOAD: '/api/search/emis/download',
  SEARCH_NEWS: '/api/search/article',
  SEARCH_RECOMMEND_NEWS: '/api/search/content/recommendnews',
  SEARCH_TRENDING_TOPICS: '/api/search/content/trendingTopics',
  SEARCH_LIBRARY_COUNT: '/api/search/library/count',
  SEARCH_CONTENT_ARTICLE: '/api/search/content/retrieveByIds',
};

const RETRIEVE_ENDPOINTS = {
  RETRIEVE_COMPANY: '/api/retrieve/company',
  RETRIEVE_BY_ID_COMPANY: '/api/retrieve/company/ids',
  RETRIEVE_COMPANY_CONNECTS: '/api/retrieve/company/connects',
  RETRIEVE_NEWS: '/api/retrieve/article',
  RETRIEVE_COMPANY_MARKET: '/api/retrieve/company/market',
  RETRIEVE_COMPANY_FINANCIAL: '/api/retrieve/company/financial',
  RETRIEVE_COMPANY_PEOPLE: '/api/retrieve/company/people',
  RETRIEVE_STATISTIC: '/api/retrieve/statistic',
};

const CAMPAIGN_ENDPOINT = {
  CAMPAIGN_RETRIEVE: '/api/campaign/retrieve',
  CAMPAIGN_REGISTER: '/api/campaign/register',
  CAMPAIGN_REQUEST: '/api/campaign/request',
  CAMPAIGN_UNSUBSCRIBE: '/api/campaign/unsubscribe',
};

const RADAR_ENDPOINT = {
  RADAR_RETRIEVE: '/api/radar/retrieve',
  RADAR_RETRIEVE_BY_IDS: '/api/radar/retrieve-by-ids',
  RADAR_LIST: '/api/radar/list',
  RADAR_CREATE: '/api/radar/create',
  RADAR_CREATE_AND_BIND: '/api/radar/createAndBind',
  RADAR_UPDATE: '/api/radar/update',
  RADAR_DELETE: '/api/radar/delete',
  RADAR_ARTICLE_OVERVIEW: '/api/radar/articleOverview',
  RADAR_STATISTIC: 'api/radar/statistic',
  RADAR_MENTIONED_COMPNAY: 'api/radar/mostMentioned',
  UPDATE_READ_TIME: 'api/radar/update-read-time',
  LIST_FAV_COMPANY: 'api/radar/listFavCompany',
  RADAR_COMPANY_OVERVIEW: '/api/radar/companyOverview',
  ACTIVATE_RADAR: '/api/radar/activate',
  RADAR_SET_QUICK_ACCESS: '/api/radar/setQuickAccess',
  RADAR_LIST_MENTION_COUNT: '/api/radar/listMentionCount',
  RADAR_COUNT_MENTIONED_COMPANY_BY_INTERVAL: '/api/radar/countMentionedCompanyByInterval'
};

const SERVERLESS_ENDPOINT = {
  RADAR_RETRIEVE_COMPANY_MENTIONED: 'api/sls/radar/retrieveCompanyMentioned',
  RADAR_RETRIEVE_COMPANY_OFTEN_MENTIONED:
    'api/sls/radar/retrieveCompanyOftenMentioned',
};

const SEARCH_CRITERIA_ENDPOINT = {
  SEARCH_CRITERIA_RETRIEVE_BY_IDS: '/api/search-criteria/retrieve-by-ids',
  SEARCH_CRITERIA_CREATE: '/api/search-criteria/create',
  SEARCH_CRITERIA_UPDATE: '/api/search-criteria/update',
  SEARCH_CRITERIA_DELETE: '/api/search-criteria/delete',
};

const DATA_ENDPOINT = {
  DATA_SCOUT_AI: '/api/data/scoutai',
};

const EXPORT_ENDPOINT = {
  COMPANY_INFO_EXPORT: '/api/export/company',
  COMPANY_EXPORT_CREDIT_RETRIEVE: '/api/export/company-credit',
  COMPANY_EXPORT_CREDIT_DEDUCT: '/api/export/company-deduct',
  RELATIONSHIP_EXPORT: '/api/relationship/download',
};

const ACCOUNT_ENDPOINTS = {
  LOGIN_ENDPOINT: '/api/account/login',
  LOGOUT_ENDPOINT: '/api/account/logout',
  RESET_PASSWORD: '/api/account/resetpassword',
  FORGET_PASSWORD: '/api/account/forgetpassword',
  ACCEPT_ORG_LINK: '/api/account/acceptorglink',
  ACCOUNT_INFO: '/api/account/accountInfo',
  CHANGE_PASSWORD: '/api/account/changepassword',
  ACCOUNT_UPDATE: '/api/account/update-info',
  CALLBACK_ENDPOINT: '/api/account/callback',
  SIGN_TOKEN: 'api/account/sign-token',
  PARTNER_CREATE: 'api/account/partner-create',
  PARTNER_VALIDATION: 'api/account/partner-validation',
  ACCOUNT_RETRIEVE: '/api/account/retrieve',
};

const USER_ENDPOINTS = {
  REQUEST_ACCESS: '/api/account/requestaccess',
  USER_SUBSCRIPTION: '/api/account/getSubscriptionByUserGuid',
  USER_SUBSCRIPTION_LIST: '/api/account/getUserSubList',
  USER_PREFERENCES: 'api/account/setUserPreferences',
  PARTNER_USER_VALIDATION: 'api/account/partnerValidation'
};

const SCOUT_AI_ENDPOINTS = {
  RETRIEVE_SCOUT_AI: '/api/scout-ai/retrieve',
  RETRIEVE_SCOUT_AI_BY_IDS: '/api/scout-ai/retrieve-by-ids',
  LIST_SCOUT_AI: '/api/scout-ai/list',
  ADD_SCOUT_AI: '/api/scout-ai/add',
  UPDATE_SCOUT_AI: '/api/scout-ai/update',
  DELETE_SCOUT_AI: '/api/scout-ai/delete',
  RETRIEVE_SCOUTAI_RESULT: '/api/scout-ai/retrieve-result',
  RETRIEVE_SCOUTAI_RESULT_WITH_CONTENT: '/api/scout-ai/retrieve-result-content',
  INITIAL_TRAIN_SCOUTAI: '/api/scout-ai/initial-train',
  CONTINUOUS_TRAIN_SCOUTAI: '/api/scout-ai/continuous-train',
  BULK_TRAIN_SCOUTAI: '/api/scout-ai/bulk-train',
  RETRIEVE_ARTICLE_UNTRAINED_SCOUT: '/api/scout-ai/retrieve-untrained',
  SEARCH_TRAINING_ARTICLE: '/api/scout-ai/list-training-content',
  PUBLIC_VISUALISATION: '/api/scout-ai/visualisation',
  CUSTOM_SUPPORT: '/api/scout-ai/custom-support'
};

const NOTIFICATIONSETTING_ENDPOINTS = {
  CREATE_NOTIFICATIONSETTING: '/api/notificationsetting/create',
  LIST_NOTIFICATIONSETTING: '/api/notificationsetting/list',
  DELETE_NOTIFICATIONSETTING: '/api/notificationsetting/delete',
  UPDATE_NOTIFICATIONSETTING: '/api/notificationsetting/update',
  ALERT_NOTIFICATIONSETTING: '/api/notificationsetting/alert',
};

const FAQ_ENDPOINTS = {
  FAQ_LIST: '/api/faq/list',
};

const CONNECTION_PLUS_ENDPOINTS = {
  REDIRECT_CONNECTION_PLUS: '/api/redirect/connex',
};

const JWT_TOKEN_ENDPOINTS = {
  JWT_TOKEN: 'api/getJWTToken/jwtToken',
};

const NIKKEI_ENDPOINTS = {
  NIKKEI_SAVEDSEARCH: '/api/225/savedsearch',
  NIKKEI_ARTICLES: '/api/225/article',
};

const BOOKMARK_ENDPOINT = {
  BOOKMARK_CONTENTLIST_RETRIEVE: '/api/bookmark/contentlist/retrieve',
  BOOKMARK_CONTENTLIST_LIST: '/api/bookmark/contentlist/list',
  BOOKMARK_CONTENTLIST_CREATE: '/api/bookmark/contentlist/create',
  BOOKMARK_CONTENTLIST_DELETE: '/api/bookmark/contentlist/delete',
  BOOKMARK_CONTENTLIST_UPDATE: '/api/bookmark/contentlist/update',
  BOOKMARK_USER_DEFAULT_BOOKMARK_UPDATE:
    '/api/bookmark/userdefaultbookmark/update',
  BOOKMARK_ADD_CONTENT_LIST_ITEM: 'api/bookmark/contentlistitem/add',
  BOOKMARK_DELETE_CONTENT_LIST_ITEM: 'api/bookmark/contentlistitem/delete',
  BOOKMARK_RETRIEVE_CONTENT_LIST_ITEM: 'api/bookmark/contentlistitem/retrieve',
  BOOKMARK_FILTERLIST_LIST: '/api/bookmark/bookmarkfilter/list',
  BOOKMARK_FILTERLIST_CREATE: '/api/bookmark/bookmarkfilter/create',
  BOOKMARK_FILTERLIST_DELETE: '/api/bookmark/bookmarkfilter/delete',
  BOOKMARK_FILTERLIST_UPDATE: '/api/bookmark/bookmarkfilter/update',
  BOOKMARK_FILTERLIST_RETRIEVE: 'api/bookmark/bookmarkfilter/retrieveByIds',
  BOOKMARK_FILTERLIST_BIND: 'api/bookmark/bookmarkfilter/bind',
  BOOKMARK_FILTERLIST_UNBIND: 'api/bookmark/bookmarkfilter/unbind',
  BOOKMARK_COMPANYLIST_BIND: 'api/bookmark/bookmarkcompanylist/bindRadar',
  BOOKMARK_COMPANYLIST_UNBIND: 'api/bookmark/bookmarkcompanylist/unbindRadar',
  BOOKMARK_COMPANYLIST_CREATE: 'api/bookmark/companylist/create',
  BOOKMARK_COMPANYLIST_DELETE: 'api/bookmark/companylist/delete',
  BOOKMARK_COMPANYLIST_UPDATE: 'api/bookmark/companylist/update',
  BOOKMARK_COMPANYLIST_LIST: 'api/bookmark/companylist/list',
  BOOKMARK_COMPANYLIST_RETRIEVE: 'api/bookmark/companylist/retrieve',
  BOOKMARK_COMPANYLIST_ITEM_RETRIEVE: 'api/bookmark/companylistItem/retrieve',
  BOOKMARK_COMPANYLIST_ITEM_ADD: 'api/bookmark/companylistItem/add',
  BOOKMARK_COMPANYLIST_ITEM_DELETE: 'api/bookmark/companylistItem/delete',
  BOOKMARK_SET_QUICK_ACCESS: '/api/bookmark/setQuickAccess',
  BOOKMARK_LIST: '/api/bookmark/list',
  BOOKMARK_RETRIEVE_NEWSHEADLINE: '/api/bookmark/retrieveNewsHeadline',
};

const RADAR_EMAIL_ALERTS_ENDPOINT = {
  RADAR_EMAIL_ALERTS_CREATE: 'api/digest/create',
  RADAR_EMAIL_ALERTS_LIST: 'api/digest/list',
  RADAR_EMAIL_ALERTS_UPDATE: 'api/digest/update',
};

const RELATIONSHIP_ENDPOINT = {
  RELATIONSHIP_LISTRELATIONSHIP: '/api/relationship/listRelationship',
};

const TOP_PAGE_ENDPOINT = {
  TOP_PAGE_RECENT_COMPANY_MENTION: 'api/toppage/recentCompanyMention',
  TOP_PAGE_SCOUT_AI_TOPICS_WIDGET: 'api/toppage/scoutAiTopicsWidget',
  TOP_PAGE_USER_PREFERENCE_RETRIEVE: 'api/toppage/userPreference/retrieve',
  TOP_PAGE_USER_PREFERENCE_UPDATE: 'api/toppage/userPreference/update'
};

export const API_ENDPOINT = {
  ...AGREEMENT_ENDPOINTS,
  ...ACCOUNT_ENDPOINTS,
  ...ANNOUNCEMENT_ENDPOINTS,
  ...RADAR_ENDPOINT,
  ...SEARCH_CRITERIA_ENDPOINT,
  ...SEARCH_ENDPOINTS,
  ...RETRIEVE_ENDPOINTS,
  ...CAMPAIGN_ENDPOINT,
  ...DATA_ENDPOINT,
  ...SCOUT_AI_ENDPOINTS,
  ...NOTIFICATIONSETTING_ENDPOINTS,
  ...FAQ_ENDPOINTS,
  ...CONNECTION_PLUS_ENDPOINTS,
  ...JWT_TOKEN_ENDPOINTS,
  ...USER_ENDPOINTS,
  ...NIKKEI_ENDPOINTS,
  ...EXPORT_ENDPOINT,
  ...SERVERLESS_ENDPOINT,
  ...BOOKMARK_ENDPOINT,
  ...RADAR_EMAIL_ALERTS_ENDPOINT,
  ...RELATIONSHIP_ENDPOINT,
  ...TOP_PAGE_ENDPOINT,
};