import { EMPTY_STRING, RADAR_STARRED_COMPANY_LIMIT, RADAR_MATCHED_COMPANY_LIMIT, RADAR_SEARCH_LIMIT, LIMIT_COUNT } from '@constants/common';
import { FONT } from '@constants/style';
import { Box, Typography } from '@mui/material';
import { BookmarkDialogError } from '@models/bookmark';
import ExclaimationMarkSquareIcon from '@react/components/icons/exclaimationMarkSquareIcon.component';
import { DUPLICATE_NAME_TYPE, RADAR_DIALOG_ERROR_TYPE } from '@constants/enum';
import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getRadarArticleSCLimitFromFeature } from '@redux/selectors/radar.selector';
import messages from './messages';
import useStyles from './styles';

interface IDialogErrorProps {
  errorType?:string;
  isCompany?:boolean;
  isSaveCompanies?:boolean;
  articleSearchTerm?:string;
  classNameType?:string;
  isList?:boolean;
  isRadar?:boolean;
}

export default function DialogError(props:IDialogErrorProps) {
  const { errorType, isCompany, isSaveCompanies, articleSearchTerm, classNameType, isList, isRadar } = props;

  const intl = useIntl();
  const classes = useStyles();
  const radarArticleSCLimit = useSelector((state) => getRadarArticleSCLimitFromFeature(state));

  const renderDuplicateErrorInfo = () => {
    if (isRadar) {
      return DUPLICATE_NAME_TYPE.RADAR;
    }
    if (isList) {
      return DUPLICATE_NAME_TYPE.LIST;
    }
    return DUPLICATE_NAME_TYPE.FILTER;
  };

  const isQuickAccess = classNameType === BookmarkDialogError.QUICK_ACCESS;

  const DialogErrorContent = () => {
    let errorMessage = EMPTY_STRING;
    let errorSubMessage: string | ReactNode = EMPTY_STRING;
    
    switch (errorType) {
      case RADAR_DIALOG_ERROR_TYPE.ALL_EXCEED_LIMIT: {
        errorMessage = isSaveCompanies ?
          intl.formatMessage(messages.limitReachedCompanies, { isAll: true, count: RADAR_STARRED_COMPANY_LIMIT })
          : intl.formatMessage(messages.limitReachedSearchFeed, { isAll: true, count: isCompany ? RADAR_MATCHED_COMPANY_LIMIT : radarArticleSCLimit, isNews: !isCompany });
        errorSubMessage = intl.formatMessage(isSaveCompanies ? messages.limitReachedSubCompanies : messages.limitReachedSubSearchFeed);
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.SELECTED_EXCEED_LIMIT: {
        errorMessage = isSaveCompanies ?
          intl.formatMessage(messages.limitReachedCompanies, { isAll: false, count: RADAR_STARRED_COMPANY_LIMIT })
          : intl.formatMessage(messages.limitReachedSearchFeed, { isAll: false, count: isCompany ? RADAR_MATCHED_COMPANY_LIMIT : radarArticleSCLimit, isNews: !isCompany });
        errorSubMessage = intl.formatMessage(isSaveCompanies ? messages.limitReachedSubCompanies : messages.limitReachedSubSearchFeed);
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.SAVE_ERROR: {
        errorMessage = intl.formatMessage(messages.unableToSave, { isSearch: !isSaveCompanies });
        errorSubMessage = intl.formatMessage(messages.limitReached, { isNews: !isCompany, count: radarArticleSCLimit });
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.DUPLICATE_NAME_ERROR: {
        errorMessage = intl.formatMessage(messages.nameExists, { type: renderDuplicateErrorInfo() });
        errorSubMessage = intl.formatMessage(messages.changeDuplicateName);
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.MORE_THAN_QUICK_ACCESS_LIMIT: {
        errorMessage = intl.formatMessage(messages.moreThanErrorTitle, { count: isQuickAccess ? LIMIT_COUNT['TWELVE'] :LIMIT_COUNT['FOUR'] });
        errorSubMessage = intl.formatMessage(messages.moreThanFourErrorContent);
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.MAXIMUM_RADARS_FILTERS_SLOTS: {
        errorMessage = intl.formatMessage(messages.maximumErrorMessage);
        errorSubMessage = (
          <>
            {intl.formatMessage(messages.maximumErrorSubMessage)}
            <a href='mailto:support@scout.asia'>{intl.formatMessage(messages.support)}</a>. {intl.formatMessage(messages.filterBeSaved)}
          </>
        );
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.MAXIMUM_RADARS_LIST_SLOTS: {
        errorMessage = intl.formatMessage(messages.maximumErrorMessage);
        errorSubMessage = (
          <>
            {intl.formatMessage(messages.maximumErrorSubMessage)}
            <a href='mailto:support@scout.asia'>{intl.formatMessage(messages.support)}</a>. {intl.formatMessage(messages.listBeSaved)}
          </>
        );
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.UPDATE_FILTER_ERROR: {
        errorMessage = intl.formatMessage(messages.updateFilterError);
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.MAXIMUM_RADARS_ERROR: {
        errorMessage = intl.formatMessage(messages.maximumRadar);
        errorSubMessage = intl.formatMessage(messages.maximumRadarSubtitle, { isList: isSaveCompanies });
        break;
      }
      case RADAR_DIALOG_ERROR_TYPE.UPDATE_LIST_ERROR: {
        errorMessage = intl.formatMessage(messages.updateListError);
        break;
      }
      default:
        break;
    }

    return (
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <ExclaimationMarkSquareIcon
            className={classes.messageIcon}
            fill={isQuickAccess ? 'none' : FONT.COLOR.TEXT.MAIN}
            color={isQuickAccess ? '#ffffff' : '#ffeef0'}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography className={classes.errorLabel}>
            {errorMessage}
          </Typography>
          <Typography>
            {errorSubMessage}
          </Typography>
        </Box>
      </Box>
    );
  };

  const witchClass = () => {
    switch (classNameType) {
      case BookmarkDialogError.RENAME:
        return classes.renameError;
      case BookmarkDialogError.TOP:
        return classes.errorTop;
      case BookmarkDialogError.SAVE_FILTER:
        return classes.saveFilterError;
      case BookmarkDialogError.QUICK_ACCESS:
        return classes.quickAccess;
      case BookmarkDialogError.MAXIMUM_RADAR_MONITOR:
        return classes.maximumRadarMonitor;
      default:
        return classes.messageBox;
    }
  };

  return (
    <Box className={classnames(witchClass(), {
      [classes.infoBox]: !!articleSearchTerm,
      [classes.errorBox]: !!errorType,
    })}
    >
      {errorType && <DialogErrorContent />}
    </Box>
  );
}
