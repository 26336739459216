import { ACTION } from '@constants/actions';
import { EMPTY_STRING, ENTITY_TYPE_TEXT } from '@constants/common';
import { transformedDateFilterLabel } from '@react/utils/common.util';
import { constructCountries, constructScoutAI, constructSectors, constructSources } from '@redux/selectors/filter.selector';
import { isEmpty } from 'lodash';
import { ExtendedMenuCompany } from '@models/company';
import createAction from '../actionCreator';
import { updateSearchQueryData } from './filterBar.actions';
import { populateSelectedCompanies } from '../company.actions';


const constructCompanies = async (mentionedCompanies, getState) => {
  if (isEmpty(mentionedCompanies)) {
    return EMPTY_STRING;
  }

  if (mentionedCompanies.length > 1) {
    return `${mentionedCompanies.length} selected`;
  }

  return mentionedCompanies[0]?.displayName;
};

export const updateSelectedPublicationDate = (
  selected,
  updateQueryObject = false
) => {
  return async function (dispatch: any) {
    dispatch(createAction(ACTION.FILTER.NEWS.PUBLICATION_DATE.UPDATE_SELECTED, selected));
    const { start, end } = selected;
    const publicationDateInfo = transformedDateFilterLabel(start, end);
    if (isEmpty(start) || isEmpty(end)) {
      dispatch(createAction(ACTION.FILTER.NEWS.PUBLICATION_DATE.UPDATE_ADDITIONAL_INFO, EMPTY_STRING));
    } else {
      dispatch(createAction(ACTION.FILTER.NEWS.PUBLICATION_DATE.UPDATE_ADDITIONAL_INFO, publicationDateInfo));
    }

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedNewsSectors = (
  selectedSectors,
  updateQueryObject = false
) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.SECTOR.UPDATE_SELECTED, selectedSectors));
    const sectorInfo = constructSectors(selectedSectors, ENTITY_TYPE_TEXT.ARTICLE, getState());
    dispatch(createAction(ACTION.FILTER.NEWS.SECTOR.UPDATE_ADDITIONAL_INFO, sectorInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedScoutAIs = (
  selectedScoutAIs,
  updateQueryObject = false
) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.SCOUT_AI.UPDATE_SELECTED, selectedScoutAIs));
    const scoutAIInfo = constructScoutAI(selectedScoutAIs, getState());
    dispatch(createAction(ACTION.FILTER.NEWS.SCOUT_AI.UPDATE_ADDITIONAL_INFO, scoutAIInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedScoutAIsTopics = (
  selectedScoutAis,
  updateQueryObject = false
) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.SCOUT_AI_TOPICS.UPDATE_SELECTED, selectedScoutAis));
    const info = constructSectors(selectedScoutAis, ENTITY_TYPE_TEXT.ARTICLE, getState());
    dispatch(createAction(ACTION.FILTER.NEWS.SCOUT_AI_TOPICS.UPDATE_ADDITIONAL_INFO, info));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedMentionedCompanies = (
  selectedMentionedCompanies,
  updateQueryObject = false
) => {
  return async (dispatch, getState) => {
    const selectedCompanyIds = isEmpty(selectedMentionedCompanies[0]?.entityId) ? selectedMentionedCompanies : selectedMentionedCompanies.map((company) => company.entityId);
    let formattedMentionedCompanies = selectedMentionedCompanies;
    if (selectedMentionedCompanies.length > 0 && isEmpty(selectedMentionedCompanies[0]?.entityId)) {
      const selectedMentionedCompanyInfo = await dispatch(populateSelectedCompanies(selectedCompanyIds));
      const formattedSelectedMentionedCompanyInfo: ExtendedMenuCompany[] = selectedMentionedCompanyInfo && selectedMentionedCompanyInfo.map((company) => {
        return {
          entityId: company.entityId,
          displayName: company.name,
          uniqueIdentifier: company.entityId,
          countryName: company?.countryName,
        };
      });
      formattedMentionedCompanies = formattedSelectedMentionedCompanyInfo;
    }

    formattedMentionedCompanies = [...formattedMentionedCompanies].sort((a, b) => a.displayName?.localeCompare(b.displayName));
    dispatch(createAction(ACTION.FILTER.NEWS.MENTIONED_COMPANY.UPDATE_LABEL, formattedMentionedCompanies));
    dispatch(createAction(ACTION.FILTER.NEWS.MENTIONED_COMPANY.UPDATE_SELECTED, selectedCompanyIds));
    const mentionedCompaniesInfo = await constructCompanies(formattedMentionedCompanies, getState);
    dispatch(createAction(ACTION.FILTER.NEWS.MENTIONED_COMPANY.UPDATE_ADDITIONAL_INFO, mentionedCompaniesInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedSource = (selectedSource, updateQueryObject = false) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.SOURCE.UPDATE_SELECTED, selectedSource));
    const sourceAdditionalInfo = constructSources(selectedSource, getState());
    dispatch(createAction(ACTION.FILTER.NEWS.SOURCE.UPDATE_ADDITIONAL_INFO, sourceAdditionalInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateNewsSelectedCountriesRegions = (selectedCountriesRegions, updateQueryObject = false) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.COUNTRIES_REGIONS.UPDATE_SELECTED, selectedCountriesRegions));
    const countriesRegionsAdditionalInfo = constructCountries(selectedCountriesRegions, getState());
    dispatch(createAction(ACTION.FILTER.NEWS.COUNTRIES_REGIONS.UPDATE_ADDITIONAL_INFO, countriesRegionsAdditionalInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const resetNewsFilters = () => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.RESET_STATES));
  };
};

export const setMentionCompaniesAccordion = (type) => {
  return (dispatch, _getState) => {
    dispatch(createAction(ACTION.FILTER.NEWS.MENTIONED_COMPANY.CONTROL_ACCORDION, type));
  };
};

export const setNewsFilterItemStatus = (filterItemStatus: {}, updateQueryObject= false) => {
  return (dispatch, getState) => {
    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};
