import React from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import useStyles from '../../radar/detail/news/styles';

interface IArticleListItemContentProps {
  isDefaultLanguage?: boolean,
  languageData?,
  showContent?: boolean,
  restricted?: boolean,
  summaries?: string[],
  content: string,
}

const ArticleListItemContent: React.FC<IArticleListItemContentProps> = (props) => {
  const { restricted, showContent, content, summaries, languageData, isDefaultLanguage } = props;
  const classes = useStyles();

  const renderContent = () => {
    return (
      <>
        <Typography
          className={classes.contentBody}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </>
    );
  };
    
  const renderSummarize = () => {
    return (
      <ul className={classes.summarizeUl}>
        {summaries.map((summary, index) => {
          return <li key={`summary-${index}`}>{summary}</li>;
        })}
      </ul>
    );
  };

  if (isEmpty(summaries)) {
    let contentHtml = content;
    if (!isEmpty(languageData)) {
      contentHtml = languageData.content;
    }
    return (
      <Typography
        component="article"
        className={classNames(
          classes.contentBody,
          !isDefaultLanguage && classes.nonEnglishContent
        )}
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      />
    );
  }
  if (restricted || showContent) {
    return renderContent();
  }

  return renderSummarize();
};

export default ArticleListItemContent;