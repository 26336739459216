import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IExclaimationMarkIconProps extends ISvgIconProps {
  fill?: string,
}

const ExclaimationMarkSquareIcon = (props: IExclaimationMarkIconProps) => {
  const { color, className, fill } = props;
  const svg = (
    <g fill={fill} fillRule='evenodd'>
      <path d="M0 0H24V24H0z" />
      <path fill={color} fillRule="nonzero" d="M20.182 3H3.818C3.366 3 3 3.336 3 3.75v16.5c0 .414.366.75.818.75h16.364c.452 0 .818-.336.818-.75V3.75c0-.414-.366-.75-.818-.75zM12 17.25c-.452 0-.818-.336-.818-.75s.366-.75.818-.75c.452 0 .818.336.818.75s-.366.75-.818.75zm.818-3h-1.636V7.5h1.636v6.75z" />
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 24 24" htmlColor={color} fontSize="inherit" className={className}>
      {svg}
    </SvgIcon>
  );
};

ExclaimationMarkSquareIcon.defaultProps = {
  color: '#BD0016',
  fill: 'none',
} as Partial<IExclaimationMarkIconProps>;

export default ExclaimationMarkSquareIcon;