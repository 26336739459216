import { ARTICLE_PUBLISHER, LOCKED, NIKKEI_PUBLISHERS, TRANSLATION_PUBLISHER } from '@constants/common';
import { IArticle } from '@models/article';
import { createSelector } from 'reselect';
import { dataMiningSourceMapping } from 'skyeye-common-const';
import { restrictedPublisher, shouldTranslationArticle } from '@react/utils/common.util';
import { isShowPopupNotPermission, isSourceLocked, subscriptionFeaturesObjectSelector } from './account.selector';
import { LOCALIZATION } from '@constants/localization';
import { isEqual } from 'lodash';

export const getArticles = (state) : IArticle[] => state.getIn(['Article', 'articleList']);
const getArticlesInProgress = (state) => state.getIn(['Article', 'searchInProgress']);
const getArticlesTotalCount = (state) => state.getIn(['Article', 'totalCount']);
const getCurrentProps = (state) => state.getIn(['Article', 'listProps']);

export const articlesProgressSelector = createSelector([getArticlesInProgress], (progress) => progress);
export const articlesCountSelector = createSelector([getArticlesTotalCount], (count) => count);
export const articlesListPropsSelector = createSelector([getCurrentProps], (currentProps) => currentProps);

const getArticle = (_state, props) => props.article;

export interface IArticleItemProps {
  includeTranslationIcon,
  isDataMiningSource,
  isLocked,
  isFT,
  sourceLocked,
  isRestrictedPublisher,
  isShowNotPermission,
  translationTypes: string[];
  isNikkeiPublisher: boolean;
}

export const getArticleItemPropsSelector = createSelector([getArticle, subscriptionFeaturesObjectSelector], (article, subscriptionFeatures): IArticleItemProps => {
  const includeTranslationIcon = TRANSLATION_PUBLISHER.includes(
    article.publisher
  );
  const dataMiningSource = dataMiningSourceMapping.map((source) => source.code);
  const isDataMiningSource = dataMiningSource.includes(article.publisher);
  const isLocked = article.status === LOCKED && !isDataMiningSource;
  const isFT = article.publisher === ARTICLE_PUBLISHER.FINANCIAL_TIMES;
  const sourceLocked = isSourceLocked(subscriptionFeatures, article.publisher);
  const isShowNotPermission = isShowPopupNotPermission(subscriptionFeatures, article.publisher);
  const isRestrictedPublisher = restrictedPublisher(article.publisher);
  const isNikkeiPublisher = !!NIKKEI_PUBLISHERS.find(x => isEqual(x.toLowerCase(), article?.publisher?.toLowerCase()));

  let translationTypes = [];
  if(includeTranslationIcon) translationTypes.push(LOCALIZATION.EN);
  if(shouldTranslationArticle(article, LOCALIZATION.JA) && !isNikkeiPublisher) translationTypes.push(LOCALIZATION.JA);
  
  return {
    includeTranslationIcon,
    isDataMiningSource,
    isLocked,
    isFT,
    sourceLocked,
    isShowNotPermission,
    isRestrictedPublisher,
    translationTypes,
    isNikkeiPublisher,
  };
});