import { SvgIcon } from '@mui/material';
import React from 'react';

interface IOutlinedStarIconProps{
  className?: string
}

const OpenInNewIcon = (props: IOutlinedStarIconProps) => {
  const { className } = props;
  const svg = (
    <path d="M1.64994 9.24998C1.39994 9.24998 1.18744 9.16248 1.01244 8.98748C0.837439 8.81248 0.749939 8.59999 0.749939 8.34999V1.64998C0.749939 1.39998 0.837439 1.18748 1.01244 1.01248C1.18744 0.837485 1.39994 0.749985 1.64994 0.749985H4.81244V1.49998H1.64994C1.61661 1.49998 1.58327 1.51665 1.54994 1.54998C1.51661 1.58332 1.49994 1.61665 1.49994 1.64998V8.34999C1.49994 8.38332 1.51661 8.41665 1.54994 8.44998C1.58327 8.48332 1.61661 8.49998 1.64994 8.49998H8.34994C8.38327 8.49998 8.41661 8.48332 8.44994 8.44998C8.48327 8.41665 8.49994 8.38332 8.49994 8.34999V5.18748H9.24994V8.34999C9.24994 8.59999 9.16244 8.81248 8.98744 8.98748C8.81244 9.16248 8.59994 9.24998 8.34994 9.24998H1.64994ZM3.86244 6.66248L3.33744 6.13748L7.97494 1.49998H5.99994V0.749985H9.24994V3.99998H8.49994V2.02498L3.86244 6.66248Z"  />
  );
  
  return (
    <SvgIcon className={className} viewBox="0 0 10 10" fontSize="inherit">
      {svg}
    </SvgIcon>
  );
};

export default OpenInNewIcon;