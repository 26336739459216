import { QUICK_ACCESS } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { BOOKMARK_NAVBAR_TAB, BOOKMARK_TYPE  } from '@constants/common';
import { isTheSameString } from '@react/utils/common.util';
import { isEmpty } from 'lodash';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';
import createAction from './actionCreator';
import { getBookmarkNewsHeadlineListAction } from './bookmarkNewsHeadline.actions';

const typeToTab = {
  [BOOKMARK_TYPE.CONTENT_LIST]: BOOKMARK_NAVBAR_TAB.NEWS,
  [BOOKMARK_TYPE.COMPANY_LIST]: BOOKMARK_NAVBAR_TAB.COMPANIES,
  [BOOKMARK_TYPE.CONTENT_FILTER]: BOOKMARK_NAVBAR_TAB.NEWSFILTERS,
  [BOOKMARK_TYPE.COMPANY_FILTER]: BOOKMARK_NAVBAR_TAB.COMPANIESFILTER,
};

export const getQuickAccessList = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(QUICK_ACCESS.LIST.GET));
  };
};

export const listQuickAccessBookmark = () => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_LIST,
      {
        isPagination: true,
        quickAccess: true,
        order: 'DESC',
        orderBy: 'createdDate',
      },
      getState
    );

    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const selected = {
          [BOOKMARK_NAVBAR_TAB.NEWS]: [],
          [BOOKMARK_NAVBAR_TAB.COMPANIES]: [],
          [BOOKMARK_NAVBAR_TAB.NEWSFILTERS]: [],
          [BOOKMARK_NAVBAR_TAB.COMPANIESFILTER]: [],
        };
        const activeBookmarkList = [];
        response.data.forEach(bookmark => {
          const activeBookmark = {
            guid: bookmark.entityGuid,
            name: bookmark.entityName,
            count: bookmark.totalCount,
          };
          selected[typeToTab[bookmark.entityType]].push(activeBookmark);
          activeBookmarkList.push(activeBookmark);
        });
        dispatch(createAction(QUICK_ACCESS.LIST.UPDATE, { selected, active: activeBookmarkList }));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

const getBookmarks = (selected, currentActive: any[]) => {
  const bookmarks = [];
  const updatedCurrentActive = [...currentActive];
  Object.values(selected).forEach((list: any) => {
    if (!isEmpty(list)) {
      list.forEach((item) => {
        const currentActiveIndex = updatedCurrentActive.findIndex(active => isTheSameString(item.guid, active.guid));
        if (currentActiveIndex !== -1) {
          updatedCurrentActive.splice(currentActiveIndex, 1);
        }
        bookmarks.push({
          entityGuid: item.guid,
          quickAccess: true,
        });
      });
    }
  });
  const noLongerActive = updatedCurrentActive.map(active => ({
    entityGuid: active.guid,
    quickAccess: false,
  }));
  return [...bookmarks, ...noLongerActive];
};

export const updateQuickAccessList = (selected: any) => {
  return async function (dispatch: any, getState: any) {
    const bookmarks = getBookmarks(selected, getState().getIn(['QuickAccess', 'active']));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_SET_QUICK_ACCESS,
      { bookmarks },
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        resolve(response);
        dispatch(listQuickAccessBookmark());
        dispatch(getBookmarkNewsHeadlineListAction({}));
      } else {
        reject(response);
      }
    });
  };
};

