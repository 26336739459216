import { SITE_URLS } from './routes';

export type Order = 'asc' | 'desc' | false;

export const ORDER = {
  asc: 'asc',
  desc: 'desc',
  false: false,
};

export const COMPANY_TABLE: ICompanyTable = {
  DEFAULT_ORDER_BY: 'operatingRevenue',
  DEFAULT_ORDER: 'desc',
  REDIRECT_URL: SITE_URLS.COMPANY_DETAIL,
};

export const COMPANY_TABLE_KEYWORD_IN: ICompanyTable = {
  DEFAULT_ORDER_BY: 'score',
  DEFAULT_ORDER: 'desc',
  REDIRECT_URL: SITE_URLS.COMPANY_DETAIL,
};

export const COPYRIGHT_TEXT = {
  BVD: 'Source: Bureau Van Dijk Electroniques SA',
  CREDITSAFE: 'Source: Creditsafe Business Information Japan Ltd.',
  FIINGROUP: 'Source: FiinGroup',
  VENTUREINTELLIGENCE: 'Source: Venture Intelligence',
  FWP: '© FWP Research (Myanmar).',
  FWPHEAD: 'Source: Copyright',
  FWPTAIL: 'All rights reserved',
};

export const SOURCE_FULL_NAME = {
  BVD: 'Bureau Van Dijk Electroniques SA',
  FACTSET: 'FactSet UK Limited',
  CREDITSAFE: 'Creditsafe Business Information Japan Ltd',
  FIINGROUP: 'FiinGroup',
  VENTUREINTELLIGENCE: 'Venture Intelligence',
  FWP: 'FWP Research (Myanmar)',
};

interface ICompanyTable {
  DEFAULT_ORDER_BY: string;
  DEFAULT_ORDER: Order;
  REDIRECT_URL: string;
}

export const SCOUT_AI_TRAIN_BUTTON_TYPE = {
  RELEVANT: 'relevant',
  NON_RELEVANT: 'nonrelevant',
};

export const FINANCIAL_TAB = {
  OVERVIEW: 'OVERVIEW',
  P_AND_L: 'P&L',
  BALANCE_SHEET: 'BALANCE SHEET',
  CASH_FLOW: 'CASH FLOW',
};

export const MARKET_DURATION_TAB = {
  FIVE_DAYS: '5 D',
  ONE_MONTH: '1 M',
  SIX_MONTHS: '6 M',
  YEAR_TO_DATE: 'YTD',
  ONE_YEAR: '1 Y',
  FIVE_YEARS: '5 Y',
  MAX: 'MAX',
};

export const STANDARD_LAYOUT_DEFAULT_UI_PROPS = {
  backToTopEnabled: false,
  textBackToTop: '',
  announcementIsFixed: false,
  noOverflow: false,
  fullBodyWidth: false,
  showConnectionPlusBanner: false,
  showLastSearchBanner: false,
  showTargetListBanner: false,
  showWelcomeBanner: false,
  responsive: true,
  paddingX: 2,
};

export const SWITCH_MENU_ITEM_ID = {
  onsite: 0,
  email: 1,
};

export enum SearchDrawerContentTab {
  Companies,
  News,
}

export const MentionCompaniesEmptyData = {
  abort : true,
  data: {
    mentionCompanies: [],
    total: 0,
  },
  isSuccess: true,
};

export interface Options {
  label: string;
  value: string;
}

export const RelationshipOptions: Options[] = [{
  value: 'ownerCountries',
  label: 'Owners',
},
{
  value: 'supplierCountries',
  label: 'Suppliers',
},
{
  value: 'customerCountries',
  label: 'Customers',
},
{
  value: 'partnerCountries',
  label: 'Partners',
},
{
  value: 'competitorCountries',
  label: 'Competitors',
}];