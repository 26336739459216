import { Box, Skeleton, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import messages from './messages';
import { FONT } from '@constants/style';
import { openLinkInNewTab, useQuery } from '@react/utils/common.util';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveStatistic } from '@redux/actions/widget.actions';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { SITE_URLS } from '@constants/routes';
import AtlasDataTrackable, { IAtlasDataTrackable } from '../atlas/atlas.component';
import { EMPTY_STRING, OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';

const SectionTitle = ({ text }) => {
    const classes = useStyles();
    return (
        <Typography
            className={classes.sectionTitle}
        >
            {text}
        </Typography>
    )
};

const sectorImageMap = {
    "0001": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/1-OilAndGas.png',
    "1000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/2-BasicMaterials.png',
    "2000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/3-Industrials.png',
    "3000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/4-ConsumerGoods.png',
    "4000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/5-Healthcare.png',
    "X4900": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/6-SocialAndGovernment.png',
    "5000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/7-ConsumerServices.png',
    "6000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/8-Telecommunication.png',
    "7000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/9-Utilities.png',
    "8000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/10-Financials.png',
    "9000": 'https://storageaccountsaeprodsvc.blob.core.windows.net/scout-asia-public/images/sectors/11-Technology.png',
};

interface IItemStatistic {
    guid: string,
    name: string,
    aggregation: number,
}

interface ISectorStatistic {
    code: string,
    name: string,
    aggregation: number,
}

interface IStatistic {
    activeTopics?: IItemStatistic[],
    activeSectors?: ISectorStatistic[],
    coMentionedCompanies?: IItemStatistic[],
}

const StatisticWidget:FC = (props) => {
    const intl = useIntl();
    const classes = useStyles();
    const query = useQuery();
    const reduxDispatch = useDispatch();
    const agreementStatus = useSelector((state: any) => state.getIn(['Account', 'agreementAccepted']));

    const entityGuid = query.get('guid');
    const startDate = query.get('from');
    const endDate = query.get('to');
    
    const [statistic, setStatistic] = useState<IStatistic>({
        activeTopics: [],
        activeSectors: [],
        coMentionedCompanies: [],
    });
    const [loading, setLoading] = useState(true);


    const getExternalParamsFromQuery = () => {
        let externalParams = `&mc=${entityGuid}`;
        if (startDate) {
            externalParams += `&f=${moment(startDate).startOf('day').toISOString()}`;
        }
        if (endDate) {
            externalParams += `&t=${moment(endDate).toISOString()}`;
        }

        return externalParams;
    }
    
    const getCompanyStatistic = async () => {
        const statisticResponse: any = await reduxDispatch(retrieveStatistic({
            mentionedCompanies: [entityGuid],
            start: startDate ? moment(startDate).startOf('day').toISOString(): moment(0).toISOString(),
            end: endDate ? moment(endDate).toISOString() : moment().toISOString(),
        }));

        if (statisticResponse && statisticResponse.isSuccess) {
            const { standardScouts, sectorScouts, coMentions } = statisticResponse.data;
            setStatistic({
                activeSectors: sectorScouts,
                activeTopics: standardScouts,
                coMentionedCompanies: coMentions,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (entityGuid && agreementStatus) {
            getCompanyStatistic();
        }
    }, [agreementStatus]);

    const renderActiveTopics = () => {
        if (loading) {
            return (
                <Box className={classes.activeTopicContainer}>
                    {[1,2,3,4,5].map(index => 
                        <Skeleton
                            key={`active-topic-loading-${index}`}
                            variant="rectangular"
                            width={60}
                        />
                    )}
                </Box>
            );
        }

        if (isEmpty(statistic.activeTopics)) {
            return (
                <Typography>
                    {intl.formatMessage(messages.emptyTopic)}
                </Typography>
            );
        }

        return (
            <Box className={classes.activeTopicContainer}>
                {statistic.activeTopics.slice(0, 5).map(topic => {
                    return (
                        <Typography
                            component="a"
                            onClick={() => {
                                openLinkInNewTab(`${window.location.origin}${SITE_URLS.SEARCH_ARTICLE}?ep&sa=${topic.guid}${getExternalParamsFromQuery()}`);
                            }}
                            className={`${classes.activeTopicItem} ${classes.statisticLink}`}
                            key={`active-topic-${topic.guid}`}
                            {...AtlasDataTrackable({
                                targetAttribute: 'sa-widget-statistic',
                                atlastracking: {
                                  uiElementGroup:  UI_ELEMENT_GROUP.STATISTIC_WIDGET,
                                  uiElement: UI_ELEMENT.ACTIVE_TOPICS,
                                  objectName: OBJECT_NAME.SCOUTAI,
                                  objectNameDetails: topic.guid,
                                }
                            })}
                        >
                            {`${topic.name} (${topic.aggregation})`}
                        </Typography>
                    )
                })}
            </Box>
        );
    };

    const renderActiveSectors = () => {
        if (loading) {
            return (
                <Box marginTop={1}>
                    {[1,2,3,4].map(index =>
                        <Box key={`active-sector-loading-${index}`} display="flex" marginBottom={0.75}>
                            <Skeleton
                                variant="rectangular"
                                width={48}
                                height={48}
                            />
                            <Box width="calc(100% - 60px)" gap={1} marginLeft={1.5} display={'flex'} flexDirection={"column"} justifyContent={"center"}>
                                <Skeleton
                                    variant="rectangular"
                                    width="60%"
                                    height="0.9em"
                                />
                                <Skeleton
                                    variant="rectangular"
                                    width="30%"
                                    height="0.9em"
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )
        }

        if (isEmpty(statistic.activeSectors)) {
            return (
                <Typography>
                    {intl.formatMessage(messages.emptySector)}
                </Typography>
            );
        }

        return (
            <Box marginTop={1}>
                {statistic.activeSectors.slice(0, 5).map(sector => {
                    return (
                        <Box display="flex" marginBottom={0.75} key={`active-sector-${sector.code}`}>
                            <img src={sectorImageMap[sector.code]} alt={sector.name} width="48" height="48" />
                            <Box marginLeft={1.5} display={'flex'} flexDirection={"column"} justifyContent={"center"}>
                                <Typography
                                    component="a"
                                    onClick={() => {
                                        openLinkInNewTab(`${window.location.origin}${SITE_URLS.SEARCH_ARTICLE}?ep&s=${sector.code}${getExternalParamsFromQuery()}`);
                                    }}
                                    className={`${classes.itemName} ${classes.statisticLink}`}
                                    {...AtlasDataTrackable({
                                        targetAttribute: 'sa-widget-statistic',
                                        atlastracking: {
                                          uiElementGroup:  UI_ELEMENT_GROUP.STATISTIC_WIDGET,
                                          uiElement: UI_ELEMENT.ACTIVE_SECTORS,
                                          objectName: OBJECT_NAME.SECTOR,
                                          objectNameDetails: sector.code,
                                        }
                                    })}
                                >
                                    {sector.name}
                                </Typography>
                                <Typography className={classes.itemMention}>
                                    {`${sector.aggregation} mentions`}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        );
    };

    const renderListWithBarComponent = (items: IItemStatistic[], itemLink: string = EMPTY_STRING, atlasTrackingObj: IAtlasDataTrackable = {}) => {
        if (loading) {
            return (
                <Box marginTop={1}>
                    {[1,2,3,4].map(index =>
                        <Box key={`list-with-bar-loading-${index}`} marginBottom={1.5} display={"flex"} flexDirection={"column"} gap={1}>
                            <Skeleton
                                variant="rectangular"
                                width="50%"
                                height="1.5em"
                            />
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height='0.4em'
                            />
                            <Skeleton
                                variant="rectangular"
                                width="10%"
                            />
                        </Box>
                    )}
                </Box>
            )
        }
        
        items.sort((a, b) => b.aggregation - a.aggregation);
        const highestCount = items.length > 0 ? items[0].aggregation : 0;

        return (
            <Box marginTop={1}>
                {items.map(item => {
                    const barWidth = ((100 / highestCount) * item.aggregation);
                    return (
                        <Box marginBottom={1.5} key={`list-with-bar-${item.guid}`}>
                            <Typography
                                component={!!itemLink ? "a" : "div"}
                                onClick={() => {
                                    if (!!itemLink) {
                                        openLinkInNewTab(`${itemLink}${item.guid}`);
                                    }
                                }}
                                className={`${classes.itemName} ${!!itemLink && classes.statisticLink}`}
                                {...!isEmpty(atlasTrackingObj) && AtlasDataTrackable({
                                    ...atlasTrackingObj,
                                    atlastracking: {
                                        ...atlasTrackingObj.atlastracking,
                                        objectNameDetails: item.guid,
                                    }
                                })}
                            >
                                {item.name}
                            </Typography>
                            <Box width={`${barWidth}%`} border={`2px solid ${FONT.COLOR.PRIMARY.DARK}`}/>
                            <Typography className={classes.itemMention}>
                                {`${item.aggregation} mentions`}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        );
    };

    const renderEmptyCoMention = () => {
        return (
            <Typography>
                {intl.formatMessage(messages.emptyCoMention)}
            </Typography>
        );
    };

    if (entityGuid === null) {
        return <Box padding={1.5}> No company guid provided </Box>
    }

    const coMentionAtlas = {
        targetAttribute: 'sa-widget-statistic',
        atlastracking: {
          uiElementGroup:  UI_ELEMENT_GROUP.STATISTIC_WIDGET,
          uiElement: UI_ELEMENT.TOP_COMENTIONS,
          objectName: OBJECT_NAME.SECTOR,
        }
    }

    return (
        <Box padding={1.5} overflow={"auto"} className={classes.widgetContentBox}>
            <Box className={classes.sectionContainer}>
                <SectionTitle text={intl.formatMessage(messages.topActiveTopics)} />
                {renderActiveTopics()}
            </Box>
            <Box className={classes.sectionContainer}>
                <SectionTitle text={intl.formatMessage(messages.activeSectors)} />
                {renderActiveSectors()}
            </Box>
            <Box>
                <SectionTitle text={intl.formatMessage(messages.topCoMentionedCompanies)} />
                {isEmpty(statistic.coMentionedCompanies) && !loading ? renderEmptyCoMention() :
                renderListWithBarComponent(statistic.coMentionedCompanies, EMPTY_STRING, coMentionAtlas)}
            </Box>
        </Box>
    );
};

export default StatisticWidget;

