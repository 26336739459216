import { ACCOUNT } from '@constants/actions';
import { EMPTY_STRING } from '@constants/common';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { routes, routesNoneStandardLayout, routesWithStandardLayout } from '@react/routes';
import { IRouteProperties } from '@interface/route';

export const getUserGuid = (state) => state.getIn(['Account', 'accountInfo', 'userGuid']);

export const getGuid = (state) => state.getIn(['Account', 'accountDetail', 'guid']);

export const getUserName = (state) => state.getIn(['Account', 'accountInfo', 'userName']);

export const getName = (state) => state.getIn(['Account', 'accountDetail', 'firstName']);

export const userGuidSelector = createSelector([getUserGuid], (userGuid) => userGuid);

export const getAccountInfo = (state) => state.getIn(['Account', 'accountInfo']);

export const getAccountDetail = (state) => state.getIn(['Account', 'accountDetail']);

export const getUserRole = (state) => state.getIn(['Account', 'userRole']);

export const getIsPublicUser = (state) => state.getIn(['Account', 'accountInfo', 'isPublicUser']);

export const getLoggedIn = (state) => state.getIn(['Account', 'status']);

export const loggedInSelector = createSelector([getLoggedIn], (status) => status === ACCOUNT.LOGGED_IN);

export const userNameSelector = createSelector([getAccountInfo], (accountInfo) => {
  const isLogin = accountInfo.size > 0;
  return !isLogin ?
    EMPTY_STRING :
    [accountInfo.get('firstName') || EMPTY_STRING, accountInfo.get('lastName') || EMPTY_STRING].join(' ');
});

export const getAccountInfoSelector = createSelector([getAccountInfo], (accountInfo) => {
  return accountInfo.toJS();
});

const getIsLoggingOut = (state) => state.getIn(['Account', 'isLoggingOut']);
export const isLoggingOutSelector = createSelector([getIsLoggingOut], (status) => status);

export const getAccountType = (account) => account.getIn(['subcriptionFeaturePermissionModel', 0, 'organisationStatus']);

export const getSubcriptionFeaturePermissionModel = (state) => state.getIn(['Account', 'accountInfo', 'subcriptionFeaturePermissionModel']);

export const subcriptionFeatureSelector = createSelector([getSubcriptionFeaturePermissionModel], (features)=>{
  return getSubscriptionFeatureList(features);
});

const getSubscriptionFeatureList = (features) => {
  if (!features) {
    return [];
  }
  let userFeatureList = [];
  for (const featurePermission of features.toJS()) {
    userFeatureList = Array.from(new Set([...userFeatureList, ...featurePermission.featureList]));
  }
  return userFeatureList;
};

export const getSubscriptionFeatureListWidthRemoveSeperator = createSelector([getSubcriptionFeaturePermissionModel], (features) => {
  const subscriptionFeatureList = getSubscriptionFeatureList(features);
  const subscriptionFeatureObject = [];
  if (subscriptionFeatureList && subscriptionFeatureList.length > 0) {
    subscriptionFeatureList.forEach(feature => {
      const featureKey = removeSeperator(feature);
      subscriptionFeatureObject.push(featureKey);
    });
  }
  return subscriptionFeatureObject;
});

const getSubscriptionPermissionRestriction = (subscriptionFeature: string) => {
  return subscriptionFeature.split('|')[1] || 'FULL';
};

const removeSeperator = (value: string) => {
  if (isEmpty(value)) {
    return EMPTY_STRING;
  }
  const result = value.split('|');
  if (!result || result.length === 0) {
    return EMPTY_STRING;
  }

  return result[0].trim();
};

export const subscriptionFeaturesObjectSelector = createSelector([getSubcriptionFeaturePermissionModel], (features) => {
  const subscriptionFeatureList = getSubscriptionFeatureList(features);
  const subscriptionFeatureObject = {};
  if (subscriptionFeatureList && subscriptionFeatureList.length > 0) {
    subscriptionFeatureList.forEach(feature => {
      // if element isn't empty, add key and value to subscriptionFeatureObject
      const featureKey = removeSeperator(feature);
      if (featureKey) {
        subscriptionFeatureObject[featureKey] = getSubscriptionPermissionRestriction(feature);
      }  
    });
  }
  return subscriptionFeatureObject;
});

export const getFeatureEnabled = (state, featureCode) => {
  const subscriptionFeatures = subscriptionFeaturesObjectSelector(state);
  return isFeatureEnabled(subscriptionFeatures, featureCode);
};

export const isFeatureEnabled = (featuresListObject, featureCode) => {
  return isEmpty(featuresListObject) || featuresListObject[featureCode] === 'LOCKED' ?
    false : !!featuresListObject[featureCode];
};

export const isSourceAvailable = (featuresListObject, featureCode) => {
  return isEmpty(featuresListObject) ? false : !!featuresListObject[featureCode];
};

export const isSourceLocked = (featuresListObject, featureCode) => {
  return !isEmpty(featuresListObject) && featuresListObject[featureCode] === 'LOCKED';
};

export const isShowPopupNotPermission = (featuresListObject, featureCode) => {
  if (isEmpty(featuresListObject)) return true;

  return featuresListObject[featureCode] !== 'FULL';
};

export const getActiveRoutes = createSelector([subscriptionFeaturesObjectSelector], (subscriptionFeatures) => {
  let standardRoutes: IRouteProperties[] = [];
  let nonStandardRoutes: IRouteProperties[] = [];
  let standardRoutesPaths: string[] = [];
  let nonStandardRoutesPaths: string[] = [];

  if (isEmpty(subscriptionFeatures)) {
    standardRoutes = routesWithStandardLayout;
    nonStandardRoutes = routesNoneStandardLayout;
    standardRoutesPaths = routesWithStandardLayout.map(route => route.path);
    nonStandardRoutesPaths = routesNoneStandardLayout.map(route => route.path);
  } else {
    routes.forEach(route => {
      if (route.permission && !isFeatureEnabled(subscriptionFeatures, route.permission)) {
        return null;
      }
      
      if (route.standardLayout) {
        standardRoutes.push(route);
        standardRoutesPaths.push(route.path);
      } else {
        nonStandardRoutes.push(route);
        nonStandardRoutesPaths.push(route.path);
      }
    });
  
  }

  return { standardRoutes, standardRoutesPaths, nonStandardRoutes, nonStandardRoutesPaths };
});