import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography, useMediaQuery, Card, CardContent } from '@mui/material';
import { getPublisherLabel, openNewPage, openLinkInNewTab } from '@react/utils/common.util';
import { isEmpty } from 'lodash';
import TranslationIcon from '@react/components/icons/translationIcon.component';
import theme from '@react/theme/theme';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { IArticle, IStandardArticle } from '@models/article';
import ArticleListItemDate from '@react/components/article/articleListItem/articleListItem.date';
import ExternalLink from '@react/components/externalLink/externalLink.component';
import useWidgetStyles from './styles';
import useRadarArticleStyles from '@react/components/radar/detail/news/styles';
import useArticleStyles from '../styles';
import { SITE_URLS } from '@constants/routes';
import AutoSummarisationIcon from '@react/components/icons/autoSummarisation.component';
import radarArticleMessages from '@react/components/radar/detail/news/messages';
import ArticleListItemTags from '../articleListItem.tag';
import { filteredScoutAiSelector } from '@react/components/list/scoutai/scoutAI.selector';
import { IArticleItemProps, getArticleItemPropsSelector } from '@redux/selectors/article.selector';
import ArticleListItemContent from '../articleListItem.content';
import KeyIcon from '@react/components/icons/keyIcon.component';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AtlasDataTrackable from '@react/components/atlas/atlas.component';
import { OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import { FONT } from '@constants/style';

interface IStandardArticleCardOwnProps {
  article: IStandardArticle,
  title: string,
  isCompact?: boolean,
  itemRef?: any,
}

interface IStandardArticleCardStateProps {
  filteredScoutAi,
  articleItemProps: IArticleItemProps,
}

export type IStandardArticleCardProps = IStandardArticleCardStateProps & IStandardArticleCardOwnProps;

const StandardWidgetArticleCardView: React.FC<IStandardArticleCardProps> = (props) => {
  const { title, article, isCompact, filteredScoutAi, articleItemProps, itemRef } = props;
  const radarArticleClasses = useRadarArticleStyles();
  const widgetClasses = useWidgetStyles();
  const articleItemClasses = useArticleStyles();
  const intl = useIntl();
  const { isDataMiningSource, isFT, isSCMP, isLocked, sourceLocked, isPublisherEmis, includeTranslationIcon, isRestrictedPublisher } = articleItemProps;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const goToExternalUrl = () => {
    let externalUrl = article.url;
    openNewPage(externalUrl);
  };

  const renderExternalLink = () => {
    return (
      <>
        <ExternalLink
          goToExternalUrl={goToExternalUrl}
        />
      </>
    );
  };

  const renderStatusIcon = () => {
    if (isLocked) {
      return <KeyIcon />;
    }
    if (sourceLocked) {
      return <LockOutlinedIcon sx={{fontSize: '16px'}} />;
    }
    return null;
  };

  const renderTitle = () => {
    return (
        <a
          aria-label="widget-news-title"
          className={classNames(articleItemClasses.titleText, articleItemClasses.titleLink, {
            [widgetClasses.title]: isCompact,
            [articleItemClasses.title]: !isCompact,
          })}
          onClick={() => {
            openLinkInNewTab(`${window.location.origin}${SITE_URLS.ARTICLE_DETAIL}/${article.entityId}`);
          }}
          dangerouslySetInnerHTML={{ __html: title }}
          {...AtlasDataTrackable({
            targetAttribute: 'sa-widget-news',
            atlastracking: {
              uiElementGroup:  UI_ELEMENT_GROUP.NEWS_WIDGET,
              uiElement: isCompact ? UI_ELEMENT.ARTICLE_LIST : UI_ELEMENT.ARTICLE_CARD,
              objectName: OBJECT_NAME.ARTICLE,
              objectNameDetails: article.entityId,
            }
          })}
        />
    )
  };

  const renderTranslationIcon = () => {
    return (
      <span className={classNames(widgetClasses.translationIcon)}><TranslationIcon /></span>
    )
  };

  if (isCompact) {
    return (
      <Box ref={itemRef} className={classNames(widgetClasses.compactRoot)}>
        <Box>
          <Typography
            component="span"
            className={classNames(widgetClasses.metadata)}
          >
            {`${getPublisherLabel(article.publisher, article.providerTitle)?.toUpperCase()} | `}
            <ArticleListItemDate article={article} />
          </Typography>
        </Box>
        <Typography className={classNames(widgetClasses.title)}>
          {renderTitle()}
          {includeTranslationIcon ? renderTranslationIcon() : null}
        </Typography>
      </Box>
    )
  }

  return (
    <Card
      ref={itemRef}
      className={classNames(
        radarArticleClasses.root,
        widgetClasses.widgetArticleCardRoot,
      )}
    >
      <CardContent className={radarArticleClasses.topSection}>
        <Box className={classNames(radarArticleClasses.header)} display="flex" justifyContent="space-between">
          <Typography
            className={radarArticleClasses.sources}
            color="textSecondary"
            component="div"
            variant="caption"
          >
            <span className={radarArticleClasses.TextStyle}>
              {getPublisherLabel(article.publisher, article.providerTitle)}
            </span>
            {renderStatusIcon()}
            <ArticleListItemDate article={article} />
          </Typography>
        </Box>
        <Box className={classNames(radarArticleClasses.titleText,{
          [radarArticleClasses.title]: !isCompact,
          // [radarArticleClasses.nonEnglishTitle]: !isDefaultLanguage,
        })}
        >
          <div>{renderTitle()}</div>
        </Box>
        <Box className={radarArticleClasses.tag}>
          <ArticleListItemTags
            scoutAis={filteredScoutAi}
            mentionedCompanies={article.mentionedCompanyDetails}
          />
          {!isEmpty(article.summaries) &&
            !sourceLocked &&
            !isRestrictedPublisher && (
              <Typography className={radarArticleClasses.tagText}>
                <AutoSummarisationIcon className={radarArticleClasses.summarizeIcon} />
                {!isMobile && intl.formatMessage(radarArticleMessages.autoSummarised)}
              </Typography>
          )}
          {includeTranslationIcon && (
            <Typography className={radarArticleClasses.tagText}>
              <TranslationIcon className={radarArticleClasses.transIcon} />
              {!isMobile && intl.formatMessage(radarArticleMessages.autoTranslate)}
            </Typography>
          )}
        </Box>
      </CardContent>
      <CardContent className={radarArticleClasses.bottomSection}>
        <ArticleListItemContent
          content={article.contentBody}
          summaries={article.summaries}
          showContent={isFT}
          restricted={isRestrictedPublisher || sourceLocked}
        />
        {isDataMiningSource && renderExternalLink()}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, props) => ({
  filteredScoutAi: filteredScoutAiSelector(state, props),
  articleItemProps: getArticleItemPropsSelector(state, props),
  defaultLanguage: state.getIn(['Dashboard', 'defaultLanguage']),
});


export default connect<
  IStandardArticleCardStateProps
>(
  mapStateToProps,
)(StandardWidgetArticleCardView);
