import { Box, Paper, CircularProgress, Grid, IconButton, List, ListItem } from '@mui/material';
import { sendPostRequestWithAuth } from 'skyeye-fe-common-util';
import { API_ENDPOINT } from '@constants/api';
import { IFRAME } from '@constants/config';
import { Close as CloseIcon, ReportProblem as ReportProblemIcon } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { pluginInit as initAtlas, trackIframePage, initialized as atlasInitialized } from '@react/utils/analytics/atlaswrapper.util';
import { AGREEMENT, ARTICLE_PUBLISHER, LOCAL_STORAGE } from '@constants/common';
import useStyles from './styles';
import { encodeToJwt, getPublisherLabel } from '../../utils/common.util';
import TranslationIcon from '../icons/translationIcon.component';

type IFrameProps = {
  requestUrl?: string,
};

const IFrame = (props: IFrameProps) => {
  const { requestUrl } = props;

  const url = new URL(requestUrl);
  const params = url.searchParams;
  const userId = params.get('userid');
  const level = params.get('level');
  const category = params.get('category');
  const theme = params.get('theme').toLowerCase();

  const [newsList, setNewsList] = useState([]);
  const [skipAmount, setSkipAmount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [analyticsInit, setAnalyticsInit] = useState(false);
  const [noMoreArticle, setNoMoreArticle] = useState(false);
  const [cookieBannerHeight, setCookieBannerHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const loader = useRef(null);

  if (atlasInitialized) {
    const detailAnalyticModel = {
      'data-trackable': 'widget',
    };
    const pageTrackingData = {
      ...detailAnalyticModel,
      content: { asset_type: 'story' },
    };  
    trackIframePage(pageTrackingData);
  }

  const handleResizeEvent = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (!analyticsInit) {
      setAnalyticsInit(true);
      const analyticsObj = {
        affiliateUserId: userId,
        userLevel: level,
        inWidget: true,
        widgetOrigin: url.origin,
        widgetData: { 
          category, 
          theme, 
        },
      };
      initAtlas(userId, analyticsObj);
    }
  }, [analyticsInit]);

  useEffect(()=>{
    setCookieBannerHeight(document.getElementById('legalese')?.clientHeight);
  }, [windowWidth, showBanner]);

  useEffect(() => {
    try {
      if (localStorage.getItem(LOCAL_STORAGE.AGREE_IFRAME_USES_COOKIES) !== AGREEMENT.AGREE) {
        setShowBanner(true);
      }
    } catch (e) {
      setShowBanner(true);
      console.log('no localstorage to use.');
    }
    window.addEventListener('resize', handleResizeEvent);
    // TODO: adjust cat after confirm the final format of url
    sendPostRequestWithAuth(API_ENDPOINT.NIKKEI_SAVEDSEARCH, { cat: category }).then((savedsearchRes)=>{
      if (isEmpty(savedsearchRes.data)) {
        setNoMoreArticle(true);
        return;
      }
      const term = JSON.parse(savedsearchRes.data[0].searchTerm);
      setSearchTerm(term);
      sendPostRequestWithAuth(API_ENDPOINT.NIKKEI_ARTICLES, { select: 25, ...term }).then((res)=>{
        setIframeLoading(false);
        if (isEmpty(res.data) || res.data.length < 25) {
          setNoMoreArticle(true);
        } else {
          setNewsList(res.data);
          if (loader.current) {
            const observer = new IntersectionObserver(handleObserver, {});
            observer.observe(loader.current);
          }
        }
      }).catch(()=>{setShowGeneralError(true);});
    }).catch(()=>{setShowGeneralError(true);});
  }, []);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {   
      setSkipAmount((preAmount) => preAmount + 25);
    }
  };

  useEffect(() => {
    if (skipAmount) {
      // TODO: adjust cat after confirm the final format of url
      sendPostRequestWithAuth(API_ENDPOINT.NIKKEI_ARTICLES, { skip: skipAmount, select: 25, ...searchTerm }).then((res)=>{
        if (isEmpty(res.data) || res.data.length < 25) {
          setNoMoreArticle(true);
        } else {
          const newList = newsList.concat(res.data);
          setNewsList(newList);
        }
      }).catch(()=>{setShowGeneralError(true);});
    }
  }, [skipAmount]);

  const classes = useStyles();

  const currentTime = new Date().getTime();
  const hashId = level.toLowerCase() === IFRAME.AFFILIATE_CONFIG.SGX.STARTER_USER_LEVEL ? IFRAME.AFFILIATE_CONFIG.SGX.STARTER_HASH_ID : userId;

  // https://github.com/auth0/node-jsonwebtoken
  const urlParams = encodeToJwt({
    affiliateUserId: hashId,
    origin: IFRAME.AFFILIATE_CONFIG.SGX.ORIGIN,
    ulv: level,
    exp: Math.floor(currentTime / 1000) + IFRAME.AFFILIATE_CONFIG.SGX.EXPIRE_TIME,
    cat: category,
    te: theme,
    originalAffiliateUserId: userId,
  });

  const articleRef = (entityId) => `/delivery?ag=${entityId}&tk=${urlParams}`;

  const caculateDifference = (difference, publishTime) => {
    const date = new Date(publishTime * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const differenceFlag = () => {
      if (difference < 3600) {
        return `${Math.round(difference / 60)} ${Math.round(difference / 60) === 1 ? 'minute' : 'minutes'} ago`;
      }
      if (difference >= 3600 && difference < 86400) {
        return  `${Math.round(difference / 3600)} ${Math.round(difference / 3600) === 1 ? 'hour' : 'hours'}  ago`;
      }
      return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    };

    return differenceFlag();
  };

  const getTimeDifference = (publishTime) => {
    const currentTimestamp = currentTime / 1000;
    const difference = currentTimestamp - publishTime;
    return caculateDifference(difference, publishTime);
  };

  const getInnerText = (title) => {
    const tempElement = document.createElement('span');
    tempElement.innerHTML = title;
    return tempElement.innerText;
  };

  const renderErrorBoxWithMessage = (message) => {
    return(
      <Grid container justify-content='center' className={classes.noContentBox}>
        <Grid item xs={8}>
          <Paper 
            variant="outlined" 
            square 
            classes={{
              root: classes.paperRoot,
              outlined: classes.paperOutlined,
            }}
          >
            <Grid container justify-content='center' spacing={3}>
              <Grid item>
                <ReportProblemIcon color='error' />
              </Grid>
              <Grid item className={theme === 'dark' && classes.darkAlertText}>
                {message}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const setConsentInLocalStorage = () => {
    try {
      localStorage.setItem(LOCAL_STORAGE.AGREE_IFRAME_USES_COOKIES, AGREEMENT.AGREE);
    } catch (e) {
      console.log('no localstorage to use.');
    }
  };

  return (
    <div className={classes.font}>
      {showBanner && 
        <Grid id='legalese' className={classes.legaleseBanner} container direction='row' alignItems='flex-start' justifyContent='space-between'>
          <div className={classes.BannerText}>
            The content available in this widget is provided by Nikkei, Inc., and belongs to us or our licensors. By using a ScoutAsia widget, you are consenting to ScoutAsia’s 
            <span> </span>
            <a target="_blank" href='https://www.scout.asia/cookie-policy/' className={classes.legaleseLink}>Cookie Policy</a> and 
            <span> </span>
            <a target="_blank" href='https://www.scout.asia/privacy-policy/' className={classes.legaleseLink}>Privacy Policy</a>, and agreeing to abide by them. Content auto-translated from the original language is indicated, and ScoutAsia disclaims all warranties, express or implied, related to these translations.   
          </div>
          <div>
            <IconButton
              edge='end'
              classes={{
                root: classes.crossButton,
              }}
              onClick={()=>{setShowBanner(false);setCookieBannerHeight(0);setConsentInLocalStorage();}}
              size="large"
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </div>
        </Grid>}
      <List 
        disablePadding={!showBanner} 
        className={classNames({
          [classes.darkThemeList]:theme === 'dark',
          [classes.list]: theme !== 'dark',
        })}
        style={{ marginTop:cookieBannerHeight }}
      >
        {!iframeLoading && 
          <ListItem className={theme === 'dark' ? classes.listItemDark : classes.listItem}>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <span className={classes.icon}>
                <TranslationIcon />
              </span> 
              <span className={theme === 'dark' ? classes.darkTranslation : classes.translation}>
                =  Auto-translated from Japanese
              </span>
            </Grid>
          </ListItem>}
        {!iframeLoading && newsList.map((value, index)=>(
          <ListItem key={index} className={theme === 'dark' ? classes.listItemDark : classes.listItem}>
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item direction='column' zeroMinWidth className={classes.leftPart}>
                <a href={articleRef(value.entityId)} className={classes.aTag} target="_blank">
                  <Grid container direction='row'>
                    <div
                      className={classNames({
                        [classes.darkTitle]:theme === 'dark',
                        [classes.title]: theme !== 'dark',
                        [classes.nikkeiTitle]: value.publisher === ARTICLE_PUBLISHER.THE_NIKKEI,
                      })}
                    >
                      {getInnerText(value.title)}        
                    </div>
                    {
                      value.publisher === ARTICLE_PUBLISHER.THE_NIKKEI && 
                      <span className={classes.icon}>
                        <TranslationIcon />
                      </span>   
                    }
                  </Grid>
                </a>
                <div className={theme === 'dark' ? classes.darkPublisher : classes.publisher}>
                  {getPublisherLabel(value.publisher, value.providerTitle)}
                </div>
              </Grid>
              <Grid item direction='column' alignItems='center' className={classes.rightPart}>
                <div className={theme === 'dark' ? classes.darkTime : classes.time}>
                  {getTimeDifference(value.publishedDate)}
                </div>
              </Grid>
            </Grid>
          </ListItem>
        ))}
        {(!noMoreArticle && !showGeneralError) && 
          <Grid className={classes.loading} ref={loader} container justifyContent='center' direction='row'>
            <Grid item>
              <Box m={2}>
                <CircularProgress color='secondary' />
              </Box>
            </Grid>
          </Grid>}
        {(noMoreArticle && isEmpty(newsList)) && renderErrorBoxWithMessage('No content is currently available. Please try again later.')}
        {showGeneralError && renderErrorBoxWithMessage('There’s been a server error (500). Please try again later.')}
      </List>
    </div>
  );
};

export default IFrame;
