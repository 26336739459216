export const ACCOUNT = {
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  UPDATE_COMPANY_EXPORT_CREDIT: 'UPDATE_COMPANY_EXPORT_CREDIT',
  GET_INFO: 'GET_INFO',
  SET_USER_ROLE: 'SET_USER_ROLE',
  SET_AGREEMENT_ACCEPTED: 'SET_AGREEMENT_ACCEPTED',
};

export const ANNOUNCEMENT = {
  INIT: {
    IN_PROGRESS: 'ANNOUNCEMENT_INIT_PROGRESS',
    NOT_IN_PROGRESS: 'ANNOUNCEMENT_INIT_NOT_IN_PROGRESS',
    RESULT: 'ANNOUNCEMENT_INIT_RESULT',
  },
};

export const BUSINESS_LINES = {
  INIT: 'BUSINESS_LINES_INIT',
};

export const COMPANY = {
  COMPANY_LIST: 'COMPANY_LIST',
  COMPANY_RETRIEVE: 'COMPANY_RETRIEVE',
  SEARCH_COMPANY_DRAWER_MENU: 'SEARCH_COMPANY_DRAWER_MENU',
  SEARCH_IN_PROGRESS: 'COMPANY_SEARCH_IN_PROGRESS',
  RETRIEVE_IN_PROGRESS: 'COMPANY_RETRIEVE_IN_PROGRESS',
  UPDATE_LIST_PROPS: 'COMPANY_UPDATE_LIST_PROPS',
  STORE_SEARCH_STR: 'COMPANY_STORE_SEARCH_STR',
};

export const CONNECTION = {
  GET_DATA: {
    IN_PROGRESS: 'CONNECTION_GET_DATA_IN_PROGRESS',
    NOT_IN_PROGRESS: 'CONNECTION_GET_DATA_NOT_IN_PROGRESS',
    RESULT: 'CONNECTION_GET_DATA_RESULT',
  },
};

export const CONTENT = {
  GET_LIST: {
    IN_PROGRESS: 'CONTENT_GET_LIST_IN_PROGRESS',
    NOT_IN_PROGRESS: 'CONTENTGET_LIST_NOT_IN_PROGRESS',
    RESULT: 'CONTENT_GET_LIST_RESULT',
  },
  GET_MENTIONED_COUNT: {
    IN_PROGRESS: 'CONTENT_GET_MENTIONED_COUNT_IN_PROGRESS',
    NOT_IN_PROGRESS: 'CONTENT_GET_MENTIONED_COUNT_NOT_IN_PROGRESS',
    RESULT: 'CONTENT_GET_MENTIONED_COUNT_RESULT',
  },
  GET_TAB_NEWS: {
    IN_PROGRESS: 'CONTENT_GET_TAB_NEWS_IN_PROGRESS',
    NOT_IN_PROGRESS: 'CONTENT_GET_TAB_NEWS_NOT_IN_PROGRESS',
    RESULT: 'CONTENT_GET_TAB_NEWS_RESULT',
  },
};

export const COUNTRY = {
  OVERVIEW: {
    IN_PROGRESS: 'COUNTRY_OVERVIEW_IN_PROGRESS',
    RESULT: 'COUNTRY_OVERVIEW_RESULT',
  },
  DETAILS: {
    IN_PROGRESS: 'COUNTRY_DETAILS_IN_PROGRESS',
    NOT_IN_PROGRESS: 'COUNTRY_DETAILS_NOT_IN_PROGRESS',
    RESULT: 'COUNTRY_DETAILS_RESULT',
    ARTICLE: {
      LIST: {
        IN_PROGRESS: 'COUNTRY_DETAILS_ARTICLE_LIST_IN_PROGRESS',
        NOT_IN_PROGRESS: 'COUNTRY_DETAILS_ARTICLE_LIST_NOT_IN_PROGRESS',
        RESULT: 'COUNTRY_DETAILS_ARTICLE_LIST_RESULT',
      },
    },
    COMPANY: {
      LIST: {
        IN_PROGRESS: 'COUNTRY_DETAILS_COMPANY_LIST_IN_PROGRESS',
        NOT_IN_PROGRESS: 'COUNTRY_DETAILS_COMPANY_LIST_NOT_IN_PROGRESS',
        RESULT: 'COUNTRY_DETAILS_COMPANY_LIST_RESULT',
      },
    },
  },
  EMIS: {
    LIST: {
      IN_PROGRESS: 'COUNTRY_EMIS_LIST_IN_PROGRESS',
      NOT_IN_PROGRESS: 'COUNTRY_EMIS_LIST_NOT_IN_PROGRESS',
      RESULT: 'COUNTRY_EMIS_LIST_RESULT',
    },
    DOWNLOAD: {
      IN_PROGRESS: 'COUNTRY_EMIS_DOWNLOAD_IN_PROGRESS',
      NOT_IN_PROGRESS: 'COUNTRY_EMIS_DOWNLOAD_NOT_IN_PROGRESS',
      RESULT: 'COUNTRY_EMIS_DOWNLOAD_RESULT',
    },
  },
  EMIS_RESULT: 'COUNTRY_EMIS_RESULT',
  EMIS_DOWNLOAD_RESULT: 'COUNTRY_EMIS_DOWNLOAD_RESULT',
  DRAWER_MENU_COUNTRY_lIST: 'DRAWER_MENU_COUNTRY_lIST',
};

export const ARTICLE = {
  ARTICLE_LIST: 'ARTICLE_LIST',
  ARTICLE_SELECTED: 'ARTICLE_SELECTED',
  STORE_ARTICLE_SEARCH_STR: 'STORE_ARTICLE_SEARCH_STR',
  SEARCH_IN_PROGRESS: 'ARTICLE_SEARCH_IN_PROGRESS',
  SEARCH_NOT_IN_PROGRESS: 'SEARCH_NOT_IN_PROGRESS',
  RETRIEVE_IN_PROGRESS: 'RETRIEVE_IN_PROGRESS',
  RETRIEVE_NOT_IN_PROGRESS: 'RETRIEVE_NOT_IN_PROGRESS',
  EMI: {
    DOWNLOAD: {
      IN_PROGRESS: 'ARTICLE_EMI_DOWNLOAD_IN_PROGRESS',
      NOT_IN_PROGRESS: 'ARTICLE_EMI_DOWNLOAD_NOT_IN_PROGRESS',
      RESULT: 'ARTICLE_EMI_DOWNLOAD_RESULT',
    },
  },
  RECOMMEND: 'ARTICLE_RECOMMEND',
  MIGHT_LIKE: 'ARTICLE_MIGHT_LIKE',
  RETRIEVE_RECOMMEND_IN_PROGRESS: 'RETRIEVE_RECOMMEND_IN_PROGRESS',
  RETRIEVE_RECOMMEND_NOT_IN_PROGRESS: 'RETRIEVE_RECOMMEND_NOT_IN_PROGRESS',
  UPDATE_LIST_PROPS: 'ARTICLE_UPDATE_LIST_PROPS',
};

export const RADAR = {
  SET_ACTIVE_INIT_RADAR: 'SET_ACTIVE_INIT_RADAR',
  SET_SELECTED_RADAR: 'SET_SELECTED_RADAR',
  ADD_CREATED_RADAR: 'ADD_CREATED_RADAR',
  CLEAR_LIST: 'CLEAR_LIST',
  UPDATE_RADAR_SAVE_DIALOG: 'UPDATE_RADAR_SAVE_DIALOG',
  SET_RADAR_LIST: 'SET_RADAR_LIST',
  LIST_IN_PROGRESS: 'LIST_IN_PROGRESS',
  REMOVE_FROM_LIST: 'REMOVE_FROM_LIST',
  ARTICLE_OVERVIEW: 'ARTICLE_OVERVIEW',
  COMPANY_OVERVIEW: 'COMPANY_OVERVIEW',
  UPDATE_IN_PROGRESS: 'UPDATE_IN_PROGRESS',
  LIST_ARTICLE_IN_PROGRESS: 'LIST_ARTICLE_IN_PROGRESS',
  NEWS_MENTION_IN_PROGRESS: 'NEWS_MENTION_IN_PROGRESS',
  COMPANY_IN_PROGRESS: 'COMPANY_IN_PROGRESS',
  UPDATE_RADAR_IN_LIST: 'UPDATE_RADAR_IN_LIST',
  RADAR_COMPANY_TOTAL_COUNT: 'RADAR_COMPANY_TOTAL_COUNT',
  MOST_MENTIONED_COMPANY: 'MOST_MENTIONED_COMPANY',
  MENTIONED_COMPANY_TOTAL_COUNT: 'MENTIONED_COMPANY_TOTAL_COUNT',
  SET_RADAR_ACTIVE_FEED: 'SET_RADAR_ACTIVE_FEED',
  LIST_FAV_COMPANY: 'LIST_FAV_COMPANY',
  LIST_COMPANY_IN_PROGRESS: 'LIST_COMPANY_IN_PROGRESS',
  ADD_SEARCH_CRITERIA: 'ADD_SEARCH_CRITERIA',
  SET_VIEW_MORE_COUNT: 'SET_VIEW_MORE_COUNT',
  UPDATE_RADAR_LIST_COUNTS: 'UPDATE_RADAR_LIST_COUNTS',
  UNBIND_COMPANY_LIST: 'UNBIND_COMPANY_LIST',
  UNBIND_FILTER_FROM_RADAR: 'UNBIND_FILTER_FROM_RADAR',
  UPDATE_SELECT_BOOKMARK_FILTERS: 'UPDATE_SELECT_BOOKMARK_FILTERS',
  UPDATE_STARRED_COMPANY_LIST: 'UPDATE_STARRED_COMPANY_LIST',
  SET_RADAR_SETTING_PARAMS: 'SET_RADAR_SETTING_PARAMS',
  EMAIL_ALERTS_LIST: 'EMAIL_ALERTS_LIST',
  CREATE_EMAIL_ALERTS_LIST: 'CREATE_EMAIL_ALERTS_LIST',
  UPDATE_EMAIL_ALERTS_LIST: 'UPDATE_EMAIL_ALERTS_LIST',
  EMAIL_ALERTS_LIST_PROGRESS: 'EMAIL_ALERTS_LIST_PROGRESS',
  SET_RADAR_QUICK_ACCESS: 'SET_RADAR_QUICK_ACCESS',
  MENTION_COMPANY_COUNT_BY_INTERVAL: 'MENTION_COMPANY_COUNT_BY_INTERVAL',
  RADAR_LIST_MENTION_COUNT_IN_PROGRESS: 'RADAR_LIST_MENTION_COUNT_IN_PROGRESS',
  RESET_ALL_COMPANIES_STATE: 'RESET_ALL_COMPANIES_STATE',
  UPDATE_RADAR_STATS: 'UPDATE_RADAR_STATS',
  SET_RADAR_STATS: 'SET_RADAR_STATS',
  RADAR_ARTICLE_LIST: 'RADAR_ARTICLE_LIST',
  ARTICLE_LIST_TOTAL_COUNT: 'ARTICLE_LIST_TOTAL_COUNT',
  ARTICLE_OVERVIEW_IN_PROGRESS: 'ARTICLE_OVERVIEW_IN_PROGRESS'
};

export const SEARCH_CRITERIA = {
  SEARCH_CRITERIA_RETRIEVE_BY_IDS: 'SEARCH_CRITERIA_RETRIEVE_BY_IDS',
  DELETE_SEARCH_CRITERIA: 'DELETE_SEARCH_CRITERIA',
  UPDATE_SEARCH_CRITERIA: 'UPDATE_SEARCH_CRITERIA',
  RETRIEVE_IN_PROGRESS: 'RETRIEVE_IN_PROGRESS',
  RETRIEVE_NOT_IN_PROGRESS: 'RETRIEVE_NOT_IN_PROGRESS',
};

export const SOURCE = {
  SOURCE_LIST: 'SOURCE_LIST',
};

export const SCOUT_AI = {
  SCOUT_AI_LIST: 'SCOUT_AI_LIST',
  SCOUT_AI_CUSTOM_LIST: 'SCOUT_AI_CUSTOM_LIST',
  SCOUT_AI_STANDARD_LIST: 'SCOUT_AI_STANDARD_LIST',
  SCOUT_AI_RETRIEVE: 'SCOUT_AI_RETRIEVE',
  SCOUT_AI_RESULT_RETRIEVE: 'SCOUT_AI_RESULT_RETRIEVE',
  SCOUT_AI_ARTICLE_UNTRAINED: 'SCOUT_AI_ARTICLE_UNTRAINED',
  TRAINING_ARTICLE_LIST: 'TRAINING_ARTICLE_LIST',
  TRAINING_AI_UPDATE: 'TRAINING_AI_UPDATE',
};

export const SCOUT_AI_TOPICS = {
  SCOUT_AI_TOPICS_UPDATE_CUSTOM: 'SCOUT_AI_TOPICS_UPDATE_CUSTOM',
};

export const CAMPAIGN = {
  CAMPAIGN_RETRIEVE: 'COMPANY_RETRIEVE',
};

export const DATA = {
  BUSINESS_LINES: {
    NAME: 'DATA.BUSINESS_LINES.NAME',
    TREE: 'DATA.BUSINESS_LINES.TREE',
    PARENT: 'DATA.BUSINESS_LINES.PARENT',
  },
  COUNTRIES_ALL: {
    LIST: 'DATA.COUNTRIES_ALL.LIST',
    MAPPING: 'DATA.COUNTRIES_ALL.MAPPING',
  },
  SECTORS: {
    LIST: 'DATA.SECTORS.LIST',
  },
  SOURCES: {
    LIST: 'DATA.SOURCES.LIST',
  },
  SCOUTAI: {
    IN_PROGRESS: 'DATA.SCOUTAI.IN_PROGRESS',
    STANDARD: {
      LIST: 'DATA.SCOUTAI.STANDARD.LIST',
    },
    VISUALISATION: {
      LIST: 'DATA.SCOUTAI.VISUALISATION.LIST',
    },
  },
  SCOUTAI_TOPICS: {
    LIST: 'DATA.SCOUTAI_TOPICS.LIST',
  }
};

export const DASHBOARD = {
  ARTICLES: {
    LIST: {
      IN_PROGRESS: 'DASHBOARD_ARTICLES_LIST_IN_PROGRESS',
      NOT_IN_PROGRESS: 'DASHBOARD_ARTICLES_LIST_NOT_IN_PROGRESS',
      RESULT: 'DASHBOARD_ARTICLES_LIST_RESULT',
    },
  },
  TARGET_LIST: {
    MENTIONS: {
      IN_PROGRESS: 'TARGET_LIST_MENTIONS_IN_PROGRESS',
      NOT_IN_PROGRESS: 'TARGET_LIST_MENTIONS_NOT_IN_PROGRESS',
      RESULT: 'TARGET_LIST_MENTIONS_RESULT',
    },
  },
  DEFAULT_LANGUAGE: 'DEFAULT_LANGUAGE',
  SHOW_TRANSLATION_LANGUAGE: 'SHOW_TRANSLATION_LANGUAGE',
  TRENDING_TOPICS: {
    FILTERED_COUNTRY: 'FILTERED_COUNTRY',
    SELECTED_CLUSTER: 'SELECTED_CLUSTER',
    SELECTED_CLUSTER_SCROLL_HEIGHT: 'SELECTED_CLUSTER_SCROLL_HEIGHT',
    LIST: {
      IN_PROGRESS: 'DASHBOARD_TRENDING_TOPICS_LIST_IN_PROGRESS',
      NOT_IN_PROGRESS: 'DASHBOARD_TRENDING_TOPICS_LIST_NOT_IN_PROGRESS',
      RESULT: 'DASHBOARD_TRENDING_TOPICS_LIST_RESULT',
    },
  },
  MY_ALERTS_LOADING: {
    IN_PROGRESS: 'MY_ALERTS_IN_PROGRESS',
  },
  TOP_PAGE_TRENDING_TOPICS: {
    ALL: 'ALL',
    LATEST_NEWS: 'LATEST_NEWS'
  }
};

export const DRAWER = {
  COMPANY: {
    AGGREGATE: {
      UPDATE: 'DRAWER_COMPANY_AGGREGATE_UPDATE',
    },
    DATA: {
      LIST_BUSINESS_LINES: {
        IN_PROGRESS: 'DRAWER_COMPANY_DATA_LIST_BUSINESS_LINES_IN_PROGRESS',
        NOT_IN_PROGRESS:
          'DRAWER_COMPANY_DATA_LIST_BUSINESS_LINES_NOT_IN_PROGRESS',
        RESULT: 'DRAWER_COMPANY_DATA_LIST_BUSINESS_LINES_RESULT',
      },
    },
    SEARCH: {
      KEYWORD: {
        UPDATE: 'DRAWER_COMPANY_SEARCH_KEYWORD_UPDATE',
      },
    },
    SORTING: {
      UPDATE: 'DRAWER_COMPANY_SORTING_UPDATE',
    },
  },
  ARTICLE: {
    AGGREGATE: {
      UPDATE: 'DRAWER_ARTICLE_AGGREGATE_UPDATE',
    },
    DATA: {
      LIST_COMPANIES: {
        IN_PROGRESS: 'DRAWER_ARTICLE_DATA_LIST_COMPANIES_IN_PROGRESS',
        NOT_IN_PROGRESS: 'DRAWER_COMPANY_DATA_LIST_COMPANIES_NOT_IN_PROGRESS',
        RESULT: 'DRAWER_COMPANY_DATA_LIST_COMPANIES_RESULT',
      },
      LIST_SCOUT_AI: {
        IN_PROGRESS: 'DRAWER_ARTICLE_DATA_LIST_SCOUT_AI_IN_PROGRESS',
        NOT_IN_PROGRESS: 'DRAWER_ARTICLE_DATA_LIST_SCOUT_AI_NOT_IN_PROGRESS',
        RESULT: 'DRAWER_ARTICLE_DATA_LIST_SCOUT_AI_RESULT',
      },
      LIST_SOURCES: 'DRAWER_ARTICLE_DATA_LIST_SOURCES',
    },
    SEARCH: {
      KEYWORD: {
        UPDATE: 'DRAWER_ARTICLE_SEARCH_KEYWORD_UPDATE',
      },
    },
    SELECTED: {
      UPDATE: 'DRAWER_ARTICLE_SELECTED_UPDATE',
    }
  },
};

export const SAVEDSEARCH = {
  SAVEDSEARCH_LIST: 'SAVEDSEARCH_LIST',
  SAVEDSEARCH_LIST_IN_PROGRESS: 'SAVEDSEARCH_LIST_IN_PROGRESS',
  SAVEDSEARCH_LIST_NOT_IN_PROGRESS: 'SAVEDSEARCH_LIST_NOT_IN_PROGRESS',
  SAVEDSEARCH_DELETE: 'SAVEDSEARCH_DELETE',
  SAVEDSEARCH_UPDATE: 'SAVEDSEARCH_UPDATE',
  SAVEDSEARCH_RETRIEVE: 'SAVEDSEARCH_RETRIEVE',
  SAVEDSEARCH_RESET: 'SAVEDSEARCH_RESET',
  NOT_SHOW_BANNER: 'NOT_SHOW_BANNER',
};

export const NOTIFICATIONSETTING = {
  CREATE: 'NOTIFICATIONSETTING_CREATE',
  LIST: 'NOTIFICATIONSETTING_LIST',
  DELETE: 'NOTIFICATIONSETTING_DELETE',
  UPDATE: 'NOTIFICATIONSETTING_UPDATE',
  UPDATE_COMPANY_ALERTS: 'NOTIFICATIONSETTING_UPDATE_COMPANY_ALERTS',
  ALERT: 'NOTIFICATIONSETTING_ALERT',
  RIGHT_DRAWER: 'NOTIFICATIONSETTING_RIGHT_DRAWER',
};

export const FAQ = {
  IN_PROGRESS: 'FAQ_IN_PROGRESS',
  NOT_IN_PROGRESS: 'FAQ_NOT_IN_PROGRESS',
  RESULT: 'FAQ_RESULT',
};

export const JWT_TOKEN = {
  RESULT: 'JWT_TOKEN_RESULT',
};

export const AUTH = {
  VALIDATION: {
    RESULT: 'AUTH_VALIDATION_RESULT',
  },
};

export const PROGRESS = {
  INCREASE_PROGRESS: 'INCREASE_PROGRESS',
  DECREASE_PROGRESS: 'DECREASE_PROGRESS',
};

export const ONBOARDING = {
  SECTOR: {
    SELECTED: {
      UPDATE: 'ONBOARDING_SECTOR_SELECTED_UPDATE',
    },
  },
  GOAL: {
    SELECTED: {
      UPDATE: 'ONBOARDING_GOAL_SELECTED_UPDATE',
    },
  },
  COUNTRY: {
    SELECTED: {
      UPDATE: 'ONBOARDING_COUNTRY_SELECTED_UPDATE',
    },
  },
};

export const LIBRARY = {
  CURRENT_TAB: 'CURRENT_TAB',
  OVERVIEW_COUNT: 'LIBRARY_OVERVIEW_COUNT',
};

export const CONFIGURATIONS = {
  PRINT: 'PRINT',
  DOWNLOAD: 'DOWNLOAD',
  DRAWER_ALERT: 'DRAWER_ALERT',
  DRAWER_SAVED_FIELD: 'DRAWER_SAVED_FIELD',
  PILL_SHAPED_FILTER: 'PILL_SHAPED_FILTER',
  OWNERSHIP_TABLE_TYPE: 'OWNERSHIP_TABLE_TYPE',
  PREVIOUS_IS_SEARCH: 'PREVIOUS_IS_SEARCH',
  SEER_OWNERSHIP_TABLE_TYPE: 'SEER_OWNERSHIP_TABLE_TYPE',
};

export const QUICK_SEARCH = {
  KEYWORD: {
    UPDATE: 'QUICK_SEARCH_KEYWORD_UPDATE',
  },
  RESULT: {
    COMPANY: 'QUICK_SEARCH_RESULT_COMPANY',
    ARTICLE: 'QUICK_SEARCH_RESULT_ARTICLE',
    BUSINESS_LINES: 'QUICK_SEARCH_RESULT_BUSINESS_LINES',
    SCOUT_AI: 'QUICK_SEARCH_RESULT_SCOUT_AI',
  },
  SEARCH: {
    RESET: 'QUICK_SEARCH_SEARCH_RESET',
  },
};

export const QUICK_ACCESS = {
  LIST: {
    GET: 'GET_QUICK_ACCESS_LIST',
    UPDATE: 'UPDATE_QUICK_ACCESS_LIST',
  },
};

export const BOOKMARK = {
  SET_BOOKMARK_DETAIL: 'SET_BOOKMARK_DETAIL',
  DELETE_BOOKMARK_DETAIL: 'DELETE_BOOKMARK_DETAIL',
  SET_SELECTED_BOOKMARK_NEWS: 'SET_SELECTED_BOOKMARK_NEWS',
  SET_COMPANIES_SELECTED_ITEMS: 'SET_COMPANIES_SELECTED_ITEMS',
  GET_BOOKMARK_LISTS: 'GET_BOOKMARK_LISTS',
  CREATE_BOOKMARK_LIST: 'CREATE_BOOKMARK_LIST',
  DELETE_BOOKMARK_LIST: 'DELETE_BOOKMARK_LIST',
  UPDATE_BOOKMARK_LIST: 'UPDATE_BOOKMARK_LIST',
  SET_DEFAULT_VALUE: 'SET_DEFAULT_VALUE',
  SET_DETAIL_BOOKMARK: 'SET_DETAIL_BOOKMARK',
  SET_BOOKMARK_ITEM_LIST: 'SET_BOOKMARK_ITEM_LIST',
  SELECT_BOOKMARK_ALL: 'SELECT_BOOKMARK_ALL',
  SET_BOOKMARK_SNACK_BAR: 'SET_BOOKMARK_SNACK_BAR',
  SET_BOOKMARK_SELECTED_ITEMS: 'SET_BOOKMARK_SELECTED_ITEMS',
  GET_BOOKMARK_LIST_ITEMS: 'GET_BOOKMARK_LIST_ITEMS',
  ADD_CONTENT_LIST_ITEMS: 'ADD_CONTENT_LIST_ITEMS',
  ADD_COMPANY_LIST_ITEM: 'ADD_COMPANY_LIST_ITEM',
  DELETE_CONTENT_LIST_ITEMS: 'DELETE_CONTENT_LIST_ITEMS',
  DELETE_COMPANY_LIST_ITEM: 'DELETE_COMPANY_LIST_ITEM',
  SET_BOOKMARK_LIST_IN_PROGRESS: 'SET_BOOKMARK_LIST_IN_PROGRESS',
  SET_BOOKMARK_FILTERS_DETAIL: 'SET_BOOKMARK_FILTERS_DETAIL',
  GET_BOOKMARK_FILTERS_LIST: 'GET_BOOKMARK_FILTERS_LIST',
  CREATE_BOOKMARK_FILTERS_LIST: 'CREATE_BOOKMARK_FILTERS_LIST',
  DELETE_BOOKMARK_FILTERS_LIST: 'DELETE_BOOKMARK_FILTERS_LIST',
  UPDATE_BOOKMARK_FILTERS_LIST: 'UPDATE_BOOKMARK_FILTERS_LIST',
  BIND_COMPANY_LIST_TO_RADAR: 'BIND_COMPANY_LIST_TO_RADAR',
  UNBIND_COMPANY_LIST_FROM_RADAR: 'UNBIND_COMPANY_LIST_FROM_RADAR',
  BIND_FILTER_LIST_TO_RADAR: 'BIND_FILTER_LIST_TO_RADAR',
  UNBIND_FILTER_LIST_FROM_RADAR: 'UNBIND_FILTER_LIST_FROM_RADAR',
  UPDATE_FILTERS_LIST_TO_RADAR: 'UPDATE_FILTERS_LIST_TO_RADAR',
  GET_BOOKMARK_NEWS_HEADLINE: 'GET_BOOKMARK_NEWS_HEADLINE',
};

export const COMPANY_DETAIL = {
  RELATIONSHIP: {
    GET_OWNERSHIP_DATA: 'GET_OWNERSHIP_DATA',
    GET_SUPPLY_CHAIN_DATA: 'GET_SUPPLY_CHAIN_DATA',
    GET_COMPETITORS_DATA: 'GET_COMPETITORS_DATA',
    GET_OWNERSHIP_LIST_SETTING: 'GET_OWNERSHIP_LIST_SETTING',
    GET_SUPPLY_CHAIN_LIST_SETTING: 'GET_SUPPLY_CHAIN_LIST_SETTING',
    GET_COMPETITORS_LIST_SETTING: 'GET_COMPETITORS_LIST_SETTING',
    SET_OWNERSHIP_PROGRESS: 'SET_OWNERSHIP_PROGRESS',
    SET_SUPPLY_CHAIN_PROGRESS: 'SET_SUPPLY_CHAIN_PROGRESS',
    SET_COMPETITORS_PROGRESS: 'SET_COMPETITORS_PROGRESS',
    GET_OWNERSHIP_TOTAL_COUNT: 'GET_OWNERSHIP_TOTAL_COUNT',
    GET_SUPPLY_CHAIN_TOTAL_COUNT: 'GET_SUPPLY_CHAIN_TOTAL_COUNT',
    GET_COMPETITORS_TOTAL_COUNT: 'GET_COMPETITORS_TOTAL_COUNT',
    GET_SUPPLIER_TOTAL_COUNT: 'GET_SUPPLIER_TOTAL_COUNT',
    GET_CUSTOMER_TOTAL_COUNT: 'GET_CUSTOMER_TOTAL_COUNT',
    GET_PARTNER_TOTAL_COUNT: 'GET_PARTNER_TOTAL_COUNT',
    GET_ALL_SUPPLY_CHAIN_TOTAL_COUNT: 'GET_ALL_SUPPLY_CHAIN_TOTAL_COUNT',
    GET_OWNERSHIP_RELATIONSHIP_DATA: 'GET_OWNERSHIP_RELATIONSHIP_DATA',
    SET_SEER_OWNERSHIP_PROGRESS: 'SET_SEER_OWNERSHIP_PROGRESS',
    GET_SEER_SUPPLY_CHAIN_DATA: 'GET_SEER_SUPPLY_CHAIN_DATA',
    SET_SEER_SUPPLY_CHAIN_PROGRESS: 'SET_SEER_SUPPLY_CHAIN_PROGRESS',
    GET_SEER_OWNERSHIP_LIST_SETTING: 'GET_SEER_OWNERSHIP_LIST_SETTING',
    GET_SEER_SUPPLY_CHAIN_LIST_SETTING: 'GET_SEER_SUPPLY_CHAIN_LIST_SETTING',
    SET_SUPPLY_CHAIN_FILTER: 'SET_SUPPLY_CHAIN_FILTER',
    SET_SEER_SUPPLY_CHAIN_FILTER: 'SET_SEER_SUPPLY_CHAIN_FILTER',
    SET_RELATIONSHIP_SOURCE_TYPE: 'SET_RELATIONSHIP_SOURCE_TYPE',
    SET_RELATIONSIHP_IN_PROGRESS: 'SET_RELATIONSIHP_IN_PROGRESS',
    SET_SEER_ALL_SUPPLY_CHAIN_TOTAL_COUNT:
      'SET_SEER_ALL_SUPPLY_CHAIN_TOTAL_COUNT',
    SET_SEER_SUPPLIER_TOTAL_COUNT: 'SET_SEER_SUPPLIER_TOTAL_COUNT',
    SET_SEER_CUSTOMER_TOTAL_COUNT: 'SET_SEER_CUSTOMER_TOTAL_COUNT',
    SET_LOADING: 'SET_LOADING',
  },
};

export const ACTION = {
  FILTER: {
    NEWS: {
      PUBLICATION_DATE: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_PUBLICATION_DATE_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_PUBLICATION_DATE_UPDATE_ADDITIONAL_INFO'
      },
      SOURCE: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_SOURCE_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_SOURCE_UPDATE_ADDITIONAL_INFO'
      },
      SECTOR: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_SECTOR_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_SECTOR_UPDATE_ADDITIONAL_INFO'
      },
      SCOUT_AI: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_SCOUT_AI_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_SCOUT_AI_UPDATE_ADDITIONAL_INFO'
      },
      SCOUT_AI_TOPICS: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_SCOUT_AI_TOPICS_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_SCOUT_AI_TOPICS_UPDATE_ADDITIONAL_INFO'
      },
      MENTIONED_COMPANY: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_MENTIONED_COMPANY_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_MENTIONED_COMPANY_UPDATE_ADDITIONAL_INFO',
        UPDATE_LABEL: 'ACTION_FILTER_NEWS_MENTIONED_COMPANY_UPDATE_LABEL',
        CONTROL_ACCORDION: 'DRAWER_ARTICLE_MENTIONED_COMPANIES_CONTROL_ACCORDION',
      },
      COUNTRIES_REGIONS: {
        UPDATE_SELECTED: 'ACTION_FILTER_NEWS_COUNTRIES_REGIONS_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_NEWS_COUNTRIES_REGIONS_UPDATE_ADDITIONAL_INFO'
      },
      RESET_STATES: 'ACTION_FILTER_NEWS_RESET_STATES',
    },
    COMPANY: {
      SECTOR: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_SECTOR_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_SECTOR_UPDATE_ADDITIONAL_INFO'
      },
      BUSINESS_LINES: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_BUSINESS_LINES_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_BUSINESS_LINES_UPDATE_ADDITIONAL_INFO'
      },
      COUNTRIES_REGIONS: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_COUNTRIES_REGIONS_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_COUNTRIES_REGIONS_UPDATE_ADDITIONAL_INFO'
      },
      FINANCIALS: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_FINANCIALS_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_FINANCIALS_UPDATE_ADDITIONAL_INFO',
        UPDATE_AGGREGATION: 'ACTION_FILTER_COMPANY_FINANCIALS_UPDATE_AGGREGATION'
      },
      OWNERSHIP: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_OWNERSHIP_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_OWNERSHIP_UPDATE_ADDITIONAL_INFO'
      },
      NUM_OF_EMPLOYEES: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_NUM_OF_EMPLOYEES_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_NUM_OF_EMPLOYEES_UPDATE_ADDITIONAL_INFO'
      },
      KEYWORD_IN: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_KEYWORD_IN_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_KEYWORD_IN_UPDATE_ADDITIONAL_INFO'
      },
      RELATIONSHIP: {
        UPDATE_SELECTED: 'ACTION_FILTER_COMPANY_RELATIONSHIP_IN_UPDATE_SELECTED',
        UPDATE_ADDITIONAL_INFO: 'ACTION_FILTER_COMPANY_RELATIONSHIP_IN_UPDATE_ADDITIONAL_INFO'
      },
      RESET_STATES: 'ACTION_FILTER_COMPANY_RESET_STATES',
    },
  },
  FILTER_BAR: {
    UPDATE_SEARCH_FLAG: 'ACTION_FILTER_BAR_UPDATE_SEARCH_FLAG',
    UPDATE_NEWS_QUERY: 'ACTION_FILTER_BAR_UPDATE_NEWS_QUERY',
    UPDATE_COMPANY_QUERY: 'ACTION_FILTER_BAR_UPDATE_COMPANY_QUERY'
  }
};

export const TOP_PAGE = {
  COMPANY_MENTION: {
    GET_RECENT_COMPANY_MENTION: 'GET_RECENT_COMPANY_MENTION',
  },
  FILTER: {
    UPDATE_FILTER: 'UPDATE_FILTER', 
    UPDATE_FILTER_PROGRESS: 'UPDATE_FILTER_PROGRESS', 
  }
};

