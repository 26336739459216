import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.externalLink';

export default defineMessages({
  externalLinkTag:{
    id: `${scope}.financialTimesExternalLinkTag`,
    defaultMessage: 'View article in publisher website'
  },
});
