import { BOOKMARK } from '@constants/actions';
import { BOOKMARK_ENTITY_TYPE, BOOKMARK_LIST_ITEM_TYPE, BOOKMARK_NAVBAR_TAB } from '@constants/common';
import {
  IContentListItem,
  IContentListItemIds,
  IBookmarkNews,
  IBookmarkSnackBar,
  IBookmarkCompany,
  ICompanyListItemIds,
  IBookmarkFilter,
} from '@models/bookmark';
import { arrayValueConvertToCase, isTheSameString } from '@react/utils/common.util';
import { Map } from 'immutable';
import { isEmpty } from 'lodash';
import moment from 'moment';

const initialState = Map({
  bookmarkList: {
    news: {
      list: [],
      selected: {} as IBookmarkNews,
    },
    companies: {
      list: [],
      selected: {} as IBookmarkCompany,
    },
    newsFilters:{
      list: [] as IBookmarkFilter[],
      selected: {} as IBookmarkFilter,
    },
    companiesFilters: {
      list: [] as IBookmarkFilter[],
      selected: {} as IBookmarkFilter,
    },
  },
  snackBar: {
    open: false,
    message: '',
    error: false,
    action: null,
    mini: false,
  } as IBookmarkSnackBar,
  selectedBookmarkItems: [] as IContentListItem[],
  selectedCompanies: [],
  bookmarkListItemIds: {
    news: [] as IContentListItemIds[],
    companies: [] as ICompanyListItemIds[],
  },
  bookmarkFetched: {
    [BOOKMARK_NAVBAR_TAB.NEWS]: false,
    [BOOKMARK_NAVBAR_TAB.NEWSFILTERS]: false,
    [BOOKMARK_NAVBAR_TAB.COMPANIES]: false,
    [BOOKMARK_NAVBAR_TAB.COMPANIESFILTER]: false,
  },
});

const Bookmark = (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARK.SET_BOOKMARK_DETAIL: {
      const result = state.setIn(['bookmarkList', action.payload.tab, 'selected'], action.payload.bookmark);
      return result;
    }
    case BOOKMARK.DELETE_BOOKMARK_DETAIL: {
      const currentBookmark = state.getIn(['bookmarkList', 'news', 'selected']) as IBookmarkNews;
      const guids = action.payload.entityGuids;
      const contentListIds = action.payload.contentListIds;
      let listItem = currentBookmark.contentListItems;
      if (!contentListIds.includes(currentBookmark?.id)) {
        return state;
      }
      listItem = listItem.filter((item: IContentListItem) => !guids.includes(item.entityId));
      return  state.setIn(['bookmarkList', 'news', 'selected'], {
        ...currentBookmark,
        contentListItems: listItem,
      });
    }
    case BOOKMARK.SET_BOOKMARK_LIST_IN_PROGRESS: {
      return state.setIn(['bookmarkFetched', action.payload.tab], action.payload.bookmarkListInprogress);
    }
    case BOOKMARK.SET_BOOKMARK_SELECTED_ITEMS: {
      return state.set('selectedBookmarkItems', action.payload.bookmarkItems);
    }
    case BOOKMARK.SET_COMPANIES_SELECTED_ITEMS: {
      const { selection } = action.payload;
      return state.set('selectedCompanies', selection);
    }
    case BOOKMARK.SET_BOOKMARK_SNACK_BAR: {
      return state.set('snackBar', action.payload.snackBar);
    }
    case BOOKMARK.SELECT_BOOKMARK_ALL: {
      const checked = action.payload.checked as boolean;
      const currentBookmark = state.getIn(['bookmarkList', 'news', 'selected']) as IBookmarkNews;
      currentBookmark.contentListItems.forEach((x) => {
        const item = x;
        item.selected = checked;
      });
      return state.setIn(['bookmarkList', 'news', 'selected'], { ...currentBookmark });
    }

    case BOOKMARK.GET_BOOKMARK_LISTS: {
      const lists = action.payload.lists;
      const tab =  action.payload.tab;
      lists.sort((a, b)=>moment(b.createdDate).diff(a.createdDate));
      lists.sort((a, b)=> {
        return b.isDefault - a.isDefault;
      });
      
      const selected = lists?.length > 0 ? lists[0] : {};

      return state.setIn(['bookmarkList', tab, 'list'], lists)
        .setIn(['bookmarkList', tab, 'selected'], selected);
    }

    case BOOKMARK.CREATE_BOOKMARK_LIST: {
      const { tab, list } = action.payload;
      const lists = state.getIn(['bookmarkList', tab, 'list']) as IBookmarkNews[] | IBookmarkCompany[];
      if (lists.length > 0) {
        lists.splice(1, 0, list);
      } else {
        lists.push(list);
      }
      return state.setIn(['bookmarkList', tab, 'list'], [...lists]);
    }
    
    case BOOKMARK.DELETE_BOOKMARK_LIST: {
      const { tab, deletedBookmark } = action.payload;
      const lists = state.getIn(['bookmarkList', tab, 'list']) as IBookmarkNews[] | IBookmarkCompany[];
      let listItemIds = [];
      listItemIds =  state.getIn(['bookmarkListItemIds', tab]) as IContentListItemIds[] | ICompanyListItemIds[];

      lists.splice(lists.findIndex((item) => isTheSameString(item.guid, deletedBookmark.guid)), 1);

      if (tab === BOOKMARK_LIST_ITEM_TYPE.NEWS) {
        const deletedItemIds = deletedBookmark.contentListItems;

        listItemIds = listItemIds.filter(listItem => deletedItemIds.find(deleteItem => {
          return isTheSameString(deleteItem.entityGuid, listItem.entityGuid) && deleteItem.contentListId === listItem.contentListId;
        }));
      } else {
        const deletedItemIds = deletedBookmark.bookmarkCompanyListItems;
        listItemIds = listItemIds.filter(listItem => deletedItemIds.find(deleteItem => {
          return isTheSameString(deleteItem.entityGuid, listItem.entityGuid) && deletedBookmark.guid === listItem.bookmarkCompanyListGuid;
        }));
      }

      return state.setIn(['bookmarkList', tab, 'list'], [...lists])
        .setIn(['bookmarkListItemIds', tab], listItemIds);
    }
    case BOOKMARK.UPDATE_BOOKMARK_LIST: {
      const { type, list } = action.payload;
      const lists = state.getIn(['bookmarkList', type, 'list']) as IBookmarkNews[] | IBookmarkCompany[];
      const selected = state.getIn(['bookmarkList', type, 'selected']) as IBookmarkNews | IBookmarkCompany;

      const findIndex = lists.findIndex((item) => isTheSameString(item.guid, list.guid));
      lists.splice(
        findIndex,
        1,
        {
          ...lists[findIndex],
          ...list,
        },
      );
      return state.setIn(['bookmarkList', type, 'list'], [...lists])
        .setIn(['bookmarkList', type, 'selected'], { ...selected, ...list });
    }
    case BOOKMARK.SET_DEFAULT_VALUE: {
      const { type, guid, currentDefault } = action.payload;
      let index;
      const bookmarkList = state.getIn(['bookmarkList', type, 'list']) as IBookmarkNews[] | IBookmarkCompany[];
      const selected =  state.getIn(['bookmarkList', type, 'selected']) as IBookmarkNews | IBookmarkCompany;

      const newBookmarkListMap = bookmarkList.map((item, i)=> {
        if (isTheSameString(item.guid, guid)) {
          index = i;
          return { ...item, isDefault: true };
        }
        return { ...item, isDefault: false };
      });
      
      const defaultItem = newBookmarkListMap.splice(index, 1);
      newBookmarkListMap.unshift(...defaultItem);

      return state.setIn(['bookmarkList', type, 'list'], [...newBookmarkListMap])
        .setIn(['bookmarkList', type, 'selected'], { ...selected, isDefault: currentDefault });
    }
    case BOOKMARK.GET_BOOKMARK_LIST_ITEMS: {
      const type = action.payload.type;
      return state.setIn(['bookmarkListItemIds', type], action.payload.bookmarkListItemIds);
    }
    case BOOKMARK.ADD_CONTENT_LIST_ITEMS: {
      const updatedContents = action.payload.contentListItemIds as IContentListItemIds[];
      const contentListItemIds = state.getIn(['bookmarkListItemIds', 'news']) as IContentListItemIds[];
      const newItemIds = updatedContents.filter(item => !contentListItemIds.find(ids => ids.entityGuid === item.entityGuid && ids.contentListId === item.contentListId));

      const bookmarkList = state.getIn(['bookmarkList', 'news', 'list']) as IBookmarkNews[];
      const updatedBookmarkList = bookmarkList.map(bookmarkItem => {
        const contentToBeAdded = updatedContents.filter(updated => updated.contentListId === bookmarkItem.id && !bookmarkItem.contentListItems.find(item => item.entityGuid === updated.entityGuid));
        if (contentToBeAdded) {
          return {
            ...bookmarkItem,
            contentListItems: [
              ...bookmarkItem.contentListItems,
              ...contentToBeAdded,
            ],
          };
        }
        return bookmarkItem;
      });
      
      return state.setIn(['bookmarkListItemIds', 'news'], [
        ...contentListItemIds,
        ...newItemIds,
      ]).setIn(['bookmarkList', 'news', 'list'], updatedBookmarkList);
    }
    case BOOKMARK.DELETE_CONTENT_LIST_ITEMS: {
      const updatedIds = action.payload.contentListIds as number[];
      const updatedEntityGuids = action.payload.entityGuid;
      const contentListItems = state.getIn(['bookmarkListItemIds', 'news']) as IContentListItemIds[];
      const filterContentListItems = contentListItems.filter((contentListItem) => !(updatedEntityGuids.includes(contentListItem.entityGuid) && updatedIds.includes(contentListItem.contentListId)));

      const bookmarkList = state.getIn(['bookmarkList', 'news', 'list']) as IBookmarkNews[];
      const updatedBookmarkList = bookmarkList.map(bookmarkItem => {
        const hasDeletedItem = updatedIds.includes(bookmarkItem.id);
        if (hasDeletedItem) {
          return {
            ...bookmarkItem,
            contentListItems: bookmarkItem.contentListItems.filter(contentListItem => !updatedEntityGuids.includes(contentListItem.entityGuid)),
          };
        }
        return bookmarkItem;
      });

      return state.setIn(['bookmarkListItemIds', 'news'], filterContentListItems)
        .setIn(['bookmarkList', 'news', 'list'], updatedBookmarkList);
    }
    case BOOKMARK.ADD_COMPANY_LIST_ITEM: {
      const newItems = action.payload.companyListItemIds as ICompanyListItemIds[];
      const bookmarkListItemIds = state.getIn(['bookmarkListItemIds', 'companies']) as ICompanyListItemIds[];
      const updateNewItems = [] as ICompanyListItemIds[];
      newItems.forEach((item)=> {
        const existInList = bookmarkListItemIds.some((bookmarkListItemId)=> {
          return isTheSameString(bookmarkListItemId.bookmarkCompanyListGuid, item.bookmarkCompanyListGuid) && 
          isTheSameString(bookmarkListItemId.entityGuid, item.entityGuid);
        });
        if (!existInList) {
          updateNewItems.push(item);
        }
      });

      const bookmarkList = state.getIn(['bookmarkList', 'companies', 'list']) as IBookmarkCompany[];

      const updatedBookmarkList = bookmarkList.map((bookmarkItem)=> {
        const companies = updateNewItems.filter((item)=> isTheSameString(item.bookmarkCompanyListGuid, bookmarkItem.guid));
        return {
          ...bookmarkItem,
          bookmarkCompanyListItems: [...bookmarkItem.bookmarkCompanyListItems, ...companies],
        };
      });

      return state.setIn(['bookmarkListItemIds', 'companies'], [...bookmarkListItemIds, ...updateNewItems])
        .setIn(['bookmarkList', 'companies', 'list'], updatedBookmarkList);
    }
    case BOOKMARK.DELETE_COMPANY_LIST_ITEM: {
      const updatedEntityGuids = arrayValueConvertToCase(action.payload.entityGuids);
      const updatedBookmarkGuids = arrayValueConvertToCase(action.payload.bookmarkCompanyListGuids);
      const fromBookmark = action.payload.fromBookmark;
      const companySelected = state.getIn(['bookmarkList', 'companies', 'selected']) as IBookmarkCompany;

      if (fromBookmark) {
        const filterCompanyListItems =  companySelected?.bookmarkCompanyListItems?.filter((item)=> {
          return !updatedEntityGuids.includes(item.entityGuid.toLowerCase());
        });
        companySelected.bookmarkCompanyListItems = filterCompanyListItems;

        state.setIn(['bookmarkListItemIds', 'companies', 'selected'], companySelected);
      }
      
      const bookmarkListItemList = state.getIn(['bookmarkListItemIds', 'companies']) as ICompanyListItemIds[];
      const filterBookmarkListItemIds = bookmarkListItemList.filter((item)=> {
        return !(updatedBookmarkGuids.includes(item.bookmarkCompanyListGuid.toLowerCase()) && updatedEntityGuids.includes(item.entityGuid.toLowerCase()));
      });

      const bookmarkList = state.getIn(['bookmarkList', 'companies', 'list']) as IBookmarkCompany[];
      const updatedBookmarkList = bookmarkList.map((bookmarkItem)=> {
        const hasDeletedItem = updatedBookmarkGuids.includes(bookmarkItem.guid.toLowerCase());
        if (hasDeletedItem) {
          return {
            ...bookmarkItem,
            bookmarkCompanyListItems: bookmarkItem.bookmarkCompanyListItems.filter((item) => !updatedEntityGuids.includes(item.entityGuid.toLowerCase())),
          };
        }
        return bookmarkItem;
      });
      
      return state.setIn(['bookmarkListItemIds', 'companies'], [...filterBookmarkListItemIds])
        .setIn(['bookmarkList', 'companies', 'list'], [...updatedBookmarkList]);
    }
    case BOOKMARK.BIND_COMPANY_LIST_TO_RADAR: {
      const addLinkedRadars = action.payload.addLinkedRadars;
      const selected = state.getIn(['bookmarkList', 'companies', 'selected']) as IBookmarkCompany;
      const linkedRadars = addLinkedRadars.concat(selected?.linkedRadars || []);
      selected.linkedRadars = linkedRadars;

      return state.setIn(['bookmarkList', 'companies', 'selected'], { ...selected })
        .setIn(['bookmarkList', 'companies', 'selected', 'linkedRadars'], linkedRadars);
    }
    case BOOKMARK.UNBIND_COMPANY_LIST_FROM_RADAR: {
      const addLinkedRadars = action.payload.filterLinkedRadars;
      const currentBookmark = state.getIn(['bookmarkList', 'companies', 'selected']) as IBookmarkCompany;
      return state.setIn(['bookmarkList', 'companies', 'selected'], {
        ...currentBookmark,
        linkedRadars: addLinkedRadars,
      });
    }
    case BOOKMARK.UPDATE_FILTERS_LIST_TO_RADAR: {
      const { radarSearchCriterias, guid, tab, isUnBind } = action.payload;
      const list = state.getIn(['bookmarkList', tab, 'list']) as IBookmarkFilter[];
      const selected = state.getIn(['bookmarkList', tab, 'selected']) as IBookmarkFilter;
      const currentList = list.find(x => isTheSameString(x.guid, guid));
      let linkedRadarSearchCriterias = [...(currentList?.linkedRadarSearchCriterias || [])];
      if (isUnBind) {
        linkedRadarSearchCriterias = linkedRadarSearchCriterias.filter(item => 
          radarSearchCriterias.find(x => isTheSameString(x.radarGuid, item.radarGuid)),
        ) || [];
      } else {
        linkedRadarSearchCriterias = [...linkedRadarSearchCriterias, ...radarSearchCriterias];
      }
      currentList.linkedRadarSearchCriterias = linkedRadarSearchCriterias;
      if (!isEmpty(selected))
        selected.linkedRadarSearchCriterias = linkedRadarSearchCriterias;
      return state.setIn(['bookmarkList', tab], { list, selected });
    }
    case BOOKMARK.GET_BOOKMARK_FILTERS_LIST: {
      const { type, lists } = action.payload;
      const selected = lists?.length > 0 ? lists[0] : {};
      const tab = type === BOOKMARK_ENTITY_TYPE.ARTICLE 
        ? BOOKMARK_LIST_ITEM_TYPE.NEWS_FILTERS
        : BOOKMARK_LIST_ITEM_TYPE.COMPANIES_FILTERS;
      if (action.payload.type) {
        return state.setIn(['bookmarkList', tab, 'list'], lists)
          .setIn(['bookmarkList', tab, 'selected'], selected);
      }
      const newsFilters = lists.filter((item => item.searchType === BOOKMARK_ENTITY_TYPE.ARTICLE));
      const companyFilters = lists.filter((item => item.searchType === BOOKMARK_ENTITY_TYPE.COMPANY));
      // Sort newsFiltersList and companyFiltersList in descending order.
      newsFilters.sort((a,b)=>b.createdDate > a.createdDate ? 1:-1)
      companyFilters.sort((a,b)=>b.createdDate > a.createdDate ? 1:-1)      
      return state.setIn(['bookmarkList', BOOKMARK_LIST_ITEM_TYPE.NEWS_FILTERS, 'list'], newsFilters)
        .setIn(['bookmarkList', BOOKMARK_LIST_ITEM_TYPE.COMPANIES_FILTERS, 'list'], companyFilters);
    }
    case BOOKMARK.CREATE_BOOKMARK_FILTERS_LIST: {
      const tab = action.payload.createData.searchType === BOOKMARK_ENTITY_TYPE.ARTICLE
        ? BOOKMARK_LIST_ITEM_TYPE.NEWS_FILTERS 
        : BOOKMARK_LIST_ITEM_TYPE.COMPANIES_FILTERS;
      let list = state.getIn(['bookmarkList', tab, 'list']) as IBookmarkFilter[];
      list.unshift(action.payload.createData);
      return state.setIn(['bookmarkList', tab, 'list'], list);
    }
    case BOOKMARK.UPDATE_BOOKMARK_FILTERS_LIST: {
      const updateData = action.payload.updateData;
      const list = state.getIn(['bookmarkList', action.payload.tab, 'list']) as IBookmarkFilter[];
      const currentBookmark = state.getIn(['bookmarkList', action.payload.tab, 'selected']) as IBookmarkFilter;
      const findIndex = list.findIndex((item) => item.guid === updateData.guid);
      list.splice(findIndex, 1, { ...list[findIndex], ...updateData });
      return state.setIn(['bookmarkList', action.payload.tab, 'list'], [...list])
        .setIn(['bookmarkList', action.payload.tab, 'selected'], { ...currentBookmark, ...updateData });
    }
    case BOOKMARK.DELETE_BOOKMARK_FILTERS_LIST: {
      const deleteData = action.payload.deleteData;
      const list = state.getIn(['bookmarkList', action.payload.tab, 'list']) as IBookmarkFilter[];
      const findIndex = list.findIndex((item) => isTheSameString(item.guid, deleteData[0].guid));
      list.splice(findIndex, 1);
      if (list.length === 0) {
        return state
          .setIn(['bookmarkList', action.payload.tab, 'list'], list)
          .setIn(['bookmarkList', action.payload.tab, 'selected'], {});
      }
      return state.setIn(['bookmarkList', action.payload.tab, 'list'], list);
    }
    default:
      return state;
  }
};

export default Bookmark;
