import { createSelector } from 'reselect';
import { ScoutAi } from '@models/scoutAi';
import { isEmpty } from 'lodash';

export const getCustomScoutMapping = (state) => {
  return state.getIn(['Data', 'scoutAIs', 'custom', 'mapping']);
};

export const getStandardScoutMapping = (state) => {
  return state.getIn(['Data', 'scoutAIs', 'standard', 'mapping']);
};
  
export const scoutMappingSelector = createSelector(
  [getCustomScoutMapping, getStandardScoutMapping], 
  (customScoutMapping, standardScoutMapping) => (
    { ...customScoutMapping, ...standardScoutMapping }
  ),
);

const getScoutAiList = (_state, props) => props.article.scoutAi;

export const filteredScoutAiSelector = createSelector([
  getScoutAiList,
  getCustomScoutMapping,
  getStandardScoutMapping,
], (scoutAiList, customScoutMapping, standardScoutMapping) => {
  const allScoutMapping = { ...customScoutMapping, ...standardScoutMapping };
  const filteredScoutAI = [];
  if (!isEmpty(scoutAiList)) {
    scoutAiList.forEach(id => {
      const uppercaseId = id.toUpperCase();
      const activeScout: ScoutAi = allScoutMapping[uppercaseId];
      if (activeScout) {
        filteredScoutAI.push(activeScout);
      }
    });
  }
  

  return filteredScoutAI;
});