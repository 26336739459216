import { Map } from 'immutable';
import { SEARCH_CRITERIA } from '@constants/actions';
import { RadarSearchCriteria } from '@models/radar';
import { isTheSameString } from '@react/utils/common.util';

const initialState = Map({
  list: [],
  retrieveInProgress: false,
});

const SearchCriteria = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CRITERIA.SEARCH_CRITERIA_RETRIEVE_BY_IDS: {
      return state.set('list', action.payload.searchCriteria);
    }
    case SEARCH_CRITERIA.DELETE_SEARCH_CRITERIA: {
      const currentList = state.get('list') as RadarSearchCriteria[];
      return state.set('list', currentList.filter(feed => !isTheSameString(feed.guid, action.payload.guid)));
    }
    case SEARCH_CRITERIA.UPDATE_SEARCH_CRITERIA: {
      const currentList = state.get('list') as RadarSearchCriteria[];
      const updatedFeed = action.payload.searchCriteria;
      return state.set('list', currentList.map(feed => {
        if (isTheSameString(feed.guid, updatedFeed.guid)) {
          return updatedFeed;
        }
        return feed;
      }));
    }
    case SEARCH_CRITERIA.RETRIEVE_IN_PROGRESS: {
      return state.set('retrieveInProgress', true);
    }
    case SEARCH_CRITERIA.RETRIEVE_NOT_IN_PROGRESS: {
      return state.set('retrieveInProgress', false);
    }
    default:
      return state;
  }
};

export default SearchCriteria;