import { naicsCodes } from 'skyeye-common-const/dist/reference/naicsCodes';
import { isEmpty, setWith } from 'lodash';

export const populateBusinessLines = () => {
  const getPath = (code) => {
    if (code.length === 2) {
      return `[${code}]`;
    }

    const fullPath = [];

    let dCode = code;
    for (let i = 2; i <= code.length; i++) {
      fullPath.push(`[${dCode}]`);
      dCode = dCode.slice(0, -1);
    }
    return [...fullPath].reverse().join('');
  };

  const getParentPath = (code) => {
    if (code.length === 2) {
      return `[${code}]`;
    }

    const parentPath = [];

    let dCode = code;
    for (let i = 2; i <= code.length; i++) {
      if (i === 2) {
        parentPath.push(`[${dCode}]`);
      }
      if (i === 6) {
        parentPath.push(`[${dCode}]`);
      }
      dCode = dCode.slice(0, -1);
    }

    return [...parentPath].reverse().join('');
  };

  const forest = {
    name: {},
    tree: {},
    parent: {},
  };

  for (const naicsCode of naicsCodes) {
    for (let c = 2; c <= 6; c++) {
      const code = naicsCode[`naics${c}code`];
      const description = naicsCode[`naics${c}description`];

      if (!forest.name[code]) {
        forest.name[code] = description;
      }
    }

    const lastCode = naicsCode.naics6code;

    const path = getPath(lastCode);
    const parentPath = getParentPath(lastCode);
    setWith(forest.tree, path, {}, Object);
    setWith(forest.parent, parentPath, naicsCode.naics6description, Object);
  }

  return forest;
};

/**
 * To search naics based on keyword
 *
 * @param forest `{ name: {...}, tree: {...}, parent: {...} }`
 * @param keyword Part of naics description
 *
 * @returns array of naics codes of length 6
 */
export function searchBusinessLinesByKeyword(keyword) {
  if (isEmpty(keyword)) {
    return [];
  }
  const keywordInLowerCase = keyword
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ')
    .toLowerCase();
  //  prevents user from typing all escape characters in search
  const keywordInLowerCaseTrimWhiteSpace = keywordInLowerCase.replace(
    /\s/g,
    ''
  );
  if (isEmpty(keywordInLowerCaseTrimWhiteSpace)) {
    return [];
  }
  const wordList = keywordInLowerCase.split(' ');
  const result = [];
  const scoringWordList = (description) => {
    const every = wordList.every(
      (word) => description.toLowerCase().indexOf(word) > -1
    );
    return every ? 1 : 0;
  };
  naicsCodes.forEach((naicsCodeItem) => {
    const { naics6code, naics6description, naicsItemLabels } = naicsCodeItem;
    let score = 0;
    score += scoringWordList(naics6description);

    const matchLabels = [];
    naicsItemLabels.forEach((label) => {
      score += scoringWordList(label);
      if (scoringWordList(label) > 0) {
        matchLabels.push(label);
      }
    });
    if (score > 0) {
      result.push({
        code: naics6code,
        description: naics6description,
        score,
        matchLabels,
      });
    }
  });
  return result;
}
