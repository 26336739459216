import React from 'react';
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
  Redirect,
} from 'react-router';
import { COOKIE, LOCAL_STORAGE } from '@constants/common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initAccountInfo } from '@redux/actions/account.actions';
import { initAnnouncement } from '@redux/actions/announcement.actions';
import { initBusinessLines } from '@redux/actions/businessLines.actions';
import { initCountries } from '@redux/actions/country.actions';
import { initSectors } from '@redux/actions/sector.actions';
import { initSources } from '@redux/actions/source.actions';
import { initScoutAis } from '@redux/actions/scoutAI.actions';
import {
  listRadar,
  listRadarStatistic,
  radarEmailAlertsList,
} from '@redux/actions/radar.actions';
import { listQuickAccessBookmark } from '@redux/actions/quickAccess.actions';
import {
  getContentList,
  retrieveNewsListItems,
  getCompanyLists,
  getFilterList,
} from '@actions/bookmark.actions';
import { getJWTToken } from '@redux/actions/jwtToken.actions';
import {
  getActiveRoutes,
  userGuidSelector,
  getUserRole,
  getIsPublicUser,
  getAccountInfo,
  getAccountInfoSelector,
} from '@redux/selectors/account.selector';
import { oidcUserSelector } from '@redux/selectors/oidc.selector';
import { SITE_URLS } from '@constants/routes';
import {
  StandardLayout,
  NonStandardLayout,
} from '@react/layout/layout.loadable';
import CallbackRoute from '@react/components/route/callbackRoute.component';
import { Cookies, withCookies } from 'react-cookie';
import { NotificationResourceType } from '@models/notificationSettingReq';
import {
  init as initAtlas,
  trackPage,
  initialized as atlasInitialized,
} from '@react/utils/analytics/atlaswrapper.util';
import { IRouteProperties } from '@interface/route';
import TagManager from 'react-gtm-module';
import { debounce, isEmpty } from 'lodash';
import { callbackRoute, maintenanceRoute, notFoundRoute } from './routes';
import { getHostDomain } from './utils/common.util';
import { FeatureToggleProvider } from './components/featureToggle/featureToggleProvider.component';
import { getFeatures } from './utils/featureToggle.util';
import { initScoutAiTopics } from '@redux/actions/scoutAiTopics.action';
import { AutoResetScroll } from './layout/autoResetScroll.layout';

class App extends React.Component<IAppProps> {
  // eslint-disable-next-line
  statsInterval = null;

  constructor(props) {
    super(props);
    this.state = {
      isHomeInit: true,
    };
    this.renderStandardRoutes = this.renderStandardRoutes.bind(this);
    this.renderNonStandardRoutes = this.renderNonStandardRoutes.bind(this);
  }

  componentDidMount() {
    if (process.env.MAINTAIN_MODE === 'true') {
      return;
    }
    if (this.props.user) {
      this.props.initAccountInfo();
      
    }

    if (!isEmpty(this.props.accountInfo)) {
      this.initAppInfo();
    }

    TagManager.initialize({
      gtmId: 'GTM-5B3KD94',
    });

    this.props.initBusinessLines();
    this.props.initCountries();
    this.props.initSectors();
    this.props.initScoutAiTopics();
    this.props.initSources();
  }

  componentWillUnmount() {
    clearInterval(this.statsInterval);
  }

  componentDidUpdate(prevProps) {
    if (process.env.MAINTAIN_MODE === 'true') {
      return;
    }
    if ((!prevProps.user && this.props.user)) {
      this.props.initAccountInfo();
    }

    if (isEmpty(prevProps.accountInfo) && !isEmpty(this.props.accountInfo) && this.props.user) {
      this.props
        .getJWTToken(this.props.user.profile.user_token, this.props.accountInfo)
        .then((response) => {
          this.addCookieToken(response.data);
          this.addP225CookieToken(response.data);
        });
    }

    if (isEmpty(prevProps.accountInfo) && !isEmpty(this.props.accountInfo)) {
      this.initAppInfo();
    }

    if (prevProps.user && !this.props.user) {
      this.removeCookieToken();
      this.removeP225CookieToken();
    }
    if (!prevProps.userGuid && this.props.userGuid) {
      const widgetData =
        JSON.parse(
          decodeURIComponent(sessionStorage.getItem(LOCAL_STORAGE.WIDGET_DATA))
        ) || {};
      if (isEmpty(prevProps.accountInfo) && !isEmpty(this.props.accountInfo))
        initAtlas(this.props.accountInfo, widgetData);
      else initAtlas(prevProps.accountInfo, widgetData);
    }
    if (
      atlasInitialized() &&
      (this.state.isHomeInit ||
        prevProps.location?.pathname !== this.props.location?.pathname)
    ) {
      const detailAnalyticModel = {};
      const pageTrackingData = {
        ...detailAnalyticModel,
        content: { asset_type: 'story' },
      };
      trackPage(pageTrackingData, false);
      window.addEventListener(
        'scroll',
        debounce(() => {
          trackPage(pageTrackingData, true);
        }, 2000)
      );
      this.homeInitchange();
    }
  }

  homeInitchange() {
    this.setState({ isHomeInit: false });
  }

  initAppInfo() {
    this.props.initAnnouncement();
    this.props.initScoutAis();
    this.props.radarEmailAlertsList();
    this.props.retrieveNewsListItems();
    this.props.getContentList();
    this.props.getCompanyLists();
    this.props.getFilterList();
    this.props.listQuickAccessBookmark();
    this.props.listRadar();

    this.statsInterval = setInterval(
      () => this.props.listRadarStatistic(),
      300000
    );
  }

  addCookieToken(token) {
    const domain = getHostDomain(window.location.hostname, true);
    this.props.cookies.set(COOKIE.CONNECTION_PLUS.SCOUT_TOKEN, token, {
      domain,
      path: COOKIE.CONNECTION_PLUS.PATH,
    });
  }

  removeCookieToken() {
    if (this.props.cookies.get(COOKIE.CONNECTION_PLUS.SCOUT_TOKEN)) {
      this.props.cookies.remove(COOKIE.CONNECTION_PLUS.SCOUT_TOKEN);
    }
  }

  addP225CookieToken(token) {
    const p225domain = window.location.hostname;
    this.props.cookies.set(COOKIE.P225.P225_TOKEN, token, {
      domain: p225domain,
      path: COOKIE.P225.PATH,
    });
  }

  removeP225CookieToken() {
    if (this.props.cookies.get(COOKIE.P225.P225_TOKEN)) {
      this.props.cookies.remove(COOKIE.P225.P225_TOKEN, {
        domain: window.location.hostname,
      });
    }
  }

  renderStandardRoutes() {
    return <StandardLayout routes={this.props.routesMaps.standardRoutes} />;
  }

  renderNonStandardRoutes() {
    return (
      <NonStandardLayout routes={this.props.routesMaps.nonStandardRoutes} />
    );
  }

  renderNotFoundRoute() {
    return <StandardLayout routes={[notFoundRoute]} />;
  }

  render() {
    const enabledFeatures = getFeatures(
      this.props.featureJSON,
      this.props.featureDb,
      this.props.isPublicUser ? 'root' : this.props.userRole
    );
    return (
      <FeatureToggleProvider
        enabledFeatures={enabledFeatures}
        featureJSON={this.props.featureJSON}
      >
        <AutoResetScroll>
          {process.env.MAINTAIN_MODE === 'true' ? (
            <Switch>
              <Route
                path={maintenanceRoute.path}
                component={maintenanceRoute.component}
              />
              <Route path="*">
                <Redirect to={SITE_URLS.MAINTENANCE} />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <CallbackRoute
                path={callbackRoute.path}
                exact={!!callbackRoute.exact}
                component={callbackRoute.component}
              />
              <Route
                path={this.props.routesMaps.nonStandardRoutesPaths}
                render={this.renderNonStandardRoutes}
              />
              <Route
                path={this.props.routesMaps.standardRoutesPaths}
                exact
                render={this.renderStandardRoutes}
              />
              <Route
                path="*"
                render={({ staticContext }) => {
                  if (staticContext) staticContext.statusCode = 404;
                  return this.renderNotFoundRoute();
                }}
              />
            </Switch>
          )}
        </AutoResetScroll>
       
      </FeatureToggleProvider>
    );
  }
}

interface IRouteProps {
  match?;
  location?;
}

interface IAppOwnProps {
  cookies: Cookies;
}

interface IAppStateProps {
  user;
  userGuid;
  routesMaps: {
    standardRoutes: IRouteProperties[];
    standardRoutesPaths: string[];
    nonStandardRoutes: IRouteProperties[];
    nonStandardRoutesPaths: string[];
  };
  isPublicUser: boolean;
  userRole: string;
  featureJSON?: any;
  featureDb?: any;
  accountInfo?: any;
}

interface IAppDispatchProps {
  initAccountInfo;
  initAnnouncement;
  initBusinessLines;
  initCountries;
  initSectors;
  initSources;
  initScoutAis;
  initScoutAiTopics,
  getJWTToken;
  listRadar;
  radarEmailAlertsList;
  retrieveBookmarkListItems;
  retrieveNewsListItems;
  getContentList;
  getCompanyLists;
  getFilterList;
  listQuickAccessBookmark;
  listRadarStatistic;
}

interface IAppProps
  extends RouteComponentProps,
  IAppOwnProps,
  IAppStateProps,
  IAppDispatchProps {}

const mapStateToProps = (state) => ({
  user: oidcUserSelector(state),
  isPublicUser: getIsPublicUser(state),
  userGuid: userGuidSelector(state),
  routesMaps: getActiveRoutes(state),
  userRole: getUserRole(state),
  accountInfo: getAccountInfoSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      initAccountInfo,
      initAnnouncement,
      initBusinessLines,
      initCountries,
      initSectors,
      initSources,
      initScoutAis,
      initScoutAiTopics,
      getJWTToken,
      listRadar,
      radarEmailAlertsList,
      retrieveNewsListItems,
      getContentList,
      getCompanyLists,
      getFilterList,
      listQuickAccessBookmark,
      listRadarStatistic,
    },
    dispatch
  ),
});

const withCookiesApp = withCookies(App);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookiesApp)
);
