import { ACTION} from '@constants/actions';
import { Map } from 'immutable';

const initialState = Map({
  searchFlag: false,
  newsQuery: {
    data: {},
  },
  companyQuery: {
    data: {},
  },
});

const FilterBar = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.FILTER_BAR.UPDATE_NEWS_QUERY: {
      return state.setIn(['newsQuery'], action.payload);
    }

    case ACTION.FILTER_BAR.UPDATE_COMPANY_QUERY: {
      return state.setIn(['companyQuery'], action.payload);
    }

    case ACTION.FILTER_BAR.UPDATE_SEARCH_FLAG: {
      return state.setIn(['searchFlag'], action.payload);
    }
    default:
      return state;
  }
};

export default FilterBar;
