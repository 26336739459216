import { Container, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import useStyles from './styles';
import messages from './messages';
import PageWarningIcon from '../icons/pageWarningIcon.component';
import PrimaryContainedButtonBase from '../button/base/primaryContainedButtonBase.component';
import WidgetHeader from './widgetHeader.component';

interface IWidgetErrorPageProps {
  errorHeader?: string;
  errorMessage?: string;
  errorCode?: number;
  showSupportButton?: boolean;
  supportEmail?: string;
}

const WidgetErrorPage = (props: IWidgetErrorPageProps) => {

  const { errorMessage, errorHeader, errorCode, showSupportButton, supportEmail } = props;
  const classes = useStyles();
  const intl = useIntl();

  const getSupport = () => {
    window.location.href = `mailto:${supportEmail}`;
  };

  return (
    <>
      <WidgetHeader />
      <Container
        className={classes.errorBox}
      >
        <div className={classes.errorCodeContainer}>
          {errorCode && 
          <Typography
            variant='h1'
            className={classes.errorCodeText}
          >
            {errorCode}
          </Typography>}
        </div>
        <div
          className={classes.errorMessageContainer}
        >
          <Typography
            variant='h2'
            className={classes.errorMessageText}
          >
            {!isEmpty(errorHeader) ? errorHeader : intl.formatMessage(messages.unAuthorizedAccess)}
          </Typography>
          {errorMessage &&
          <Typography
            variant='body1'
            className={classes.errorMessageLabel}
          >
            {errorMessage}
          </Typography>}
          <div className={classes.buttonContainer}>
            {showSupportButton && 
            <PrimaryContainedButtonBase
              onClick={getSupport}
            >
              {intl.formatMessage(messages.getSupport)}
            </PrimaryContainedButtonBase>}
          </div>
        </div>
        <div className={classes.bottomIcon}>
          <PageWarningIcon
            className={classes.pageWarningIcon}
          />
        </div>
      </Container>
    </>
  );
};

export default WidgetErrorPage;