import React, { ReactNode, Fragment } from 'react';
import classnames from 'classnames';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { RADAR_DIALOG_ERROR_TYPE } from '@constants/enum';
import { BookmarkDialogError } from '@models/bookmark';
import useStyles from './styles';
import DialogError from '../dialogError/dialogError.component';

interface IHsDialogClassesStyle {
  title?: string,
  content?: string,
  action?: string,
  root?: string,
}

export interface IHsDialogProps {
  content: ReactNode,
  open?: boolean,
  onClose?: () => void,
  enableCloseButton?: boolean,
  title: string | ReactNode,
  action: ReactNode,
  customClasses?: IHsDialogClassesStyle,
  errorDisplay?: boolean,
  isList?: boolean,
  isRadar?:boolean,
  disableEscapeKey?: boolean;
}

function HsDialog(props: IHsDialogProps) {
  const {
    content,
    open,
    onClose,
    title,
    action,
    enableCloseButton,
    customClasses,
    errorDisplay,
    isList,
    isRadar,
    disableEscapeKey,
  } = props;

  const classes = useStyles();

  const handleOnKeyDown = (e) => {
    if (e.key === 'Escape' && !disableEscapeKey) {
      onClose();
    }
  };

  const handleOnClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  }

  const renderDialogTitle = () => (
    <DialogTitle
      id="hs-dialog-title"
      className={classnames(classes.dialogTitleRoot, customClasses.title)}
    >
      {
        typeof title === 'string' ? 
          <Fragment>
            <Typography
              className={classes.titleRoot}
              component="span"
              variant="h5"
            >
              {title}
            </Typography>
          </Fragment> :
          title
      }
      {enableCloseButton ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )
        :
        null}
    </DialogTitle>
  );

  const renderDialogContent = () => (
    <DialogContent dividers={false} className={customClasses.content}>
      {content}
    </DialogContent>
  );

  const renderDialogAction = () => (
    <DialogActions className={classnames(classes.actionRoot, customClasses.action)}>
      {action}
    </DialogActions>
  );

  const renderDialogErrorInfo = () => {
    if(errorDisplay) {
      return (
        <DialogError
          errorType={RADAR_DIALOG_ERROR_TYPE.DUPLICATE_NAME_ERROR}
          classNameType={BookmarkDialogError.RENAME}
          isList={isList}
          isRadar={isRadar}
        />
      );
    }
    
    return null;
  };

  return (
    <Dialog
      onKeyDown={handleOnKeyDown}
      disableEscapeKeyDown
      open={open}
      onClose={handleOnClose}
      aria-labelledby="hs-dialog"
      aria-describedby="hs-dialog-description"
      classes={{
        paper: classnames(classes.paper, customClasses.root),
      }}
    >
      {renderDialogTitle()}
      {renderDialogContent()}
      {renderDialogErrorInfo()}
      {renderDialogAction()}
    </Dialog>
  );
}

HsDialog.defaultProps = {
  open: false,
  customClasses: {},
} as Partial<IHsDialogProps>;

export default HsDialog;
