import { FONT } from '@constants/style';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontStyle: 'normal',
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      fontSize: '28px',
      lineHeight: '32px',
      color: '#404040',
    },
    dialogContent: {
      padding: '24px 0 32px 0',
    },
    titleRoot: {
      padding: '0 !important',
      paddingBottom: '24px !important',
    },
    dialogRoot: {
      padding: '32px',
      width: '582px',
      '& > .MuiDialogActions-root': {
        padding: '0px 8px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '24px',
      },
    },
    actionRoot: {
      padding: 0,
    },
    action: {
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      color: '#404040 !important',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    scrollContent: {
      height: '265px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        '&:horizontal': {
          height: '13px',
        },
        '&:vertical': {
          width: '13px',
        },
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        border: '2px solid #f9f9f9',
        backgroundColor: '#bababa',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: '#f9f9f9',
      },
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: 0,
      marginLeft: 0,
      '& > button': {
        marginTop: '5px',
      },
    },
    itemDescription: {
      paddingLeft: '30px',
    },
    itemButtonDefault: {
      '& > button': {
        marginTop: '18px',
      },
    },
    itemLabel: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 16px 10.5px',
      fontWeight: FONT.WEIGHT.REGULAR,
      color: '#404040',
      fontSize: '14px',
      lineHeight: '16px',
    },
    itemName: {
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      fontSize: '18px',
      lineHeight: '24px',
    },
    itemCount: {
      color: '#707070',
    },
    itemDefault: {
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '1px',
      fontWeight: FONT.WEIGHT.MEDIUM,
      textTransform: 'uppercase',
    },
    itemLabelDisabled: {
      opacity: '0.5',
    },
    itemDisplay: {
      display: 'none',
    },
    createNewInModal: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
      '& > button': {
        marginTop: '4px',
      },
    },
    createNewRightSide: {
      display: 'flex',
      paddingLeft: '10px',
      width: '100%',
      color: '#404040',
      alignItems: 'center',
      '& > p': {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: FONT.WEIGHT.REGULAR,
      },
      '& > div': {
        position: 'relative',
        bottom: '4px',
        paddingLeft: '3px',
        flex: 1,
        '& > div > div > .MuiInputBase-input': {
          paddingBottom: '1px',
          lineHeight:24,
          fontSize: 18,
        },
        '& > div > .MuiInput-underline::before': {
          borderBottom: '1px solid #A1A1A1',
        },
      },
    },
    contentText: {
      fontSize: '18px',
      lineHeight: '24px',
    }
  }),
);

export default useStyles;