import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.articleListitem';

export default defineMessages({
  companyMention: {
    id: `${scope}.companyMention`,
    defaultMessage: '{count, plural, one {# Company Mentioned} other {# Companies Mentioned}}',
  },
  noSummaryAvailable: {
    id: `${scope}.noSummaryAvailable`,
    defaultMessage: 'No Summary Available',
  },
  scoutAITag: {
    id: `${scope}.scoutAITag`,
    defaultMessage: '{count, plural, one {# Scout AI Tag} other {# Scout AI Tags}}',
  },
  yesterday: {
    id: `${scope}.yesterday`,
    defaultMessage: 'YESTERDAY',
  },
});