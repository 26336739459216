
import { connectRouter } from 'connected-react-router/immutable';
import { History } from 'history';
import { combineReducers } from 'redux-immutable';
import { reducer as oidcReducer } from 'redux-oidc';
import Article from './article.reducer';
import Account from './account.reducer';
import Announcement from './announcement.reducer';
import Radar from './radar.reducer';
import BusinessLines from './businessLines.reducer';
import Company from './company.reducer';
import Connection from './connection.reducer';
import Content from './content.reducer';
import Country from './country.reducer';
import Drawer from './drawer.reducer';
import ScoutAI from './scoutAI.reducer';
import Source from './source.reducer';
import Campaign from './campaign.reducer';
import Data from './data.reducer';
import Dashboard from './dashboard.reducer';
import NotificationSetting from './notificationSetting.reducer';
import Faq from './faq.reducer';
import Auth from './auth.reducer';
import Progress from './progress.reducer';
import Onboarding from './onboarding.reducer';
import SearchCriteria from './searchCriteria.reducer';
import Library from './library.reducer';
import Configurations from './configurations.reducer';
import QuickSearch from './quickSearch.reducer';
import Bookmark from './bookmark.reducer';
import QuickAccess from './quickAccess.reducer';
import Relationship from './relationship.reducer';
import NewsFilter from './filters/newsFilters.reducer';
import FilterBar from './filters/filterBar.reducer';
import CompanyFilter from './filters/companyFilters.reducer';
import TopPage from './toppage.reducer';
import BookMarkNewsHeadLine from './bookmarkNewsHeadLine.reducer';

export const allReducers = {
  Account,
  Announcement,
  Article,
  BusinessLines,
  Company,
  Connection,
  Content,
  Country,
  Drawer,
  ScoutAI,
  Source,
  Oidc: oidcReducer,
  Campaign,
  Data,
  Radar,
  SearchCriteria,
  Dashboard,
  NotificationSetting,
  Faq,
  Auth,
  Progress,
  Onboarding,
  Library,
  Configurations,
  QuickSearch,
  QuickAccess,
  Bookmark,
  Relationship,
  NewsFilter,
  FilterBar,
  CompanyFilter,
  TopPage,
  BookMarkNewsHeadLine,
};

export const rootReducer = (history: History) => combineReducers({
  ...allReducers,
  router: connectRouter(history),
});
