import { ACTION } from '@constants/actions';
import { EMPTY_STRING, SEARCH_FILTER_COMPANIES_ACCORDION } from '@constants/common';
import { Map } from 'immutable';

const initialState = Map({
  selectedCalendar: {
    start: null,
    end: null,
  },
  selectedSources: [],
  selectedNewsSectors: [],
  selectedScoutAIs: [],
  selectedScoutAiTopics: [],
  selectedMentionedCompanies: [],
  selectedCountriesRegions: [],
  selectedMentionedCompanyLabels: [],
  newsAdditionalInfo: {
    publicationDate: EMPTY_STRING,
    source: EMPTY_STRING,
    sector: EMPTY_STRING,
    scoutAI: EMPTY_STRING,
    mentionedCompany: EMPTY_STRING,
    countries: EMPTY_STRING
  },
  companiesFilterAccordion: SEARCH_FILTER_COMPANIES_ACCORDION.ACCORDION_ADD_COMPANY_LIST,
});

const NewsFilter = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.FILTER.NEWS.PUBLICATION_DATE.UPDATE_SELECTED: {
      return state.setIn(['selectedCalendar'], action.payload);
    }

    case ACTION.FILTER.NEWS.PUBLICATION_DATE.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'publicationDate'], action.payload);
    }

    case ACTION.FILTER.NEWS.SOURCE.UPDATE_SELECTED: {
      return state.setIn(['selectedSources'], action.payload);
    }

    case ACTION.FILTER.NEWS.SOURCE.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'source'], action.payload);
    }

    case ACTION.FILTER.NEWS.SECTOR.UPDATE_SELECTED: {
      return state.setIn(['selectedNewsSectors'], action.payload);
    }

    case ACTION.FILTER.NEWS.SECTOR.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'sector'], action.payload);
    }

    case ACTION.FILTER.NEWS.SCOUT_AI.UPDATE_SELECTED: {
      return state.setIn(['selectedScoutAIs'], action.payload);
    }

    case ACTION.FILTER.NEWS.SCOUT_AI.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'scoutAI'], action.payload);
    }

    case ACTION.FILTER.NEWS.SCOUT_AI_TOPICS.UPDATE_SELECTED: {
      return state.setIn(['selectedNewScoutAiTopics'], action.payload);
    }

    case ACTION.FILTER.NEWS.SCOUT_AI_TOPICS.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'scoutAiTopics'], action.payload);
    }

    case ACTION.FILTER.NEWS.MENTIONED_COMPANY.UPDATE_SELECTED: {
      return state.setIn(['selectedMentionedCompanies'], action.payload);
    }

    case ACTION.FILTER.NEWS.MENTIONED_COMPANY.UPDATE_LABEL: {
      return state.setIn(['selectedMentionedCompanyLabels'], action.payload);
    }

    case ACTION.FILTER.NEWS.MENTIONED_COMPANY.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'mentionedCompany'], action.payload);
    }

    case ACTION.FILTER.NEWS.COUNTRIES_REGIONS.UPDATE_SELECTED: {
      return state.setIn(['selectedCountriesRegions'], action.payload);
    }

    case ACTION.FILTER.NEWS.COUNTRIES_REGIONS.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['newsAdditionalInfo', 'countries'], action.payload);
    }

    case ACTION.FILTER.NEWS.RESET_STATES: {
      return initialState;
    }
      
    case ACTION.FILTER.NEWS.MENTIONED_COMPANY.CONTROL_ACCORDION: {
      return state.setIn(['companiesFilterAccordion'], action.payload);
    }
    default:
      return state;
  }
};

export default NewsFilter;
