import { Map } from 'immutable';
import { CONNECTION } from '@constants/actions';

const initialState = Map({
  data: [],
  id: '',
  status: {
    inProgress: true,
    error: false,
    notFound: false,
  },
});

const Connection = (state = initialState, action) => {
  switch (action.type) {
    case CONNECTION.GET_DATA.IN_PROGRESS: {
      return state.setIn(['status', 'inProgress'], true);
    }
    case CONNECTION.GET_DATA.NOT_IN_PROGRESS: {
      return state.setIn(['status', 'inProgress'], false);
    }
    case CONNECTION.GET_DATA.RESULT: {
      const { data, id } = action.payload; 
      return state
        .set('data', data)
        .set('id', id);
    }
    default:
      return state;
  }
};

export default Connection;