import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from '@mui/material';
import { APPBAR, FONT, PAGE } from '../../constants/style';
import metricWeb from './font/metricWeb';

const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableElevation: true,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        },
      },
    },
    palette: {
      hover: {
        main: '#eef4f6',
        contrastText: '#0c6460',
      },
      primary: {
        main: FONT.COLOR.PRIMARY.MAIN,
        dark: '#0a7a73',
      },
      secondary: {
        main: FONT.COLOR.SECONDARY.MAIN,
        light: FONT.COLOR.SECONDARY.LIGHT,
      },
      error: {
        main: colors.red.A400,
        dark: FONT.COLOR.ERROR.DARK,
      },
      background: {
        default: '#f8f9fb',
      },
      tab: {
        main: FONT.COLOR.TAB.MAIN,
        contrastText: FONT.COLOR.TAB.CONTRAST_TEXT,
      },
    },
    typography: {
      h1: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      h2: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      h3: {
        fontSize: '2.5rem',
        color: FONT.COLOR.TEXT.MAIN,
      },
      h4: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      h5: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      h6: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      subtitle1: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      subtitle2: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      body1: {
        color: FONT.COLOR.TEXT.MAIN,
        lineHeight: 1.25,
      },
      body2: {
        color: FONT.COLOR.TEXT.MAIN,
      },
      caption: {
        color: FONT.COLOR.CAPTION.MAIN,
        textTransform: 'uppercase',
      },
      fontFamily: FONT.FAMILY,
    },
  })
);

theme.components = {
  ...theme.components,
  MuiAppBar: {
    styleOverrides: {
      root: {
        height: `${APPBAR.HEIGHT}px`,
        // Display on top of left drawer
        // 1202
        zIndex: theme.zIndex.drawer + 2,
        background: '#FFF'
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      noOptions: {
        fontFamily: FONT.FAMILY,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      ...({
        ...Object.keys(metricWeb).map((key) => {
          return { '@font-face': metricWeb[key] };
        }),
      } as any),
      body: {
        padding: 0,
        margin: 0,
        backgroundColor: PAGE.BG,
        '& a': {
          textDecoration: 'none',
          color: theme.palette.primary.main,
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      button: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: FONT.COLOR.SECONDARY.MAIN,
        boxShadow: 'none',
        textTransform: 'none',
      },
      indicator: {
        backgroundColor: '#ffffff',
      },
      scrollButtons: {
        color: '#ffffff',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.light,
        },
        '&$selected': {
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      regular: {
        [theme.breakpoints.up('xs')]: {
          minHeight: `${APPBAR.HEIGHT}px`,
        },
      },
      gutters: {
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      outlined: {
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: 0,
        maxHeight: '200px',
        marginTop: '4px',
        '& .MuiList-padding': {
          padding: 0,
        },
        '&::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          background: 'white',
          '&:horizontal': {
            height: '13px',
          },
          '&:vertical': {
            width: '13px',
          },
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '8px',
          border: '4px solid white',
          backgroundColor: '#bababa',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '0 !important',
        fontSize: theme.spacing(2),
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderWidth: '1px',
          },
        },
      },
    },
  },
};

export const widgetTheme = {
  ...theme,
  components: {
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: {
        ...({
          ...Object.keys(metricWeb).map((key) => {
            return { '@font-face': metricWeb[key] };
          }),
        } as any),
        html: {
          height: '100%',
          overflow: 'hidden',
        },
        body: {
          height: '100%',
          overflow: 'hidden',
          padding: '0',
          margin: 0,
          backgroundColor: 'white', 
          '& a': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          },
          '& > #widget-app': {
            height: '100%',
          },
        },
      },
    },
  }
};

export default theme;
