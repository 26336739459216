import React from 'react';
import { PAGE_TITLE } from '@constants/routes';
import { Typography } from '@mui/material';
import HsTooltip, { generateMultilineTooltipText } from '@react/components/tooltip/hsTooltip.component';
import { isEmpty } from 'lodash';
import useStyles from '../../radar/detail/news/styles';
import { useIntl } from 'react-intl';
import messages from '../../radar/detail/news/messages';

interface IArticleListItemTags {
    scoutAis: any[],
    mentionedCompanies: any[],
    scoutAisTracking?: any,
    mentionedCompaniesTracking?: any,
}

const ArticleListItemTags = (props: IArticleListItemTags) => {
    const { scoutAis, mentionedCompanies, scoutAisTracking, mentionedCompaniesTracking } = props;
    const classes = useStyles();
    const intl = useIntl();
    return (
        <>
          {!isEmpty(scoutAis) && (
            <HsTooltip
              placement="bottom"
              arrow
              tooltipText={generateMultilineTooltipText(
                scoutAis.map((i) => i.name)
              )}
              customClasses={{ popper: classes.popper }}
              atlastTrackingObj={scoutAisTracking || {}}
            >
              <Typography className={classes.textColor}>
                {`${scoutAis.length} ${PAGE_TITLE.SCOUT_AI}`}
              </Typography>
            </HsTooltip>
          )}
          {!isEmpty(mentionedCompanies) && (
            <HsTooltip
              placement="bottom"
              arrow
              tooltipText={generateMultilineTooltipText(
                mentionedCompanies.map((i) =>
                  i.preferredName?.toLowerCase()
                )
              )}
              customClasses={{
                tooltip: classes.companyToolTip,
                popper: classes.popper,
              }}
              atlastTrackingObj={mentionedCompaniesTracking || {}}
            >
              <Typography className={classes.textColor}>
                {intl.formatMessage(messages.company, {
                  count: mentionedCompanies.length,
                })}
              </Typography>
            </HsTooltip>
          )}
        </>
      );
};

export default ArticleListItemTags;