import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Button } from '@mui/material';
import AtlasDataTrackable, { IAtlasDataTrackable } from '@react/components/atlas/atlas.component';
import { IButtonProps } from '../../props/common/button.props';
import { IMobileProps } from '../../props/common/mobile.props';
import { useStyles } from './style';

interface IPrimaryContainedButtonBaseProps extends IButtonProps, IMobileProps {
  size?: 'small' | 'medium' | 'large',
  icon?: ReactNode,
  children?: ReactNode,
  type?: 'submit' | 'reset' | 'button',
  disabled?: boolean,
  disabledClassName?: string,
  atlasTrackingObj?: IAtlasDataTrackable,
}

const PrimaryContainedButtonBase = (props: IPrimaryContainedButtonBaseProps) => {
  const {
    className,
    onClick,
    mobile,
    icon,
    type,
    children,
    disabled,
    disabledClassName,
    size,
    atlasTrackingObj,
    ...otherProps
  } = props;

  const color = 'primary';
  const variant = 'contained';

  const classes = useStyles();

  return (
    mobile ?
      <Button
        color={color}
        onClick={onClick}
        size={size}
        variant={variant}
        type={type}
        disableRipple
        disableElevation
        disabled={disabled}
        classes={{
          root: classnames(classes.root, classes.primaryContainedRoot, {
            [className]: !!className,
            [disabledClassName]: disabled,
            [classes.primaryContainedDisabledRoot]: disabled,
          }),
        }}
        {...AtlasDataTrackable(atlasTrackingObj || {})}
        {...otherProps}
      >
        {icon}
      </Button> :
      <Button
        color={color}
        onClick={onClick}
        size={size}
        variant={variant}
        startIcon={icon}
        type={type}
        disableRipple
        disableElevation
        disabled={disabled}
        classes={{
          root: classnames(classes.root, classes.primaryContainedRoot, {
            [className]: !!className,
            [disabledClassName]: disabled,
            [classes.primaryContainedDisabledRoot]: disabled,
          }),
        }}
        {...AtlasDataTrackable(atlasTrackingObj || {})}
        {...otherProps}
      >
        {children}
      </Button>
  );
};

PrimaryContainedButtonBase.defaultProps = {
  mobile: false,
  type: 'button',
  size: 'medium',
} as Partial<IPrimaryContainedButtonBaseProps>;

export default PrimaryContainedButtonBase;