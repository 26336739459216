import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.radar.dialogError';

export default defineMessages({
  unableToSave: {
    id: `${scope}.unableToSave`,
    defaultMessage: 'Unable to save {isSearch, select, true {Search Feed} other {Companies}} to Radar.',
  },
  limitReached: {
    id: `${scope}.limitReached`,
    defaultMessage: 'Selected Radar has reached {isNews, select, true {News} other {Companies}} Search Feed limit of {count}.',
  },
  limitReachedSearchFeed: {
    id: `${scope}.limitReachedSearchFeed`,
    defaultMessage: '{isAll, select, true {All Radars have} other {Radar selected has}} reached the limit of {count} {isNews, select, true {News} other {Company}} Search Feeds.',
  },
  limitReachedCompanies: {
    id: `${scope}.limitReachedCompanies`,
    defaultMessage: '{isAll, select, true {All Radars have} other {Radar selected has}} reached the limit of {count} Starred Companies.',
  },
  limitReachedSubSearchFeed: {
    id: `${scope}.limitReachedSubSearchFeed`,
    defaultMessage: 'Edit or delete existing search feeds in your Radars to save new search feeds.',
  },
  limitReachedSubCompanies: {
    id: `${scope}.limitReachedSubCompanies`,
    defaultMessage: 'Remove existing Starred Companies in your Radars to save new Starred Companies.',
  },
  nameExists: {
    id: `${scope}.nameExists`,
    defaultMessage: 'A {type, select, LIST {list} FILTER {filter} other {radar} } with the same name exists.',
  },
  changeDuplicateName: {
    id: `${scope}.changeDuplicateName`,
    defaultMessage: 'Enter another name to proceed.',
  },
  moreThanErrorTitle: {
    id: `${scope}.moreThanFourErrorTitle`,
    defaultMessage: 'You may only select up to {count} items',
  },
  moreThanFourErrorContent: {
    id: `${scope}.moreThanFourErrorContent`,
    defaultMessage: 'Uncheck an item to make another selection.',
  },
  maximumErrorMessage: {
    id: `${scope}.maximumErrorMessage`,
    defaultMessage: 'You have reached the maximum number of Radars and list/filter slots.',
  },
  maximumErrorSubMessage: {
    id: `${scope}.maximumErrorSubMessage`,
    defaultMessage: 'If you need more Radars, reach out to your sales representative or contact ',
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: 'support@scout.asia',
  },
  filterBeSaved: {
    id: `${scope}.filterBeSaved`,
    defaultMessage: 'This filter will be saved.',
  },
  listBeSaved: {
    id: `${scope}.listBeSaved`,
    defaultMessage: 'This list will be saved.',
  },
  updateFilterError: {
    id: `${scope}.updateFilterError`,
    defaultMessage: 'Updating this filter will update synced Radars. ',
  },
  maximumRadar: {
    id: `${scope}.maximumRadar`,
    defaultMessage: 'You have reached the maximum number of Radars.',
  },
  maximumRadarSubtitle: {
    id: `${scope}.maximumRadarSubtitle`,
    defaultMessage: 'Sync this {isList, select, true {list} other {filter}} to Radar(s) with an available slot.',
  },
  updateListError: {
    id: `${scope}.updateListError`,
    defaultMessage: 'Updating selected company lists will update synced Radars. ',
  },
});