import React, { useEffect } from 'react';
import { Route, withRouter, RouteComponentProps, Redirect } from 'react-router';
import { authValidateUser } from '@redux/actions/auth.action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ROUTE_PATHS } from '@constants/routes';
import { isValidUser } from '@react/utils/auth.util';
import BlankPage from '@react/components/blank/blank.component';
import { authValidSelector } from '@redux/selectors/auth.selector';
import { isLoadingUserSelector, oidcUserSelector } from '@redux/selectors/oidc.selector';
import { isLoggingOutSelector } from '@redux/selectors/account.selector';
import { LOCAL_STORAGE } from '@constants/common';

interface CallbackRouteState {
  isValid: boolean;
  oidcUser: any;
  isLoadingUser: boolean;
  isLoggingOut: boolean;
}

interface CallbackRouteDispatch {
  validate: (user) => void;
}

interface CallbackRouteOwnProps extends RouteComponentProps {
  path: string;
  exact?: boolean;
  component?;
  render?;
}

type CallbackRouteProps = 
  CallbackRouteState &
  CallbackRouteDispatch &
  CallbackRouteOwnProps;
 
const CallbackRoute: React.FC<CallbackRouteProps> = (props) => {
  const { isValid, validate, oidcUser, isLoadingUser, isLoggingOut } = props;
  useEffect(() => {
    if (!isLoggingOut && !isLoadingUser) {
      validate(isValidUser(oidcUser));
    } 
  }, [oidcUser, isLoadingUser]);
  
  if (isValid === undefined) {
    return <BlankPage />;
  }
  if (!isValid) {
    return (
      <Route
        {...props}
      />
    );
  }
  // TODO: if oidc logged in then go to redirectUrl. (this fix when start from p225 delivery page, but is not good practice.)
  const redirectUrl = sessionStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) ? decodeURIComponent(sessionStorage.getItem(LOCAL_STORAGE.REDIRECT_URL)) : ROUTE_PATHS.MAIN;
  
  return <Redirect to={{ pathname: redirectUrl }} />;
};
 
const mapStateToProps = (state) => ({
  isValid: authValidSelector(state),
  oidcUser: oidcUserSelector(state),
  isLoadingUser: isLoadingUserSelector(state),
  isLoggingOut: isLoggingOutSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      validate: authValidateUser,
    },
    dispatch,
  ),
});

const connected = connect<
CallbackRouteState,
CallbackRouteDispatch,
CallbackRouteOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CallbackRoute);

export default withRouter(connected);

