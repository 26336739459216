import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IPageLockedIcon extends ISvgIconProps {}

const PageLockedIcon = (props: IPageLockedIcon) => {
  const { className } = props;
  const svg = (
    <React.Fragment>
      <defs>
        <linearGradient id="a" x1="0%" y1="50.001%" y2="50.001%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="b" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="c" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="d" x1="50.002%" x2="50.002%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="e" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="f" x1="50%" x2="50%" y1="100.042%" y2=".042%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="g" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <path id="h" d="M150.333 101.565H145v-5.391C145 87.254 137.824 80 129 80s-16 7.254-16 16.174v5.391h-5.333c-1.472 0-2.667 1.208-2.667 2.696V142c0 1.488 1.195 2.696 2.667 2.696h42.666c1.472 0 2.667-1.208 2.667-2.696v-37.74c0-1.487-1.195-2.695-2.667-2.695zm-18.666 26.46v5.888h-5.334v-5.887a8.07 8.07 0 0 1-5.333-7.591c0-4.459 3.59-8.087 8-8.087s8 3.628 8 8.087a8.067 8.067 0 0 1-5.333 7.59zm8-26.46h-21.334v-5.391c0-5.947 4.784-10.783 10.667-10.783s10.667 4.836 10.667 10.783v5.391z" />
      </defs>
      <g fill="none" fillRule="nonzero" transform="translate(1 8)">
        <path fill="#F5F5F5" d="M119.39 19.831l.752-4.943 133.468 20.28-.752 4.944z" />
        <path fill="url(#a)" d="M126 6h107v141H126z" transform="rotate(-81.36 179.5 76.5)" />
        <path fill="#F5F5F5" d="M104.33 118.298l14.872-97.876 133.468 20.28-14.872 97.876z" />
        <circle cx="122.5" cy="18.5" r="1.5" fill="#FF5252" transform="rotate(-81.36 122.5 18.5)" />
        <circle cx="127.5" cy="19.5" r="1.5" fill="#FF0" transform="rotate(-81.36 127.5 19.5)" />
        <circle cx="130.5" cy="20.5" r="1.5" fill="#69F0AE" transform="rotate(-81.36 130.5 20.5)" />
        <path fill="url(#b)" d="M163-4h35v122h-35z" transform="rotate(-81.36 180.5 57)" />
        <path fill="#007B78" d="M124.154 32.249l117.65 17.877-4.958 32.625-117.65-17.877z" />
        <path fill="url(#c)" d="M136 82h7v8h-7z" transform="rotate(-81.36 139.5 86)" />
        <path fill="#007B78" d="M136.421 83.427l7.91 1.202-.752 4.944-7.91-1.202z" />
        <path fill="#FF5252" d="M136.421 93.427l7.91 1.202-.752 4.944-7.91-1.202z" />
        <path fill="#FF9800" d="M134.421 101.427l7.91 1.202-.752 4.944-7.91-1.202z" />
        <path fill="#007B78" d="M156.652 87.366l61.297 9.314-.601 3.954-61.297-9.314zM155.652 96.366l61.297 9.314-.601 3.954-61.297-9.314zM153.652 106.366l61.297 9.314-.601 3.954-61.297-9.314z" opacity=".4" />
        <path fill="#F5F5F5" d="M4.189 34.767L144.72 3.354l1.09 4.88L5.28 39.645z" />
        <path fill="url(#d)" d="M10 16h150v106H10z" transform="rotate(-12.6 85 69)" />
        <path fill="#F5F5F5" d="M4.154 40.874L144.686 9.462l21.16 94.664-140.532 31.412z" />
        <path fill="url(#e)" d="M15 34h129v35H15z" transform="rotate(-12.6 79.5 51.5)" />
        <path fill="#007B78" d="M13.918 48.14l122.965-27.486 7.2 32.206L21.116 80.346z" />
        <path fill="url(#f)" d="M45 89h9v7h-9z" transform="rotate(-12.6 49.5 92.5)" />
        <path fill="#007B78" d="M45.55 90.937l7.805-1.752 1.095 4.878-7.805 1.752z" />
        <path fill="#FF5252" d="M46.551 99.933l7.807-1.745 1.091 4.88-7.807 1.744z" />
        <path fill="#FF9800" d="M49.55 109.937l7.805-1.752 1.095 4.878-7.805 1.752z" />
        <path fill="#007B78" d="M66.346 87.138l63.435-14.18.873 3.904-63.435 14.18zM68.346 96.138l63.435-14.18.873 3.904-63.435 14.18zM69.346 106.138l63.435-14.18.873 3.904-63.435 14.18z" opacity=".4" />
        <path fill="#F5F5F5" d="M49 6h163v7H49z" />
        <path fill="url(#e)" d="M46 4h170v131H46z" />
        <path fill="#FFF" d="M49 13h163v119H49z" />
        <path fill="url(#g)" d="M56 24h146v43H56z" />
        <path fill="#007B78" d="M57 26h143v40H57z" />
        <path fill="url(#e)" d="M81 84h10v8H81z" />
        <path fill="#007B78" d="M81 85h9v7h-9z" />
        <path fill="#FF5252" d="M81 96h9v7h-9z" />
        <path fill="#FF9800" d="M81 108h9v7h-9z" />
        <path fill="#007B78" d="M106 86h74v4h-74zM106 98h74v4h-74zM106 109h74v4h-74z" opacity=".4" />
      </g>
      <g fill="#007B78">
        <path id="h" d="M150.333 101.565H145v-5.391C145 87.254 137.824 80 129 80s-16 7.254-16 16.174v5.391h-5.333c-1.472 0-2.667 1.208-2.667 2.696V142c0 1.488 1.195 2.696 2.667 2.696h42.666c1.472 0 2.667-1.208 2.667-2.696v-37.74c0-1.487-1.195-2.695-2.667-2.695zm-18.666 26.46v5.888h-5.334v-5.887a8.07 8.07 0 0 1-5.333-7.591c0-4.459 3.59-8.087 8-8.087s8 3.628 8 8.087a8.067 8.067 0 0 1-5.333 7.59zm8-26.46h-21.334v-5.391c0-5.947 4.784-10.783 10.667-10.783s10.667 4.836 10.667 10.783v5.391z" />
      </g>
    </React.Fragment>
  );

  return (
    <SvgIcon viewBox="0 0 260 160" className={className}>
      {svg}
    </SvgIcon>
  );
};

export default PageLockedIcon;