import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IPageWarningIcon extends ISvgIconProps {}

const PageWarningIcon = (props: IPageWarningIcon) => {
  const { className } = props;
  const svg = (
    <React.Fragment>
      <path d="M120.316 19.9851L121.073 15.0035L255.575 35.4412L254.819 40.4228L120.316 19.9851Z" fill="#F5F5F5" />
      <rect x="102.552" y="119.723" width="107.829" height="142.093" transform="rotate(-81.36 102.552 119.723)" fill="url(#paint0_linear_1928_6542)" />
      <path d="M105.139 119.215L120.126 20.5798L254.629 41.0175L239.641 139.653L105.139 119.215Z" fill="#F5F5F5" />
      <ellipse cx="123.45" cy="18.6435" rx="1.51163" ry="1.51163" transform="rotate(-81.36 123.45 18.6435)" fill="#FF5252" />
      <ellipse cx="128.488" cy="19.6513" rx="1.51163" ry="1.51163" transform="rotate(-81.36 128.488 19.6513)" fill="#FFFF00" />
      <ellipse cx="131.512" cy="20.6589" rx="1.51163" ry="1.51163" transform="rotate(-81.36 131.512 20.6589)" fill="#69F0AE" />
      <path d="M118.475 65.6426L123.773 30.7715L245.324 49.2411L240.025 84.1122L118.475 65.6426Z" fill="url(#paint1_linear_1928_6542)" />
      <path d="M125.116 32.4988L243.678 50.5142L238.682 83.3926L120.121 65.3772L125.116 32.4988Z" fill="#007B78" />
      <path d="M136.066 89.5483L137.126 82.5741L145.097 83.7852L144.037 90.7595L136.066 89.5483Z" fill="url(#paint2_linear_1928_6542)" />
      <path d="M137.479 84.0742L145.449 85.2853L144.692 90.2669L136.722 89.0558L137.479 84.0742Z" fill="#007B78" />
      <path d="M137.479 94.1519L145.449 95.363L144.692 100.345L136.722 99.1334L137.479 94.1519Z" fill="#FF5252" />
      <path d="M135.463 102.214L143.434 103.425L142.677 108.407L134.706 107.195L135.463 102.214Z" fill="#FF9800" />
      <path opacity="0.4" d="M157.867 88.043L219.638 97.4292L219.033 101.414L157.261 92.0282L157.867 88.043Z" fill="#007B78" />
      <path opacity="0.4" d="M156.859 97.1128L218.63 106.499L218.025 110.484L156.253 101.098L156.859 97.1128Z" fill="#007B78" />
      <path opacity="0.4" d="M154.844 107.19L216.615 116.577L216.01 120.562L154.238 111.176L154.844 107.19Z" fill="#007B78" />
      <path d="M4.22119 35.0361L145.843 3.38L146.942 8.29741L5.32036 39.9535L4.22119 35.0361Z" fill="#F5F5F5" />
      <path d="M0.246582 33.8979L147.769 0.922806L171.071 105.172L23.549 138.147L0.246582 33.8979Z" fill="url(#paint3_linear_1928_6542)" />
      <path d="M4.18628 41.1914L145.808 9.53527L167.132 104.933L25.5102 136.589L4.18628 41.1914Z" fill="#F5F5F5" />
      <path d="M12.8347 48.8677L139.704 20.5091L147.398 54.9309L20.5289 83.2895L12.8347 48.8677Z" fill="url(#paint4_linear_1928_6542)" />
      <path d="M14.0259 48.5137L137.945 20.8146L145.199 53.2695L21.2804 80.9686L14.0259 48.5137Z" fill="#007B78" />
      <path d="M44.6887 90.7642L53.5401 88.7857L55.0789 95.67L46.2276 97.6485L44.6887 90.7642Z" fill="url(#paint5_linear_1928_6542)" />
      <path d="M45.9026 91.6418L53.7688 89.876L54.8725 94.7924L47.0063 96.5582L45.9026 91.6418Z" fill="#007B78" />
      <path d="M46.9119 100.708L54.7797 98.9488L55.8789 103.866L48.011 105.625L46.9119 100.708Z" fill="#FF5252" />
      <path d="M49.9336 110.789L57.7998 109.023L58.9035 113.94L51.0373 115.705L49.9336 110.789Z" fill="#FF9800" />
      <path opacity="0.4" d="M66.8608 87.8135L130.787 73.5242L131.667 77.4582L67.7402 91.7474L66.8608 87.8135Z" fill="#007B78" />
      <path opacity="0.4" d="M68.8762 96.8831L132.803 82.5938L133.682 86.5278L69.7556 100.817L68.8762 96.8831Z" fill="#007B78" />
      <path opacity="0.4" d="M69.884 106.961L133.81 92.6715L134.69 96.6054L70.7634 110.895L69.884 106.961Z" fill="#007B78" />
      <path d="M49.3799 6.04663H213.643V13.1009H49.3799V6.04663Z" fill="#F5F5F5" />
      <path d="M46.3567 4.03101H217.675V136.047H46.3567V4.03101Z" fill="url(#paint6_linear_1928_6542)" />
      <path d="M49.3799 13.1008H213.643V133.023H49.3799V13.1008Z" fill="white" />
      <path d="M56.4341 24.186H203.566V67.5194H56.4341V24.186Z" fill="url(#paint7_linear_1928_6542)" />
      <path d="M57.4419 26.2017H201.55V66.5117H57.4419V26.2017Z" fill="#007B78" />
      <path d="M81.6279 84.6514H91.7055V92.7134H81.6279V84.6514Z" fill="url(#paint8_linear_1928_6542)" />
      <path d="M81.6279 85.6589H90.6977V92.7132H81.6279V85.6589Z" fill="#007B78" />
      <path d="M81.6279 96.7444H90.6977V103.799H81.6279V96.7444Z" fill="#FF5252" />
      <path d="M81.6279 108.837H90.6977V115.892H81.6279V108.837Z" fill="#FF9800" />
      <path opacity="0.4" d="M106.822 86.6667H181.395V90.6978H106.822V86.6667Z" fill="#007B78" />
      <path opacity="0.4" d="M106.822 98.7598H181.395V102.791H106.822V98.7598Z" fill="#007B78" />
      <path opacity="0.4" d="M106.822 109.845H181.395V113.876H106.822V109.845Z" fill="#007B78" />
      <path d="M157.53 98.7598H103.478C101.983 98.7598 100.775 99.8696 100.775 101.237V155.74C100.775 157.107 101.983 158.217 103.478 158.217H157.53C159.024 158.217 160.233 157.107 160.233 155.74V101.237C160.233 99.8696 159.024 98.7598 157.53 98.7598Z" fill="#E74C3C" />
      <path d="M130.504 145.83C129.012 145.83 127.801 144.72 127.801 143.353C127.801 141.985 129.012 140.875 130.504 140.875C131.996 140.875 133.206 141.985 133.206 143.353C133.206 144.72 131.996 145.83 130.504 145.83Z" fill="white" />
      <path d="M133.206 135.921H127.801V113.624H133.206V135.921Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_1928_6542" x1="102.552" y1="261.817" x2="210.381" y2="261.817" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint1_linear_1928_6542" x1="240.025" y1="84.1122" x2="245.324" y2="49.2411" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint2_linear_1928_6542" x1="144.037" y1="90.7595" x2="145.097" y2="83.7852" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint3_linear_1928_6542" x1="171.074" y1="105.171" x2="147.772" y2="0.922176" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint4_linear_1928_6542" x1="147.398" y1="54.9309" x2="139.704" y2="20.5091" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint5_linear_1928_6542" x1="55.0796" y1="95.6729" x2="53.5407" y2="88.7886" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint6_linear_1928_6542" x1="217.675" y1="136.047" x2="217.675" y2="4.03101" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint7_linear_1928_6542" x1="203.566" y1="67.5194" x2="203.566" y2="24.186" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint8_linear_1928_6542" x1="91.7055" y1="92.7134" x2="91.7055" y2="84.6514" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </React.Fragment>
  );

  return (
    <SvgIcon viewBox="0 0 260 160" className={className}>
      {svg}
    </SvgIcon>
  );
};

export default PageWarningIcon;