import loadable from '@loadable/component';
import React, { Fragment } from 'react';


const options = { fallback : <div /> };

const LoadableHome = loadable(
  () => import(/* webpackPreload: true */'./home/home.page'),
  options,
);

const LoadablePartnerAccountActivation = loadable(
  () => import('./account/partnerAccountActivation.page'),
  options,
);

const LoadablePartnerAccountRegistration = loadable(
  () => import('./account/partnerAccountRegistration.page'),
  options,
);

const LoadableError = loadable(
  () => import(/* webpackPreload: true */'./error/error.page'),
  options,
);

const LoadableServerError = loadable(
  () => import(/* webpackPreload: true */'./error/serverError.page'),
  options,
);

const LoadableCompanyList = loadable(
  () => import(/* webpackPreload: true */'./company/list.page'),
  options,
);

const LoadableCompanyDetail = loadable(
  () => import(/* webpackPreload: true */'./company/detail.page'),
  options,
);

const LoadableConnection = loadable(
  () => import(/* webpackPreload: true */'./connections/connection.page'),
  options,
);

const LoadableConnectionPlusTerms = loadable(
  () => import(/* webpackPreload: true */'./connectionPlusTerms/connectionPlusTerms.page'),
  options,
);

const LoadableAlertSettings = loadable(
  () => import('./alertSettings/alertSettings.page'),
  options,
);

const LoadableArticleResult = loadable(
  () => import('./article/list.page'),
  options,
);

const LoadableArticleDetail = loadable(
  () => import(/* webpackPreload: true */'./article/detail.page'),
  options,
);

const LoadableClusterResult = loadable(
  () => import(/* webpackPreload: true */'./trendingTopics/list.page'),
  options,
);

const LoadableProfile = loadable(
  () => import('./profile/profile.page'),

  options,
);

const LoadableResetPassword = loadable(
  () => import('./profile/resetPassword.page'),
  options,
);

const LoadableForgetPassword = loadable(
  () => import('./profile/forgetPassword.page'),
  options,
);

const LoadableRequestAccess = loadable(
  () => import('./profile/requestAccess.page'),
);

const LoadableAcceptOrgLink = loadable(
  () => import('./profile/linkOrg.page'),
  options,
);

const LoadableChangePassword = loadable(
  () => import('./profile/changePassword.page'),
  options,
);

const LoadableOidcCallback = loadable(
  () => import('./oidc/callback.page'),
  options,
);

const LoadableScoutAITrain = loadable(
  () => import('./scoutAI/scoutTraining.page'),
  options,
);

const LoadableScoutAIResult = loadable(
  () => import('./scoutAI/scoutResult.page'),
  options,
);

const LoadableScoutAIVisualisation = loadable(
  () => import('./scoutAI/visualisation.page'),
  options,
);

const LoadableOidcSilent = loadable(
  () => import('./oidc/silent.page'),
);

const LoadableCampaignRegister = loadable(
  () => import('./campaign/register.page'),
  options,
);

const LoadableCampaignRequest = loadable(
  () => import('./campaign/request.page'),
  options,
);

const LoadableCampaignUnsubscribe = loadable(
  () => import('./campaign/unsubscribe.page'),
  options,
);

const LoadableCmsOverview = loadable(
  () => import('./country/overview.page'),
  options,
);

const LoadableCmsFaq = loadable(
  () => import('./faq/faq.page'),
  options,
);

const LoadableCountryRegionSector = loadable(
  () => import('./country/countryRegionSector.page'),
  options,
);

const LoadableBlankPage = loadable(
  () => import('./blankPage/blankPage.component'),
  options,
);

const LoadableMaintenance = loadable(
  () => import('./maintenance/maintenance.page'),
  options,
);

const LoadableNotFound = loadable(
  () => import('./error/notFound.page'),
  options,
);

const LoadableRadarLanding = loadable(
  () => import('./radar/landing.page'),
  options,
);

const LoadableRadarDetail = loadable(
  () => import('./radar/detail.page'),
  options,
);

const LoadableRadarSettings = loadable(
  () => import('./radar/settings.page'),
  options,
);

const LoadableNoticeOfNoticeOfAssignment = loadable(
  () => import('./noticeOfAssignment/noticeOfAssignment.page'),
  options,
);


const LoadableLibrary = loadable(
  () => import('./library/library.page'),
  options,
);

const LoadableSearch = loadable(
  () => import('./search/main.page'),
);

const LoadableBookmarkDetail = loadable(
  () => import('./bookmark/detail.page'),
  options,
);

const loadableToFC = <P extends object>(Component: React.ComponentType<P>): React.ComponentType => {
  return (props: any) => {
    return (
      <Fragment>
        <Component {...props} /> 
      </Fragment>
    );
  };
};

export const Home = loadableToFC(LoadableHome);
export const PartnerAccountActivation = loadableToFC(LoadablePartnerAccountActivation);
export const PartnerAccountRegistration = loadableToFC(LoadablePartnerAccountRegistration);
export const Error = loadableToFC(LoadableError);
export const ServerError = loadableToFC(LoadableServerError);
export const CompanyList = loadableToFC(LoadableCompanyList);
export const CompanyDetail = loadableToFC(LoadableCompanyDetail);
export const Connection = loadableToFC(LoadableConnection);
export const ConnectionPlusTerms = loadableToFC(LoadableConnectionPlusTerms);
export const AlertSettings = loadableToFC(LoadableAlertSettings);
export const ArticleList = loadableToFC(LoadableArticleResult);
export const ArticleDetail = loadableToFC(LoadableArticleDetail);
export const ClusterList = loadableToFC(LoadableClusterResult);
export const Profile = loadableToFC(LoadableProfile);
export const ChangePassword = loadableToFC(LoadableChangePassword);
export const ResetPassword = loadableToFC(LoadableResetPassword);
export const RequestAccess = loadableToFC(LoadableRequestAccess);
export const ForgetPassword = loadableToFC(LoadableForgetPassword);
export const AcceptOrgLink = loadableToFC(LoadableAcceptOrgLink);
export const ScoutAIResult = loadableToFC(LoadableScoutAIResult);
export const ScoutAITrain = loadableToFC(LoadableScoutAITrain);
export const ScoutAIVisualisation = loadableToFC(LoadableScoutAIVisualisation);

export const OidcCallback = loadableToFC(LoadableOidcCallback);
export const OidcSilent = loadableToFC(LoadableOidcSilent);

export const CampaignRegister = loadableToFC(LoadableCampaignRegister);
export const CampaignRequest = loadableToFC(LoadableCampaignRequest);
export const CampaignUnsubscribe = loadableToFC(LoadableCampaignUnsubscribe);

export const CmsOverview = loadableToFC(LoadableCmsOverview);
export const CmsFaq = loadableToFC(LoadableCmsFaq);
export const CountryRegionSector = loadableToFC(LoadableCountryRegionSector);

export const NoticeOfNoticeOfAssignmentPage = loadableToFC(LoadableNoticeOfNoticeOfAssignment);

export const LogoutRedirect = loadableToFC(LoadableBlankPage);
export const Maintenance = loadableToFC(LoadableMaintenance);
export const NotFound = loadableToFC(LoadableNotFound);

export const RadarLandingPage = loadableToFC(LoadableRadarLanding);
export const RadarDetailPage = loadableToFC(LoadableRadarDetail);
export const RadarSettingsPage = loadableToFC(LoadableRadarSettings);
export const Library = loadableToFC(LoadableLibrary);
export const SearchPage = loadableToFC(LoadableSearch);
export const BookmarkDetailPage = loadableToFC(LoadableBookmarkDetail);
