import { Map } from 'immutable';
import { RADAR, SEARCH_CRITERIA } from '@constants/actions';
import { Radar as RadarType, ActiveRadarFeed, RadarCompaniesOverview, RadarEmailAlerts, RadarSearchCriteria, RadarMentionCompanyCountByInterval, RadarStats, RadarArticleOverview, RadarArticle } from '@models/radar';
import { ENTITY_TYPE, RADAR_SEARCH_TYPE, SAVE_TO_RADAR_TYPE, VIEW_MORE_COUNT_DEFAULT } from '@constants/common';
import { isTheSameString } from '@react/utils/common.util';
import { isEmpty } from 'lodash';
import { IUpdateCounts } from '@redux/actions/radar.actions';
import { IBookmarkFilter } from '@models/bookmark';

const initialState = Map({
  activeInitRadar: {},
  selectedRadar: {},
  saveDialogTypeOpen: SAVE_TO_RADAR_TYPE.NONE,
  radarList: [] as RadarType[],
  listInProgress: false,
  articleOverviewInProgress: false,
  radarStatsList: {} as RadarStats,
  articles: {
    articleOverView: [] as RadarArticleOverview[],
    list: [] as RadarArticle[],
    totalCount: 0,
    viewMoreCount: VIEW_MORE_COUNT_DEFAULT,
    articleInProgress: false,
    mentionedCompany: [],
    mentionedCompanyTotalCount: 0,
    mentionInProgress: false,
  },
  companies: {
    matched: {
      list: [] as RadarCompaniesOverview,
      totalCount: 0,
      mentionedCompany: [],
      companyInProgress: false,
      mentionedCompanyTotalCount: 0,
      listMentionCountInProgress: false,
      mentionCompanyCountByInterval: [] as RadarMentionCompanyCountByInterval[],
    },
    starred: {
      list: [] as RadarCompaniesOverview,
      totalCount: 0,
      mentionedCompany: [],
      companyInProgress: false,
      mentionedCompanyTotalCount: 0,
      listMentionCountInProgress: false,
      mentionCompanyCountByInterval: [] as RadarMentionCompanyCountByInterval[],
    },
  },
  updateInProgress: false,
  activeRadarFeed: {} as ActiveRadarFeed,
  favCompany: [] as string[],
  listRadarCompanyInProgress: false,
  radarSettingParam: {
    isFromRadarSetting: false,
    isSelectedCompanyList: false,
    radarGuid: '',
  },
  emailAlertsList:[] as RadarEmailAlerts[],
  emailAlertsListInProgress: false,
});

const updateRadarCompanyCount = (currentRadarList: RadarType[], addedList: any[]) => {
  return currentRadarList.map(radar => {
    const addedRecord = addedList.find(addedRadar => isTheSameString(addedRadar.radarGuid, radar.guid));
    if (addedRecord) {
      return {
        ...radar,
        counts: {
          ...(radar.counts ? radar.counts : {} as any),
          favoriteCompany: addedRecord.count,
        },
      };
    }
    return radar;
  });
};

const updateRadarSCCount = (currentRadarList: RadarType[], scList: any[]) => {
  return currentRadarList.map(radar => {
    const addedRecord = scList.find(sC => isTheSameString(sC.radarGuid, radar.guid));
    if (addedRecord) {
      return {
        ...radar,
        counts: addedRecord.counts,
      };
    }
    return radar;
  });
};

const Radar = (state = initialState, action) => {
  switch (action.type) {
    case RADAR.SET_ACTIVE_INIT_RADAR: {
      return state.set('activeInitRadar', action.payload.radar);
    }
    case RADAR.SET_SELECTED_RADAR: {
      return state.set('selectedRadar', action.payload.radar);
    }
    case RADAR.ADD_CREATED_RADAR: {
      const currentList = state.get('radarList') as RadarType[];
      return state.set('radarList', [...currentList, action.payload.radar]);
    }
    case RADAR.UPDATE_RADAR_SAVE_DIALOG: {
      return state.set('saveDialogTypeOpen', action.payload.openDialogType);
    }
    case RADAR.SET_RADAR_LIST: {
      const radarList = action.payload.radarList;
      return state.set('radarList', radarList);
    }
    case RADAR.SET_RADAR_ACTIVE_FEED: {
      return state.set('activeRadarFeed', action.payload.activeRadarFeed);
    }
    case RADAR.LIST_IN_PROGRESS: {
      return state.set('listInProgress', action.payload.inProgress);
    }
    case RADAR.ARTICLE_OVERVIEW_IN_PROGRESS: {
      return state.set('articleOverviewInProgress', action.payload.inProgress);
    }
    case RADAR.EMAIL_ALERTS_LIST_PROGRESS: {
      return state.set('emailAlertsListInProgress', action.payload.inProgress);
    }
    case RADAR.UPDATE_IN_PROGRESS: {
      return state.set('updateInProgress', action.payload.inProgress);
    }
    case RADAR.LIST_ARTICLE_IN_PROGRESS: {
      return state.setIn(['articles', 'articleInProgress'], action.payload.inProgress);
    }
    case RADAR.NEWS_MENTION_IN_PROGRESS: {
      const payload = action.payload;
      return state.setIn(['articles', 'mentionInProgress'], global.globalAbortPromise != null || payload.inProgress);
    }
    case RADAR.COMPANY_IN_PROGRESS: {
      const payload = action.payload;
      return state.setIn(['companies', payload.mapName, 'companyInProgress'], payload.inProgress);
    }
    case RADAR.REMOVE_FROM_LIST: {
      const currentList = state.get('radarList') as RadarType[];
      return state.set('radarList', currentList.filter(radar => !isTheSameString(radar.guid, action.payload.resourceGuid)));
    }
    case RADAR.ARTICLE_OVERVIEW: {
      const articleOverView: RadarArticleOverview[] = action.payload.articleOverView;
      return state.setIn(['articles', 'articleOverView'], articleOverView);
    }
    case RADAR.RADAR_ARTICLE_LIST: {
      const articleList = action.payload.articles;
      const currentArticleList = state.getIn(['articles', 'list']) as RadarArticle[];
      return state.setIn(['articles', 'list'], [...currentArticleList, ...articleList]);
    }
    case RADAR.ARTICLE_LIST_TOTAL_COUNT: {
      return state.setIn(['articles', 'totalCount'], action.payload.totalCount);
    }
    case RADAR.SET_VIEW_MORE_COUNT: {
      const count = action.payload.count;
      return state.setIn(['articles', 'viewMoreCount'], count);
    }
    case RADAR.COMPANY_OVERVIEW: {
      if (action.payload.isReset) {
        return state.setIn(['companies', 'matched', 'list'], [])
          .setIn(['companies', 'starred', 'list'], []);
      }

      const companies: RadarCompaniesOverview[] = action.payload.companies;
      const compare = (a: RadarCompaniesOverview, b: RadarCompaniesOverview) => {
        if (a?.mentionCount > b?.mentionCount) {
          return -1;
        }
        if (a?.mentionCount < b?.mentionCount) {
          return 1;
        }
      
        return a?.name?.localeCompare(b?.name);
      };

      companies.sort(compare);
      const mapName = action.payload.mapName;
      return state.setIn(['companies', mapName, 'list'], companies);
    }
    case RADAR.CLEAR_LIST: {
      const listName = action.payload.listName;
      if (listName === 'articles') {
        return state.setIn(['articles', 'list'], []);
      }
      return state.setIn(['companies', listName, 'list'], []);
    }
    case RADAR.RADAR_COMPANY_TOTAL_COUNT: {
      const countName = action.payload.countName;
      if (countName === 'all') {
        return state.setIn(['companies', 'matched', 'totalCount'], 0)
          .setIn(['companies', 'starred', 'totalCount'], 0);
      }
      return state.setIn(['companies', countName, 'totalCount'], action.payload.totalCount);
    }
    case RADAR.UPDATE_RADAR_IN_LIST: {
      const currentList = state.get('radarList') as RadarType[];
      const updatedRadar = action.payload.radar;
      return state.set('radarList', currentList.map(radar => {
        if (isTheSameString(radar.guid, updatedRadar.guid)) {
          if (radar.quickAccess) {
            return {
              quickAccess: true,
              ...updatedRadar,
            };
          }
          return {
            quickAccess: false,
            ...updatedRadar,
          };
        }
        return radar;
      }));
    }
    case RADAR.MOST_MENTIONED_COMPANY: {
      const payload = action.payload;
      if (payload.type === ENTITY_TYPE.ARTICLE) {
        return state.setIn(['articles', 'mentionedCompany'], payload.sortedCompanyList);
      }
      return state.setIn(['companies', payload.type, 'mentionedCompany'], payload.sortedCompanyList);
    }
    case RADAR.MENTIONED_COMPANY_TOTAL_COUNT: {
      const payload = action.payload;
      if (payload.type === ENTITY_TYPE.ARTICLE) {
        return state.setIn(['articles', 'mentionedCompanyTotalCount'], payload.total);
      }
      return state.setIn(['companies', payload.type, 'mentionedCompanyTotalCount'], payload.total);
    }
    case RADAR.LIST_FAV_COMPANY: {
      let favCompanyList = action.payload.data;
      if (!isEmpty(favCompanyList)) {
        favCompanyList = favCompanyList.map((company) => company.companyGuid.toUpperCase());
      }
      return state.set('favCompany', favCompanyList);
    }
    case RADAR.LIST_COMPANY_IN_PROGRESS: {
      return state.set('listRadarCompanyInProgress', action.payload.inProgress);
    }
    case RADAR.ADD_SEARCH_CRITERIA: {
      const searchCriteriasList = action.payload.searchCriterias as any[];
      const currentRadarList = state.get('radarList') as RadarType[];
      const updatedRadarList = updateRadarSCCount(currentRadarList, searchCriteriasList);
      
      return state.set('radarList', updatedRadarList);
    }
    case SEARCH_CRITERIA.DELETE_SEARCH_CRITERIA: {
      const selectedRadar = state.get('selectedRadar') as RadarType;
      const currentRadarList = state.get('radarList') as RadarType[];
      const { radarCounts } = action.payload;

      const updatedRadarList = currentRadarList.map(radar => {
        if (isTheSameString(selectedRadar.guid, radar.guid)) {
          return {
            ...radar,
            counts: radarCounts,
          };
        }
        return radar;
      });

      return state.set('radarList', updatedRadarList);
    }
    case RADAR.UPDATE_RADAR_LIST_COUNTS: {
      const { addRadarGuids, deleteRadarGuids, type }: IUpdateCounts = action.payload.updateCounts;
      const currentRadarList = state.get('radarList') as RadarType[];

      const updateRadarList = currentRadarList.map((radar) => {
        const addUpdate = addRadarGuids && addRadarGuids.includes(radar.guid);
        const deleteUpdate = deleteRadarGuids && deleteRadarGuids.includes(radar.guid);

        if (addUpdate) {
          return {
            ...radar,
            counts: {
              ...radar.counts,
              [type]: radar.counts[type] + 1,
            },
          };
        }
        if (deleteUpdate) {
          return {
            ...radar,
            counts: {
              ...radar.counts,
              [type]: radar.counts[type] - 1,
            },
          };
        }
        return radar;
      });
      return state.set('radarList', updateRadarList);
    }
    case RADAR.UPDATE_SELECT_BOOKMARK_FILTERS: {
      const data = action.payload;
      const bookmarkFilters = state.getIn(['selectedRadar', 'linkedBookmark', 'bookmarkFilters']) as IBookmarkFilter[];
      const currentFilter = bookmarkFilters.map((item) => {
        if (isTheSameString(item.guid, action.payload.guid)) {
          return data;
        }
        return item;
      });
      return state.setIn(['selectedRadar', 'linkedBookmark', 'bookmarkFilters'], currentFilter);
    }
    case RADAR.UNBIND_FILTER_FROM_RADAR: {
      const { filterGuid, filterName, type } = action.payload;
      const bookmarkFilters = state.getIn(['selectedRadar', 'linkedBookmark', 'bookmarkFilters']) as IBookmarkFilter[];
      const newFilterList = bookmarkFilters?.filter((filter: IBookmarkFilter) => !isTheSameString(filter.guid, filterGuid));

      const allSearchCriteria = state.getIn(['selectedRadar', 'searchCriteria']) as RadarSearchCriteria[];
      const searchCriteria = allSearchCriteria.filter(x => !(isTheSameString(x.name, filterName) && x.type === type));

      return state.setIn(['selectedRadar', 'linkedBookmark', 'bookmarkFilters'], newFilterList).setIn(['selectedRadar', 'searchCriteria'], searchCriteria);
    }
    case RADAR.UPDATE_STARRED_COMPANY_LIST: {
      const count = state.getIn(['companies', 'starred', 'totalCount']) as number;
      const radarFavCompaniesList = state.getIn(['companies', 'starred', 'list']) as RadarCompaniesOverview[];
      const lists = radarFavCompaniesList.filter(item =>
        item.companyGuid !== action.payload.entityGuids.find((id) => id),
      );
      return state
        .setIn(['companies', 'starred', 'list'], lists)
        .setIn(['companies', 'starred', 'totalCount'], count - 1);
    }
    case RADAR.SET_RADAR_SETTING_PARAMS: {
      return state.setIn(['radarSettingParam'], action.payload);
    }
    case RADAR.UNBIND_COMPANY_LIST: {
      const selectedRadar = state.get('selectedRadar') as RadarType;

      if (!isEmpty(selectedRadar)) {
        const searchCriteria = selectedRadar.searchCriteria.filter(x => x.type !== RADAR_SEARCH_TYPE.FAVORITE_COMPANY);
        return state.setIn(['selectedRadar', 'linkedBookmark', 'bookmarkCompanyList'], action.payload).setIn(['selectedRadar', 'searchCriteria'], searchCriteria);
      }

      return state;
    }
    case RADAR.EMAIL_ALERTS_LIST: {
      return state.setIn(['emailAlertsList'], action.payload.data);
    }
    case RADAR.CREATE_EMAIL_ALERTS_LIST: {
      const emailAlertsList = state.getIn(['emailAlertsList']) as RadarEmailAlerts[];
      emailAlertsList.push(action.payload.data)
      return state.setIn(['emailAlertsList'], emailAlertsList);
    }
    case RADAR.UPDATE_EMAIL_ALERTS_LIST: {
      const updateItem = action.payload.data;
      const emailAlertsList = state.getIn(['emailAlertsList']) as RadarEmailAlerts[];
      const newData = emailAlertsList.map(item => {
        if (isTheSameString(updateItem.resourceGuid, item.resourceGuid) || (updateItem.id === item.id)) {
          return {
            ...item,
            ...updateItem,
          };
        }
        return item;
      });
      return state.setIn(['emailAlertsList'], newData);
    }
    case RADAR.SET_RADAR_QUICK_ACCESS: {
      const setQuickAccess: string[] = action.payload.setQuickAccess;
      const removeQuickAccess: string[] = action.payload.removeQuickAccess;
      const radarList = state.get('radarList') as RadarType[];

      const updateRadarList = radarList.map((item) => {
        if (setQuickAccess?.includes(item.guid)) {
          return {
            ...item,
            quickAccess: true,
          };
        }
        if (removeQuickAccess?.includes(item.guid)) {
          return {
            ...item,
            quickAccess: false,
          };
        }
        return item;
      });
      return state.set('radarList', updateRadarList);
    }

    case RADAR.MENTION_COMPANY_COUNT_BY_INTERVAL: {
      const { mapName, countInterval } = action.payload;
      return state.setIn(['companies', mapName, 'mentionCompanyCountByInterval'], countInterval);
    }
    case RADAR.RADAR_LIST_MENTION_COUNT_IN_PROGRESS: {
      const { mapName, listMentionCountInProgress } = action.payload;
      return state.setIn(['companies', mapName, 'listMentionCountInProgress'], listMentionCountInProgress);
    }
    case RADAR.RESET_ALL_COMPANIES_STATE: {
      return state.setIn(['companies', 'matched', 'totalCount'], 0)
        .setIn(['companies', 'starred', 'totalCount'], 0)
        .setIn(['companies', 'matched', 'list'], [])
        .setIn(['companies', 'starred', 'list'], [])
        .setIn(['companies', 'matched', 'mentionedCompany'], [])
        .setIn(['companies', 'starred', 'mentionedCompany'], [])
        .setIn(['companies', 'matched', 'mentionCompanyCountByInterval'], [])
        .setIn(['companies', 'starred', 'mentionCompanyCountByInterval'], [])
        .setIn(['companies', 'matched', 'mentionedCompanyTotalCount'], 0)
        .setIn(['companies', 'starred', 'mentionedCompanyTotalCount'], 0);
    }
    case RADAR.SET_RADAR_STATS: {
      return state.set('radarStatsList', action.payload.stats);
    }
    case RADAR.UPDATE_RADAR_STATS: {
      const payload = action.payload;
      const radarToUpdate: any = state.getIn(['radarStatsList', payload.guid]);
      return state.setIn(['radarStatsList', payload.guid], { ...radarToUpdate, ...payload });
    }
    default:
      return state;
  }
};

export default Radar;