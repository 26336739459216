export enum RADAR_DIALOG_ERROR_TYPE {
  SAVE_ERROR = 'SAVE_ERROR',
  ALL_EXCEED_LIMIT = 'ALL_EXCEED_LIMIT',
  SELECTED_EXCEED_LIMIT = 'SELECTED_EXCEED_LIMIT',
  REPORT_NOT_SUPPORTED = 'REPORT_NOT_SUPPORTED',
  SERVER_ERROR = 'SERVER_ERROR',
  OTHER_ERROR = 'OTHER_ERROR',
  DUPLICATE_NAME_ERROR = 'DUPLICATE_NAME_ERROR',
  MORE_THAN_QUICK_ACCESS_LIMIT = 'MORE_THAN_QUICK_ACCESS_LIMIT',
  MAXIMUM_RADARS_FILTERS_SLOTS = 'MAXIMUM_RADARS_FILTERS_SLOTS',
  MAXIMUM_RADARS_LIST_SLOTS = 'MAXIMUM_RADARS_LIST_SLOTS',
  UPDATE_FILTER_ERROR = 'UPDATE_FILTER_ERROR',
  MAXIMUM_RADARS_ERROR = 'MAXIMUM_RADARS_ERROR',
  UPDATE_LIST_ERROR = 'UPDATE_LIST_ERROR',
}

export enum DUPLICATE_NAME_TYPE {
  LIST = 'LIST',
  FILTER = 'FILTER',
  RADAR = 'RADAR',
}

export enum WIDGET_TYPE {
  STATISTIC = 'statistic',
  NEWS = 'news'
}
