import { Map, fromJS } from 'immutable';
import { ACCOUNT } from '@constants/actions';
import { localStorageReady } from '@react/utils/common.util';

const initialState = Map({
  accountInfo: Map({}),
  accountDetail: {},
  status: '',
  isLoggingOut: false,
  subcriptionFeaturePermissionModel: [],
  companyExportCredit: 0,
  userRole: localStorageReady() ? localStorage.getItem('userRole') : null,
  agreementAccepted: false,
});

const Account = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT.LOGGED_IN: {
      const accountInfo = fromJS(action.payload);
      return state.set('accountInfo', accountInfo)
        .set('status', ACCOUNT.LOGGED_IN);
    } 
    case ACCOUNT.GET_INFO: {
      const accountInfo = fromJS(action.payload);
      return state.set('accountDetail', accountInfo);
    }
    case ACCOUNT.LOGGED_OUT:{
      return initialState.set('isLoggingOut', true);
    }
    case ACCOUNT.UPDATE_COMPANY_EXPORT_CREDIT: {
      return state.set('companyExportCredit', action.payload.credit);
    }
    case ACCOUNT.SET_USER_ROLE: {
      localStorage.setItem('userRole', action.payload.role);
      return state.set('userRole', action.payload.role);
    }
    case ACCOUNT.SET_AGREEMENT_ACCEPTED: {
      return state.set('agreementAccepted', action.payload.accepted);
    }
    default:
      return state;
  }
};

export default Account;