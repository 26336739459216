import { DRAWER } from '@constants/actions';
import { Map } from 'immutable';
import { mapValues, keyBy, isEmpty } from 'lodash';
import { KEYWORD_IN_COMPANY_FIELDS_PARAMS_WITHOUT_IDENTIFIERS_ID } from '@constants/common';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  company: {
    aggregate: {
      businessLines: {},
      keywordIn:{},
      sectors: {},
      countries: {},
      financials: {
        marketCapitalization: {},
        numberOfEmployees: {},
        operatingRevenue: {},
        totalAssets: {},
        shareholdersFunds: {},
        plBeforeTax: {},
      },
      ownership: {},
      numberEmployees: {
        min: 0,
        max: 1525000,
      },
    },
    data: {
      businessLines: {
        inProgress: false,
        name: {},
        tree: {},
        search: {
          keyword: '',
          result: [],
        },
      },
      countries: [],
    },
  },
  article: {
    aggregate: {
      sectors: {},
      countries: {},
      companies: {},
      sources: {},
      scoutAis: {},
    },
    data: {
      companies: {
        inProgress: false,
        keyword: '',
        data: [],
        totalCount: 0,
      },
      countries: [],
      sources: [],
    },
    selected: {
      companies: [],
      countries: [],
      calendar: {
        start: null,
        end: null,
      },
      scoutAis: [],
      scoutAiTopics: [],
      sectors: [],
      sources: [],
    },
  },
});

const Drawer = (state = initialState, action) => {
  switch (action.type) {
    case DRAWER.COMPANY.AGGREGATE.UPDATE: {
      const aggregation = action.payload.aggregation || {};
      const totalCount = action.payload.totalCount || 0;
      
      const aggregateFinancials = {
        marketCapitalization: { ...(aggregation.marketCapitalization || {}) },
        operatingRevenue: { ...(aggregation.operatingRevenue || {}) },
        totalAssets: { ...(aggregation.totalAssets || {}) },
        shareholdersFunds: { ...(aggregation.shareholdersFunds || {}) },
        plBeforeTax: { ...(aggregation.plBeforeTax || {}) },
      };

      const aggregateNumberEmployee = {
        ...(aggregation.numOfEmployees || {}),
      };

      const aggregateCountries = mapValues(keyBy(aggregation.country, (c) => c.value), (c) => c.count);

      const aggregateSectors = mapValues(keyBy(aggregation.icbSector, (c) => c.value), (c) => c.count);

      const aggregateBusinessLines = mapValues(keyBy(aggregation.naicsSector, (c) => c.value), (c) => c.count);

      const aggregateOwnership = mapValues(keyBy(aggregation.listingStatus, (c) => c.value), (c) => c.count);
      const aggregateKeyWordIn = (() => {
        const result = { namedescription: 0, description: 0, name: 0 };
        const nameDescriptionField = aggregation.companyCount?.find(item => item?.fields.join(',') === KEYWORD_IN_COMPANY_FIELDS_PARAMS_WITHOUT_IDENTIFIERS_ID.NAMEDESCRIPTION);
        const descriptionField = aggregation.companyCount?.find(item => item?.fields.join(',') === KEYWORD_IN_COMPANY_FIELDS_PARAMS_WITHOUT_IDENTIFIERS_ID.DESCRIPTION);
        const nameField = aggregation.companyCount?.find(item => item?.fields.join(',') === KEYWORD_IN_COMPANY_FIELDS_PARAMS_WITHOUT_IDENTIFIERS_ID.NAME);
        
        if(!isEmpty(nameDescriptionField) && !isEmpty(descriptionField)) {
          result.description = descriptionField.fieldsCount;
          result.name = totalCount;
          result.namedescription = nameDescriptionField.fieldsCount;
        } else if(!isEmpty(nameDescriptionField) && !isEmpty(nameField)) {
          result.name = nameField.fieldsCount;
          result.description = totalCount;
          result.namedescription = nameDescriptionField.fieldsCount;
        } else if(!isEmpty(descriptionField) && !isEmpty(nameField)) {
          result.description = descriptionField.fieldsCount;
          result.name = nameField.fieldsCount;
          result.namedescription = totalCount;
        }
        
        return result;
      })();

      return state
        .setIn(['company', 'aggregate'], {
          financials: aggregateFinancials,
          numberEmployees: aggregateNumberEmployee,
          countries: aggregateCountries,
          sectors: aggregateSectors,
          businessLines: aggregateBusinessLines,
          ownership: aggregateOwnership,
          keywordIn: aggregateKeyWordIn,
        });
    }
    case DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.IN_PROGRESS: {
      return state.setIn(['company', 'data', 'businessLines', 'inProgress'], true);
    }
    case DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.NOT_IN_PROGRESS: {
      return state.setIn(['company', 'data', 'businessLines', 'inProgress'], false);
    }
    case DRAWER.COMPANY.DATA.LIST_BUSINESS_LINES.RESULT: {
      const { name, tree, search } = action.payload;
      return state
        .setIn(['company', 'data', 'businessLines', 'name'], name)
        .setIn(['company', 'data', 'businessLines', 'tree'], tree)
        .setIn(['company', 'data', 'businessLines', 'search'], search);
    }
    case DRAWER.COMPANY.SEARCH.KEYWORD.UPDATE: {
      const { keyword } = action.payload;
      return state
        .setIn(['company', 'search'], {
          keyword,
        });
    }
    case DRAWER.ARTICLE.AGGREGATE.UPDATE: {
      /**
       * The shape of `aggregation`:
       * 
       * "aggregation": {
       *   "companyMentions": [
       *    {
       *        "count": 2,
       *        "value": "<guid>"
       *    }
       *  ],
       *  "locationMentions": [
       *    {
       *        "count": 1,
       *        "value": "<country alpha 3 code>"
       *    }
       *  ],
       *  "icbSector": [],
       *  "publisher": [
       *    {
       *        "count": 2,
       *        "value": "<publisher name>"
       *    }
       *  ],
       *  "scoutAI": []
       *}
       */
      const aggregation = action.payload.aggregation || {};

      const aggregateCompanyMention = isEmpty(aggregation.companyMentions) ? {} :
        mapValues(keyBy(aggregation.companyMentions, (c) => c.value), (c) => c.count);

      const aggregateCountyMentions = isEmpty(aggregation.locationMentions) ? {} :
        mapValues(keyBy(aggregation.locationMentions, (c) => c.value), (c) => c.count);

      const aggregateScoutAis = isEmpty(aggregation.scoutAI) ? {} :
        mapValues(keyBy(aggregation.scoutAI, (c) => c.value.toUpperCase()), (c) => c.count);

      const aggregateSectors = isEmpty(aggregation.icbSector) ? {} :
        mapValues(keyBy(aggregation.icbSector, (c) => c.value), (c) => c.count);

      const aggregateSources = isEmpty(aggregation.publisher) ? {} :
        mapValues(keyBy(aggregation.publisher, (c) => c.value), (c) => c.count);

      return state
        .setIn(['article', 'aggregate'], {
          sectors: aggregateSectors,
          countries: aggregateCountyMentions,
          companies: aggregateCompanyMention,
          sources: aggregateSources,
          scoutAis: aggregateScoutAis,
        });
    }
    case DRAWER.ARTICLE.DATA.LIST_COMPANIES.IN_PROGRESS: {
      return state.setIn(['article', 'data', 'companies', 'inProgress'], true);
    }
    case DRAWER.ARTICLE.DATA.LIST_COMPANIES.NOT_IN_PROGRESS: {
      return state.setIn(['article', 'data', 'companies', 'inProgress'], false);
    }
    case DRAWER.ARTICLE.DATA.LIST_COMPANIES.RESULT: {
      const { companies, totalCount, keyword } = action.payload;
      return state
        .setIn(['article', 'data', 'companies', 'data'], companies)
        .setIn(['article', 'data', 'companies', 'totalCount'], totalCount)
        .setIn(['article', 'data', 'companies', 'keyword'], keyword);
    }
    case DRAWER.ARTICLE.DATA.LIST_SOURCES: {
      const sources = action.payload;
      return state
        .setIn(['article', 'data', 'sources'], sources);
    }
    case DRAWER.ARTICLE.SEARCH.KEYWORD.UPDATE: {
      const { keyword } = action.payload;
      return state
        .setIn(['article', 'search'], {
          keyword,
        });
    }
    case DRAWER.ARTICLE.SELECTED.UPDATE: {
      //   companies,
      //   countries,
      //   calendar,
      //   scoutAis,
      //   sectors,
      //   sources,
      const newState = Object.keys(action.payload).reduce((accumulator, key) => {
        return accumulator.setIn(['article', 'selected', key], action.payload[key]);
      }, state);
      const selected = newState.getIn(['article', 'selected']) as any;
      const filterApplied = !isEmpty(selected.companies) ||
        !isEmpty(selected.countries) ||
        !isEmpty(selected.scoutAis) ||
        !isEmpty(selected.sectors) ||
        (selected.calendar.start !== null && selected.calendar.end !== null ) ||
        !isEmpty(selected.sources);
      return newState.setIn(['article', 'filterApplied'], filterApplied);
    }
    case DRAWER.COMPANY.SORTING.UPDATE: {
      return state.setIn(['company', 'sorting'], action.payload);
    }
    default:
      return state;
  }
};

export default Drawer;