import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FONT, BORDER, SECTION_BORDER, BUTTON } from '@constants/style';
import theme from '@react/theme/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appWidget: {
      background: '#F8F9FB',
      height: '100vh',
      color: '#404040'
    },
    wrapperHeaderBox: {
      padding: '8px 12px',
      backgroundColor: '#EAEAEA',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-end',
      width: '100%',
      height: '36px',
    },
    wrapperHeaderText: {
      marginRight: theme.spacing(1),
      fontSize: '8px',
    },
    headerSAIcon: {
      width: theme.spacing(12.5),
      height: theme.spacing(2.25),
    },
    widgetContentBox: {
      height: 'calc(100% - 36px)',
    },
    articleListToggleGroup: {
      margin: 'unset !important',
    },
    articleListToggleButton: {
      color: '#FFFFFF',
      borderRadius: 0,
      background: '#D9D9D9',
      '&.Mui-selected': {
        color: BUTTON.COLOR.CONFIRM.MAIN,
        background: '#FFFFFF !important',
      },
      '&:hover': {
        background: '#D4ECEA',
        color: BUTTON.COLOR.CONFIRM.MAIN,
      },
    },
    filterButton: {
      borderRadius: 20,
    },
    sectionTitle: {
      lineHeight: theme.spacing(3),
      fontSize: theme.spacing(2.25),
      fontWeight: FONT.WEIGHT.SEMIBOLD,
    },
    sectionContainer: {
      borderBottom: `1px solid ${SECTION_BORDER.COLOR}`,
      paddingBottom: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    activeTopicContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    activeTopicItem: {
      padding: '2px 4px',
      border: '1px solid #E2E2E2',
      borderRadius: '2%',
      whiteSpace: 'nowrap',
    },
    itemName: {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    itemMention: {
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(2),
      color: FONT.COLOR.CAPTION.LIGHT,
    },
    agreementButton: {
      '@media only screen and (min-width: 352px)': {
        width: 'auto !important',
        padding: '0 38px !important',
      },
    },
    statisticLink: {
      '&:hover': {
        color: FONT.COLOR.PRIMARY.MAIN,
        textDecoration: 'none !important',
        cursor: 'pointer',
      }
    },
    snackBar: {
      top: '100px'
    },
    errorBox:{
      marginTop: theme.spacing(14),
      border: `1px dashed ${theme.palette.primary.main}`,
      maxWidth: theme.spacing(100),
      position: 'relative',
      '@media (max-width: 1000px)': {
        scale: 0.8,
        marginTop: theme.spacing(7)
      },
    },
    errorMessageContainer: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorMessageText: {
      fontSize: 40,
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      marginBottom: '15px'
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
      '& > button:last-of-type': {
        marginLeft: theme.spacing(2),
      },
    },
    bottomIcon: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      bottom: theme.spacing(-10),
      left: 0,
      width: '100%',
      zIndex: 1,
    },
    pageWarningIcon: {
      width: 170,
      height: 170,
    },
    errorCodeContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      top: theme.spacing(-7.5),
      left: 0,
      width: '100%',
    },
    errorCodeText: {
      width: 'fit-content',
      fontSize: 72,
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      color: theme.palette.primary.main,
      padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
      background: theme.palette.background.default,
      zIndex: 1,
    },
    errorMessageLabel: {
      fontSize: 24,
      fontWeight: FONT.WEIGHT.REGULAR,
    },
    noNews: {
      fontSize: '24px',
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      marginTop: '16px',
      textAlign: 'center',
    },
  }),
);

export default useStyles;
