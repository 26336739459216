import React from 'react';
import classnames from 'classnames';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export interface ITranslationIconProps extends ISvgIconProps {}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '19px',
      height: '16px',
    },
  }),
);

const TranslationIcon = (props: ITranslationIconProps) => {
  const { color, className } = props;
  const classes = useStyles();
  const svg = (
    <g fill="none" fillRule="evenodd"><path fill="#878787" d="M0 3h19v11H0z" />
      <path 
        d="M11.66 11.5l.43-1.19h2.23l.44 1.19h1.63l-2.34-6.14h-1.64l-2.34 6.14h1.59zm2.18-2.51h-1.26l.63-1.73.63 1.73zM3.791 11.707c1.204-.364 2.023-.742 2.786-1.288.77.553 1.582.945 2.667 1.274.189-.525.364-.84.693-1.288-.966-.168-1.729-.441-2.401-.847.308-.343.56-.707.777-1.134.217-.434.329-.742.476-1.351.413 0 .686.014.994.042V5.848c-.315.035-.595.049-1.015.049H7.102V5.47c0-.147.014-.287.042-.427H5.737c.028.14.042.273.042.434v.42H4.232c-.42 0-.693-.014-1.015-.049v1.267c.35-.028.588-.042 1.001-.042.175.553.273.798.483 1.197.259.483.539.882.903 1.281-.679.413-1.414.686-2.485.917.343.462.504.756.672 1.239zm2.751-2.94c-.49-.525-.756-.98-.98-1.694h1.855c-.189.707-.448 1.204-.875 1.694z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 19 16" htmlColor={color} fontSize="small" className={classnames(classes.root, className)}>
      {svg}
    </SvgIcon>
  );
};

TranslationIcon.defaultProps = {
  color: '#404040',
} as Partial<ITranslationIconProps>;

export default TranslationIcon;
