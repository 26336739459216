import {
  EMPTY_STRING,
  SCOUTAI_ALL,
  SCOUT_AI_CUSTOM_CODE,
  SCOUT_AI_STATUS,
  SCOUT_AI_TOPICS_CREATE_ITEM,
  SCOUT_AI_TYPE,
} from '@constants/common';
import { getRelationshipLabel } from '@react/utils/common.util';
import { getMenuCountriesByFeature } from '@react/utils/selectors/countrySelectors';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { naicsToIcbMaps } from 'skyeye-common-const/dist/reference/naicsToIcbMaps';

const getDataSectorsMapping = (state) => state.getIn(['Data', 'sectors', 'mapping']);
const getDataSectors = (state) => state.getIn(['Data', 'sectors', 'list']);
const getDataScoutAiAllList = (state) => state.getIn(['Data', 'scoutAIs', 'allList']);
const getDataScoutAiAllMapping = (state) => state.getIn(['Data', 'scoutAIs', 'allMapping']);
const getDataScoutAiCustomMapping = (state) => state.getIn(['Data', 'scoutAIs', 'custom', 'mapping']);
const getDataBusinessLinesMapping = (state) => state.getIn(['Data', 'businessLines', 'name']);
const getDataSourcesMapping = (state) => state.getIn(['Data', 'sources', 'mapping']);
const getSelectedSectors = (state) => state.getIn(['CompanyFilter', 'selectedSectors']);
const getAggregate = (state) => state.getIn(['Drawer', 'company', 'aggregate', 'businessLines']);
const getDataCountriesMapping = (state) => state.getIn(['Data', 'countries', 'mapping']);
const getSelectedCalendar = (state) => state.getIn(['NewsFilter', 'selectedCalendar']);
const getSelectedMentionedCompanies = (state) => state.getIn(['NewsFilter', 'selectedMentionedCompanyLabels']);
const getMentionedCompaniesAggregation = (state) => state.getIn(['Drawer', 'article', 'aggregate', 'companies']);

/**
 * Return sector
 *
 * @param sectorCode the sector code
 */
const sectorSelector = (sectorCode: string) =>
  createSelector([getDataSectorsMapping], (mapping) => {
    if (isEmpty(mapping) || isEmpty(sectorCode)) return null;
    return mapping[sectorCode];
  });

const scoutAiStandardSelector = (guid: string) =>
  createSelector([getDataScoutAiAllMapping], (mapping) => {
    if (isEmpty(mapping) || isEmpty(guid)) return null;
    return mapping[guid];
  });

const scoutAiCustomSelector = (guid: string) =>
  createSelector([getDataScoutAiCustomMapping], (mapping) => {
    if (isEmpty(mapping) || isEmpty(guid)) return null;
    return mapping[guid];
  });

/**
 * Return key-value pair where [key] = naics code and [value] = naics name
 *
 * @param naicsCode the country alpha3 code
 */
const businessLineSelector = (naicsCode: string) =>
  createSelector([getDataBusinessLinesMapping], (mapping) => {
    if (isEmpty(mapping) || isEmpty(naicsCode)) return null;
    return mapping[naicsCode];
  });

// get relevant business lines to selected sectors
export const relevantBusinessLinesSelector = createSelector(
  [getSelectedSectors, getAggregate],
  (selectedSectors, aggregateBusinessLines) => {
    const relevantBusinessLines = [];
    selectedSectors.forEach((selected) => {
      naicsToIcbMaps.forEach((map) => {
        if (map.icb3code === selected) {
          relevantBusinessLines.push({
            code: map.naics6code,
            description: map.naics6description,
            displayName: map.naics6description,
            count: parseInt(
              `${aggregateBusinessLines[map.naics6code] || 0}`,
              10
            ),
            uniqueIdentifier: map.naics6code,
            type: 'relevant',
          });
        }
      });
    });
    relevantBusinessLines.sort((a, b) => a.count - b.count);

    return relevantBusinessLines;
  }
);

/**
 * Return [ExtendedMenuSource] model
 *
 * @param sourceCode the source code
 */
const sourceSelector = (sourceCode: string) =>
  createSelector([getDataSourcesMapping], (mapping) => {
    if (isEmpty(mapping) || isEmpty(sourceCode)) return null;
    return mapping[sourceCode];
  });

/**
 * Return [ExtendedMenuCompany] model
 *
 * @param countryCode the country alpha3 code
 */
const countrySelector = (countryCode: string) =>
  createSelector([getDataCountriesMapping], (mapping) => {
    if (isEmpty(mapping) || isEmpty(countryCode)) return null;
    return mapping[countryCode];
  });

export const selCalendarSelector = createSelector(
  [getSelectedCalendar],
  (calendar) => {
    /**
     * Calendar { start: "", end: ""}
     * are stored in Date().toISOString()
     *
     * Hence, we need to convert iso string to date
     */
    if (
      !isEmpty(calendar) &&
      !isEmpty(calendar.start) &&
      !isEmpty(calendar.end)
    ) {
      return {
        start: new Date(calendar.start),
        end: new Date(calendar.end),
      };
    }
    return calendar;
  }
);

export const getSelectedMentionedCompaniesSelector = createSelector(
  [getSelectedMentionedCompanies, getMentionedCompaniesAggregation],
  (mentionedCompanies, mentionedCompanyAggregation) => {
    const formattedMentionedCompanies =
      mentionedCompanies &&
      mentionedCompanies.map((company) => {

        return {
          ...company,
          count:
            mentionedCompanyAggregation[company.entityId] === undefined
              ? 0
              : mentionedCompanyAggregation[company.entityId],
        };
      });
    return formattedMentionedCompanies;
  }
);

const computeSelectedCount = (
  state,
  sectors: string[],
  displayType: string
) => {
  const sectorsList = getDataSectors(state).filter((item) =>
    item.display.includes(displayType)
  );
  let selectedCount = 0;
  const accountedList = [];
  sectors.forEach((itemCode) => {
    const currentItem = sectorsList.filter(
      (sectorItem) => sectorItem.code === itemCode
    );
    const numberOfChildExists = sectorsList.filter(
      (sectorItem) => sectorItem.parent === itemCode
    );

    if (numberOfChildExists.length > 0) {
      accountedList.push(...numberOfChildExists);
      if (accountedList.find((item) => item.code === itemCode)) {
        selectedCount -= 1;
      }
      selectedCount += numberOfChildExists.length;

      // Count next level sectors
      const subChildren = numberOfChildExists.map((sector) => {
        if (!sectors.includes(sector.code)) return sector.code;
      });

      subChildren.forEach((subItemCode) => {
        const numberOfSubChildExists = sectorsList.filter(
          (sectorItem) => sectorItem.parent === subItemCode
        );
        if (numberOfSubChildExists.length > 0) {
          accountedList.push(...numberOfSubChildExists);
          if (accountedList.find((item) => item.code === subItemCode)) {
            selectedCount -= 1;
          }
          selectedCount += numberOfSubChildExists.length;
        }
      });
    } else if (!accountedList.find((item) => item.code === itemCode)) {
      selectedCount += 1;
    }

    if (!accountedList.find((item) => item.code === itemCode)) {
      accountedList.push(...currentItem);
    }
  });
  return selectedCount;
};

const getScoutAiName = (guid: string, state) => {
  const standardScoutAi = scoutAiStandardSelector(guid)(state);
  const customScoutAi = scoutAiCustomSelector(guid)(state);

  if (!isEmpty(standardScoutAi)) {
    return standardScoutAi.name;
  }

  if (!isEmpty(customScoutAi)) {
    return customScoutAi.name;
  }

  return EMPTY_STRING;
};

export const constructScoutAiStandard = (scoutAIs, state) => {
  let info = EMPTY_STRING;
  if (!isEmpty(scoutAIs)) {
    if (scoutAIs.length > 1) {
      /**
       * ScoutAI may contain ALL,
       * which cause double counting.
       * hence, we need to remove ALL which has guid of -1.
       */
      const excludeAllScoutAIs = scoutAIs.filter(
        (guid) => guid !== SCOUTAI_ALL.guid
      );
      info = `${excludeAllScoutAIs.length} selected`;
    } else {
      const scoutAiName = getScoutAiName(scoutAIs[0], state);
      info = scoutAiName || EMPTY_STRING;
    }
  }
  return info;
};

export const constructSectors = (sectors, searchType, currentState) => {
  let info = EMPTY_STRING;
  if (!isEmpty(sectors)) {
    if (sectors.length > 1) {
      /**
       * sectors will contain those level 1 sectors,
       * which cause double counting.
       * hence, we need to remove those level 1 sectors.
       */
      const selectedCount = computeSelectedCount(
        currentState,
        sectors,
        searchType
      );
      info = `${selectedCount} selected`;
    } else {
      // `sector` should follow [ExtendedMenuSector] model
      const sector = sectorSelector(sectors[0])(currentState);
      info = sector?.label || EMPTY_STRING;
    }
  }
  return info;
};

const computeScoutAISelectedCount = (state, scoutAis: string[]) => {
  const scoutAisList = getDataScoutAiAllList(state);

  let selectedCount = 0;
  const accountedList = [];
  scoutAis.forEach((itemCode) => {
    const currentItem = scoutAisList.filter(
      (scoutAiItem) => scoutAiItem.code === itemCode
    );
    const numberOfChildExists = scoutAisList.filter(
      (scoutAiItem) => scoutAiItem.parent === itemCode
    );

    if (numberOfChildExists.length > 0) {
      accountedList.push(...numberOfChildExists);
      if (accountedList.find((item) => item.code === itemCode)) {
        selectedCount -= 1;
      }
      selectedCount += numberOfChildExists.length;

      const subChildren = numberOfChildExists.map((scoutAi) => {
        if (!scoutAis.includes(scoutAi.code)) return scoutAi.code;
      });

      subChildren.forEach((subItemCode) => {
        const numberOfSubChildExists = scoutAisList.filter(
          (scoutAiItem) => scoutAiItem.parent === subItemCode
        );
        if (numberOfSubChildExists.length > 0) {
          accountedList.push(...numberOfSubChildExists);
          if (accountedList.find((item) => item.code === subItemCode)) {
            selectedCount -= 1;
          }
          selectedCount += numberOfSubChildExists.length;
        }
      });
    } else if (!accountedList.find((item) => item.code === itemCode)) {
      selectedCount += 1;
    }

    if (!accountedList.find((item) => item.code === itemCode)) {
      accountedList.push(...currentItem);
    }
  });
  return selectedCount;
};

export const constructScoutAI = (scoutAIs, currentState) => {
  let info = EMPTY_STRING;
  if (!isEmpty(scoutAIs)) {
    if (scoutAIs.length > 1) {
      const selectedCount = computeScoutAISelectedCount(currentState, scoutAIs);
      info = `${selectedCount} selected`;
    } else {
      const scoutAi = getDataScoutAiAllList(currentState).find(x => x.code === scoutAIs[0]);
      info = scoutAi?.label || EMPTY_STRING;
    }
  }
  return info;
};

export const constructBusinessLines = (naics, currentState) => {
  let info = EMPTY_STRING;
  if (!isEmpty(naics)) {
    if (naics.length > 1) {
      info = `${naics.length} selected`;
    } else {
      // `country` should follow [ExtendedMenuSector] model
      const businessLine = businessLineSelector(naics[0])(currentState);
      info = businessLine || EMPTY_STRING;
    }
  }
  return info;
};

export const constructSources = (publishers, currentState) => {
  let info = EMPTY_STRING;
  if (!isEmpty(publishers)) {
    if (publishers.length > 1) {
      /**
       * publishers will contain those level 1 sources,
       * which cause double counting.
       * hence, we need to remove those level 1 sources.
       */
      const excludeLevelOnePublishers = publishers.filter(
        (code) =>
          sourceSelector(code)(currentState)?.level !== 1 &&
          sourceSelector(code)(currentState)?.level !== 3 &&
          sourceSelector(code)(currentState)?.level !== undefined
      );
      info =
        excludeLevelOnePublishers.length > 0
          ? `${excludeLevelOnePublishers.length} selected`
          : EMPTY_STRING;
    } else {
      // `source` should follow [ExtendedMenuSource] model
      const source = sourceSelector(publishers[0])(currentState);
      info = source?.label || EMPTY_STRING;
    }
  }
  return info;
};

export const constructCountries = (mentionedLocations, currentState) => {
  let info = EMPTY_STRING;
  if (!isEmpty(mentionedLocations)) {
    if (mentionedLocations.length > 1) {
      info = `${mentionedLocations.length} selected`;
    } else {
      // `country` should follow [ExtendedMenuCompany] model
      const country = countrySelector(mentionedLocations[0])(currentState);
      info = country?.name || EMPTY_STRING;
    }
  }
  return info;
};

export const constructRelationship = (
  selectedRelationshipSearch,
  currentState
) => {
  const { relationship, countriesRegions } = selectedRelationshipSearch;
  const countriesMapping = getMenuCountriesByFeature(currentState).reduce(
    (obj, curr) => {
      obj[curr.alpha3] = curr.displayName;
      return obj;
    },
    {}
  );

  const isRelationshipEmpty = isEmpty(relationship);
  const isCountryRegionEmpty = isEmpty(countriesRegions);
  let relationshipText = 'Shareholder';
  let countryRegionText = 'All Countries & regions';

  const text = (startText, endText) => {
    return `${startText} come from ${endText}`;
  };

  if (isRelationshipEmpty && isCountryRegionEmpty) {
    return EMPTY_STRING;
  }

  if (!isRelationshipEmpty) {
    relationshipText = getRelationshipLabel(relationship);
  }
  if (isCountryRegionEmpty) {
    countryRegionText = 'All Countries & regions';
  }
  if (!isCountryRegionEmpty) {
    if (countriesRegions.length === 1) {
      countryRegionText = countriesMapping[countriesRegions[0]];
    }
    if (countriesRegions.length > 1) {
      countryRegionText = `${countriesRegions.length} Countries & regions`;
    }
  }

  return text(relationshipText, countryRegionText);
};