import { applyMiddleware, createStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router/immutable';
import { loadUser } from 'redux-oidc';
import { UserManager } from 'oidc-client';
import { rootReducer } from './reducers';

const configureStore = (
  state: any,
  history: any,
  userManager: UserManager
): Store<any> => {
  let appliedMiddlewares;
  const middlewares = [];
  middlewares.push(thunkMiddleware);
  middlewares.push(routerMiddleware(history));

  if (
    process.env.ENVIRONMENT === undefined ||
    process.env.ENVIRONMENT === 'development' ||
    process.env.ENVIRONMENT === 'preview'
  ) {
    // If not in PROD use logger and devtools middlewares
    const { createLogger } = require('redux-logger'); // eslint-disable-line

    const loggerMiddleware = createLogger({
      stateTransformer: (currentState: any) => currentState.toJS(),
    });

    middlewares.push(loggerMiddleware);
    appliedMiddlewares = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    appliedMiddlewares = applyMiddleware(...middlewares);
  }

  const store = createStore(rootReducer(history), state, appliedMiddlewares);
  loadUser(store, userManager);
  store.dispatch({ type: 'INIT_APP' });
  return store;
};

export default configureStore;
