import React from 'react';
import { Card, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useStyles from './styles';
import { BORDER } from '@constants/style';

export type IArticleListItemProps = {
  isCompact?: boolean
};

const StandardArticleCardViewLoading = (props) => {
  const { isCompact } = props;
  const classes = useStyles(props);

  if (isCompact) {
    return (
      <Box padding="12px" borderBottom={`1px solid ${BORDER.COLOR}`}>
        <Box>
          <Skeleton
            variant="rectangular"
            width="30%"
          />
        </Box>
        <Box mt={1}>
          <Skeleton
            variant="rectangular"
            width="80%"
          />
        </Box>
      </Box>
    )
  }

  return (
    <Card className={classes.widgetArticleCardRoot}>
      <Box>
        <Box mb={5}>
          <Skeleton
            variant="rectangular"
            height={25}
            width="100%"
          />
        </Box>
        <Box mb={1}>
          <Skeleton
            variant="rectangular"
            width="70%"
          />
        </Box>
        <Box mb={4}>
          <Skeleton
            variant="rectangular"
            width="80%"
          />
        </Box>
      </Box>
    </Card>
  );
};

export default StandardArticleCardViewLoading;
