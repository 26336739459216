export enum FinancialUnitType {
  none = 'N',
  million = 'M',
  billion = 'B',
  trillion = 'T',
}

export interface FinancialUnit {
  unit: FinancialUnitType;
  label: string;
}
