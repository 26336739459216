import { useContext } from 'react';
import { FeatureToggleContext } from '@react/components/featureToggle/featureToggleContext';
import { EMPTY_STRING } from '@constants/common';

const DEFAULT_ROLE_NAME = 'root';

export const getFeatureJSON = () => {
  if (document.getElementById('feature-json')) {
    return JSON.parse(document.getElementById('feature-json').innerText);
  }
  return null;
};

export const getFeatures = (allFeatures, featureDb, customRole = EMPTY_STRING) => {
  if (!allFeatures || !featureDb) {
    return [];
  }
  let roleSpecificFeatureList = {};
  let rootFeatureList = {};
  const finalFeatureList = [];

  const allFeatureRoles = allFeatures.roles;
  if (allFeatureRoles[DEFAULT_ROLE_NAME]) {
    rootFeatureList = converFeatureListToMap(allFeatureRoles[DEFAULT_ROLE_NAME]);
  }
  if (customRole && allFeatureRoles[customRole]) {
    roleSpecificFeatureList = converFeatureListToMap(allFeatureRoles[customRole]);
  }
  const finalFeatureMap = { ...rootFeatureList, ...roleSpecificFeatureList };
  Object.keys(finalFeatureMap).forEach(featureKey => {
    if (finalFeatureMap[featureKey]) {
      finalFeatureList.push(featureKey);
    }
  });

  return finalFeatureList;
};

const converFeatureListToMap = (featureList) => {
  const featureMap = {};
  featureList.forEach(feature => {
    featureMap[feature.name] = feature.enabled;
  });

  return featureMap;
};

export const getFeatureRoles = (allFeatures, activeUserRole: string = null) => {
  if (allFeatures) {
    const allFeatureRoles = allFeatures.roles;
    return Object.keys(allFeatureRoles).filter(role => {
      if (!activeUserRole) {
        return role !== DEFAULT_ROLE_NAME;
      }

      return role !== activeUserRole;
    });
  }

  return [];
};

export const useFeatureToggle = () => {
  const { enabledFeatures } = useContext(FeatureToggleContext);

  const isEnabled = (featureName: string) => {
    return enabledFeatures && enabledFeatures.includes(featureName);
  };

  return [
    isEnabled,
  ];
};