import { COMPANY, DRAWER, COUNTRY, ACCOUNT } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { COMPANY_CONSTANT, EMPTY_STRING, SCOUTASIA_SOURCE_UNDEFINED, URL_REGEX, ZERO, ENTITY_TYPE_TEXT, ONBOARDING_COMPANY_COUNT, KEYWORD_IN_COMPANY_FIELDS_PARAMS, KEYWORD_IN_COMPANY_AGGREGATION_FIELDS_PARAMS, KEYWORD_IN_COMPANY_AGGREGATION_SECONDARY_FIELDS_PARAMS, KEYWORD_IN_HIGHLIGHT_PARAMS, KEYWORD_IN_COMPANY_IDENTIFIERS_ID } from '@constants/common';
import { sendPostRequestWithState, sendPostRequestWithKey } from 'skyeye-fe-common-util';
import { isEmpty } from 'lodash';
import { ICompany, ICompanyExportData, ICompanyExportRequest } from '@models/company';
import { splitByForwardSlash, isCompanySourceCritical, isEqualCaseInsensitive, removeHttpFromUrl } from '@react/utils/common.util';
import CompanyListReq from '@models/companyListReq';
import CompanyAggregationReq from '@models/companyAggregationReq';
import { IListProps } from '@interface/tableProps';
import moment from 'moment';
import createAction from './actionCreator';
import { increaseProgress, decreaseProgress } from './progress.actions';
import { updateFinancialsAggregation } from './filters/companyFilters.actions';

interface ICompanyAdditions {
  marketDataHistory: [];
  financialDataHistory: [];
  officers: []
}

const formatCompanies = (companies) => {
  return companies.map((company) => {
    return {
      ...company,
      name: isEmpty(company.name) ?
        (company.names?.find((c) => c.nameType.toLowerCase() === COMPANY_CONSTANT.NAME.TYPE.CURRENT)?.name ?? '') :
        company.name,
      emails: splitByForwardSlash(company.email),
      telephones: splitByForwardSlash(company.telephone),
      urls: splitByForwardSlash(company.url),
      showCopyright: isCompanySourceCritical(company.source),
    };
  });
};

const formattedCompanies = (companies) => {
  return formatCompanies(companies).map((company) => {
    return {
      ...company,
      uniqueIdentifier: company.entityId,
      displayName: company.name,
    };
  });
};

const getCompanyAdditions = (values) => {
  let payload: ICompanyAdditions = {
    marketDataHistory: null,
    financialDataHistory: null,
    officers: null,
  };
  values.forEach((value: any) => {
    payload = {
      ...payload,
      ...value,
    };
  });
  return payload;
};

/**
 * Convert comapny url to an array of urls
 * 
 * @param url A string which may contain more than one url
 */
const formatCompanyUrl = (url) => {
  if (isEmpty(url) || isEqualCaseInsensitive(url, SCOUTASIA_SOURCE_UNDEFINED)) {
    return [];
  }

  const matches = url.match(URL_REGEX);
  if (isEmpty(matches)) {
    return [];
  }

  return matches.map((match) => removeHttpFromUrl(match));
};

export const getCompanyAggregation = (companyAggregationReq: CompanyAggregationReq = {}) => {
  let request: CompanyAggregationReq = {
    ...companyAggregationReq,
    isPrimary: true,
  };

  if (isEmpty(request)) {
    request = {
      searchStr: EMPTY_STRING,
    };
  }

  const key = isEmpty(request.keywordIn) ? COMPANY_CONSTANT.KEYWORD_IN.NAME.toUpperCase() : request.keywordIn?.toUpperCase();
  request.searchFields = KEYWORD_IN_COMPANY_AGGREGATION_FIELDS_PARAMS[key];
  request.secondarySearchFields = KEYWORD_IN_COMPANY_AGGREGATION_SECONDARY_FIELDS_PARAMS[key];
  let cleanAggregation = {};
  
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.AGGREGATION_COMPANY, request, getState);
    return new Promise<void>((resolve, reject) => {
      if (response && response.isSuccess) {
        const rawAggregation = response.aggregation;
        const rawNumEmployee = rawAggregation?.numOfEmployees;

        cleanAggregation = {
          ...rawAggregation,
          numOfEmployees: {
            min: rawNumEmployee?.min === null || rawNumEmployee?.min < 0 ? 0 : rawNumEmployee.min,
            max: rawNumEmployee?.max === null || rawNumEmployee?.max < 0 ? 0 : rawNumEmployee.max,
          },
        };
        dispatch(createAction(DRAWER.COMPANY.AGGREGATE.UPDATE, { aggregation: cleanAggregation, totalCount:response.totalCount  }));
        resolve();
      } else {
        reject(response);
      }
      dispatch(updateFinancialsAggregation(cleanAggregation));
    });
  };
};

export const populateSelectedCompanies = (selectedCountryGuids: string[]) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    return new Promise(async (resolve, reject) => {
      if (isEmpty(selectedCountryGuids)) {
        dispatch(decreaseProgress());
        return resolve([]);
      }

      const request = {
        guids: selectedCountryGuids,
      };

      const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_BY_ID_COMPANY, request, getState);
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        const companies = response.data;
        resolve(formattedCompanies(companies));
      } else {
        reject([]);
      }
    });
  };
};

export const searchCompaniesForOnboarding = (companyListReq: CompanyListReq = {}) => {
  return async function () {
    const filter = [];
    !isEmpty(companyListReq.countries) && filter.push({ attr: 'country', condition: '', value: companyListReq.countries });
    !isEmpty(companyListReq.sectors) && filter.push({ attr: 'sector', condition: '', value: companyListReq.sectors, isCollection: true });
    const request = 
    {
      index: 'company',
      searchStr: '*',
      filter: [...filter, { attr: 'isPrimary', condition: 'eq', value: true }],
      top: ONBOARDING_COMPANY_COUNT,
      select: ['entityId', 'name'],
      orderBy: [{ attr: 'operatingRevenue', orderBy: 'desc' }, { attr: 'creationDate', orderBy: 'desc' }],
      count: true,
    };
    const response = await sendPostRequestWithKey(API_ENDPOINT.SEARCH_COMPANY_ONBOARDING, request, process.env.API_KEY);
    return new Promise<void>((resolve, reject) => {
      if (response && response.isSuccess) {
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const searchCompanies = (companyListReq: CompanyListReq = {}, withAPIKey: boolean = false) => {
  const companyListReqObj = companyListReq;

  return async function (dispatch: any, getState: any) {

    if(companyListReq.orderBy === 'score') {
      companyListReqObj.orderBy = 'search.score()';
    }
    const key = isEmpty(companyListReqObj.keywordIn) ? COMPANY_CONSTANT.KEYWORD_IN.NAME.toUpperCase() : companyListReqObj.keywordIn?.toUpperCase();
    companyListReqObj.searchFields = [...KEYWORD_IN_COMPANY_FIELDS_PARAMS[key].split(',')];
    companyListReqObj.highlightFields = KEYWORD_IN_HIGHLIGHT_PARAMS[key];

    const request: CompanyListReq = {
      ...companyListReqObj,
      aggregation: false,
      isPrimary: true,
    };
    dispatch(createAction(COMPANY.SEARCH_IN_PROGRESS, { inProgress: true }));
    const response = withAPIKey ? await sendPostRequestWithKey(API_ENDPOINT.SEARCH_COMPANY, request, process.env.API_KEY) :
      await sendPostRequestWithState(API_ENDPOINT.SEARCH_COMPANY, request, getState);
    return new Promise<void>((resolve, reject) => {
      dispatch(createAction(COMPANY.SEARCH_IN_PROGRESS, { inProgress: false }));
      if (response && response.isSuccess) {
        const companies: ICompany[] = response.data;
        dispatch(createAction(COMPANY.COMPANY_LIST, { data: formatCompanies(companies), totalCount: response.totalCount }));
        dispatch(createAction(COMPANY.STORE_SEARCH_STR, { searchStr:companyListReqObj.searchStr }));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const searchCountrySectorDetailCompanies = (companyListReq: CompanyListReq = {}) => {
  const request: CompanyListReq = {
    ...companyListReq,
    aggregation: false,
    isPrimary: true,
  };
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(COUNTRY.DETAILS.COMPANY.LIST.IN_PROGRESS));

    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_COMPANY, request, getState);
    return new Promise<void>((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(COUNTRY.DETAILS.COMPANY.LIST.NOT_IN_PROGRESS));

      if (response && response.isSuccess) {
        const companies: ICompany[] = response.data;
        const aggregateIcbSectors = response.aggregation?.icbSector || [];

        dispatch(createAction(COUNTRY.DETAILS.COMPANY.LIST.RESULT, {
          data: formatCompanies(companies),
          aggregateSectors: aggregateIcbSectors,
        }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const searchDrawerMenuCompanies = (companyListReq: CompanyListReq = {}, reset?: boolean) => {
  return async function (dispatch: any, getState: any) {
    if (reset) {
      dispatch(createAction(DRAWER.ARTICLE.DATA.LIST_COMPANIES.RESULT, { companies: [], totalCount: ZERO, keyword: EMPTY_STRING }));
      return;
    }

    dispatch(increaseProgress());
    dispatch(createAction(DRAWER.ARTICLE.DATA.LIST_COMPANIES.IN_PROGRESS));
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_COMPANY_WITH_CONTENT_COUNT, companyListReq, getState);

    return new Promise<void>((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(DRAWER.ARTICLE.DATA.LIST_COMPANIES.NOT_IN_PROGRESS));

      if (response && response.isSuccess) {
        const companies: ICompany[] = response.data;
        dispatch(createAction(DRAWER.ARTICLE.DATA.LIST_COMPANIES.RESULT, {
          companies: formattedCompanies(companies),
          totalCount: companies.length,
          keyword: isEmpty(companyListReq.searchStr) ? EMPTY_STRING : companyListReq.searchStr,
        }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};


const additionRequest = (list) => {
  return new Promise((resolve, reject) => {
    Promise.all(list).then((values) => {
      resolve(getCompanyAdditions(values));
    }).catch(() => {
      reject();
    });
  });
};

export const getCompany = (companyReq) => {
  const fromDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  const marketRequest = {
    ...companyReq,
    from: fromDate,
  };

  let progress = {
    base: true,
    addition: true, 
    additionPeople: true,
  };

  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(COMPANY.RETRIEVE_IN_PROGRESS, { progress }));
    const { additionRequestPeople } = retrieveCompanyDataPeople(companyReq, getState);
    const promiseList = [
      new Promise(async (resolve, reject) => {
        const financialResponse = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_COMPANY_FINANCIAL, companyReq, getState);
        if (financialResponse && financialResponse.isSuccess) {
          resolve({ financialDataHistory: financialResponse.data });
        } else {
          reject();
        }
      }),
    ];

    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_COMPANY, companyReq, getState);
    return new Promise<void>((resolve, reject) => {
      progress = { ...progress, base: false };
      dispatch(decreaseProgress());
      dispatch(createAction(COMPANY.RETRIEVE_IN_PROGRESS, { ...progress }));
      if (response && response.isSuccess) {

        const company: ICompany = response.data;

        /**
         * Map sectors from code to label
         */
        const sectorMapping = getState().getIn(['Data', 'sectors', 'mapping'], {});
        const sectorValueMappings = {};
        if (!isEmpty(company.sector)) {
          company.sector.forEach((sectorCode) => {
            sectorValueMappings[sectorCode] = sectorMapping[sectorCode] ? sectorMapping[sectorCode].label : EMPTY_STRING;
          });
        }
        /**
         * Fix name, email, telephone and url
         */
        const newCompany = {
          ...company,
          name: isEmpty(company.name) ?
            (company.names?.find((c) => c.nameType.toLowerCase() === COMPANY_CONSTANT.NAME.TYPE.CURRENT)?.name ?? '') :
            company.name,
          emails: isEmpty(company.email) || isEqualCaseInsensitive(company.email, SCOUTASIA_SOURCE_UNDEFINED) ?
            [] : splitByForwardSlash(company.email),
          telephones: isEmpty(company.telephone) || isEqualCaseInsensitive(company.telephone, SCOUTASIA_SOURCE_UNDEFINED) ?
            [] : splitByForwardSlash(company.telephone),
          urls: formatCompanyUrl(company.url),
          showCopyright: isCompanySourceCritical(company.source),
          sectorValueMappings,
        };
        if (company.listingStatus === COMPANY_CONSTANT.LISTING.STATUS.LISTED) {
          promiseList.push(
            new Promise(async (resolve, reject) => {
              const marketResponse = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_COMPANY_MARKET, marketRequest, getState);
              if (marketResponse && marketResponse.isSuccess) {
                resolve({ marketDataHistory: marketResponse.data });
              } else {
                reject();
              }
            }));
        }
        dispatch(createAction(COMPANY.COMPANY_RETRIEVE, { data: newCompany }));
        additionRequest(promiseList).then((data: any) => {
          const existingCompany: ICompany = getState().getIn(['Company', 'selectedCompany']);
          const updatedCompanyMarketAndFinancial = {
            ...newCompany,
            ...existingCompany,
            marketDataHistory: data.marketDataHistory,
            financialDataHistory: data.financialDataHistory,
          };
          progress = { ...progress, addition: false };
          dispatch(createAction(COMPANY.RETRIEVE_IN_PROGRESS, { ...progress }));
          dispatch(createAction(COMPANY.COMPANY_RETRIEVE, { data: updatedCompanyMarketAndFinancial }));
        });
        additionRequestPeople.then((data: any) => {
          const selectedCompany: ICompany = getState().getIn(['Company', 'selectedCompany']);
          const updatedCompanyPeople = {
            ...selectedCompany,
            officers: {
              source: data.source,
              officers: data.officers,
            },
          };
          progress = { ...progress, additionPeople: false };
          dispatch(createAction(COMPANY.RETRIEVE_IN_PROGRESS, { ...progress }));
          dispatch(createAction(COMPANY.COMPANY_RETRIEVE, { data: updatedCompanyPeople }));
        });
        resolve();
      } else {
        reject();
      }
    });
  };
};

export const updateCompany = (companyReq) => {
  const toDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  const marketRequest = {
    ...companyReq,
    to: toDate,
  };

  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const { additionRequestMarket } = retrieveCompanyDataMarket(marketRequest, getState);

    return new Promise<void>((resolve, _reject) => {
      dispatch(decreaseProgress());

      additionRequestMarket.then((data: any) => {
        const company: ICompany = getState().getIn(['Company', 'selectedCompany']);
        const updatedMarketDataHistory = [].concat(company.marketDataHistory, data.marketDataHistory);
        const updatedCompany = {
          ...company,
          marketDataHistory: updatedMarketDataHistory,
        };
        dispatch(createAction(COMPANY.COMPANY_RETRIEVE, { data: updatedCompany }));
      });
      resolve();
    });
  };
};

const retrieveCompanyDataMarket = (companyReq, getState) => {
  const promiseList = [
    new Promise(async (resolve, reject) => {
      const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_COMPANY_MARKET, companyReq, getState);
      if (response && response.isSuccess) {
        resolve({ marketDataHistory: response.data });
      } else {
        reject();
      }
    }),
  ];
  const additionRequestMarket = additionRequest(promiseList);

  return ({ additionRequestMarket });
};

const retrieveCompanyDataPeople = (companyReq, getState) => {
  const promisePeople = [
    new Promise(async (resolve, reject) => {
      const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_COMPANY_PEOPLE, companyReq, getState);
      if (response && response.isSuccess) {
        resolve({ source: response?.data?.source, officers: response?.data?.officers });
      } else {
        reject();
      }
    }),
  ];
  
  const additionRequestPeople = new Promise((resolve, reject) => {
    Promise.all(promisePeople).then((values: any) => {
      resolve(getCompanyAdditions(values));
    }).catch(() => {
      reject();
    });
  });

  return ({ additionRequestPeople });
};

export const updateCompanyListProps = (props: IListProps) => {
  return function (dispatch) {
    dispatch(createAction(COMPANY.UPDATE_LIST_PROPS, { ...props }));
  };
};

export const getCompanyExportCredit = () => {
  return async function (dispatch, getState: any) {
    const userGuid = getState().getIn(['Account', 'accountInfo', 'userGuid']);
    const response = await sendPostRequestWithState(API_ENDPOINT.COMPANY_EXPORT_CREDIT_RETRIEVE, { userGuid }, getState);
    if (response && response.isSuccess) {
      const remainingCredit = response.data.remainingExportCredit;
      dispatch(createAction(ACCOUNT.UPDATE_COMPANY_EXPORT_CREDIT, { credit: remainingCredit }));
    }
  };
};

export const deductCompanyExportCredit = () => {
  return async function (dispatch, getState: any) {
    const userGuid = getState().getIn(['Account', 'accountInfo', 'userGuid']);
    const response = await sendPostRequestWithState(API_ENDPOINT.COMPANY_EXPORT_CREDIT_DEDUCT, { userGuid, exportType: ENTITY_TYPE_TEXT.COMPANY }, getState);
    if (response && response.isSuccess) {
      const remainingCredit = response.data.remainingExportCredit;
      dispatch(createAction(ACCOUNT.UPDATE_COMPANY_EXPORT_CREDIT, { credit: remainingCredit }));
    }
  };
};

export const companyExportCSV = (exportProps: any) => {
  return async function (_dispatch: any, getState: any) {
    const exportRequest: ICompanyExportRequest = {};
    if (exportProps.count && exportProps.searchQuery) {
      const request: CompanyListReq = {
        ...exportProps.searchQuery,
        aggregation: false,
        select: exportProps.count,
        skip: 0,
      };
      
      exportRequest.searchTerm = request;
      exportRequest.guids = [];
    } else {
      exportRequest.guids = exportProps.selected;
    }
    
    const response = await sendPostRequestWithState(API_ENDPOINT.COMPANY_INFO_EXPORT, exportRequest, getState);
    return new Promise<ICompanyExportData[]>((resolve, reject) => {
      if (response && response.isSuccess) {
        resolve(response.data);
      } else {
        reject();
      }
    });
  };
};
