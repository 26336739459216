import { CALENDAR } from '@constants/common';
import { isEmpty } from 'lodash';

interface IFromSearchTerm {
  term: string;
  filters: string[];
  order: string,
  orderBy: string
}

export const fromSearchTerm = (searchTermString: string, isDescription:boolean = false): IFromSearchTerm => {
  const filters = [];
  let term: string = '';
  let order: string = '';
  let orderBy: string = '';
  // parse the search str and filters
  try {
    const searchTerm = JSON.parse(searchTermString);
    order = searchTerm.order ? searchTerm.order : '';
    orderBy = searchTerm.orderBy ? searchTerm.orderBy : '';
    const exist = (key) => (key in searchTerm);
    const isKeywordIn = () => {
      if (exist('keywordIn')) {
        if (searchTerm.keywordIn === 'namedescription') {
          return 'in Both name and description';
        }
        if (searchTerm.keywordIn === 'name') {
          return 'in Name only';
        }
        return 'in Description only';
      }
      return 'in Name only';
    };
    const descriptionAndNameExist = () => {
      return (isDescription)?isKeywordIn(): null;
    };
    const isTerm = () => {
      if (isEmpty(searchTerm.searchStr)) {
        return null;
      }
      if (!isEmpty(searchTerm.searchStr) && isEmpty(descriptionAndNameExist())) {
        return `"${searchTerm.searchStr}"`;
      }
      return  `"${searchTerm.searchStr}" ${descriptionAndNameExist()}`;
    };

    term = isTerm();
    if (exist('naics') && searchTerm.naics.length !== 0) {
      filters.push('Business Lines');
    }
    if (exist('sectors') && searchTerm.sectors.length !== 0) {
      filters.push('Sectors'); 
    }
    if ((exist('countries') && searchTerm.countries.length !== 0) || (exist('mentionedLocations') && searchTerm.mentionedLocations.length !== 0)) {
      filters.push('Countries & regions');
    }
    if (['operatingRevenueFrom', 'operatingRevenueTo', 'marketCapitalizationFrom', 'marketCapitalizationTo', 'totalAssetsFrom',
      'totalAssetsTo', 'shareholdersFundsFrom', 'shareholdersFundsTo', 'plBeforeTaxFrom', 'plBeforeTaxTo'].some(key => key in searchTerm)) {
      filters.push('Financials');
    }
    if (exist('listingStatus')) {
      filters.push('Ownership');
    }
    if (['numOfEmployeesFrom', 'numOfEmployeesTo'].every(key => key in searchTerm)) {
      filters.push('No. Employees');
    }
    if (!!searchTerm.start && !!searchTerm.end && (searchTerm.start !== CALENDAR.DEFAULT_FIRST_DATE.toISOString || searchTerm.end !== CALENDAR.DEFAULT_LAST_DATE.toISOString)) {
      filters.push('Publication Date');
    }
    if (exist('publishers')) {
      filters.push('Source');
    }
    if (exist('scoutAIs')) {
      filters.push('scoutAIs');
    }
    if (exist('mentionedCompanies')) {
      filters.push('Companies');
    }
    if (exist('relationship') && exist('relationshipCountries')) {
      filters.push('Relationship Search');
    }
  } catch (e) {
    // bad smell, get to check will real data in cluding not-serialiezed object string
    term = searchTermString;
  }
  return {
    term,
    filters,
    order,
    orderBy,
  };
};