import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';

export const getAgreementStatus = () => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.AGREEMENT_STATUS, {}, getState);
    return response;
  };
};

export const acceptAgreement = () => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.AGREEMENT_ACCEPT, {}, getState);
    return response;
  };
};

interface IRetrieveStatisticReq {
  mentionedCompanies: string[];
  start: string;
  end: string;
}

export const retrieveStatistic = (req: IRetrieveStatisticReq) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_STATISTIC, req, getState);
    return response;
  };
};