import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.widget';

export default defineMessages({
  topActiveTopics: {
    id: `${scope}.topActiveTopics`,
    defaultMessage: 'Top Active Topics',
  },
  activeSectors: {
    id: `${scope}.activeSectors`,
    defaultMessage: 'Top Active Sectors',
  },
  topCoMentionedCompanies: {
    id: `${scope}.topCoMentionedCompanies`,
  defaultMessage: 'Top Co-Mentioned Companies',
  },
  agreementDialogTitle: {
    id: `${scope}.agreementDialogTitle`,
    defaultMessage: 'Accept Usage Terms & Conditions',
  },
  agreementText: {
    id: `${scope}.agreementText`,
    defaultMessage: 'The content available in this widget is provided by Nikkei, Inc., and belongs to us or our licensors. By using a ScoutAsia widget, you are consenting to ScoutAsia’s Cookie Policy and Privacy Policy, and agreeing to abide by them. Content auto-translated from the original language is indicated, and ScoutAsia disclaims all warranties, express or implied, related to these translations.'
  },
  accept: {
    id: `${scope}.accept`,
    defaultMessage: 'Accept',
  },
  badToken: {
    id: `${scope}.badToken`,
    defaultMessage: 'Bad Token',
  },
  unAuthorizedAccess: {
    id: `${scope}.unAuthorizedAccess`,
    defaultMessage: 'Unauthorized access',
  },
  notAvailableMessage: {
    id: `${scope}.notAvailableMessage`,
    defaultMessage: 'We\'re sorry, you do not have permission to access this page.',
  },
  invalidUrlHeader: {
    id: `${scope}.invalidUrlHeader`,
    defaultMessage: 'Bad Request',
  },
  invalidUrlMessage: {
    id: `${scope}.invalidUrlMessage`,
    defaultMessage: 'We\'re sorry, there was an error while processing your request.',
  },
  getSupport: {
    id: `${scope}.getSupport`,
    defaultMessage: 'GET SUPPORT',
  },
  accountNotYetCreated: {
    id: `${scope}.accountNotYetCreated`,
    defaultMessage: 'Account not yet created',
  },
  contactSupport: {
    id: `${scope}.contactSupport`,
    defaultMessage: 'Please contact ScoutAsia support for assistance.',
  },
  confirmEmailAddress: {
    id: `${scope}.confirmEmailAddress`,
    defaultMessage: 'Confirm Your Email Address',
  },
  checkEmailForVerification: {
    id: `${scope}.checkEmailForVerification`,
    defaultMessage: "You're almost ready to use ScoutAsia in {partnerService}. Please check your email, or contact support@scout.asia for assistance.",
  },
  userAccountNotFound: {
    id: `${scope}.userAccountNotFound`,
    defaultMessage: 'User account not found',
  },
  contactForAssistance: {
    id: `${scope}.contactForAssistance`,
    defaultMessage: 'Please contact ScoutAsia Support for assistance.',
  },
  errorProcessingRequest: {
    id: `${scope}.errorProcessingRequest`,
    defaultMessage: "We're sorry, there was an error while processing your request.",
  },
  emptyTopic: {
    id: `${scope}.emptyTopic`,
    defaultMessage: 'View the Topics tagged with the articles mentioning the entity you care about. No Active Topics detected.',
  },
  emptySector: {
    id: `${scope}.emptySector`,
    defaultMessage: 'View the Sectors tagged with the articles mentioning the entity you care about. No Sectors detected.',
  },
  emptyCoMention: {
    id: `${scope}.emptyCoMention`,
    defaultMessage: 'View companies mentioned together with the entity you care about. No Co-Mentioned Companies detected.',
  },
  noNewsFound: {
    id: `${scope}.noNewsFound`,
    defaultMessage: 'No news found.',
  },
});