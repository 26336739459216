import { ARTICLE } from '@constants/actions';
import { Map } from 'immutable';
import { EMPTY_STRING, RESULT_PER_PAGE } from '@constants/common';

const initialState = Map({
  articleList: [],
  selectedArticle: {},
  totalCount: 0,
  searchStr: EMPTY_STRING,
  searchInProgress: true,
  retrieveInProgress: false,
  recommend: [],
  mightLike: [],
  retrieveRecommendInProgress: false,
  listProps: {
    page: 1,
    resultPerPage: RESULT_PER_PAGE,
  },
});

const Article = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE.ARTICLE_LIST:
      return state.set('totalCount', action.payload.totalCount)
        .set('articleList', action.payload.data);
    case ARTICLE.STORE_ARTICLE_SEARCH_STR:
      return state.set('searchStr', action.payload.searchStr);
    case ARTICLE.ARTICLE_SELECTED:
      return state.set('selectedArticle', action.payload.selectedArticle);
    case ARTICLE.SEARCH_IN_PROGRESS:
      return state.set('searchInProgress', true);
    case ARTICLE.SEARCH_NOT_IN_PROGRESS:
      return state.set('searchInProgress', false);
    case ARTICLE.RETRIEVE_IN_PROGRESS:
      return state.set('retrieveInProgress', true);
    case ARTICLE.RETRIEVE_NOT_IN_PROGRESS:
      return state.set('retrieveInProgress', false);
    case ARTICLE.RECOMMEND:
      return state.set('recommend', action.payload.recommend);
    case ARTICLE.MIGHT_LIKE: 
      return state.set('mightLike', action.payload.mightLike);
    case ARTICLE.RETRIEVE_RECOMMEND_IN_PROGRESS:
      return state.set('retrieveRecommendInProgress', true);
    case ARTICLE.RETRIEVE_RECOMMEND_NOT_IN_PROGRESS:
      return state.set('retrieveRecommendInProgress', false);
    case ARTICLE.UPDATE_LIST_PROPS: {
      const currentProps = state.get('listProps') as object;
      const updatedProps = { ...currentProps, ...action.payload };
      return state.set('listProps', updatedProps);
    }
    default:
      return state;
  }
};

export default Article;