import { getAccountState } from '@react/utils/auth.util';
import { EMPTY_STRING, LOCAL_STORAGE } from '@constants/common';
import { isEmpty } from 'lodash';
import { UserInfo } from 'src/express/model';
import { IAnalyticsWrapper, IPageTrackingData } from './interfaces/analyticswrapper.interface';

let atlas: any;
export function init(accountInfo: UserInfo, widgetObject: any) {
  let organisationName:string;
  let organisationStatus: string;
  if (!isEmpty(accountInfo)) {
    accountInfo.subcriptionFeaturePermissionModel.map(model => {
      if (model.organisationName)
        organisationName = model.organisationName.toString();
      if (model.organisationStatus)
        organisationStatus = model.organisationStatus.toString();
    });
  }
  const customObject = {
    affiliateUserId: widgetObject.originalAffiliateUserId ? widgetObject.originalAffiliateUserId : null,
    userLevel: widgetObject.ulv ? widgetObject.ulv : null,
    inWidget: false,
    widgetOrigin: widgetObject.origin ? widgetObject.origin : null,
    widgetData: { 
      category: widgetObject.cat ? widgetObject.cat : null, 
      theme: widgetObject.te ? widgetObject.te : null,
    },
  };
  if (process.env.ATLAS_ENABLED) {
    atlas = new AtlasWrapper(accountInfo.userGuid, organisationName || null, organisationStatus || null, customObject);
  }
}

export function initAtlasForErrorPage(widgetObject:any) {
  if (process.env.ATLAS_ENABLED) {
    atlas = new AtlasWrapper(null, EMPTY_STRING, EMPTY_STRING, widgetObject);
  }
}

export function pluginInit(alliateUserId:string, customObject:any) {
  if (process.env.ATLAS_ENABLED) {
    atlas = new AtlasWrapper(alliateUserId, null, null, customObject);
  }
}

export function trackPage(pageTrackingData: object, isInit: boolean) {
  if (atlas && process.env.ATLAS_ENABLED) {
    atlas.page(pageTrackingData, isInit);
  }
}

export function trackIframePage(pageTrackingData: object) {
  if (atlas && process.env.ATLAS_ENABLED) {
    atlas.iframePage(pageTrackingData);
  }
}

export const initialized = () => !!atlas;

export class AtlasWrapper implements IAnalyticsWrapper {
  constructor(private userId: string, private organisationName: string, private organisationStatus: string, private customObject:any) {}

  page(pageTrackingData: IPageTrackingData, isInit:boolean): void {
    atlasTracking.config({
      system: {
        endpoint: process.env.ATLAS_ENDPOINT,
        cookieDomain: 'scout.asia',
      },
      defaults: {
        pageUrl: window.parent.document.location.href,
        pageReferrer: window.parent.document.referrer,
        pageTitle: window.parent.document.title,
      },
      product: {
        productFamily: 'scoutasia-v2',
        productName: 'scoutasia-web-v2',
      },
      options: {
        useGet: true,
        exchangeAtlasId: {
          pass: false,
          passParamKey: 'atlas_id',
          passTargetDomains: [],
          catch: false,
          catchParamKey: 'atlas_id',
          catchTargetDomains: [],
        },
        trackClick: {
          enable: true,
          targetAttribute: 'data-trackable',
        },
        trackLink: {
          enable: false,
        },
        trackDownload: {
          enable: false,
        },
        trackPerformance: {
          enable: true,
        },
        trackScroll: {
          enable: isInit,
        },
        trackInfinityScroll: {
          enable: false,
        },
        trackRead: {
          enable: false,
        },
        trackViewability: {
          enable: false,
        },
        trackMedia: {
          enable: false,
        },
        trackForm: {
          enable: false,
        },
        trackUnload: {
          enable: true,
        },
      },
    });
    atlasTracking.initPage({
      user: {
        user_id: this.userId,
        user_status: undefined,
        custom_object: {
          organisation_name: this.organisationName,
          organisation_status: this.organisationStatus,
          original_userguid: this.userId,
          affiliateUserId: this.customObject.affiliateUserId,
          affiliateUserLevel: this.customObject.userLevel,
          in_widget: this.customObject.inWidget,
          widget_origin: this.customObject.widgetOrigin,
          widget_data: this.customObject.widgetData,
          affiliateCode: this.customObject.affiliateCode,
        },
        site_session: undefined,
      },
      context: {
        app: undefined,
        app_version: '1.0.0',
        source: undefined,
        edition: undefined,
        id: null,
        root_id: null,
        content_id: undefined,
        content_name: undefined,
        content_status: undefined,
        page_name: undefined,
        page_num: undefined,
        category_l1: undefined,
        category_l2: undefined,
        category_l3: undefined,
        tracking_code: atlasTracking.getQueryValue('n_cid'),
        campaign: {
          name: decodeURIComponent(
            atlasTracking.getQueryValue('utm_campaign'),
          ),
          source: decodeURIComponent(
            atlasTracking.getQueryValue('utm_source'),
          ),
          medium: decodeURIComponent(
            atlasTracking.getQueryValue('utm_medium'),
          ),
          term: decodeURIComponent(
            atlasTracking.getQueryValue('utm_term'),
          ),
          content: decodeURIComponent(
            atlasTracking.getQueryValue('utm_content'),
          ),
        },
        events: null,
        custom_vars: {},
        funnel: {},
        custom_object: pageTrackingData,
      },
    });
    !isInit && atlasTracking.trackPage();
  }

  iframePage(pageTrackingData: IPageTrackingData): void {
    atlasTracking.config({
      system: {
        endpoint: process.env.ATLAS_ENDPOINT,
        cookieDomain: 'scout.asia',
        targetWindow: 'self',
      },
      defaults: {
      },
      product: {
        productFamily: 'scoutasia-v2',
        productName: 'scoutasia-web-v2',
      },
      options: {
        useGet: true,
        exchangeAtlasId: {
          pass: false,
          passParamKey: 'atlas_id',
          passTargetDomains: [],
          catch: false,
          catchParamKey: 'atlas_id',
          catchTargetDomains: [],
        },
        trackClick: {
          enable: true,
          targetAttribute: 'data-trackable',
        },
        trackLink: {
          enable: false,
        },
        trackDownload: {
          enable: false,
        },
        trackPerformance: {
          enable: true,
        },
        trackScroll: {
          enable: true,
          granularity: 25,
          threshold: 2,
        },
        trackInfinityScroll: {
          enable: false,
        },
        trackRead: {
          enable: false,
        },
        trackViewability: {
          enable: false,
        },
        trackMedia: {
          enable: false,
        },
        trackForm: {
          enable: false,
        },
        trackUnload: {
          enable: true,
        },
      },
    });
    atlasTracking.initPage({
      user: {
        user_id: this.userId,
        user_status: undefined,
        custom_object: {
          organisation_name: this.organisationName,
          organisation_status: this.organisationStatus,
          original_userguid: this.userId,
          affiliateUserId: this.customObject.affiliateUserId,
          affiliateUserLevel: this.customObject.userLevel,
          in_widget: this.customObject.inWidget,
          widget_origin: this.customObject.widgetOrigin,
          widget_data: this.customObject.widgetData,
          affiliateCode: this.customObject.affiliateCode,
        },
        site_session: undefined,
      },
      context: {
        app: undefined,
        app_version: '1.0.0',
        source: undefined,
        edition: undefined,
        id: null,
        root_id: null,
        content_id: undefined,
        content_name: undefined,
        content_status: undefined,
        page_name: undefined,
        page_num: undefined,
        category_l1: undefined,
        category_l2: undefined,
        category_l3: undefined,
        tracking_code: atlasTracking.getQueryValue('n_cid'),
        campaign: {
          name: decodeURIComponent(
            atlasTracking.getQueryValue('utm_campaign'),
          ),
          source: decodeURIComponent(
            atlasTracking.getQueryValue('utm_source'),
          ),
          medium: decodeURIComponent(
            atlasTracking.getQueryValue('utm_medium'),
          ),
          term: decodeURIComponent(
            atlasTracking.getQueryValue('utm_term'),
          ),
          content: decodeURIComponent(
            atlasTracking.getQueryValue('utm_content'),
          ),
        },
        events: null,
        custom_vars: {},
        funnel: {},
        custom_object: pageTrackingData,
      },
    });
    atlasTracking.trackPage();
  }
}
