import { IRouteProperties } from '@interface/route';
import { PAGE_TITLE, ROUTE_PATHS } from '@constants/routes';
import { FEATURE_CODE } from '@constants/common';
import {
  Home,
  Connection,
  ConnectionPlusTerms,
  CompanyDetail,
  ArticleDetail,
  ClusterList,
  ScoutAITrain,
  OidcCallback,
  OidcSilent,
  AcceptOrgLink,
  Profile,
  CampaignRegister,
  CampaignRequest,
  CampaignUnsubscribe,
  ResetPassword,
  ForgetPassword,
  ChangePassword,
  CmsOverview,
  CmsFaq,
  CountryRegionSector,
  AlertSettings,
  ScoutAIResult,
  Error,
  ScoutAIVisualisation,
  LogoutRedirect,
  RequestAccess,
  Maintenance,
  NotFound,
  RadarLandingPage,
  RadarDetailPage,
  RadarSettingsPage,
  Library,
  NoticeOfNoticeOfAssignmentPage,
  SearchPage,
  BookmarkDetailPage,
  PartnerAccountActivation,
  ServerError,
  PartnerAccountRegistration,
} from './pages';

export const callbackRoute: IRouteProperties = {
  path: ROUTE_PATHS.CALLBACK,
  exact: true,
  component: OidcCallback,
  documentTitle: () => PAGE_TITLE.LOGIN,
  standardLayout: false,
  authValid: false,
  footer: false,
};

export const silentRoute: IRouteProperties = {
  path: ROUTE_PATHS.SILENT,
  exact: true,
  component: OidcSilent,
  documentTitle: () => PAGE_TITLE.LOGIN,
  standardLayout: false,
  authValid: true,
  footer: false,
  availableForStartUser: false,
  availableForNonActivatedUser: false,
};

export const logOutRedirect: IRouteProperties = {
  path: ROUTE_PATHS.LOGOUT_REDIRECT,
  exact: true,
  component: LogoutRedirect,
  documentTitle: () => '',
  standardLayout: false,
  authValid: false,
  footer: false,
};

export const routes: IRouteProperties[] = [
  {
    path: '/',
    exact: true,
    component: Home,
    documentTitle: () => 'Home',
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_ACTIVATION,
    exact: true,
    component: Home,
    documentTitle: () => 'Home',
    standardLayout: true,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: true,
  },
  {
    path: ROUTE_PATHS.PARTNER_USER_ACCOUNT_ACTIVATION,
    exact: true,
    component: PartnerAccountActivation,
    documentTitle: () => 'Activating...',
    standardLayout: true,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: true,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_REGISTRATION,
    exact: true,
    component: PartnerAccountRegistration,
    documentTitle: () => 'Registration',
    standardLayout: true,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: true,
  },
  {
    path: ROUTE_PATHS.ERROR,
    exact: true,
    component: Error,
    documentTitle: () => PAGE_TITLE.ERROR,
    standardLayout: true,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.COMPANY_DETAIL,
    exact: true,
    component: CompanyDetail,
    documentTitle: () => PAGE_TITLE.COMPANY,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CONNECTION_DETAIL,
    exact: true,
    component: Connection,
    documentTitle: () => PAGE_TITLE.CONNECTION,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CONNECTION_PLUS_TERMS,
    exact: true,
    component: ConnectionPlusTerms,
    documentTitle: () => PAGE_TITLE.CONNECTION_PLUS_TERMS,
    standardLayout: false,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.ARTICLE_DETAIL,
    exact: true,
    component: ArticleDetail,
    documentTitle: () => PAGE_TITLE.ARTICLE,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: true,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.TRENDING_TOPIC_CLUSTER,
    exact: true,
    component: ClusterList,
    documentTitle: () => PAGE_TITLE.TRENDING_TOPICS,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: true,
  },
  {
    path: ROUTE_PATHS.LIBRARY_SCOUT_AI,
    exact: true,
    component: Library,
    documentTitle: () => PAGE_TITLE.SCOUT_AI,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.SCOUT_AI_TRAINING,
    exact: true,
    component: ScoutAITrain,
    documentTitle: () => PAGE_TITLE.SCOUT_AI_TRAINING,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.SCOUT_AI_CONTINUE_TRAINING,
    exact: true,
    component: ScoutAITrain,
    documentTitle: () => PAGE_TITLE.SCOUT_AI_CONTINUE_TRAINING,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.SCOUT_AI_VISUALISATION,
    exact: true,
    component: ScoutAIVisualisation,
    documentTitle: () => PAGE_TITLE.SCOUT_AI_VISUALISATION,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  silentRoute,
  {
    path: ROUTE_PATHS.PROFILE,
    exact: true,
    component: Profile,
    documentTitle: () => PAGE_TITLE.PROFILE,
    standardLayout: false,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CHANGE_PASSWORD,
    exact: true,
    component: ChangePassword,
    documentTitle: () => '',
    standardLayout: false,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    exact: true,
    component: ResetPassword,
    documentTitle: () => '',
    standardLayout: false,
    authValid: false,
    footer: false,
    availableForStartUser: true,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.FORGET_PASSWORD,
    exact: true,
    component: ForgetPassword,
    documentTitle: () => '',
    standardLayout: false,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.ACCEPT_ORG_LINK,
    exact: true,
    component: AcceptOrgLink,
    documentTitle: () => PAGE_TITLE.SCOUTASIA,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.REQUEST_ACCESS,
    exact: true,
    component: RequestAccess,
    documentTitle: () => '',
    standardLayout: false,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_REGISTER,
    exact: true,
    component: CampaignRegister,
    documentTitle: () => PAGE_TITLE.CAMPAIGN,
    standardLayout: true,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_REQUEST,
    exact: true,
    component: CampaignRequest,
    documentTitle: () => PAGE_TITLE.CAMPAIGN,
    standardLayout: false,
    authValid: false,
    footer: true,
    availableForStartUser: true,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_UNSUBSCRIBE,
    exact: true,
    component: CampaignUnsubscribe,
    documentTitle: () => PAGE_TITLE.CAMPAIGN,
    standardLayout: false,
    authValid: false,
    footer: true,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_UNSUBSCRIBE_USER,
    exact: true,
    component: CampaignUnsubscribe,
    documentTitle: () => PAGE_TITLE.CAMPAIGN,
    standardLayout: false,
    authValid: false,
    footer: true,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CMS_OVERVIEW,
    exact: true,
    component: CmsOverview,
    documentTitle: () => PAGE_TITLE.CMS_OVERVIEW,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.CMS_FAQ,
    exact: true,
    component: CmsFaq,
    documentTitle: () => PAGE_TITLE.CMS_FAQ,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: true,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.COUNTRIES_OVERVIEW,
    exact: true,
    component: CountryRegionSector,
    documentTitle: () => PAGE_TITLE.SCOUTASIA,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.SECTORS_OVERVIEW,
    exact: true,
    component: CountryRegionSector,
    documentTitle: () => PAGE_TITLE.SCOUTASIA,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.COUNTRIES_REGION_SECTORS,
    exact: true,
    component: CountryRegionSector,
    documentTitle: () => PAGE_TITLE.SCOUTASIA,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.ALERT_SETTINGS,
    exact: true,
    component: AlertSettings,
    documentTitle: () => PAGE_TITLE.ALERT_SETTINGS,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.EMAIL_ALERTS,
    exact: true,
    component: AlertSettings,
    documentTitle: () => PAGE_TITLE.EMAIL_ALERTS,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: false,
  },
  {
    path: ROUTE_PATHS.NOTICE_OF_ASSIGNMENT,
    exact: true,
    component: NoticeOfNoticeOfAssignmentPage,
    documentTitle: () => PAGE_TITLE.NOTICE_OF_ASSIGNMENT,
    standardLayout: true,
    authValid: true,
    footer: false,
  },
  {
    path: ROUTE_PATHS.LOGOUT_REDIRECT,
    exact: true,
    component: LogoutRedirect,
    documentTitle: () => '',
    standardLayout: false,
    authValid: false,
    footer: false,
  },
  {
    path: ROUTE_PATHS.RADAR_LANDING,
    exact: true,
    component: RadarLandingPage,
    documentTitle: () => PAGE_TITLE.RADAR,
    standardLayout: true,
    authValid: true,
    footer: false,
    permission: FEATURE_CODE.RADAR_COUNT,
  },
  {
    path: ROUTE_PATHS.RADAR_DETAIL,
    exact: true,
    component: RadarDetailPage,
    documentTitle: () => PAGE_TITLE.RADAR,
    standardLayout: true,
    authValid: true,
    footer: false,
  },
  {
    path: ROUTE_PATHS.RADAR_SETTINGS,
    exact: true,
    component: RadarSettingsPage,
    documentTitle: () => PAGE_TITLE.RADAR_SETTINGS,
    standardLayout: true,
    authValid: true,
    footer: false,
    permission: FEATURE_CODE.RADAR_COUNT,
  },
  {
    path: ROUTE_PATHS.LIBRARY_OVERVIEW,
    exact: true,
    component: Library,
    documentTitle: () => PAGE_TITLE.LIBRARY,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
  },
  {
    path: ROUTE_PATHS.QUICK_SEARCH,
    exact: true,
    component: SearchPage,
    documentTitle: () => PAGE_TITLE.SEARCH,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: true,
  },
  {
    path: ROUTE_PATHS.SAVED,
    exact: true,
    component: BookmarkDetailPage,
    documentTitle: () => PAGE_TITLE.SAVED,
    standardLayout: true,
    authValid: true,
    footer: false,
    availableForStartUser: false,
  },
  {
    path: ROUTE_PATHS.SERVER_ERROR,
    exact: true,
    component: ServerError,
    documentTitle: () => 'Server Error',
    standardLayout: true,
    authValid: false,
    footer: false,
    availableForStartUser: false,
    availableForNonActivatedUser: true,
  }
];

export const maintenanceRoute: IRouteProperties = {
  path: ROUTE_PATHS.MAINTENANCE,
  exact: true,
  component: Maintenance,
  documentTitle: () => PAGE_TITLE.MAINTENANCE,
  standardLayout: false,
  authValid: false,
  footer: false,
};

export const notFoundRoute: IRouteProperties = {
  path: '*',
  exact: false,
  component: NotFound,
  documentTitle: () => PAGE_TITLE.NOT_FOUND,
  standardLayout: true,
  authValid: false,
  footer: false,
};

export const routesNoneStandardLayout: IRouteProperties[] = routes.filter(
  (route) => !route.standardLayout
);
export const routesWithStandardLayout: IRouteProperties[] = routes.filter(
  (route) => route.standardLayout
);
