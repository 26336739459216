import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface IAutoSummarisationIconProps extends ISvgIconProps {
  className?: string,
}

const AutoSummarisationIcon = (props: IAutoSummarisationIconProps) => {
  const { className } = props;
  const svg = (
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <rect id="Rectangle-1" fill="#0A7A73" x="5" y="6" width="9" height="2" rx="1" />
        <rect id="Rectangle-2" fill="#0A7A73" x="5" y="12" width="5" height="2" rx="1" />
        <rect id="Rectangle-3" fill="#0A7A73" x="5" y="9" width="9" height="2" rx="1" />
        <path d="M8.81792808,2 C4.33136196,2.77265533 1.33559691,6.95128544 2.12669957,11.3332316 C2.91780223,15.7151777 7.49500855,18.6711657 11.6827642,17.8684307 C15.8705199,17.0656957 17.1663278,14.2797064 18,12.375876" id="Path" stroke="#0A7A73" strokeLinecap="round" transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) " />
        <path d="M13.4192709,20 C18.6076379,18.0526192 21.2827807,12.1366112 19.3943695,6.78622641 C17.5059584,1.43584159 11.7690961,-1.32283823 6.58072913,0.62454259 C1.39236211,2.57192341 -1.28278067,8.48793136 0.605630489,13.8383162" id="Path-Copy" stroke="#0A7A73" strokeLinecap="round" transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) " />
      </g>
      <path d="M0 0h24v24H0z" />
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 24 24" className={className}>
      {svg}
    </SvgIcon>
  );
};

AutoSummarisationIcon.defaultProps = {} as Partial<IAutoSummarisationIconProps>;

export default AutoSummarisationIcon;