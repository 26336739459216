import { Box, CircularProgress, Typography } from '@mui/material';
import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { searchNews } from '@redux/actions/article.actions';
import { splitByCharacter, useQuery } from '@react/utils/common.util';
import { ARTICLE_CARD_VIEW, LOCAL_STORAGE, OBJECT_NAME, UI_ELEMENT, UI_ELEMENT_GROUP } from '@constants/common';
import { BORDER } from '@constants/style';
import moment from 'moment';
import { isEmpty } from 'lodash';
import useStyles from './styles';
import StandardArticleCardViewLoading from '../article/articleListItem/widget/standardArticleCardView.loading';
import HsToggleButton from '../toggle/toggleButton.component';
import StandardArticleCardView from '../article/articleListItem/widget/standardArticleCardView.component';
import messages from './messages';

const COUNT_PER_PAGE = 20;

const ArticlesWidget:FC = (props) => {
  const [loading, setLoading] = useState(true);
  const [showingArticles, setShowingArticles] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(null);
  const [skipAmount, setSkipAmount] = useState(0);
  const [noMoreArticle, setNoMoreArticle] = useState(false);
  const [listView, setListView] = useState(localStorage.getItem(LOCAL_STORAGE.WIDGET_LIST_VIEW) || ARTICLE_CARD_VIEW.COMPACT);
  const reduxDispatch = useDispatch();
  const agreementStatus = useSelector((state: any) => state.getIn(['Account', 'agreementAccepted']));
  const classes = useStyles();
  const query = useQuery();
  const intl = useIntl();
  const scoutAGuids = query.get('scout_ais');
  const regions = query.get('regions');
  const startDate = query.get('from');
  const endDate = query.get('to');
  const entityGuid = query.get('guid');

  const loader = useRef(null);

  // search article
  const searchArticles = async () => {
    const searchRequest = {
      skip: skipAmount,
      select: COUNT_PER_PAGE,
      scoutAIs: splitByCharacter(scoutAGuids, ',', true),
      start: moment(startDate).startOf('day').toISOString(),
      end: moment(endDate).toISOString(),
      mentionedLocations: splitByCharacter(regions, ',', true),
      mentionedCompanies: splitByCharacter(entityGuid, ',', true),
    };
    setLoading(true);
    try {
      const response: any = await reduxDispatch(searchNews(searchRequest));
      if (response.isSuccess) {
        if (response.data && response.data.length < COUNT_PER_PAGE) {
          setNoMoreArticle(true);
        }
        setShowingArticles([...showingArticles, ...response.data]);
        setTotalCount(response.totalCount || 0);
        setLoading(false);
      }
    } catch {
      setTotalCount(0);
      setNoMoreArticle(true);
      setLoading(false);
    }
  };

  const handleToggleListView = (_, value: string | null) => {
    if (value === null) return;
    localStorage.setItem(LOCAL_STORAGE.WIDGET_LIST_VIEW, value);
    setListView(value);
  };

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoading((prevLoading) => {
        // observer handle do not have access to current state
        // use set state to access in order to prevent increment when loading
        if (!prevLoading) {
          setSkipAmount((prevSkip) => prevSkip + COUNT_PER_PAGE);
        }

        return prevLoading;
      });
    }
  };

  const renderLoadingArticles = () => {
    const loadingArticles = [];
    if (skipAmount > 0) {
      return null;
    }
    for (let i = 0; i < 2; i++) {
      loadingArticles.push(
        <StandardArticleCardViewLoading key={`article-loading-${i}`} isCompact={listView === ARTICLE_CARD_VIEW.COMPACT} />,
      );
    }
    return (loadingArticles);
  };

  useEffect(() => {
    if (agreementStatus) {
      searchArticles();
    }
  }, [agreementStatus]);

  useEffect(() => {
    if (loader.current) {
      const observer = new IntersectionObserver(handleObserver, {});
      observer.observe(loader.current);
    }
  }, [loader]);

  useEffect(() => {
    if (skipAmount > 0) {
      searchArticles();
    }
  }, [skipAmount]);

  const constructAtlasTrackingObjForToggle = (uiElement) => {
    return {
      targetAttribute: 'sa-widget-news',
      atlastracking: {
        uiElementGroup: UI_ELEMENT_GROUP.NEWS_WIDGET,
        uiElement,
        objectName: OBJECT_NAME.TOGGLE,
      },
    };
  };

  return (
    <Box className={classes.widgetContentBox}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="12px"
        borderBottom={`1px solid ${BORDER.COLOR}`}
        height="65px"
      >
        <Typography>
          {totalCount !== null ? `${new Intl.NumberFormat().format(totalCount)} results` : null}
        </Typography>
        <HsToggleButton
          view={listView}
          handleChange={handleToggleListView}
          customClasses={{
            toggleGroup: classes.articleListToggleGroup,
            toggleButton: classes.articleListToggleButton,
          }}
          listViewAtlasTracking={constructAtlasTrackingObjForToggle(UI_ELEMENT.ARTICLE_LIST)}
          cardViewAtlasTracking={constructAtlasTrackingObjForToggle(UI_ELEMENT.ARTICLE_CARD)}
        />
      </Box>
      <Box maxHeight="calc(100% - 65px)" overflow="auto" display="grid">
        {showingArticles.map(article => {
          return (
            <StandardArticleCardView
              key={article.guid}
              article={article}
              title={article.title}
              isCompact={listView === ARTICLE_CARD_VIEW.COMPACT}
            />
          );
        })}
        {!loading && isEmpty(showingArticles) &&
        <Typography className={classes.noNews}>
          {intl.formatMessage(messages.noNewsFound)}
        </Typography>}
        <Box display="flex" justifyContent='center' flexDirection='row'>
          <Box width="100%">
            {loading && renderLoadingArticles()}
          </Box>
        </Box>
        {!noMoreArticle &&
        <Box width="100%" display="flex" justifyContent='center' ref={loader}>
          {!isEmpty(showingArticles) && <CircularProgress style={{ margin: '12px' }} size="24px" />}
        </Box>}
      </Box>
    </Box>
  );
};

export default ArticlesWidget;

