import { BOOKMARK } from '@constants/actions';
import { Map } from 'immutable';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  list: [],
});

const BookMarkNewsHeadLine = (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARK.GET_BOOKMARK_NEWS_HEADLINE: {
      const { data } = action.payload;
      return state.set('list', data);
    }
    default:
      return state;
  }
};

export default BookMarkNewsHeadLine;
