export const IFRAME = {
  AFFILIATE_CONFIG: {
    SGX: {
      STARTER_HASH_ID: '785eef8d-8dc9-4085-af90-441bbf93e242',
      ORIGIN: 'SGX',
      DOMAIN_SUFFIX: 'SGX', // TODO: use domain suffix to get origin value from URL
      EXPIRE_TIME: 86400,
      STARTER_USER_LEVEL: 'starter',
    },
  },
};
