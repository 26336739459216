import React from 'react';
import classnames from 'classnames';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ISvgIconProps } from '../props/common/svgicon.props';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
      padding: 0,
      lineHeight: '20px',
      height: '8px',
      position: 'relative',
    },
  }),
);

export interface IKeyIconProps extends ISvgIconProps {}

const KeyIcon = (props: IKeyIconProps) => {
  const { className } = props;
  const classes = useStyles();
  const svg = (
    <g fill="none" fillRule="evenodd" transform="translate(-2 -6)">
      <path d="M0 0h20v20H0z" />
      <path fill="#808080" id="a" d="M17.246 8.38L19 10.136l-1.754 1.754-1.17-1.17-1.17 1.17-1.169-1.17-1.17 1.17-1.169-1.17-1.17 1.17h-.353c-.198.423-.467.82-.816 1.17a4.135 4.135 0 1 1 0-5.848c.349.35.618.747.816 1.17h7.37zM5.46 12.153a.598.598 0 0 0 .27-.5V8.911a.598.598 0 0 0-.836-.548 2.093 2.093 0 0 0 0 3.837c.184.08.397.063.566-.048z" />
    </g>
  );

  return (
    <SvgIcon className={classnames(classes.root, className)} viewBox="0 0 17 9">
      { svg }
    </SvgIcon>
  );
};

export default KeyIcon;
