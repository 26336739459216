import { QUICK_ACCESS } from '@constants/actions';
import { Map } from 'immutable';

const initialState = Map({
  list: {},
  active: [],
});

const QuickAccess = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_ACCESS.LIST.UPDATE:
      return state.set('list', action.payload.selected)
        .set('active', action.payload.active);
    default:
      return state;
  }
};

export default QuickAccess;