export enum NotificationPeriod {
  NEVER = 'NEVER',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum NotificationResourceType {
  SAVED_SEARCH = 'SAVED_SEARCH',
  TARGET_LIST = 'TARGET_LIST',
  SCOUTAI = 'SCOUT_AI',
}

export enum NotificationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  ONSITE = 'ONSITE',
}

export interface INotificationSettingCreate {
  onsite: boolean;
  email: boolean;
  emailPeriod: NotificationPeriod;
  resourceType: NotificationResourceType;
  resourceName: string;
  resourceGuid: string;
  userGuid: string;
}

export interface INotificationSettingList {
  resourceType: NotificationResourceType;
  resourceGuid?: string;
  notificationType?: NotificationType;
  notificationPeriod?: Array<NotificationPeriod>;
  userGuid?: string;
  status: Array<number>;
  skip: number;
  select: number;
  orderBy: string;
  order: string;
}

export interface INotificationSettingAlert {
  resourceType: NotificationResourceType[];
  newsCount?: number;
  userGuid: string;
  historical?: boolean;
}

export interface INotificationSettingAlertCompanySavedSearch {
  userGuid: string;
  savedSearchGuids: string[];
  count?: number;
  historical?: boolean;
}
