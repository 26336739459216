import { ACTION } from '@constants/actions';
import { EMPTY_STRING, FILTER_EMPTY_DATA, FILTER_FINANCIAL_EMPTY_DATA, KEYWORD_IN_DISPLAY_TYPE } from '@constants/common';
import { FinancialUnitType } from '@models/financialUnit';
import { Map } from 'immutable';

const initialState = Map({
  selectedSectors: [],
  selectedBusinessLines: [],
  selectedCountriesRegions: [],
  selectedFinancials: {
    marketCapitalization: FILTER_FINANCIAL_EMPTY_DATA(),
    operatingRevenue: FILTER_FINANCIAL_EMPTY_DATA(),
    totalAssets: FILTER_FINANCIAL_EMPTY_DATA(),
    shareholdersFunds: FILTER_FINANCIAL_EMPTY_DATA(),
    plBeforeTax: FILTER_FINANCIAL_EMPTY_DATA(),
  },
  selectedNumOfEmployees: FILTER_EMPTY_DATA,
  selectedOwnership: EMPTY_STRING,
  selectedKeywordIn: {},
  additionalInfo: {
    sector: EMPTY_STRING,
    businessLines: EMPTY_STRING,
    countries: EMPTY_STRING,
    financials: EMPTY_STRING,
    ownership: EMPTY_STRING,
    numOfEmployees: EMPTY_STRING,
    keywordIn: KEYWORD_IN_DISPLAY_TYPE.NAME,
    relationshipSearch: EMPTY_STRING,
  },
  selectedRelationshipSearch: {
    relationship: '',
    countriesRegions: [],
  },
});
  
const CompanyFilter = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.FILTER.COMPANY.SECTOR.UPDATE_SELECTED: {
      return state.setIn(['selectedSectors'], action.payload);
    }

    case ACTION.FILTER.COMPANY.SECTOR.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'sector'], action.payload);
    }

    case ACTION.FILTER.COMPANY.BUSINESS_LINES.UPDATE_SELECTED: {
      return state.setIn(['selectedBusinessLines'], action.payload);
    }

    case ACTION.FILTER.COMPANY.BUSINESS_LINES.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'businessLines'], action.payload);
    }

    case ACTION.FILTER.COMPANY.COUNTRIES_REGIONS.UPDATE_SELECTED: {
      return state.setIn(['selectedCountriesRegions'], action.payload);
    }

    case ACTION.FILTER.COMPANY.COUNTRIES_REGIONS.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'countries'], action.payload);
    }

    case ACTION.FILTER.COMPANY.FINANCIALS.UPDATE_SELECTED: {
      return state.setIn(['selectedFinancials'], action.payload);
    }

    case ACTION.FILTER.COMPANY.FINANCIALS.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'financials'], action.payload);
    }

    case ACTION.FILTER.COMPANY.FINANCIALS.UPDATE_AGGREGATION: {
      const currentSelectedFinancials = state.getIn(['selectedFinancials']) as any;
      const aggregateFinancials = action.payload;
      return state.setIn(['selectedFinancials'], {
        marketCapitalization: {
          fromValue: currentSelectedFinancials.marketCapitalization?.fromValue,
          toValue: currentSelectedFinancials.marketCapitalization?.toValue,
          maxValue: aggregateFinancials.marketCapitalization.max,
          minValue: aggregateFinancials.marketCapitalization.min,
          fromUnit: currentSelectedFinancials.marketCapitalization?.fromUnit || FinancialUnitType.none,
          toUnit: currentSelectedFinancials.marketCapitalization?.toUnit || FinancialUnitType.none,
        },
        operatingRevenue: {
          fromValue: currentSelectedFinancials.operatingRevenue?.fromValue,
          toValue:  currentSelectedFinancials.operatingRevenue?.toValue,
          maxValue: aggregateFinancials.operatingRevenue.max,
          minValue: aggregateFinancials.operatingRevenue.min,
          fromUnit: currentSelectedFinancials.operatingRevenue?.fromUnit || FinancialUnitType.none,
          toUnit: currentSelectedFinancials.operatingRevenue?.toUnit || FinancialUnitType.none,
        },
        totalAssets: {
          fromValue: currentSelectedFinancials.totalAssets?.fromValue,
          toValue: currentSelectedFinancials.totalAssets?.toValue,
          maxValue: aggregateFinancials.totalAssets.max,
          minValue: aggregateFinancials.totalAssets.min,
          fromUnit: currentSelectedFinancials.totalAssets?.fromUnit || FinancialUnitType.none,
          toUnit: currentSelectedFinancials.totalAssets?.toUnit || FinancialUnitType.none,
        },
        shareholdersFunds: {
          fromValue: currentSelectedFinancials.shareholdersFunds?.fromValue,
          toValue: currentSelectedFinancials.shareholdersFunds?.toValue,
          maxValue: aggregateFinancials.shareholdersFunds.max,
          minValue: aggregateFinancials.shareholdersFunds.min,
          fromUnit: currentSelectedFinancials.shareholdersFunds?.fromUnit || FinancialUnitType.none,
          toUnit: currentSelectedFinancials.shareholdersFunds?.toUnit || FinancialUnitType.none,
        },
        plBeforeTax: {
          fromValue: currentSelectedFinancials.plBeforeTax?.fromValue,
          toValue: currentSelectedFinancials.plBeforeTax?.toValue,
          maxValue: aggregateFinancials.plBeforeTax.max,
          minValue: aggregateFinancials.plBeforeTax.min,
          fromUnit: currentSelectedFinancials.plBeforeTax?.fromUnit || FinancialUnitType.none,
          toUnit: currentSelectedFinancials.plBeforeTax?.toUnit || FinancialUnitType.none,
        },
      });
    }

    case ACTION.FILTER.COMPANY.OWNERSHIP.UPDATE_SELECTED: {
      return state.setIn(['selectedOwnership'], action.payload);
    }

    case ACTION.FILTER.COMPANY.OWNERSHIP.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'ownership'], action.payload);
    }

    case ACTION.FILTER.COMPANY.NUM_OF_EMPLOYEES.UPDATE_SELECTED: {
      return state.setIn(['selectedNumOfEmployees'], action.payload);
    }

    case ACTION.FILTER.COMPANY.NUM_OF_EMPLOYEES.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'numOfEmployees'], action.payload);
    }

    case ACTION.FILTER.COMPANY.KEYWORD_IN.UPDATE_SELECTED: {
      return state.setIn(['selectedKeywordIn'], action.payload);
    }

    case ACTION.FILTER.COMPANY.KEYWORD_IN.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'keywordIn'], action.payload);
    }   
        
    case ACTION.FILTER.COMPANY.RELATIONSHIP.UPDATE_SELECTED: {
      return state.setIn(['selectedRelationshipSearch'], action.payload);
    }

    case ACTION.FILTER.COMPANY.RELATIONSHIP.UPDATE_ADDITIONAL_INFO: {
      return state.setIn(['additionalInfo', 'relationshipSearch'], action.payload);
    }

    case ACTION.FILTER.COMPANY.RESET_STATES: {
      return initialState;
    }

    default:
      return state;
  }
};
  
export default CompanyFilter;