
import { ANNOUNCEMENT } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';
import { isEmpty } from 'lodash';
import { getLocalStorageAnnouncement, saveLocalStorageAnnouncement } from '@react/utils/common.util';
import IAnnouncement from '@models/announcement';
import createAction from './actionCreator';
import { increaseProgress, decreaseProgress } from './progress.actions';

export const initAnnouncement = () => {
  return async function (dispatch: any, getState: any) {
    const storedAnnouncement = getLocalStorageAnnouncement();
    const hasFetched = !!storedAnnouncement && storedAnnouncement.hasFetched(); 

    const inProgress = getState().getIn(['Announcement', 'inProgress']);
    const currentAnnouncements = getState().getIn(['Announcement', 'list']);

    if (hasFetched || inProgress || !isEmpty(currentAnnouncements)) {
      return;
    }
    
    dispatch(increaseProgress());
    dispatch(createAction(ANNOUNCEMENT.INIT.IN_PROGRESS));
    const request = {
      skip: 0,
      select: 1,
      deletedStatus: [0],
      orderBy: 'endDate',
      order: 'desc',
    };
    const response = await sendPostRequestWithState(API_ENDPOINT.ANNOUNCEMENT_LIST, request, getState);
    return new Promise((resolve, reject) => {
      dispatch(createAction(ANNOUNCEMENT.INIT.NOT_IN_PROGRESS));
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        let announcements: IAnnouncement[] = response.data;
        dispatch(createAction(ANNOUNCEMENT.INIT.RESULT, { data: announcements }));

        if (!isEmpty(announcements)) {
          announcements = announcements.sort((a, b) => {
            return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
          });

          saveLocalStorageAnnouncement(announcements[0]);
        }

        resolve();
      } else {
        reject(response);
      }
    });
  };
};
