import { ACCOUNT } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { SITE_URLS } from '@constants/routes';
import { sendPostRequestWithState, sendPostRequestWithKey, sendPostRequestWithAuth } from 'skyeye-fe-common-util';
import { getAccountState } from '@react/utils/auth.util';
import { cleanLocalStorageMostRecentSearch } from '@react/utils/common.util';
import { UserManager } from 'oidc-client';
import { EMPTY_STRING, LOCAL_STORAGE } from '@constants/common';
import { getUserGuid, getGuid, getName } from '../selectors/account.selector';
import { getOidcUser } from '../selectors/oidc.selector';
import createAction from './actionCreator';
import { defaultPromise } from './base.action';

export const logout = (history, oidcUserManager: UserManager, token: string) => {
  return async function (dispatch: any, getState: any) {
    const oldUserGuidState = getUserGuid(getState()) as string;

    // oidc is realtime user data
    const oidcCurrentUser = await oidcUserManager.getUser();
    const oidcSubId = oidcCurrentUser?.profile?.sub ?? ''; // format is '0a6975e7-674a-44e9-9e85-cbd8db2ca3c2|' = userid + '|'
    const oidcUserGuid = oidcSubId?.split('|')?.[0]; 

    // check if the oidcUserGuid is the same as the one in the state => this mean user logged in in another tab with another account
    // or don't have oidcUserGuid => this mean user logged out in another tab
    // => reload the page to get the latest state or auto redirect to login page
    if (!oidcUserGuid || oldUserGuidState.toLocaleLowerCase() !== oidcUserGuid.toLocaleLowerCase()) {
      return window.location.reload();
    }
  
    // process logout action
    cleanLocalStorageMostRecentSearch();
    localStorage.removeItem(LOCAL_STORAGE.SEARCH_CRITERIA);
    dispatch(createAction(ACCOUNT.LOGGED_OUT));
    history.push(SITE_URLS.LOGOUT_REDIRECT);
    oidcUserManager.removeUser();
    oidcUserManager.signoutRedirect({ 'id_token_hint': token });
  };
};

export const changePassword = (existPassword: string, newPassword: string, confirmPassword: string) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.CHANGE_PASSWORD, { existPassword, newPassword, confirmPassword }, getState);
    return defaultPromise(response);
  };
};

export const requestAccess = (requestType: string, requestKey: string) => {
  return async function (_dispatch: any, getState: any) {
    const requestData = {
      requestType,
      requestKey,
    };
    const response = await sendPostRequestWithState(API_ENDPOINT.REQUEST_ACCESS, requestData, getState);
    return defaultPromise(response);
  };
};

export const getUserSubscription = (userGuid: string) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithKey(API_ENDPOINT.USER_SUBSCRIPTION, { userGuid }, process.env.API_KEY);
    return defaultPromise(response);
  };
};

export const getUserAccountInfo = (userGuid: string) => {
  return async function (dispatch: any) {
    const response = await sendPostRequestWithKey(API_ENDPOINT.ACCOUNT_INFO, { guids: [userGuid] }, process.env.API_KEY);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(ACCOUNT.GET_INFO, response.data[0]));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const getAccountInfo = (token) => {
  return async function (dispatch: any) {
    const response = await sendPostRequestWithAuth(API_ENDPOINT.ACCOUNT_RETRIEVE, {}, token);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const userCountry = response?.data?.organisation?.filter(x => x?.country).map(x => x.country) || [];
        localStorage.setItem('userCountry', JSON.stringify(userCountry));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const setUserPerferences = (selectedGoals) => {
  return async function (_dispatch: any, getState: any) {
    const payload = {
      userGuid: getGuid(getState()),
      preferences: { 'goals': selectedGoals },
      userInfoExtraction: { userName: getName(getState()) },
    };
    const response = await sendPostRequestWithKey(API_ENDPOINT.USER_PREFERENCES, payload, process.env.API_KEY);
    return defaultPromise(response);
  };
};

export const resetPassword = (resetType: string, resetKey: string, newPassword: string, confirmPassword: string, allowAccess: boolean) => {
  return async function (_dispatch: any, getState: any) {
    const requestData = {
      resetType,
      resetKey,
      newPassword,
      confirmPassword,
      allowAccess,
    };
    const response = await sendPostRequestWithState(API_ENDPOINT.RESET_PASSWORD, requestData, getState);
    return defaultPromise(response);
  };
};

export const forgetPassword = (email: string, isLogin: boolean = false) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.FORGET_PASSWORD, { email, isLogin }, getState);
    return defaultPromise(response);
  };
};

export const acceptLink = (acceptLinkReq = {}) => {
  return async function (_dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.ACCEPT_ORG_LINK, acceptLinkReq, getState);
    return defaultPromise(response);
  };
};

export const oidcLogin = (oidcUserManager: UserManager, extraData: any = null) => {
  return async function () {
    oidcUserManager.signinRedirect({ data: extraData });
    oidcUserManager.clearStaleState();
  };
};

export const callback = (redirectUrl: string = SITE_URLS.MAIN, history?, extraLocationState = {}) => {
  return async function (dispatch: any, getState: any) {
    const oidcUser = getOidcUser(getState());
    const accountInfo = await dispatch(getAccountInfo(oidcUser.profile.user_token))
    dispatch(createAction(ACCOUNT.LOGGED_IN, getAccountState(accountInfo.data)));
    if (history)
      history.push(decodeURIComponent(redirectUrl), {
        ...extraLocationState
      });
  };
};

export const initAccountInfo = () => {
  return async function (dispatch: any, getState: any) {
    const oidcUser = getOidcUser(getState());
    if (oidcUser) {
      const accountInfo = await dispatch(getAccountInfo(oidcUser.profile.user_token))
      const accountState = getAccountState(accountInfo.data);
      let organisationName: string;
      let organisationStatus: string;
      accountState.get('subcriptionFeaturePermissionModel').map(model => {
        if (model.get('organisationName'))
          organisationName = model.get('organisationName').toString();
        if (model.get('organisationStatus'))
          organisationStatus = model.get('organisationStatus').toString();
      });
      dispatch(createAction(ACCOUNT.LOGGED_IN, accountState));
    }
  };
};

export const setUserRole = (role) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(ACCOUNT.SET_USER_ROLE, { role }));
    localStorage.setItem('userRole', role);
  };
};

export const getLoginToken = (userManager, redirectUrl, partnerInfo) => {
  return new Promise<any>((resolve, reject) => {
    userManager.createSigninRequest()
      .then(async (res) => {
        const returnUrl = res.url.replace(process.env.OIDC_AUTHORITY, EMPTY_STRING);
        const stateId = new URLSearchParams(returnUrl).get('state');
        localStorage.setItem(`${stateId}-redirectUrl`, encodeURIComponent(redirectUrl));
        localStorage.setItem(`${stateId}-partnerInfo`, JSON.stringify(partnerInfo));
        // store in session storage the session queries.
        const tokenRes = await sendPostRequestWithKey(API_ENDPOINT.SIGN_TOKEN, {
          partnerCode: partnerInfo.partnerCode,
          partnerUserGuid: partnerInfo.partnerUserId,
          returnUrl
        });

        if (tokenRes && tokenRes.isSuccess) {
          resolve(tokenRes);
        } else {
          reject(tokenRes);
        }
      });
  });
};

export const getUserSubscriptionList = () => {
  return async function (_: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.USER_SUBSCRIPTION_LIST, { },
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  };
};
