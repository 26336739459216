import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FONT } from '@constants/style';

interface IStyleProps {
  visitedStyle: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IStyleProps) => ({
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'underline',
      },
      ...(props.visitedStyle && {
        '&:visited': {
          color: `${FONT.COLOR.CAPTION.MAIN} !important`,
          '&:hover': {
            color: `${theme.palette.primary.main} !important`,
          },
        },
      }),
    }),
  }),
);

interface ICustomLinkProps extends LinkProps {
  children?: React.ReactNode,
  className?: string,
  visitedStyle?: boolean,
  state?,
}

const CustomLink = (props: ICustomLinkProps) => {
  const { to, children, className, visitedStyle, ...otherProps } = props;
  const classes = useStyles({ visitedStyle });

  return (
    <Link
      className={`${classes.root} ${className}`}
      to={to}
      {...otherProps}
    >
      {children}
    </Link>
  );
};

export default CustomLink;