import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() => 
  createStyles({
    toggleButtonGroup: {
      textAlign: 'right',
      marginBottom: '16px',
    },
    toggleButton: {
      color: '#404040',
      borderRadius: 0,
      '&.Mui-selected': {
        color: '#404040',
        background: '#11D1C4 !important',
      },
      '&:hover': {
        background: '#D4ECEA',
        color: '#404040',
      },
    },
  }),
);

export default useStyles;