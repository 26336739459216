import { COMPANY_DETAIL } from '@constants/actions';
import { NO_RESULT_PER_PAGE, PILL_SHAPED } from '@constants/common';
import { Map } from 'immutable';

const initialState = Map({
  source: '',
  inProgress: false,
  loading: false,
  ownership: {
    data: [],
    inProgress: true,
    totalCount: 0,
    listProps: {
      page: 1,
      resultPerPage: NO_RESULT_PER_PAGE.TEN,
    },
  },
  supplyChain: {
    data: [],
    inProgress: false,
    totalCount: 0,
    listProps: {
      page: 1,
      resultPerPage: NO_RESULT_PER_PAGE.TEN,
    },
    allSupplyChainDataCount: 0,
    supplierDataCount: 0,
    customerDataCount: 0,
    partnerDataCount: 0,
    filter: PILL_SHAPED.ALL,
  },
  competitors: {
    data: [],
    inProgress: false,
    totalCount: 0,
    listProps: {
      page: 1,
      resultPerPage: NO_RESULT_PER_PAGE.TEN,
    },
  },
  seerOwnership: {
    inProgress: false,
    data: [],
    totalCount: 0,
    listProps: {
      page: 1,
      resultPerPage: NO_RESULT_PER_PAGE.TEN,
    },
  },
  seerSupplyChain: {
    inProgress: false,
    data: [],
    totalCount: 0,
    listProps: {
      page: 1,
      resultPerPage: NO_RESULT_PER_PAGE.TEN,
    },
    filter: PILL_SHAPED.ALL,
    allSeerSupplyChainDataCount: 0,
    seerSupplierDataCount: 0,
    seerCustomerDataCount: 0,
  },
});

const Relationship = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case COMPANY_DETAIL.RELATIONSHIP.GET_OWNERSHIP_DATA: {
      return state.setIn(['ownership', 'data'], payload.ownershipData);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SUPPLY_CHAIN_DATA: {
      return state.setIn(['supplyChain', 'data'], payload.supplyChainData);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_COMPETITORS_DATA: {
      return state.setIn(['competitors', 'data'], payload.competitorData);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_OWNERSHIP_LIST_SETTING: {
      return state.setIn(['ownership', 'listProps'], { ...payload });
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SUPPLY_CHAIN_LIST_SETTING: {
      return state.setIn(['supplyChain', 'listProps'], { ...payload });
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_COMPETITORS_LIST_SETTING: {
      return state.setIn(['competitors', 'listProps'], { ...payload });
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_OWNERSHIP_PROGRESS: {
      return state.setIn(['ownership', 'inProgress'], payload.progress);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SUPPLY_CHAIN_PROGRESS: {
      return state.setIn(['supplyChain', 'inProgress'], payload.progress);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_COMPETITORS_PROGRESS: {
      return state.setIn(['competitors', 'inProgress'], payload.progress);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_OWNERSHIP_TOTAL_COUNT: {
      return state.setIn(['ownership', 'totalCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SUPPLY_CHAIN_TOTAL_COUNT: {
      return state.setIn(['supplyChain', 'totalCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_COMPETITORS_TOTAL_COUNT: {
      return state.setIn(['competitors', 'totalCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_ALL_SUPPLY_CHAIN_TOTAL_COUNT: {
      return state.setIn(['supplyChain', 'allSupplyChainDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SUPPLIER_TOTAL_COUNT: {
      return state.setIn(['supplyChain', 'supplierDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_CUSTOMER_TOTAL_COUNT: {
      return state.setIn(['supplyChain', 'customerDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_PARTNER_TOTAL_COUNT: {
      return state.setIn(['supplyChain', 'partnerDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SEER_OWNERSHIP_PROGRESS: {
      return state.setIn(['seerOwnership', 'inProgress'], payload.inProgress);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_OWNERSHIP_RELATIONSHIP_DATA: {
      return state.setIn(['seerOwnership', 'data'], payload.relationshipData)
        .setIn(['seerOwnership', 'totalCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SEER_OWNERSHIP_LIST_SETTING: {
      return state.setIn(['seerOwnership', 'listProps'], { ...payload });
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SEER_SUPPLY_CHAIN_PROGRESS: {
      return state.setIn(['seerSupplyChain', 'inProgress'], payload.inProgress);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SEER_SUPPLY_CHAIN_DATA: {
      return state.setIn(['seerSupplyChain', 'data'], payload.relationshipData)
        .setIn(['seerSupplyChain', 'totalCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.GET_SEER_SUPPLY_CHAIN_LIST_SETTING: {
      return state.setIn(['seerSupplyChain', 'listProps'], { ...payload });
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SUPPLY_CHAIN_FILTER: {
      return state.setIn(['supplyChain', 'filter'], action.payload.value);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SEER_SUPPLY_CHAIN_FILTER: {
      return state.setIn(['seerSupplyChain', 'filter'], action.payload.value);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_RELATIONSHIP_SOURCE_TYPE: {
      return state.setIn(['source'], payload.source);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_RELATIONSIHP_IN_PROGRESS: {
      return state.setIn(['inProgress'], payload.progress);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SEER_ALL_SUPPLY_CHAIN_TOTAL_COUNT: {
      return state.setIn(['allSeerSupplyChainDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SEER_SUPPLIER_TOTAL_COUNT: {
      return state.setIn(['seerSupplierDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_SEER_CUSTOMER_TOTAL_COUNT: {
      return state.setIn(['seerCustomerDataCount'], payload.totalCount);
    }
    case COMPANY_DETAIL.RELATIONSHIP.SET_LOADING: {
      return state.setIn(['loading'], action.payload.value);
    }
    default:
      return state;
  }
};

export default Relationship;