import { RADAR } from '@constants/actions';
import {
  ActiveRadarFeed,
  Radar,
  RadarArticleOverview,
  RadarEmailAlerts,
  RadarStats,
  STATUS_FOR_RADAR,
  UpdateRadarEmailAlerts,
} from '@models/radar';
import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';
import { SAVE_TO_RADAR_TYPE, RADAR_SEARCH_TYPE, ZERO, RADAR_COMPANY_OVERVIEW_TYPE, RADAR_COUNTS, DEFAULT_CRON, ENTITY_TYPE, RADAR_SHOWING_LIMIT, VIEW_MORE_COUNT_DEFAULT, RESULT_PER_PAGE } from '@constants/common';
import { isEmpty, keyBy } from 'lodash';
import {
  operateSearchCriteriaToLocalStorage,
  setSearchCriteriaToLocalStorage,
} from '@react/components/radar/util';
import { ICompanyExportCSV, ICompanyExportData } from '@models/company';
import { constructCompanyExportObject } from '@react/utils/component.util';
import { downloadCsv, isTheSameString } from '@react/utils/common.util';
import { getUserGuid } from '@redux/selectors/account.selector';
import { getRadarLimitFromFeature, radarGuidListSelector } from '@redux/selectors/radar.selector';
import { companyExportCSV, deductCompanyExportCredit } from './company.actions';
import createAction from './actionCreator';

global.globalAbortPromise = null;

const isDuplicateNameError = (errMessage: string) => {
  return isTheSameString(errMessage, 'Duplicate radar name.');
};

const setActiveInitRadar = (radar: Radar) => {
  return createAction(RADAR.SET_ACTIVE_INIT_RADAR, { radar });
};

const setSelectedRadar = (radar: Radar) => {
  return createAction(RADAR.SET_SELECTED_RADAR, { radar });
};

const addCreatedToList = (radar: Radar) => {
  return createAction(RADAR.ADD_CREATED_RADAR, { radar });
};

export interface IUpdateCounts {
  addRadarGuids?: string[];
  deleteRadarGuids?: string[];
  type: RADAR_COUNTS;
}

export const updateRadarListCounts = (updateCounts: IUpdateCounts) => {
  return createAction(RADAR.UPDATE_RADAR_LIST_COUNTS, { updateCounts });
};

export const setRadarSettingParams = (params) => {
  return createAction(RADAR.SET_RADAR_SETTING_PARAMS, params);
};

export const updateRadarLinkedBookmark = (currentRequest) => {
  return createAction(RADAR.UNBIND_FILTER_FROM_RADAR, { filterGuid: currentRequest.bookmarkFilterGuid });
};

export const createNewRadar = (radar: Radar) => {
  return async function (dispatch: any, getState: any) {
    const currRadarList = getState().getIn(['Radar', 'radarList']);
    const currQuickAccess = currRadarList.filter(radar => radar.quickAccess);
    const newRadar = {...radar};
    const radarLimit = getRadarLimitFromFeature(getState());

    if (currQuickAccess.length < Math.min(RADAR_SHOWING_LIMIT, radarLimit)) {
      newRadar.quickAccess = true;
    }

    const response = await sendPostRequestWithState(API_ENDPOINT.RADAR_CREATE, newRadar, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const newRadar: Radar = response.data;
        newRadar.guid = newRadar.guid.toUpperCase();
        newRadar.counts = {
          articleSearchCriteria: ZERO,
          favoriteCompany: ZERO,
          companySearchCriteria: ZERO,
          reportFileSearchCriteria: ZERO,
        };
        setSearchCriteriaToLocalStorage(newRadar.guid, []);
        dispatch(addCreatedToList(newRadar));
        dispatch(initiateRadar(newRadar));
        dispatch(createRadarEmailAlerts(newRadar.guid));
        dispatch(setSelectedRadar(newRadar));
        resolve(response.data);
      } else {
        reject({ ...response, isDuplicateName: isDuplicateNameError(response.message) });
      }
    });
  };
};

export const createRadarEmailAlerts = (
  resourceGuid: string,
) => {
  return async function (dispatch: any, getState: any) {
    const req = {
      digestType: 'RADAR',
      cron: DEFAULT_CRON,
      userGuid:getUserGuid(getState()),
      resourceGuid,
    };
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_EMAIL_ALERTS_CREATE,
      req,
      getState,
    );
    return new Promise<void>((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(RADAR.CREATE_EMAIL_ALERTS_LIST, { data: response.data }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const initiateRadar = (radar: Radar) => {
  return async function (dispatch: any) {
    dispatch(setActiveInitRadar(radar));
  };
};

export const listRadar = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(RADAR.LIST_IN_PROGRESS, { inProgress: true }));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_LIST,
      { 
        isPagination: true,
      },
      getState,
    );
    return new Promise<void>((resolve, reject) => {
      dispatch(createAction(RADAR.LIST_IN_PROGRESS, { inProgress: false }));
      if (response && response.isSuccess) {
        const radarList = response.data;
        radarList.forEach(radar => {
          setSearchCriteriaToLocalStorage(radar.guid, radar.searchCriteria);
        });
        dispatch(createAction(RADAR.SET_RADAR_LIST, { radarList }));
        dispatch(
          listRadarStatistic(radarList.map((radar) => radar.guid)),
        );
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const radarEmailAlertsList = () => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(RADAR.EMAIL_ALERTS_LIST_PROGRESS, { inProgress: true }));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_EMAIL_ALERTS_LIST,
      { userGuid: getUserGuid(getState()) },
      getState,
    );
    return new Promise<void>((resolve, reject) => {
      dispatch(createAction(RADAR.EMAIL_ALERTS_LIST_PROGRESS, { inProgress: false }));
      if (response && response.isSuccess) {
        dispatch(createAction(RADAR.EMAIL_ALERTS_LIST, { data: response.data }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const updateRadarEmailAlerts = (
  params: UpdateRadarEmailAlerts,
) => {
  return async function (dispatch: any, getState: any) {
    return new Promise<void>(async (resolve, reject) => {
      if (params?.digestNotActive) {
        resolve();
      } else {
        if (params?.isEmailAlertsEmpty) {
          await dispatch(createRadarEmailAlerts(params.req.resourceGuid));
        }
        const { req } = params;
        const response = await sendPostRequestWithState(
          API_ENDPOINT.RADAR_EMAIL_ALERTS_UPDATE,
          { 
            ...req,
            userGuid: getUserGuid(getState())
          },
          getState,
        );
  
        if (response && response.isSuccess) {
          dispatch(createAction(RADAR.UPDATE_EMAIL_ALERTS_LIST, { data: response.data }));
          resolve();
        } else {
          reject(response);
        }
      }
    });
  };
};

export const listRadarStatistic = (
  guids: string[] = []
) => {
  return async function (dispatch: any, getState: any) {
    const radarArticleFetching: boolean = getState().getIn([
      'Radar',
      'articles',
      'articleInProgress'
    ]);
    let guidList = guids;
    if (isEmpty(guids)) {
      guidList = radarGuidListSelector(getState());
    }
    if (!isEmpty(guidList)) {
      const response = await sendPostRequestWithState(
        API_ENDPOINT.RADAR_STATISTIC,
        { radarGuids: guidList },
        getState,
      );
      if (response && response.isSuccess) {
        const statistic: RadarStats[] = response.data;
        const statisticMap = keyBy(statistic, 'radarGuid');
        dispatch(createAction(RADAR.SET_RADAR_STATS, { stats: statisticMap }));
      }
    }

    const currentActiveFeed: ActiveRadarFeed = getState().getIn([
      'Radar',
      'activeRadarFeed',
    ]);
    if (!isEmpty(currentActiveFeed) && !radarArticleFetching) {
      if (currentActiveFeed.radarSearchType === RADAR_SEARCH_TYPE.ARTICLE) {
        dispatch(
          radarArticleList({
            type: currentActiveFeed.type as string,
            searchCriteriaGuids: currentActiveFeed.searchCriteriaGuid ? [currentActiveFeed.searchCriteriaGuid] : undefined,
            radarGuids: [currentActiveFeed.radarGuid],
            isPagination: true,
            select: RESULT_PER_PAGE,
            skip: 0,
          }),
        );
        dispatch(updateViewMoreCount(VIEW_MORE_COUNT_DEFAULT));
      }
    } 
  };
};

interface ISearchCriteriaListItem {
  searchTerm: string;
  type: RADAR_SEARCH_TYPE;
}

export interface ISaveSearchToRadarReq {
  radarGuids: string[];
  userGuid?: string; // optional
  name: string;
  searchCriterias: ISearchCriteriaListItem[];
  newRadarName?: string;
}

export const saveSearchToRadar = (req: ISaveSearchToRadarReq) => {
  const currentRequest = { ...req };
  return async function (dispatch: any, getState: any) {
    return new Promise(async (resolve, reject) => {
      if (currentRequest.newRadarName) {
        const createResponse = await sendPostRequestWithState(
          API_ENDPOINT.RADAR_CREATE,
          { name: currentRequest.newRadarName },
          getState,
        );
        if (createResponse && createResponse.isSuccess) {
          dispatch(addCreatedToList(createResponse.data));
          currentRequest.radarGuids = [
            ...currentRequest.radarGuids,
            createResponse.data.guid,
          ];
          delete currentRequest.newRadarName;
        }
      }
      if (currentRequest.searchCriterias.length > 1) {
        const responseOne = await sendPostRequestWithState(
          API_ENDPOINT.SEARCH_CRITERIA_CREATE,
          {
            radarGuids: currentRequest.radarGuids,
            name: currentRequest.name,
            searchCriteria: currentRequest.searchCriterias[0].searchTerm,
            type: currentRequest.searchCriterias[0].type,
          },
          getState,
        );
        const responseTwo = await sendPostRequestWithState(
          API_ENDPOINT.SEARCH_CRITERIA_CREATE,
          {
            radarGuids: currentRequest.radarGuids,
            name: currentRequest.name,
            searchCriteria: currentRequest.searchCriterias[1].searchTerm,
            type: currentRequest.searchCriterias[1].type,
          },
          getState,
        );

        if (
          responseOne &&
          responseOne.isSuccess &&
          responseTwo &&
          responseTwo.isSuccess
        ) {
          if (!isEmpty(getState().getIn(['Radar', 'activeInitRadar']))) {
            dispatch(clearActiveInit());
          }
          resolve(currentRequest);
          currentRequest.radarGuids.forEach((radarGuid, index) => {
            operateSearchCriteriaToLocalStorage(
              radarGuid,
              currentRequest.searchCriterias[0].type,
              responseOne.data[index].guid,
              'add',
            );
            operateSearchCriteriaToLocalStorage(
              radarGuid,
              currentRequest.searchCriterias[1].type,
              responseTwo.data[index].guid,
              'add',
            );
          });
          dispatch(
            createAction(RADAR.ADD_SEARCH_CRITERIA, {
              searchCriterias: responseTwo.data,
            }),
          );
        } else {
          reject(responseOne);
        }
      } else {
        const responseOne = await sendPostRequestWithState(
          API_ENDPOINT.SEARCH_CRITERIA_CREATE,
          {
            radarGuids: currentRequest.radarGuids,
            name: currentRequest.name,
            searchCriteria: currentRequest.searchCriterias[0].searchTerm,
            type: currentRequest.searchCriterias[0].type,
          },
          getState,
        );

        if (responseOne && responseOne.isSuccess) {
          if (!isEmpty(getState().getIn(['Radar', 'activeInitRadar']))) {
            dispatch(clearActiveInit());
          }
          resolve(currentRequest);
          currentRequest.radarGuids.forEach((radarGuid, index) => {
            operateSearchCriteriaToLocalStorage(
              radarGuid,
              currentRequest.searchCriterias[0].type,
              responseOne.data[index].guid,
              'add',
            );
          });
          dispatch(
            createAction(RADAR.ADD_SEARCH_CRITERIA, {
              searchCriterias: responseOne.data,
            }),
          );
        } else {
          reject(responseOne);
        }
      }
    });
  };
};

export const getRadar = (guid: string, isGoBackToRadarInitial: boolean) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_RETRIEVE,
      { guid },
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const selectedRadar: Radar = response.data;
        if (selectedRadar.status === STATUS_FOR_RADAR.INITIAL || isGoBackToRadarInitial) {
          dispatch(setActiveInitRadar(selectedRadar));
        } else {
          setSearchCriteriaToLocalStorage(guid, selectedRadar.searchCriteria);
          dispatch(setSelectedRadar(selectedRadar));
        }
        resolve(selectedRadar);
      } else {
        reject(response);
      }
    });
  };
};

interface IUpdateRadarReq {
  guid: string;
  name: string;
  description: string;
}

export const updateRadar = (req: IUpdateRadarReq) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(RADAR.UPDATE_IN_PROGRESS, { inProgress: true }));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_UPDATE,
      req,
      getState,
    );
    return new Promise<void>((resolve, reject) => {
      dispatch(createAction(RADAR.UPDATE_IN_PROGRESS, { inProgress: false }));
      if (response && response.isSuccess) {
        const radar = response.data;
        dispatch(createAction(RADAR.UPDATE_RADAR_IN_LIST, { radar }));
        if (!isEmpty(getState().getIn(['Radar', 'activeInitRadar']))) {
          dispatch(setActiveInitRadar(radar));
        }
        resolve(radar);
      } else {
        reject({ ...response, isDuplicateName: isDuplicateNameError(response.message) });
      }
    });
  };
};

export const deleteRadar = (currentRadarEamilAlert: RadarEmailAlerts) => {
  const {id, resourceGuid} = currentRadarEamilAlert;
  return async function (dispatch: any, getState: any) {
    const emailDigestList = getState().getIn(['Radar', 'emailAlertsList']);
    const digestNotActive = !emailDigestList.find(digest => isTheSameString(digest.resourceGuid, resourceGuid) && digest.deletedStatus === 0);
    return new Promise<void>((resolve, reject) => {
      dispatch(updateRadarEmailAlerts(
        {
          req:{
            digestId: id,
            deletedStatus: 2,
          },
          digestNotActive }))
        .then(async () => {
          const response = await sendPostRequestWithState(
            API_ENDPOINT.RADAR_DELETE,
            { guid: resourceGuid, deletedStatus: 1 },
            getState,
          );
          if (response && response.isSuccess) {
            dispatch(createAction(RADAR.REMOVE_FROM_LIST, { resourceGuid }));
            resolve();
          } else {
            reject();
          }
        })
        .catch(() => reject());
    });
  };
};

export const clearActiveInit = () => {
  return async function (dispatch: any) {
    dispatch(setActiveInitRadar({}));
  };
};

export const clearSelected = () => {
  return async function (dispatch: any) {
    dispatch(setSelectedRadar({}));
    dispatch(
      createAction(RADAR.SET_RADAR_ACTIVE_FEED, { activeRadarFeed: {} }),
    );
  };
};

export const updateSaveDialog = (openDialogType: SAVE_TO_RADAR_TYPE) => {
  return async function (dispatch: any) {
    dispatch(createAction(RADAR.UPDATE_RADAR_SAVE_DIALOG, { openDialogType }));
  };
};

export const updateViewMoreCount = (count: number) => {
  return async function (dispatch: any) {
    dispatch(createAction(RADAR.SET_VIEW_MORE_COUNT, { count }));
  };
};

export interface IOverviewReq {
  type: string;
  radarGuids: string[];
  searchCriteriaGuids?: string[];
  isPagination: boolean;
  skip?: number;
  select?: number;
}

export const radarArticleOverview = (req: IOverviewReq) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(RADAR.ARTICLE_OVERVIEW_IN_PROGRESS, { inProgress: true }));

    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_ARTICLE_OVERVIEW,
      req,
      getState
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const articleOverView: RadarArticleOverview[] = response.data;
        dispatch(createAction(RADAR.ARTICLE_OVERVIEW, { articleOverView }));
        dispatch(createAction(RADAR.ARTICLE_OVERVIEW_IN_PROGRESS, { inProgress: false }));

        resolve(articleOverView);
      } else {
        reject(response);
      }
    });
  };
};

export const radarArticleList = (req: IOverviewReq) => {
  return async function (dispatch: any, getState: any) {
    dispatch(
      createAction(RADAR.LIST_ARTICLE_IN_PROGRESS, { inProgress: true }),
    );
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_ARTICLE_OVERVIEW,
      req,
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        if (req.skip === 0) {
          dispatch(createAction(RADAR.CLEAR_LIST, { listName: 'articles' }));
        }
        dispatch(
          createAction(RADAR.LIST_ARTICLE_IN_PROGRESS, { inProgress: false }),
        );
        const articleOverView: RadarArticleOverview[] = response.data;
        const articleList = articleOverView[0].articles;
        const totalCount = articleOverView[0].totalCount;
        dispatch(createAction(RADAR.RADAR_ARTICLE_LIST, { articles: articleList }));
        dispatch(createAction(RADAR.ARTICLE_LIST_TOTAL_COUNT, { totalCount }));
        resolve(articleOverView);
      } else {
        dispatch(
          createAction(RADAR.LIST_ARTICLE_IN_PROGRESS, { inProgress: false }),
        );
        reject(response);
      }
    });
  };
};

export const radarCompanyOverview = (req: IOverviewReq) => {
  return async function (dispatch: any, getState: any) {
    const isMatched = req.type === RADAR_COMPANY_OVERVIEW_TYPE.MATCHED;
    const mapName = isMatched ? 'matched' : 'starred';
    dispatch(
      createAction(RADAR.COMPANY_IN_PROGRESS, { mapName, inProgress: true }),
    );
    if (req.skip === 0) {
      dispatch(createAction(RADAR.CLEAR_LIST, { listName: mapName }));
    }
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_COMPANY_OVERVIEW,
      req,
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const companies = response.data;
        dispatch(createAction(RADAR.COMPANY_OVERVIEW, { companies, mapName }));
        dispatch(
          createAction(RADAR.RADAR_COMPANY_TOTAL_COUNT, {
            totalCount: response.totalCount,
            countName: mapName,
          }),
        );
        dispatch(
          createAction(RADAR.COMPANY_IN_PROGRESS, {
            mapName,
            inProgress: false,
          }),
        );
        resolve(req.type);
      } else {
        dispatch(
          createAction(RADAR.COMPANY_IN_PROGRESS, {
            mapName,
            inProgress: false,
          }),
        );
        reject(response);
      }
    });
  };
};

export const resetRadarCompanyOverview = () => {
  return async function (dispatch: any) {
    dispatch(createAction(RADAR.COMPANY_OVERVIEW, { isReset: true }));
    dispatch(
      createAction(RADAR.RADAR_COMPANY_TOTAL_COUNT, { countName: 'all' }),
    );
  };
};

function getPromiseWithAbort(promise) {
  const obj = { } as any;
  const abortPromise = new Promise((resolve) => {
    obj.abort = resolve;
  });

  obj.promise = Promise.race([promise, abortPromise]);
  return obj;
}

export const getMentionedCompany = (req) => {
  const type = 'article';
  return async function (dispatch: any, getState: any) {
    dispatch(
      createAction(RADAR.NEWS_MENTION_IN_PROGRESS, { inProgress: true }),
    );
    global.globalAbortPromise = getPromiseWithAbort(sendPostRequestWithState(
      API_ENDPOINT.RADAR_RETRIEVE_COMPANY_MENTIONED,
      req,
      getState,
    ));
    const response = await global.globalAbortPromise.promise;
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        if(!response['abort'])
          global.globalAbortPromise = null;
        const mentionCompanies = response.data.mentionCompanies;
        const keys = Object.keys(mentionCompanies);
        const mostMentionedCompany = keys.map((i) => ({
          guid: i,
          ...mentionCompanies[i],
        }));
        const sortedCompanyList = [...mostMentionedCompany].
          sort((a, b) => b.count - a.count).
          slice(0, 100);
        const total = response.data.total;
        dispatch(updateMentionedCompany(sortedCompanyList, total, type));
        dispatch(
          createAction(RADAR.NEWS_MENTION_IN_PROGRESS, { inProgress: false }),
        );
        resolve(mentionCompanies);
      } else {
        dispatch(
          createAction(RADAR.NEWS_MENTION_IN_PROGRESS, { inProgress: false }),
        );
        reject(response);
      }
    });
  };
};

const updateMentionedCompany = (
  companyList: any[],
  total: number,
  type?: string,
) => {
  return async function (dispatch: any) {
    dispatch(
      createAction(RADAR.MOST_MENTIONED_COMPANY, {
        sortedCompanyList: companyList,
        type,
      }),
    );
    dispatch(
      createAction(RADAR.MENTIONED_COMPANY_TOTAL_COUNT, { total, type }),
    );
  };
};

export const clearMentionedCompany = (type?: string) => {
  return async function (dispatch: any) {
    dispatch(updateMentionedCompany([], 0, type));
  };
};

export const clearAllMentionedCompany = () => {
  return async function (dispatch: any) {
    dispatch(updateMentionedCompany([], 0, ENTITY_TYPE.ARTICLE));
    dispatch(updateMentionedCompany([], 0, 'starred'));
    dispatch(updateMentionedCompany([], 0, 'matched'));
  };
};

export const updateActiveRadarFeed = (activeRadarFeed: ActiveRadarFeed) => {
  return async function (dispatch: any) {
    dispatch(createAction(RADAR.SET_RADAR_ACTIVE_FEED, { activeRadarFeed }));
  };
};

interface IUpdateReadTimeReq {
  guid: string;
  type: RADAR_SEARCH_TYPE;
}

export const updateReadTime = (req: IUpdateReadTimeReq) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.UPDATE_READ_TIME,
      req,
      getState,
    );
    return new Promise<void>((resolve, reject) => {
      if (response && response.isSuccess) {
        const updateStatPayload: any = { guid: req.guid.toUpperCase() };
        if (req.type === RADAR_SEARCH_TYPE.ARTICLE) {
          updateStatPayload.articleCount = 0;
        }
        dispatch(
          createAction(RADAR.UPDATE_RADAR_STATS, { ...updateStatPayload }),
        );
        resolve();
      } else {
        reject();
      }
    });
  };
};

interface IListFavCompanyReq {
  radarGuid: string;
  isPagination?: boolean;
  skip?: number;
  select?: number;
  orderBy?: string;
  order?: string;
}

export const listFavCompany = (req: IListFavCompanyReq) => {
  return async function (dispatch: any, getState: any) {
    dispatch(
      createAction(RADAR.LIST_COMPANY_IN_PROGRESS, { inProgress: true }),
    );
    const response = await sendPostRequestWithState(
      API_ENDPOINT.LIST_FAV_COMPANY,
      req,
      getState,
    );
    return new Promise((resolve, reject) => {
      dispatch(
        createAction(RADAR.LIST_COMPANY_IN_PROGRESS, { inProgress: false }),
      );
      if (response && response.isSuccess) {
        dispatch(createAction(RADAR.LIST_FAV_COMPANY, response));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const exportRadarCompanies = (radarGuid: string) => {
  return async function (dispatch: any) {
    const exportProps: ICompanyExportCSV = {};
    return new Promise((resolve, reject) => {
      dispatch(
        listFavCompany({
          radarGuid,
        }),
      )
        .then((response: any) => {
          exportProps.selected = response.data.map(
            (company) => company.companyGuid,
          );
          dispatch(companyExportCSV(exportProps))
            .then((companiesData: ICompanyExportData[]) => {
              const exportObj = constructCompanyExportObject(companiesData);
              downloadCsv(exportObj, 'Company-Export.csv');
              dispatch(deductCompanyExportCredit());
              resolve(companiesData);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const activateRadar = (radarGuid: string) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.ACTIVATE_RADAR,
      { radarGuid },
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        setSearchCriteriaToLocalStorage(response.data.guid, response.data.searchCriteria);
        dispatch(setSelectedRadar(response.data));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const setRadarQuickAccess = (req: any) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_SET_QUICK_ACCESS,
      req,
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(createAction(RADAR.SET_RADAR_QUICK_ACCESS, {
          setQuickAccess: req.setQuickAccess,
          removeQuickAccess: req.removeQuickAccess,
        }));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};  

export const getCountMentionedCompanyByInterval = (req) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_COUNT_MENTIONED_COMPANY_BY_INTERVAL,
      req,
      getState,
    );
    return new Promise((resolve, reject) => {
      const mapName = req.type === RADAR_COMPANY_OVERVIEW_TYPE.MATCHED ? 'matched' : 'starred';
      if (response && response.isSuccess) {
        dispatch(createAction(RADAR.MENTION_COMPANY_COUNT_BY_INTERVAL, {
          countInterval: response.data,
          mapName,
        }));
        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};

export const resetAllCompaniesState = () => {
  return async function (dispatch: any) {
    dispatch(createAction(RADAR.RESET_ALL_COMPANIES_STATE));
  };
};

export const getListMentionCount = (req) => {
  return async function (dispatch: any, getState: any) {
    const mapName = req.type === RADAR_COMPANY_OVERVIEW_TYPE.MATCHED ? 'matched' : 'starred';
    dispatch(createAction(RADAR.RADAR_LIST_MENTION_COUNT_IN_PROGRESS, { listMentionCountInProgress: true, mapName }));
    const response = await sendPostRequestWithState(
      API_ENDPOINT.RADAR_LIST_MENTION_COUNT,
      req,
      getState,
    );
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        const { mention, coMention } = response.data;
        const companyTotalCount = response.totalCount;
        const mentionTotalCount = coMention.totalCount === null ? '0' : coMention.totalCount;
        dispatch(createAction(RADAR.MOST_MENTIONED_COMPANY, { sortedCompanyList: coMention.top100MostMentioned || [], type: mapName }));
        dispatch(createAction(RADAR.MENTIONED_COMPANY_TOTAL_COUNT, { total: mentionTotalCount.includes('+') ? 10001 : parseInt(mentionTotalCount, 10), type: mapName }));
        dispatch(createAction(RADAR.COMPANY_OVERVIEW, { companies: mention || [], mapName }));
        dispatch(createAction(RADAR.RADAR_COMPANY_TOTAL_COUNT, { totalCount: companyTotalCount || mention?.length || 0, countName: mapName }));
        dispatch(createAction(RADAR.RADAR_LIST_MENTION_COUNT_IN_PROGRESS, { listMentionCountInProgress: false, mapName }));
        resolve(response);
      } else {
        dispatch(createAction(RADAR.RADAR_LIST_MENTION_COUNT_IN_PROGRESS, { listMentionCountInProgress: false, mapName }));
        reject(response);
      }
    });
  };
};