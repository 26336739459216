import React from 'react';
import { FeatureToggleContext } from '@react/components/featureToggle/featureToggleContext';

type IFeatureToggleProps = {
  children: any;
  enabledFeatures: string[];
  featureJSON: any;
};

export const FeatureToggleProvider = ({ children, enabledFeatures, featureJSON }: IFeatureToggleProps) => {
  return (
    <FeatureToggleContext.Provider value={{ enabledFeatures, featureJSON }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};