export class DetailAnalyticModel {
  articleSource: string; // Publisher

  articleGuid: string; // Guid

  articleScoutGuid: string; // ScoutAI Guid

  articleIsLocked: boolean;

  articleSystemSource: string; // Source

  articleSystemSourceGuid: string; // SourceKey

  constructor(articleSource: string, articleGuid: string, articleScoutGuid: string, articleIsLocked: boolean, articleSystemSource: string, articleSystemSourceGuid: string) {
    this.articleSource = articleSource; 
    this.articleGuid = articleGuid;
    this.articleScoutGuid = articleScoutGuid;
    this.articleIsLocked = articleIsLocked;
    this.articleSystemSource = articleSystemSource;
    this.articleSystemSourceGuid = articleSystemSourceGuid;
  }
}