import { Map, List, fromJS } from 'immutable';
import { SOURCE } from '@constants/actions';

const initialState = Map({
  sources: List([]),
});

const Source = (state = initialState, action) => {
  if (action.type === SOURCE.SOURCE_LIST) {
    const sources = fromJS(action.payload);
    return state.set('sources', sources);
  }
  return state;
};

export default Source;