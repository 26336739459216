import { CONFIGURATIONS } from '@constants/actions';
import { DRAWER_ALERT_CONTROL, OWNERSHIP_TABLE, SEER_OWNERSHIP_TABLE } from '@constants/common';
import { Map } from 'immutable';

const initialState = Map({
  isPrinting: false,
  isDownloading: false,
  drawerAlertControl: DRAWER_ALERT_CONTROL.none,
  drawerSaveFieldShow: false,
  ownershipTable: OWNERSHIP_TABLE.OWNERSHIP,
  previousIsSearch: false,
  seerOwnershipTable: SEER_OWNERSHIP_TABLE.OWNERSHIP,
});

const Configurations = (state = initialState, action) => {
  switch (action.type) {
    case CONFIGURATIONS.PRINT: {
      return state.set('isPrinting', action.payload.isPrinting);
    }
    case CONFIGURATIONS.DOWNLOAD: {
      return state.set('isDownloading', action.payload.isDownloading);
    }
    case CONFIGURATIONS.DRAWER_ALERT: {
      return state.set('drawerAlertControl', action.payload.value);
    }
    case CONFIGURATIONS.DRAWER_SAVED_FIELD: {
      return state.set('drawerSaveFieldShow', action.payload.isShow);
    }
    case CONFIGURATIONS.OWNERSHIP_TABLE_TYPE: {
      return state.set('ownershipTable', action.payload.value);
    }
    case CONFIGURATIONS.PREVIOUS_IS_SEARCH: {
      return state.set('previousIsSearch', action.payload.isShow);
    }
    case CONFIGURATIONS.SEER_OWNERSHIP_TABLE_TYPE: {
      return state.set('seerOwnershipTable', action.payload.value);
    }
    default:
      return state;
  }
};
  
export default Configurations;