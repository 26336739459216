export const executive = {
  CEO: 'CEO',
  PSD: 'President',
  COO: 'COO',
  DFI: 'CFO',
  SAM: 'Sales & Marketing',
  CIO: 'Chief Investment Officer',
  CTO: 'CTO',
  TRE: 'Treasurer',
  LAW: 'Legal Counsel',
  INV: 'Director of Investments',
  IRC: 'Investor Relations',
  HRO: 'HR Director',
  ADM: 'Chief Admin Officer',
  CMP: 'Compliance Officer',
  PRN: 'Corporate Officer',
  AUD: 'Auditor',
};

export const board = {
  CHM: 'Chairman',
  BRD: 'Board Member',
  SEC: 'Corporate Secretary',
  IND: 'Independent Board Member',
  SHA: 'Shariah Board Member',
};

export const founder = { FOU: 'Founder' };
