import { BOOKMARK } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState } from 'skyeye-fe-common-util';
import createAction from './actionCreator';
import { getUserGuid } from '@redux/selectors/account.selector';

export const getBookmarkNewsHeadlineListAction = (
  bookmarkNewsHeadlineListReq: any
) => {

  return async function (dispatch: any, getState: any) {
    const requestBody = {
      ...bookmarkNewsHeadlineListReq,
      userGuid: getUserGuid(getState()),
      type: 'QUICKACCESS',
    }

    const response = await sendPostRequestWithState(
      API_ENDPOINT.BOOKMARK_RETRIEVE_NEWSHEADLINE,
      requestBody,
      getState
    );

    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        dispatch(
          createAction(BOOKMARK.GET_BOOKMARK_NEWS_HEADLINE, {
            data: response.data,
          })
        );

        resolve(response);
      } else {
        reject(response);
      }
    });
  };
};
