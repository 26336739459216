import { AUTH } from '@constants/actions';
import { Map } from 'immutable';

const initialState = Map({
  validation: {
    valid: undefined,
  },
});

const Auth = (state = initialState, action) => {
  if (action.type === AUTH.VALIDATION.RESULT) {
    return state.setIn(['validation', 'valid'], action.payload.valid);
  }
  return state;
};

export default Auth;