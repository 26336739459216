import React from 'react';
import { FONT, PAGE } from '@constants/style';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, Typography } from '@mui/material';
import WidgetHeader from '../widget/widgetHeader.component';

const useStyles = makeStyles(() =>
  createStyles({
    widgetLoading: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: '100vh',
      alignItems: 'center',
      background: 'white',
    },
    loginText: {
      fontSize: '24px',
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      marginTop: '16px',
    },
  }),
);

interface IBlankPageProps {
  isWidget?: boolean,
  children?
}

const BlankPage = (props: IBlankPageProps) => {
  const { children, isWidget } = props;
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: PAGE.BLANK,
        maxWidth: '100%',
        minHeight: '100vh',
        padding: '0',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    >
      {isWidget ? 
      <div className={classes.widgetLoading}>
        <WidgetHeader />
        <Typography className={classes.loginText}>Logging you in</Typography>
        <CircularProgress style={{marginTop: '24px'}} size={"16px"}/>
      </div>
      : null}
      {children}
    </div>
  );
};

export default BlankPage;