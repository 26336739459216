import Easing from './easing.class';

let scrollSingleton: Scroll;

class Scroll {
  public scrollTopTranslate = scrollTop =>
    `translate3d(0, ${scrollTop}px, 0)`;

  /**
	 * Scroll to target Y in pixels using easing animation
	 */
  public scrollTo(
    target: number = 0, // Target scrolltop
    speed: number = 1000, // Speed in pixels per second
    onScroll: (scrollTop: number) => void = () => null,
    onComplete: (completeScrollTop: number) => void = () => null,
  ) {
    const maxDuration = 0.8; // seconds
    const minDuration = 0.1; // seconds

    const currentScrollTop = document.documentElement.scrollTop;
    const targetScrollTop = target;
    let currentTime = 0;

    // Calculate target duration bound by min and max
    const duration = Math.max(
      maxDuration,
      Math.min(
        Math.abs(currentScrollTop - targetScrollTop) / speed,
        minDuration,
      ),
    );

    const tick = () => {
      // Animation loop
      currentTime += 1 / 60;

      const percentageComplete = currentTime / duration;
      const easedPercentage = Easing.easeInOutQuart(percentageComplete);
      const frameScrollTop = Math.round(
        currentScrollTop +
					(targetScrollTop - currentScrollTop) * easedPercentage,
      );

      if (percentageComplete < 1) {
        requestAnimationFrame(tick);
        window.scrollTo(0, frameScrollTop);
        onScroll(frameScrollTop);
      } else {
        window.scrollTo(0, targetScrollTop);
        onComplete(targetScrollTop);
      }
    };

    tick(); // Begin animation
  }
}

const ScrollFactory = (): Scroll => {
  if (!scrollSingleton) {
    scrollSingleton = new Scroll();
  }
  return scrollSingleton;
};

export default ScrollFactory();
