import MetricWebBold from '@assets/fonts/MetricWeb-Bold.woff';
import MetricWebBoldItalic from '@assets/fonts/MetricWeb-BoldItalic.woff';
import MetricWebLight from '@assets/fonts/MetricWeb-Light.woff';
import MetricWebLightItalic from '@assets/fonts/MetricWeb-LightItalic.woff';
import MetricWebMedium from '@assets/fonts/MetricWeb-Medium.woff';
import MetricWebRegular from '@assets/fonts/MetricWeb-Regular.woff';
import MetricWebRegularItalic from '@assets/fonts/MetricWeb-RegularItalic.woff';
import MetricWebSemiBold from '@assets/fonts/MetricWeb-SemiBold.woff';
import { FONT } from '@constants/style';

const metricWeb = {
  bold: {
    fontFamily: 'MetricWeb',
    fontStyle: 'normal',
    fontWeight: FONT.WEIGHT.BOLD,
    src: `url(${MetricWebBold}) format('woff')`,
  },
  boldItalic: {
    fontFamily: 'MetricWeb',
    fontStyle: 'italic',
    fontWeight: FONT.WEIGHT.BOLD,
    src: `url(${MetricWebBoldItalic}) format('woff')`,
  },
  light: {
    fontFamily: 'MetricWeb',
    fontStyle: 'normal',
    fontWeight: FONT.WEIGHT.LIGHT,
    src: `url(${MetricWebLight}) format('woff')`,
  },
  lightItalic: {
    fontFamily: 'MetricWeb',
    fontStyle: 'italic',
    fontWeight: FONT.WEIGHT.LIGHT,
    src: `url(${MetricWebLightItalic}) format('woff')`,
  },
  medium:{
    fontFamily: 'MetricWeb',
    fontStyle: 'normal',
    fontWeight: FONT.WEIGHT.MEDIUM,
    src: `url(${MetricWebMedium}) format('woff')`,
  },
  regular: {
    fontFamily: 'MetricWeb',
    fontStyle: 'normal',
    fontWeight: FONT.WEIGHT.REGULAR,
    src: `url(${MetricWebRegular}) format('woff')`,
  },
  regularItalic: {
    fontFamily: 'MetricWeb',
    fontStyle: 'italic',
    fontWeight: FONT.WEIGHT.REGULAR,
    src: `url(${MetricWebRegularItalic}) format('woff')`,
  },
  semiBold: {
    fontFamily: 'MetricWeb',
    fontStyle: 'normal',
    fontWeight: FONT.WEIGHT.SEMIBOLD,
    src: `url(${MetricWebSemiBold}) format('woff')`,
  },
};

export default metricWeb;