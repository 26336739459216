import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { IOidcConfiguration } from '@models/oidcConfiguration';

const generateUserManagerConfiguration = (configuration: IOidcConfiguration) => {
  return {
    authority: configuration.authority,
    client_id: configuration.clientId,
    client_secret: configuration.clientSecret,
    redirect_uri: configuration.redirectUri,
    response_type: 'code',
    scope: configuration.scope,
    silent_redirect_uri: configuration.silentRedirectUri,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    monitorSession: false,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
};

const userManager: any = (configuration: IOidcConfiguration) => (typeof window !== 'undefined') ?
  createUserManager(generateUserManagerConfiguration(configuration)) :
  {};

export default userManager;
