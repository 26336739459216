import { PROGRESS } from '@constants/actions';
import createAction from './actionCreator';

export const increaseProgress = () => {
  return function (dispatch: any) {
    // this is thanks to redux-thunk.
    // Can do transformation or anything else before dispatch the action.
    dispatch(createAction(PROGRESS.INCREASE_PROGRESS));
  };
};

export const decreaseProgress = () => {
  return function (dispatch: any) {
    // this is thanks to redux-thunk.
    // Can do transformation or anything else before dispatch the action.
    dispatch(createAction(PROGRESS.DECREASE_PROGRESS));
  };
};
