import { BUSINESS_LINES } from '@constants/actions';
import { Map } from 'immutable';

const initialState = Map({
  name: {},
  tree: {},
  parent: {},
});

const BusinessLines = (state = initialState, action) => {
  if (action.type === BUSINESS_LINES.INIT) {
    return state
      .setIn(['name'], action.payload.name)
      .setIn(['tree'], action.payload.tree)
      .setIn(['parent'], action.payload.parent);
  }
  return state;
};

export default BusinessLines;
