import { ReactNode } from 'react';
import { IArticle } from './article';

export interface IBookmarkNews {
  id?: number;
  guid?: string;
  name?: string;
  description?: string;
  userGuid?: string;
  createdDate?: string;
  createBy?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  lastModifiedContent?: string;
  isDefault?: boolean;
  count?: string;
  searchTerm?: string;
  searchType?: string;
  contentListItems?: IContentListItem[];
}

export interface ILinkedRadars {
  radarGuid: string;
  radarName: string;
}

export interface ILinkRadarSearchCriterias {
  radarGuid: string;
  radarName: string;
}

export interface IBookmarkFilter {
  guid?: string;
  name?: string;
  userGuid?: string;
  createdDate?: string;
  createBy?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  searchTerm?: string;
  searchType?: string;
  description?: string | null;
  linkedRadarSearchCriterias?: ILinkRadarSearchCriterias[];
  isEnabledForEmail?: boolean;
}
export interface IRadarParams {
  isFromRadarSetting: boolean;
  isSelectedCompanyList: boolean;
  radarGuid?: string;
  isFromRadarInitial?: boolean;
}

export interface IBookmarkCompany {
  createdBy?: string;
  createdDate?: string;
  description?: string;
  guid?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name?: string;
  userGuid?: string;
  bookmarkCompanyListItems?: IBookmarkCompanyListItems[];
  exportRemainingCount?: number;
  linkedRadars?: ILinkedRadars[];
  isDefault?: boolean;
}

export interface IBookmarkCompanyListItems {
  entityGuid: string;
  entityName: string;
  entityType: string;
  guid: string;
}

export interface IContentListItem extends IArticle {
  contentListId: number;
  id: number;
  entityGuid: string;
  entityType: string;
  selected?: boolean;
}

export interface IBookmarkSnackBar {
  open: boolean;
  message: string;
  error?: boolean;
  action?: ReactNode;
  mini?: boolean;
  title?: ReactNode;
}

export type IContentListItemIds = {
  contentListId: number;
  entityGuid: string;
};

export type ICompanyListItemIds = {
  bookmarkCompanyListGuid: string;
  entityGuid: string;
};

export interface INewsEntities {
  entityGuid: string;
  entityType: 'ARTICLE' | 'COMPANY';
}

export type IAddNewsToBookmarkRequest = {
  contentListIds: number[];
  userGuid: string;
  entities: INewsEntities[];
};

export enum BookmarkCreateNew {
  RADAR = 'Radar',
  FILTER = 'Filter',
  List = 'List',
}

export enum BookmarkDialogError {
  RENAME = 'RENAME',
  TOP = 'TOP',
  SAVE_FILTER = 'SAVE_FILTER',
  QUICK_ACCESS = 'QUICK_ACCESS',
  MAXIMUM_RADAR_MONITOR = 'MAXIMUM_RADAR_MONITOR'
}

export interface IBookmarkForm {
  name: string;
  description: string;
}
