import { SCOUT_AI } from '@constants/actions';
import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
// immutable has many good use cases. Read more at: https://immutable-js.github.io/immutable-js/

const initialState = Map({
  selectedScout: {},
  scoutAIs: [],
  selectedScoutResult: [],
  totalCount: 0,
  articleUntrainedScouts: [],
  articleScoutAIs: [],
  trainingArticles: [],
  totalTrainingArticlesCount: 0,
  standardScoutAIs: [],
});

const ScoutAI = (state = initialState, action) => {
  switch (action.type) {
    case SCOUT_AI.SCOUT_AI_RESULT_RETRIEVE: {
      const { data, totalCount } = action.payload;
      return state.set('selectedScoutResult', data)
        .set('totalCount', totalCount);
    }
    case SCOUT_AI.SCOUT_AI_ARTICLE_UNTRAINED: {
      const untrainedScouts = action.payload.data;
      return state.set('articleUntrainedScouts', untrainedScouts);
    }
    case SCOUT_AI.SCOUT_AI_RETRIEVE: {
      const scoutAI = action.payload.data;
      return state.set('selectedScout', scoutAI);
    }
    case SCOUT_AI.SCOUT_AI_LIST: {
      const scoutAIs = action.payload.data;
      return state.set('scoutAIs', scoutAIs);
    }
    case SCOUT_AI.TRAINING_ARTICLE_LIST: {
      const trainingArticles = action.payload.data;
      const articleTotalCount = action.payload.totalCount;
      return state.set('trainingArticles', trainingArticles)
        .set('totalTrainingArticlesCount', articleTotalCount);
    }
    case SCOUT_AI.TRAINING_AI_UPDATE: {
      let list = state.get('scoutAIs') as any[];
      const { description, guid, name } = action.payload.scoutAIReq;
      const index = list.findIndex(item => item.guid === guid);
      if (index !== -1) {
        list = cloneDeep(list);
        list[index].name = name;
        list[index].description = description;
      }
      return state.setIn(['detail', 'name'], name)
        .setIn(['detail', 'description'], description)
        .set('scoutAIs', list);
    }
    case SCOUT_AI.SCOUT_AI_STANDARD_LIST: {
      const standardScoutAIs = action.payload.data;
      return state.set('standardScoutAIs', standardScoutAIs);
    }
    default:
      return state;
  }
};

export default ScoutAI;