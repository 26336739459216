export const SITE_URLS = {
  MAIN: '/',
  ERROR: '/error',
  SERVER_ERROR: '/servererror',
  LOGIN: '/login',
  LANDING: '/landing',
  FORGET_PASSWORD: '/forgetpassword',
  PROFILE: '/profile',
  LOGOUT_REDIRECT: '/logoutandredirect',
  ACCOUNT_REGISTRATION: '/accountregistration',
  ACCOUNT_ACTIVATION: '/accountactivate',
  PARTNER_USER_ACCOUNT_ACTIVATION: '/partneruseraccountactivate',

  COMPANY_DETAIL: '/companies',
  SEARCH_COMPANY: '/search/companies',
  CONNECTIONS_DETAIL: '/connections',
  CONNECTION_PLUS_TERMS: '/connection-plus-terms',

  SEARCH_ARTICLE: '/search/news',
  ARTICLE_DETAIL: '/articles',

  SEARCH_ALL: '/search/all',

  TRENDING_TOPIC_CLUSTER: '/trendingTopics',

  ALERT_SETTINGS: '/alerts',
  ALERT_SETTINGS_SCOUT_AI: '/alerts/scout-ai',
  EMAIL_ALERTS: '/alerts/email',

  SCOUT_AI_TRAINING: '/scouts-training',
  SCOUT_AI_CONTINUE_TRAINING: '/scouts-continue-training',
  SCOUT_AI_RESULT: '/scouts-result',
  SCOUT_AI_VISUALISATION: '/scouts-visualise',
  SCOUT_AI: '/scout-ai',

  TARGET_LIST: '/target-lists',
  SAVED_SEARCH: '/saved-searches',

  CMS_OVERVIEW: '/cms/overview',
  CMS_FAQ: '/cms/faq',
  COUNTRIES_OVERVIEW: '/countries',
  SECTORS_OVERVIEW: '/sectors',

  CAMPAIGN_REGISTER: '/campaign/register',
  CAMPAIGN_REQUEST: '/campaign/request',
  CAMPAIGN_UNSUBSCRIBE: '/campaign/unsubscribe',

  MAINTENANCE: '/maintenance',

  DELIVERY: '/delivery',

  RADAR: '/radar',
  RADAR_SETTINGS: '/radar-settings',
  NOTICE_OF_ASSIGNMENT: '/noticeOfAssignment',
  LIBRARY: '/library',
  SEARCH: '/search/',
  QUICK_SEARCH: '/search/:type',
  SEARCH_COMPANIES: '/search/companies',

  SAVED: '/saved',
};

export const ROUTE_PATHS = {
  MAIN: SITE_URLS.MAIN,
  LOGIN: SITE_URLS.LOGIN,
  LANDING: SITE_URLS.LANDING,
  CALLBACK: '/callback',
  SILENT: '/silent',
  ERROR: '/error/:errorCode?',
  SERVER_ERROR: SITE_URLS.SERVER_ERROR,
  LOGOUT_REDIRECT: SITE_URLS.LOGOUT_REDIRECT,
  ACCOUNT_REGISTRATION: SITE_URLS.ACCOUNT_REGISTRATION,
  ACCOUNT_ACTIVATION: `${SITE_URLS.ACCOUNT_ACTIVATION}/:userType`,
  PARTNER_USER_ACCOUNT_ACTIVATION: `${SITE_URLS.PARTNER_USER_ACCOUNT_ACTIVATION}/:userType`,

  CHANGE_PASSWORD: '/changepassword',
  FORGET_PASSWORD: '/forgetpassword',
  RESET_PASSWORD: '/resetpassword/:resetType/:resetKey',
  PROFILE: SITE_URLS.PROFILE,
  ACCEPT_ORG_LINK: '/orgacceptlink/:linkType/:linkKey',
  REQUEST_ACCESS: '/requestaccess/:requestType/:requestKey',

  SEARCH_COMPANY: `${SITE_URLS.SEARCH}companies`,
  COMPANY_DETAIL: `${SITE_URLS.COMPANY_DETAIL}/:companyGuid`,

  CONNECTION_DETAIL: `${SITE_URLS.CONNECTIONS_DETAIL}/:connectionGuid`,
  CONNECTION_PLUS_TERMS: `${SITE_URLS.CONNECTION_PLUS_TERMS}`,

  SEARCH_ARTICLE: `${SITE_URLS.SEARCH}news`,
  ARTICLE_DETAIL: `${SITE_URLS.ARTICLE_DETAIL}/:articleSid`,

  TRENDING_TOPIC_CLUSTER: `${SITE_URLS.TRENDING_TOPIC_CLUSTER}/:region/:cluster`,

  ALERT_SETTINGS: `${SITE_URLS.ALERT_SETTINGS}/:type`,
  EMAIL_ALERTS: SITE_URLS.EMAIL_ALERTS,

  SCOUT_AI_TRAINING: `${SITE_URLS.SCOUT_AI_TRAINING}/:scoutGuid`,
  SCOUT_AI_CONTINUE_TRAINING: `${SITE_URLS.SCOUT_AI_CONTINUE_TRAINING}/:scoutGuid`,
  SCOUT_AI_RESULT: `${SITE_URLS.SCOUT_AI_RESULT}/:scoutGuid`,
  SCOUT_AI_VISUALISATION: `${SITE_URLS.SCOUT_AI_VISUALISATION}/:scoutGuid`,

  TARGET_LIST: `${SITE_URLS.TARGET_LIST}/:targetListGuid?/:defaultTab?`,
  SAVED_SEARCH: `${SITE_URLS.SAVED_SEARCH}/:savedSearchGuid?`,

  CMS_OVERVIEW: SITE_URLS.CMS_OVERVIEW,
  CMS_FAQ: SITE_URLS.CMS_FAQ,
  COUNTRIES_OVERVIEW: `${SITE_URLS.COUNTRIES_OVERVIEW}/:countryCode`,
  SECTORS_OVERVIEW: `${SITE_URLS.SECTORS_OVERVIEW}/:sectorCode`,
  COUNTRIES_REGION_SECTORS: `${SITE_URLS.COUNTRIES_OVERVIEW}/:countryCode${SITE_URLS.SECTORS_OVERVIEW}/:sectorCode`,

  CAMPAIGN_REGISTER: `${SITE_URLS.CAMPAIGN_REGISTER}/:campaignGuid`,
  CAMPAIGN_REQUEST: `${SITE_URLS.CAMPAIGN_REQUEST}/:requestType/:requestKey`,
  CAMPAIGN_UNSUBSCRIBE: `${SITE_URLS.CAMPAIGN_UNSUBSCRIBE}/:campaignUserGuid/:campaignEventGuid`,
  CAMPAIGN_UNSUBSCRIBE_USER: `${SITE_URLS.CAMPAIGN_UNSUBSCRIBE}/:campaignUserGuid`,

  MAINTENANCE: SITE_URLS.MAINTENANCE,

  DELIVERY: SITE_URLS.DELIVERY,
  RADAR_LANDING: `${SITE_URLS.RADAR}`,
  RADAR_DETAIL: `${SITE_URLS.RADAR}/:radarGuid`,
  RADAR_SETTINGS: `${SITE_URLS.RADAR_SETTINGS}/:radarGuid`,

  NOTICE_OF_ASSIGNMENT: SITE_URLS.NOTICE_OF_ASSIGNMENT,

  LIBRARY_OVERVIEW: `${SITE_URLS.LIBRARY}/overview`,
  LIBRARY_SCOUT_AI: `${SITE_URLS.LIBRARY}/scout-ai`,
  QUICK_SEARCH: `${SITE_URLS.SEARCH}:type`,
  SEARCH_ALL: `${SITE_URLS.SEARCH}all`,

  SAVED: `${SITE_URLS.SAVED}`,
};

export const PAGE_TITLE = {
  ALERT_SETTINGS: 'Alert Settings',
  EMAIL_ALERTS: 'Email Alerts',
  RADAR: 'Radar',
  RADAR_SETTINGS: 'Radar Settings',
  SEARCH_COMPANY: 'Companies',
  COMPANY: 'Company',
  NOTICE_OF_ASSIGNMENT: 'Notice of Assignment',
  SEARCH_ARTICLE: 'News',
  ARTICLE: 'News',
  TRENDING_TOPICS: 'Trending Topics',
  LOGIN: 'Login',
  SCOUTASIA: 'scoutAsia',
  SCOUT_AI: 'Scout AI Topics',
  SCOUT_AI_TRAINING: 'Scout AI Training',
  SCOUT_AI_CONTINUE_TRAINING: 'Scout AI Continue Training',
  SCOUT_AI_RESULT: 'Scout AI Result',
  SCOUT_AI_VISUALISATION: 'Scout AI Visualisation',
  PROFILE: 'My Account',
  CAMPAIGN: 'Campaign',
  SAVED_SEARCHES: 'Saved Searches',
  TARGET_LIST: 'Targets',
  CMS_OVERVIEW: 'Macroeconomic Overview',
  CMS_FAQ: 'Frequently Asked Questions',
  CONNECTION: 'Connections',
  CONNECTION_PLUS_TERMS: 'Connection Plus Terms',
  ERROR: 'Error',
  MAINTENANCE: 'Planned maintenance',
  NOT_FOUND: 'Not Found',
  LIBRARY: 'Library',
  SEARCH: 'Search',
  SAVED: 'Saved',
};
