import React from 'react';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill-locales';
import { LOCALIZATION } from '@constants/localization';
import messagesEn from '@localisation/en.json';
import messagesJa from '@localisation/ja.json';

export type ILanguageProviderProps =  {
  children?: any,
};

const messages = {
  en: messagesEn,
  ja: messagesJa,
};

function LanguageProvider(props: ILanguageProviderProps) {
  const { children } = props;
  const locale = LOCALIZATION.DEFAULT_LOCALE;

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
}

export default LanguageProvider;
