import { ACTION } from '@constants/actions';
import { COMPANY_CONSTANT, EMPTY_STRING, ENTITY_TYPE_TEXT, KEYWORD_IN_DISPLAY_TYPE, RELATIONSHIP_SEARCH_SELECT } from '@constants/common';
import { constructFinancials, constructNumberEmployee, constructOwnership } from '@react/utils/common.util';
import { constructBusinessLines, constructCountries, constructRelationship, constructSectors } from '@redux/selectors/filter.selector';
import { isArray, isEmpty, isString } from 'lodash';
import { Options } from '@react/components/menu/relationshipSearch/menuRelationshipSearch.component';
import createAction from '../actionCreator';
import { updateSearchQueryData } from './filterBar.actions';

export const updateSelectedCompanySectors = (
  selectedSectors,
  updateQueryObject = false
) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.COMPANY.SECTOR.UPDATE_SELECTED, selectedSectors));
    const sectorInfo = constructSectors(selectedSectors, ENTITY_TYPE_TEXT.COMPANY, getState());
    dispatch(createAction(ACTION.FILTER.COMPANY.SECTOR.UPDATE_ADDITIONAL_INFO, sectorInfo));
  
    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedBusinessLines = (selectedBusinessLines, updateQueryObject = false) => {
  return (dispatch, getState) => {
    let selected = selectedBusinessLines;
    const formattedSelectedBusinessLines = selectedBusinessLines.map((businessLine) => businessLine.code);

    if (!isEmpty(formattedSelectedBusinessLines[0])) {
      selected = formattedSelectedBusinessLines;
    }

    dispatch(createAction(ACTION.FILTER.COMPANY.BUSINESS_LINES.UPDATE_SELECTED, selected));
    const businessLinesInfo = constructBusinessLines(selected, getState());
    dispatch(createAction(ACTION.FILTER.COMPANY.BUSINESS_LINES.UPDATE_ADDITIONAL_INFO, businessLinesInfo));
  
    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateCompanySelectedCountriesRegions = (selectedCountriesRegions, updateQueryObject = false) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.COMPANY.COUNTRIES_REGIONS.UPDATE_SELECTED, selectedCountriesRegions));
    const countriesRegionsAdditionalInfo = constructCountries(selectedCountriesRegions, getState());
    dispatch(createAction(ACTION.FILTER.COMPANY.COUNTRIES_REGIONS.UPDATE_ADDITIONAL_INFO, countriesRegionsAdditionalInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedFinancials = (selectedFinancials, updateQueryObject = false) => {
  return (dispatch, getState) => {
    const aggregateFinancial = getState().getIn(['Drawer', 'company', 'aggregate', 'financials']);
    dispatch(createAction(ACTION.FILTER.COMPANY.FINANCIALS.UPDATE_SELECTED, selectedFinancials));
    const financialsAdditionalInfo = constructFinancials(selectedFinancials, aggregateFinancial);
    dispatch(createAction(ACTION.FILTER.COMPANY.FINANCIALS.UPDATE_ADDITIONAL_INFO, financialsAdditionalInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedOwnership = (selectedOwnership, updateQueryObject = false) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.COMPANY.OWNERSHIP.UPDATE_SELECTED, selectedOwnership));
    const ownershipAdditionalInfo = constructOwnership(selectedOwnership, true);
    dispatch(createAction(ACTION.FILTER.COMPANY.OWNERSHIP.UPDATE_ADDITIONAL_INFO, ownershipAdditionalInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedEmployeeNum = (selectedNumOfEmployees, updateQueryObject = false) => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.COMPANY.NUM_OF_EMPLOYEES.UPDATE_SELECTED, selectedNumOfEmployees));
    const numOfEmployeesAdditionalInfo = constructNumberEmployee(selectedNumOfEmployees);
    dispatch(createAction(ACTION.FILTER.COMPANY.NUM_OF_EMPLOYEES.UPDATE_ADDITIONAL_INFO, numOfEmployeesAdditionalInfo));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

export const updateSelectedKeywordIn = (selectedKeywordIn, updateQueryObject = false) => {
  return (dispatch, getState) => {
    let selectedKeywordInObj = selectedKeywordIn;

    if (isEmpty(selectedKeywordIn)) {
      const keywordInNameCount = getState().getIn(['Drawer', 'company', 'aggregate', 'keywordIn', 'name']);
      selectedKeywordInObj = {
        value: COMPANY_CONSTANT.KEYWORD_IN.NAME,
        label: KEYWORD_IN_DISPLAY_TYPE.NAME,
        count: keywordInNameCount || 0
      };
    } else if (typeof(selectedKeywordIn) === 'string') {
      const keywordInCount = getState().getIn(['Drawer', 'company', 'aggregate', 'keywordIn', selectedKeywordIn]);
      selectedKeywordInObj = {
        value: COMPANY_CONSTANT.KEYWORD_IN[selectedKeywordIn.toUpperCase()],
        label: KEYWORD_IN_DISPLAY_TYPE[selectedKeywordIn.toUpperCase()],
        count: keywordInCount || 0
      };
    }

    dispatch(createAction(ACTION.FILTER.COMPANY.KEYWORD_IN.UPDATE_SELECTED, selectedKeywordInObj));
    dispatch(createAction(ACTION.FILTER.COMPANY.KEYWORD_IN.UPDATE_ADDITIONAL_INFO, selectedKeywordInObj.label));

    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};

type relationshipValue = string | string[] | { relationship: string, countriesRegions: Options[] };

export const updateRelationship = (value:relationshipValue, updateQueryObject = false, type? ) => {
  return (dispatch, getState) => {
    let data = { ...getState().getIn(['CompanyFilter', 'selectedRelationshipSearch']) };
    if (type === RELATIONSHIP_SEARCH_SELECT.SELECT_RELATIONSHIP) {
      data.relationship = value;
    }
    if (type === RELATIONSHIP_SEARCH_SELECT.SELECT_COUNTRY_REGION && isArray(value)) {
      data.countriesRegions = [...value];

      if (isEmpty(data.countriesRegions)) {
        data.relationship = EMPTY_STRING;
        type = EMPTY_STRING;
      }
    }
    // refresh after add filter 
    if (isEmpty(type) && !isArray(value) && !isString(value) ) {
      data = {
        ...value,
      };
    }
    dispatch(createAction(ACTION.FILTER.COMPANY.RELATIONSHIP.UPDATE_SELECTED, data));
    if ((!isEmpty(data.relationship) && !isEmpty(data.countriesRegions)) || isEmpty(type)) {
      const numOfRelationshipAdditionalInfo = constructRelationship(data, getState());
      dispatch(createAction(ACTION.FILTER.COMPANY.RELATIONSHIP.UPDATE_ADDITIONAL_INFO, numOfRelationshipAdditionalInfo));

      if (updateQueryObject) {
        dispatch(updateSearchQueryData()); 
      }
    }
  };
};


export const updateFinancialsAggregation = (aggregation) => {
  if (isEmpty(aggregation)) {
    return;
  }

  const {
    marketCapitalization,
    operatingRevenue,
    plBeforeTax,
    shareholdersFunds,
    totalAssets
  } = aggregation;

  const financialsAggregation = {
    marketCapitalization,
    operatingRevenue,
    plBeforeTax,
    shareholdersFunds,
    totalAssets
  };
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.COMPANY.FINANCIALS.UPDATE_AGGREGATION, financialsAggregation));
  };
};

export const resetCompanyFilters = () => {
  return (dispatch, getState) => {
    dispatch(createAction(ACTION.FILTER.COMPANY.RESET_STATES));
  };
};


export const setCompanyFilterItemStatus = (filterItemStatus: {}, updateQueryObject=false) => {
  return (dispatch, getState) => {
    if (updateQueryObject) {
      dispatch(updateSearchQueryData());
    }
  };
};
