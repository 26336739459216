import { ExtendedMenuSector } from '@models/sector';
import { countries } from 'skyeye-common-const/dist/reference/countries';
import { icbSectors } from 'skyeye-common-const/dist/reference/icbSectors';
import { newsScoutAI } from 'skyeye-common-const/dist/reference/newsScoutAI';
import { ExtendedMenuScoutAi } from '@models/scoutAi';
import { ExtendedMenuCountry } from '@models/country';
import { FinancialUnitType } from '@models/financialUnit';
import { AnnouncementType } from '@models/announcementType';

export const ANNOUNCEMENT = {
  TYPE: {
    MAP: {
      INFO: AnnouncementType.info,
      WARNING: AnnouncementType.warning,
    },
  },
};

export const CALENDAR = {
  WIDTH: 320,
  ROW_HEIGHT: 44,
  DEFAULT_FIRST_DATE: new Date(1970, 1, 1),
  DEFAULT_LAST_DATE: new Date(),
};

export const COOKIE = {
  CONNECTION_PLUS: {
    PATH: '/',
    SCOUT_TOKEN: 'scout-token2',
  },
  P225: {
    PATH: '/',
    P225_TOKEN: 'p225-token',
  },
};

export const COUNTRY_ALL: ExtendedMenuCountry = {
  uniqueIdentifier: 'All',
  displayName: 'Macro Overview',
  name: 'All',
  aliases: ['All'],
  alpha2: '',
  alpha3: 'ALL',
  countryCode: '-1',
  iso_3166_2: '',
  region: 'All',
  subRegion: 'All',
  regionCode: '',
  subRegionCode: '',
  concept: '',
  menuItem: true,
  visible: true,
  count: 0,
  aliasesLowered: ['all'],
};

export const DATE = {
  FORMAT: {
    GRAPH_TOOLTIP: 'ddd Do MMM YYYY',
    GRAPH_X_AXIS: 'DD MMM YYYY',
    FINANCIAL_DATA: 'DD MMM YYYY',
    COMPANY_NUMBER_EMPLOYEE: 'DD MMM YYYY',
    MONTH_YEAR: 'MMM YYYY',
    ARTICLE_DATE: 'D MMM YYYY',
  },
};

export const DRAWER_KEY_ARTICLE_SUFFIX = 'filter-menu-news-';
export const DRAWER_KEY_COMPANY_SUFFIX = 'filter-menu-';
export const DRAWER_KEY = {
  COMPANY: {
    KEYWORD_IN: `${DRAWER_KEY_COMPANY_SUFFIX}keywordIn`,
    BUSINESS_LINES: `${DRAWER_KEY_COMPANY_SUFFIX}business-lines`,
    SECTORS: `${DRAWER_KEY_COMPANY_SUFFIX}sectors`,
    COUNTRIES: `${DRAWER_KEY_COMPANY_SUFFIX}countries`,
    FINANCIALS: `${DRAWER_KEY_COMPANY_SUFFIX}finanicals`,
    OWNERSHIP: `${DRAWER_KEY_COMPANY_SUFFIX}ownership`,
    NUMBER_EMPLOYEES: `${DRAWER_KEY_COMPANY_SUFFIX}numberEmployees`,
    RELATIONSHIP_SEARCH: `${DRAWER_KEY_COMPANY_SUFFIX}relationship-ship`,
  },
  ARTICLE: {
    SECTORS: `${DRAWER_KEY_ARTICLE_SUFFIX}sectors`,
    SOURCES: `${DRAWER_KEY_ARTICLE_SUFFIX}source`,
    SCOUTAI: `${DRAWER_KEY_ARTICLE_SUFFIX}scoutAi`,
    SCOUTAI_TOPICS: `${DRAWER_KEY_ARTICLE_SUFFIX}scoutAiTopics`,
    PUBLICATION_DATE: `${DRAWER_KEY_ARTICLE_SUFFIX}publicationDate`,
    COMPANIES: `${DRAWER_KEY_ARTICLE_SUFFIX}companies`,
    COUNTRIES: `${DRAWER_KEY_ARTICLE_SUFFIX}countries`,
  },
};
export const DRAWER_FILTER_SCOUT_AI_ITEMS_PER_COLUMN = 10;

export const EXTERNAL_LINK = {
  SCOUT_ASIA: {
    COOKIES: 'https://www.scout.asia/cookie-policy/',
    TERM_CONDITION: 'https://www.scout.asia/terms-conditions/',
    PRIVACY: 'https://www.scout.asia/privacy-policy/',
  },
  FWP: 'https://fwpresearch.com/lp/',
  NIKKEI: ' https://www.nikkei.co.jp/nikkeiinfo/en',
  HANDSHAKES: 'https://www.handshakes.com.sg',
  INFORMATION_TRANSMISSION: 'https://www.nikkei.com/lounge/privacy/cookie/optout.html#nikkeiscoutasia'
};

export const FINANCIAL_UNIT = {
  SHORTHAND: {
    BILLION: 'B',
    MILLION: 'M',
    TRILLION: 'T',
  },
  mapUnitTypeToValues: {
    [FinancialUnitType.none]: 1,
    [FinancialUnitType.million]: 1000000,
    [FinancialUnitType.billion]: 1000000000,
    [FinancialUnitType.trillion]: 1000000000000,
  },
  getFinancialUnitTypeFromShorthand: (shorthand: string) => {
    switch (shorthand) {
      case FINANCIAL_UNIT.SHORTHAND.BILLION:
        return FinancialUnitType.billion;
      case FINANCIAL_UNIT.SHORTHAND.MILLION:
        return FinancialUnitType.million;
      case FINANCIAL_UNIT.SHORTHAND.TRILLION:
        return FinancialUnitType.trillion;
      default:
        return FinancialUnitType.none;
    }
  },
  getValueWithUnit(value: number, unit: FinancialUnitType) {
    return (value || 0) * FINANCIAL_UNIT.mapUnitTypeToValues[unit];
  },
};

export const CURRENCY = {
  USD: 'USD',
  VND: 'VND',
  INR: 'INR',
  JPY: 'JPY',
  KRW: 'KRW',
};

export const REPORT_CODE = {
  IND: 'IND',
  BANK: 'BANK',
  INS: 'INS',
  OTH: 'OTH',
};

export const KEYWORD_IN_DISPLAY_TYPE = {
  NAMEDESCRIPTION: 'Both name and description',
  DESCRIPTION: 'Description only',
  NAME: 'Name only',
};

export const KEYWORD_IN_COMPANY_IDENTIFIERS_ID = 'identifiers/id';

export const KEYWORD_IN_COMPANY_FIELDS_PARAMS = {
  NAMEDESCRIPTION: 'name,names/name,description,equities/tickerExchange,equities/tickerRegion, identifiers/id',
  DESCRIPTION: 'description',
  NAME: 'name,names/name,equities/tickerExchange,equities/tickerRegion, identifiers/id',
};

export const KEYWORD_IN_COMPANY_FIELDS_PARAMS_WITHOUT_IDENTIFIERS_ID = {
  NAMEDESCRIPTION: 'name,names/name,description,equities/tickerExchange,equities/tickerRegion',
  DESCRIPTION: 'description',
  NAME: 'name,names/name,equities/tickerExchange,equities/tickerRegion',
};

export const KEYWORD_IN_COMPANY_AGGREGATION_FIELDS_PARAMS = {
  NAMEDESCRIPTION: ['name', 'names/name', 'description', 'equities/tickerExchange', 'equities/tickerRegion', 'identifiers/id'],
  DESCRIPTION: ['description'],
  NAME: ['name', 'names/name', 'equities/tickerExchange', 'equities/tickerRegion', 'identifiers/id'],
};

export const KEYWORD_IN_COMPANY_AGGREGATION_SECONDARY_FIELDS_PARAMS = {
  NAMEDESCRIPTION: [['name', 'names/name', 'equities/tickerExchange', 'equities/tickerRegion'], ['description']],
  DESCRIPTION: [['name', 'names/name', 'equities/tickerExchange', 'equities/tickerRegion'], ['name', 'names/name', 'description', 'equities/tickerExchange', 'equities/tickerRegion']],
  NAME: [['name', 'names/name', 'description', 'equities/tickerExchange', 'equities/tickerRegion'], ['description']],
};

export const KEYWORD_IN_HIGHLIGHT_PARAMS = {
  NAMEDESCRIPTION: 'name,description,names/name',
  DESCRIPTION: 'description',
  NAME: 'name,names/name',
};

export const COMPANY_CONSTANT = {
  KEYWORD_IN: {
    NAMEDESCRIPTION: 'namedescription',
    NAME: 'name',
    DESCRIPTION: 'description',
  },
  LISTING: {
    STATUS: {
      LISTED: 'LISTED',
      UNLISTED: 'UNLISTED',
    },
    WORDING: {
      LISTED: 'Listed',
      UNLISTED: 'Private',
    },
  },
  NAME: {
    TYPE: {
      ALIAS: 'alias',
      CURRENT: 'current',
      LOCAL: 'local',
      TRADE: 'trade',
      PREVIOUS: 'previous',
      LEGAL: 'legal',
      SHORT: 'short',
    },
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    UNKNOWN: 'UNKNOWN',
    BVD: {
      ACTIVE: 'Active',
      BANKRUPTCY: 'Bankruptcy',
      DISSOLVED_BANKRUPTCY: 'Dissolved (bankruptcy)',
      DEFAULT_OF_PAYMENT: 'Active (default of payment)',
      DISSOLVED: 'Dissolved',
      DORMANT: 'Active (dormant)',
      IN_LIQUIDATION: 'In liquidation',
      INSOLVENCY_PROCEEDINGS: 'Active (insolvency proceedings)',
      DISSOLVED_LIQUIDATION: 'Dissolved (liquidation)',
      MERGER_OR_TAKEOVER: 'Dissolved (merger or take-over)',
      REORGANISATION: 'Active (reorganization)',
      UNKNOWN: 'Unknown',
    },
    FWP: {
      ACTIVE: 'Active',
      DISSOLVED: 'Dissolved',
      TERMINATED: 'Terminated',
      UNKNOWN: 'Unknown',
    },
    FACTSET: {
      ACTIVE: 'Active',
    },
    CREDITSAFE: {
      ACTIVE: 'Active',
    },
    FIINGROUP: {
      ACTIVE: 'Active',
      UNKNOWN: 'Unknown',
    },
    VENTUREINTELLIGENCE: {
      UNKNOWN: 'Unknown',
    },
    SCOUTASIA: {
      ACTIVE: 'Active',
    },
  },
  SOURCE: {
    FINANCIAL_TIMES: {
      LONG: 'Financial Times',
      SHORT: 'FT',
    },
    FACTSET: 'FACTSET',
    BVD: 'BVD',
    FIINGROUP: 'FIINGROUP',
    VI: 'VENTUREINTELLIGENCE',
    CREDITSAFE: 'CREDITSAFE',
    FWP: 'FWP',
    SCOUTASIA: 'SCOUTASIA',
  },
};

export const CONTACT_TYPE = {
  WEBSITE: 'WEBSITE',
  EMAIL: 'EMAIL',
};

export const AGREEMENT = {
  AGREE: 'AGREE',
  DISAGREE: 'DISAGREE',
};

export const LOCATION_STATE = {
  COMPANY_TAB: 'companyTab',
  EDIT_SC_SUCCESS: 'editSCSuccess',
  FROM_RADAR: 'fromRadar',
  INIT_RADAR_GUID: 'initRadarGuid',
  RADAR_INIT: 'radarInit',
  RADAR_TAB: 'radarTab',
  SAVE_RADAR_TYPE: 'saveRadarType',
  SEARCH_CRITERIA_TO_EDIT: 'searchCriteriaToEdit',
  SYNC_TO_RADAR_TYPE: 'syncToRadarType',
};

export const LOCAL_STORAGE = {
  ANNOUNCEMENT: 'sa-announcement',

  /**
   * Indicate connections accept term of use
   * `1` (true) indicate accept
   * `0` (false) indicate not accept (default)
   */
  CONNEX_ACCEPT_TERM_OF_USE: 'sa-connex-term-of-use',

  CONNEX_BUTTON_NUMBER_CLICKS: 'sa-btn-to-connex-num-clicks',

  CONNEX_TRY_FEATURE_NOW: 'sa-connex-try-feature-now',

  /**
   * Indicate disable connections plus modal
   * `1` (true) indicate do not show again,
   * `0` (false) indicate show again (default)
   */
  CONNEX_MODAL_DO_NOT_SHOW_AGAIN: 'sa-modal-connex-dnsa-flag',

  FINANCIAL_UNITS: 'sa-financial-units',

  /**
   * `0` or `1`
   * where `0` is company
   * and `1` is news
   */
  LEFT_DRAWER_TAB_SELECTED: 'sa-left-drawer-tab-selected',

  // search box tab
  MOST_RECENT_SEARCHES: 'most-recent-searches',

  RECENT_SEARCHES: 'recent-searches',

  LOGIN_RETRY_COUNT: 'login-retry-count',

  REDIRECT_URL: 'sa-redirect-url',

  LAST_SEARCH: 'sa-last-search',

  SCOUT_AI_CACHE: 'sa-scout-ai-cache',

  RETURN_URL: 'delivery-return-url',

  WIDGET_DATA: 'sa-widget-data',

  AGREE_IFRAME_USES_COOKIES: 'agree-iframe-uses-cookies',

  DEFAULT_LANGUAGE: 'default-language',

  TRENDING_TOPICS: 'trending-topic',

  ONBOARDING: 'onboarding',

  SEARCH_CRITERIA: 'search-criteria',

  SEARCH_TAB_SELECTION: 'sa-search-tab-selection',

  LIST_VIEW: 'list-view',
  WIDGET_LIST_VIEW: 'widget-list-view',

  HIDE_EDIT_FILTER_WARNING_MODAL: 'HIDE_EDIT_FILTER_WARNING_MODAL',

  FIRST_SET_LANGUAGE: 'first-set-language'
};

export const ONBOARDING_TYPE = {
  GOAL: 'goal',
  OTHERGOAL: 'other goal',
  COUNTRY: 'country',
  SECTOR: 'sector',
};

export const ONBOARDING_ONE_COUNTRY_SELECTED =
  'onboarding-one-country-selected';

export const CONNEX = {
  BUTTON_MAX_NUMBER_CLICKS: 3,
  URL_VIDEO_DEMO: process.env.CONNECTS_DEMO_VIDEO,
};

export const NUMBER_EMPLOYEE = {
  MIN: 1,
  MAX: Number.MAX_SAFE_INTEGER,
};

export const REG_EXP = {
  FINANCIAL_UNIT_WITH_NUMERIC: /^[0-9nNbBmMtT\.]$/g,
  FINANCIAL_UNIT: /^[nNbBmMtT]$/g,
  NUMBER_EMPLOYEE: /^[0-9\b]+$/g,
};

export const SEARCH_RESULT_TYPES = {
  NEWS: 'news',
  COMPANIES: 'companies',
  TRENDING_NEWS: 'trending_news',
};

export enum ENTITY_TYPE {
  ARTICLE = 'article',
  COMPANY = 'company',
}

export enum QUICK_SEARCH_TYPE {
  ARTICLE = 'article',
  COMPANY = 'company',
  BUSINESS_LINES = 'businessLines',
  SCOUT_AIS = 'scoutAIs',
}

export enum ENTITY_TYPE_TEXT {
  ARTICLE = 'ARTICLE',
  COMPANY = 'COMPANY',
  ALL = 'ALL',
}

export const SAVED_SEARCH_TYPE = {
  ARTICLE: 'ARTICLE',
  COMPANY: 'COMPANY',
};

export function getAllCountries(): Array<ExtendedMenuCountry> {
  return countries.map((country) => {
    return {
      ...country,
      uniqueIdentifier: country.alpha3,
      displayName: country.name,
    };
  });
}

export function getMenuCountries(
  allCountries: Array<ExtendedMenuCountry>
): Array<ExtendedMenuCountry> {
  return allCountries
    .filter((country) => country.menuItem)
    .sort((aCountry, bCountry) => aCountry.name.localeCompare(bCountry.name));
}

export function getExtendedMenuSectors(
  display: ENTITY_TYPE_TEXT
): Array<ExtendedMenuSector> {
  return icbSectors.filter((s) => {
    switch (display) {
      case ENTITY_TYPE_TEXT.ARTICLE:
      case ENTITY_TYPE_TEXT.COMPANY: {
        return s.display.includes(display);
      }
      case ENTITY_TYPE_TEXT.ALL: {
        return s.display.length >= 1;
      }
      default: {
        return false;
      }
    }
  });
}

export function getScoutAiFromJson() {
  return newsScoutAI;
}

export const MINIMUM_ARTICLES_DATE = '2001-01-01T00:00:00Z';

export const SCOUTAI_ALL: ExtendedMenuScoutAi = {
  uniqueIdentifier: '-1',
  displayName: 'All Scout AI',
  guid: '-1',
  name: 'All',
  description: '',
  scoutType: 'STANDARD',
  standard: true,
  isPublic: true,
  status: '',
  deletedStatus: 0,
};

export const SCOUT_AI_STATUS = {
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  GRADUATE: 'GRADUATE',
};

export const SCOUT_AI_RELEVANCE = {
  RELEVANT: 1,
  NON_RELEVANT: -1,
  NEUTRAL: 0,
};

export const SECTOR = {
  /**
   * Referenced from `skyeye-common-const` [Sector]
   * where level 1 is the primary sector group
   * and contain several sub sectors with level > 1
   */
  LEVEL_1: 1,
};

export const SOURCE = {
  LEVEL_1: 1,
};

export const SITE = {
  TITLE: 'scoutAsia',
  ERROR: 'Error',
};

export const TAB_COMPANY_NEWS = {
  COMPANY: {
    KEY: 'COMPANY',
    POSITION: 0,
  },
  GROUP: {
    KEY: 'GROUP',
    POSITION: 1,
  },
  SECTOR_REGION: {
    KEY: 'SECTOR_REGION',
    POSITION: 2,
  },
  REGION: {
    KEY: 'REGION',
    POSITION: 3,
  },
};

export const TARGET_LIST = {
  MENTIONS: {
    TYPE: {
      PROVIDED: 'provided',
      RELATED: 'related',
    },
  },
};

export const EMPTY_STRING = '';
export const UNDEFINED_YEAR_FOUNDED = 'SCOUTASIA_SOURCE_UNDEFINED';

export const ZERO = 0;
export const HUNDRED = 100;
export const DASH = '-';
export const MORE_THAN_TEN = '10+';
export const UNKNOWN = 'Unknown';
export const LOCKED = 'LOCKED';
export const NULL_STRING = 'NULL';

export const RESULT_PER_PAGE = 20;
export const ARTICLE_PER_TAB = 10;
export const COMPANY_RESULT_PER_PAGE = 50;

export const MAX_EXPORT_LIMIT = 500;
export const BASE_EXPORT_CREDIT = 20;

export const RADAR_SHOWING_LIMIT = 12;
export const RADAR_SEARCH_LIMIT = 3;
export const RADAR_SEARCH_UPPER_LIMIT = 20;
export const RADAR_MATCHED_COMPANY_LIMIT = 1;
export const RADAR_NAME_LENGTH = 100;
export const RADAR_DESC_LENGTH = 255;
export const RADAR_STARRED_COMPANY_LIMIT = 500;

export const BOOKMARK_NAME_LENGTH = 100;
export const BOOKMARK_DESC_LENGTH = 255;

export const ONBOARDING_COMPANY_COUNT = 50;

export const TARGET_LIST_COMPANY_LIMIT = 100;

export const MAX_TOTAL_TRAINED_ARTICLE = 20;

export const VIEW_MORE_COUNT_DEFAULT = 2;

export const ARTICLE_DATE_FORMAT = 'DD MMM YYYY';
export const ARTICLE_PUBLISHER = {
  SGX: 'SGX',
  HKEX: 'HKEX',
  EMIS: 'EMIS',
  ARAI:'ARAI',
  NAVIGA: 'Naviga',
  BUSINESS_WIRE: 'BusinessWire',
  BUSINESS_TIMES: 'TheBusinessTimes',
  STRAITS_TIMES: 'TheStraitsTimes',
  THE_NIKKEI: 'TheNikkei',
  NIKKEI_XTECH: 'NikkeiXTECH',
  SCRIPTS_ASIA: 'ScriptsAsia',
  FINANCIAL_TIMES: 'FinancialTimes',
  SOUTH_CHINA_MORNING_POST: 'SouthChinaMorningPost',
  VN_EXPRESS_NET: 'Vnexpress.Net',
  Nikkei_Business_Trend: 'NikkeiBusinessTrend',
  Nikkei_Financial_Summary: 'NikkeiFinancialSummary',
  AUSTRALIAN_FINANCIAL_REVIEW: 'AustralianFinancialReview',
  ASHU_RESEARCH_AAEAN_ECONOMIC_NEWS: 'AshuResearchAseanEconomicNews',
  EXPERT_CONNECT_ASIA: 'ExpertConnectAsia',
  VIETNAM_SECTOR_REPORT: 'GLiN',
};

export const TRANSLATION_PUBLISHER_JAPANESE = [
  ARTICLE_PUBLISHER.THE_NIKKEI,
  ARTICLE_PUBLISHER.NIKKEI_XTECH,
  ARTICLE_PUBLISHER.Nikkei_Business_Trend,
  ARTICLE_PUBLISHER.Nikkei_Financial_Summary,
  ARTICLE_PUBLISHER.ASHU_RESEARCH_AAEAN_ECONOMIC_NEWS,
];

export const NIKKEI_PUBLISHERS = [
  ARTICLE_PUBLISHER.THE_NIKKEI,
  ARTICLE_PUBLISHER.NIKKEI_XTECH,
  ARTICLE_PUBLISHER.ASHU_RESEARCH_AAEAN_ECONOMIC_NEWS,
];

export const TRANSLATION_PUBLISHER_Vietnamese = [
  ARTICLE_PUBLISHER.VN_EXPRESS_NET,
];

/* The publishers who have translation function */
export const TRANSLATION_PUBLISHER = [
  ...TRANSLATION_PUBLISHER_JAPANESE,
  ARTICLE_PUBLISHER.VN_EXPRESS_NET,
];

export const SHOW_TRANSLATION_PUBLISHER = [
  ARTICLE_PUBLISHER.Nikkei_Business_Trend,
  ARTICLE_PUBLISHER.Nikkei_Financial_Summary
];

export const SHOW_TRANSLATION_ICON_PUBLISHER = [...SHOW_TRANSLATION_PUBLISHER, ...TRANSLATION_PUBLISHER_Vietnamese];

export const RENAME_PUBLISHER_LIST_ON_MENU = [
  'AutoEconomictimes',
  'HKEX',
  'GovHK',
  'TourismAuthorityofThailandNewsroom',
];

export const SCOUTASIA_SOURCE_UNDEFINED = 'SCOUTASIA_SOURCE_UNDEFINED';
export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+]*)/gi;
export const GUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const SCOUT_AI_TYPE = {
  CUSTOM: 'CUSTOM',
  STANDARD: 'STANDARD',
};

export const SCOUT_AI_LABEL = {
  AI: 'AI',
  TAAS: 'TAAS',
  USER: 'USER',
};

export const RESET_PASSWORD_TYPE = {
  createPublicUser: 'PUBLIC_USER_ACCOUNT_CREATE',
  createInternalUser: 'INTERNAL_USER_ACCOUNT_CREATE',
  publicForgetPassword: 'PUBLIC_USER_ACCOUNT_FORGET_PASSWORD',
  internalForgetPassword: 'INTERNAL_USER_ACCOUNT_FORGET_PASSWORD',
  createSalesPublicUser: 'PUBLIC_USER_ACCOUNT_CREATE_SALES',
};

export const URL_VALUE = {
  ALL: 'all',
};

export const OIDC = {
  CONFIGURATION: {
    HTML_ID: 'spartans',
  },
  REDIRECT_URI: '/callback',
  SILENT_REDIRECT_URI: '/silent',
};

export const FILTER_EMPTY_DATA = {
  fromValue: null,
  toValue: null,
  maxValue: null,
  minValue: null,
};

export const FILTER_FINANCIAL_EMPTY_DATA = () => ({
  ...FILTER_EMPTY_DATA,
  fromUnit: FinancialUnitType.none,
  toUnit: FinancialUnitType.none,
});

export enum ACCOUNT_TYPE {
  TRIAL = 'TRIAL',
}

export const FEATURE_CODE = {
  HIDE_ARTICLE_TOOLBAR_COUNTRY: 'HIDE_ARTICLE_TOOLBAR_COUNTRY',
  HIDE_ARTICLE_TOOLBAR_SECTOR: 'HIDE_ARTICLE_TOOLBAR_SECTOR',
  HIDE_ARTICLE_TOOLBAR_RIGHTTABS: 'HIDE_ARTICLE_TOOLBAR_RIGHTTABS',
  HIDE_ARTICLE_TOOLBAR_AVATAR: 'HIDE_ARTICLE_TOOLBAR_AVATAR',
  HIDE_ARTICLE_SEARCHDRAWER: 'HIDE_ARTICLE_SEARCHDRAWER',
  HIDE_ARTICLE_HIGHLIGHTS: 'HIDE_ARTICLE_HIGHLIGHTS',
  LIBRARY: 'LIBRARY',
  RADAR_COUNT: 'RADAR_COUNT',
  RADAR: 'RADAR',
  SEARCH_TEXTBOX: 'SEARCH_TEXTBOX',
  COMPANIES: 'COMPANIES',
  NEWS: 'NEWS',
  DISABLE_ARTICLE_MENTION: 'DISABLE_ARTICLE_MENTION',
  SHOW_ARTICLE_TRANSLATE: 'SHOW_ARTICLE_TRANSLATE',
  SHOW_ARTICLE_READMORE: 'SHOW_ARTICLE_READMORE',
  SHOW_ARTICLE_LEGAL_DISCLAIMER: 'SHOW_ARTICLE_LEGAL_DISCLAIMER',
  BOOKMARK: 'BOOKMARK',
  RADAR_ARTICLE_SEARCH_CRITERIA_COUNT: 'RADAR_ARTICLE_SEARCH_CRITERIA_COUNT',
};

export const STARTER_225_SAVEDSEARCH_CATEGORY = {
  COAL: 'Coal',
  DIVIDEND_INDEX: 'Dividend Index',
  ELECTRICITY: 'Electricity',
  EQUITIES: 'Equities',
  EQUITY_INDEX: 'Equity Index',
  FREIGHT: 'Freight',
  FX: 'FX',
  INTEREST_RATE: 'Interest Rate',
  IRON_ORE: 'Iron Ore',
  OIL: 'Oil',
  PETROCHEMICALS: 'Petrochemicals',
  RUBBER: 'Rubber',
};

export const UI_ELEMENT_GROUP = {
  LATEST_NEWS: 'latest_news',
  NEWS_SEARCH_SIDEBAR: 'newssearch_sidebar',
  NEWS_SEARCH_RESULT: 'newssearch_result',
  COMPANY_SEARCH_SIDEBAR: 'companysearch_sidebar',
  NAVBAR: 'navbar',
  COUNTRY_SECTOR_FILTER: 'countrysector_filter',
  ARTICLE_METADATAPANEL: 'article_metadatapanel',
  NEWS_FEED: 'news_feed',
  MY_ALERTS: 'my_alerts',
  TRENDING_NEWS: 'trending_news',
  LANGUAGE_SWITCHER: 'language_switcher',
  RELATED_COMPANIES: 'related_companies',
  COMPANY_SEARCH_RESULT: 'companysearch_result',
  ARTICLE_CONTENT: 'article_content',
  ARTICLE_DETAILS: 'article_details',
  COMPANY_DETAILS: 'company_details',
  ARTICLE: 'article',
  HOMEPAGE_LANGUAGE: 'homepage_language',
  NEWSSEARCH_RESULT: 'newssearch_result',
  // trending-topic
  TRENDING_TOPICS_WIDGET: 'trending_topics_widget',
  TT_CLUSTER_PAGE: 'tt_cluster_page',
  LIBRARY_OVERVIEW: 'library_overview',
  // onboarding
  ONBOARDING_MODAL: 'onboarding_modal',
  COMPANY: 'company',
  ONBOARDING_USER_INPUT: 'onboarding_user_input',
  // radar
  RADAR_SETTINGS: 'radar_settings',
  RADAR_INDIVIDUAL: 'radar_individual',
  RADAR_CREATION: 'radar_creation',
  RADAR_INIT: 'radar_init',
  NEWS_SEARCH_SAVED_LIST: 'newssearch_savedlist',
  RADAR_LANDING: 'radar_landing',
  RADAR_SA_HOME: 'radar_sa_home',
  RADAR_ALERTS: 'radar_alerts',
  // Daily Headlines
  DAILY_HEADLINES_ALERTS: 'daily_headline_alerts',
  // quick search
  QUICK_SEARCH_BAR: 'quicksearch_bar',
  QUICK_SEARCH_BAR_HOMEPAGE: 'quicksearch_bar_homepage',
  // filter news scout AI
  NEWSSEARCH_SIDEBAR: 'newssearch_sidebar',
  SEARCH: 'search',
  // bookmark
  SEARCH_RESULT_PAGE: 'search_result_page',
  HOMEPAGE_ALLNEWS: 'homepage_allnews',
  COMPANY_DETAIL_PAGE: 'company_detail_page',
  HOMEPAGE_MYNEWS: 'homepage_mynews',
  REGIONSECTOR_PAGE: 'regionsector_page',
  BM_CONTENTLIST_PAGE: 'bm_contentlist_page',
  SAVED_ITEMS: 'saveditems',
  SAVEDITEMS_NEWSLISTS: 'saveditems_newslists',
  TARGET_LISTS: 'targetlists',
  ARTICLE_PAGE: 'article_page',
  RECOMMENDED_ARTICLES_SECTION: 'recommended_articles_section',
  YOU_MIGHT_ALSO_LIKE_SECTION: 'you_may_also_like_section',
  COMPANY_PAGE: 'company_page',
  CONNECTIONS: 'connections',
  COMPANY_PAGE_COMPANY_TOGGLE: 'company_page_company_toggle',
  COMPANY_PAGE_SECTOR_REGION_TOGGLE: 'company_page_sector_region_toggle',
  COMPANY_PAGE_REGION_TOGGLE: 'company_page_region_toggle',
  CUSTOMSCOUT_TRAIN: 'customscout_train',
  CUSTOMSCOUT_ARTICLEWINDOW: 'customscout_articlewindow',
  CUSTOMSCOUT_ARTICLEWINDOW_RA: 'customscout_articlewindow_ra',
  // homepage
  HOMEPAGE_ALLNES_MYNEWS: 'homepage_allnews_mynews',
  COUNTRY_SECTOR_HOMEPAGE: 'country_sector_homepage',
  SCOUTAI_HOMEPAGE: 'scoutai_homepage',
  // relationship
  COMPANY_OS: 'company_os',
  TARGETLISTS: 'targetlists',

  SECTOR_REPORT_HOMEPAGE: 'sector_report_homepage',
  LATEST_NEWS_HOMEPAGE: 'latest_news_homepage',
  LATEST_REPORT_HOMEPAGE: 'latest_report_homepage',
  RADAR_HOMEPAGE: 'radar_homepage',
  // Quick_Access
  QUICK_ACCESS_HOMEPAGE: 'quick_access_homepage',
  // Widget
  REGISTER_WIDGET: 'register_widget',
  NEWS_WIDGET: 'news_widget',
  STATISTIC_WIDGET: 'statistics_widget',
  COMPANY_HOMEPAGE: 'company_homepage',
  // NEWS_SAVED_ITEM_HOMEPAGE
  NEWS_SAVED_ITEM_HOMEPAGE: 'news_saved_item_homepage'
};

export const UI_ELEMENT = {
  KEYWORD_FILTER: 'keyword_filter',
  LATEST_NEWS_ARTICLE: 'latest_news_article',
  LATEST_NEWS_COMPANY: 'latest_news_company',
  LATEST_NEWS_SCOUTAI: 'latest_news_scoutai',
  LATEST_REPORT: 'latest_report',
  NEW_SEARCH_RESULTS: 'newssearch_results_article',
  NEW_SEARCH_RESULTS_SCOUTAI: 'newssearch_results_scoutai',
  NEW_SEARCH_RESULTS_COMPANY: 'newssearch_results_company',
  COMPANY_SEARCH_RESULTS_COMPANY: 'companysearch_results_company',
  SECTOR_FILTER: 'sector_filter',
  SECTOR_DROPDOWN: 'sector_dropdown',
  SECTOR_PANEL: 'sector_panel',
  NEWS_FEED: 'news_feed',
  MY_ALERTS: 'my_alerts',
  RELATED_COMPANIES: 'related_companies',
  LANGUAGE_SWITCHER: 'language_switcher',
  NAVBAR: 'navbar',
  NAVBAR_LOGO: 'navbar_logo',
  NAVBAR_NEWS: 'navbar_news',
  NAVBAR_COMPANIES: 'navbar_companies',
  NAVBAR_REPORTS: 'navbar_reports',
  NAVBAR_MYCONTENT: 'navbar_mycontent',
  NAVBAR_ACCOUNT: 'navbar_account',
  NAVBAR_MACRO: 'navbar_macro',
  TRENDING_NEWS: 'trending_news',
  ALERT_TARGETLIST: 'alerts_targetlists',
  ALERT_NEWSSEARCH: 'alerts_newssearch',
  ALERT_SAVEDSEARCH: 'alerts_savedsearch',
  ALERT_COMPANYSEARCH: 'alerts_companysearch',
  ALERT_SCOUTAI: 'alerts_scoutai',
  BUSINESS_LINES_FILTER: 'bizlines_filter',
  RESULTS_HEADER: 'results_header',
  ARTICLE_INFO: 'article_info',
  ARTICLE_BODY: 'article_body',
  ARTICLE_SCOUTAI: 'article_scoutai',
  ARTICLE_RECOMMENDED: 'article_recommended',
  REGIONSECTOR_ARTICLE: 'regionsector_article',
  COMPANY_ARTICLE: 'company_article',
  RECOMMENDED_ARTICLES_ARTICLE: 'recommended_articles_article',
  YOU_MIGHT_ALSO_LIKE_SECTION_ARTICLE: 'you_may_also_like_section_article',
  CUSTOMSCOUT_TRAIN_ARTICLE: 'customscout_train_article',
  TARGETLISTS_ARTICLE: 'targetlists_article',
  LANGUAGE_SWITCH: 'language_switch',
  ARTICLE_LINK: 'article_link',
  // trending-topic
  REGION_SELECTOR: 'region_selector',
  TT_CLUSTER_CARD: 'tt_cluster_card',
  TRENDING_CLUSTER: 'trending_cluster',
  COUNTRIESREGIONS: 'countriesregions',
  COMPANIES: 'companies',
  NEWS: 'news',
  REPORTS: 'reports',
  // onboarding
  USERGOAL: 'usergoal',
  REGIONS: 'regions',
  SECTORS: 'sectors',
  PASSWORD: 'password',
  SUMMARY: 'summary',
  COMPANY_EXPORT: 'company_export',
  ROLE_SURVEY: 'role_survey',
  // radar
  NEWS_SEARCHFEED: 'news_searchfeed',
  COMPANIES_SEARCHFEED: 'companies_searchfeed',
  NEWS_INDIVIDUAL: 'news_individual',
  COMPANIES_INDIVIDUAL: 'companies_individual',
  NEWSARTICLE_CARD: 'newsarticle_card',
  NEWSWIDGET_MOSTMENTIONES: 'newswidget_mostmentioned',
  NEWS_DROPDOWN: 'news_dropdown',
  NEWS_MATCHEDCOMPANIES: 'news_matchedcompanies',
  NEWS_STARREDCOMPANIES: 'news_starredcompanies',
  MATCHEDCOMPANIES_SECTION: 'matchedcompanies_section',
  STARREDCOMPANIES_SECTION: 'starredcompanies_section',
  STARREDCOMPANIES_EMPTYSTATE: 'starredcompanies_emptystate',
  MATCHEDCOMPANIES_EMPTYSTATE: 'matchedcompanies_emptystate',
  NEWS_EMPTYSTATE: 'news_emptystate',
  NEWSFEED: 'newsfeed',
  SECTIONTABS: 'sectiontabs',
  ADD_INPUT: 'add_input',
  SETTINGS: 'settings',
  RADAR_INPUT_TYPE: 'radar_inputtype',
  RADAR_SAVE: 'radar_save',
  RADAR_CARD: 'radar_card',
  RADAR_LANDING_PAGE: 'radar_landing_page',
  STARREDCOMPANIES_CARD: 'starredcompanies_card',
  MATCHEDCOMPANIES_CARD: 'matchedcompanies_card',
  MATCHEDCOMPANIES_WIDGET_OFTENMENTIONED:
    'matchedcompanies_widget_oftenmentioned',
  STARREDCOMPANIES_WIDGET_OFTENMENTIONED:
    'starredcompanies_widget_oftenmentioned',
  MATCHEDCOMPANIES_RUNSEARCH: 'matchedcompanies_runsearch',
  COMPANIES_SECTION: 'companies_section',
  EMIS_REPORT: 'emis_report',
  ASHU_REPORT: 'ashu_report',
  EMIS_FEATURED: 'emis_featured',
  ASHU_FEATURED: 'ashu_featured',
  EXPERTCONNECTASIA_FEATURED:'expertconnectasia_featured',
  EMIS_ALLREPORTS: 'emis_allreports',
  ASHU_ALLREPORTS: 'ashu_allreports',
  GLIN_FEATURED: 'vietnamsector_featured',
  GLIN_ALLREPORT: 'vietnamsector_allreports',
  EXPERTCONNECTASIA_ALLREPORTS:'expertconnectasia_allreports',
  EXPERTCONNECTASIA_REPORT: 'expertconnectasia_report',
  GLIN_REPORT: 'vietnamsector_report',
  EMAIL_ALERTS: 'email_alerts',
  EMAIL_ALERTS_COMPANY_LIST: 'email_alerts_company_list',
  EMAIL_ALERTS_NEWS_SEARCH: 'email_alerts_news_search',
  EMAIL_ALERTS_COMPANY_SEARCH: 'email_alerts_company_search',
  EMAIL_ALERTS_MODAL: 'email_alerts_modal',
  EMPTYSTATE: 'emptystate',
  QUICK_ACCESS: 'quick_access',
  // radar-setting
  COMPANYLISTS_CARD: 'companylists_card',
  NEWSFILTERS_CARD: 'newsfilters_card',
  COMPANYFILTERS_CARD: 'companyfilters_card',

  // quick search
  QUICK_SEARCH_BAR: 'quicksearch_bar',
  TOGGLE_SEARCH_DROPDOWN: 'toggle_search_dropdown',
  TOGGLE_SEARCH_TAB: 'toggle_search_tab',
  COMPANY_SCREENER: 'company_screener',
  NEWS_SCREENER: 'news_screener',
  BIZLINES_SCREENER: 'bizlines_screener',
  SCOUTAI_SCREENER: 'scoutai_screener',

  // bookmark
  BOOKMARK_SYMBOL: 'bookmark_symbol',
  DUAL_CONTENT_CARD: 'dual_content_card',
  DUAL_CONTENT_LIST: 'dual_content_list',
  SAVED_ITEMS: 'saveditems',
  SAVEDITEMS_NEWSLISTS: 'saveditems_newslists',
  SAVEDITEMS_COMPANYLISTS: 'saveditems_companylists',
  CREATE_NEW: 'create_new',
  SELECT_NEWSLIST: 'select_newslist',
  VISUAL_DIVIDERS: 'visual_dividers',
  DUAL_CONTENT_SWITCHER: 'dual_content_switcher',
  MANAGE_NEWSLIST: 'manage_newslist',
  MODAL_CHANGE_NEWSLIST: 'modal_change_newslist',
  LISTVIEW_MOVE: 'listview_move',
  LISTVIEW_DUPLICATE: 'listview_duplicate',
  LISTVIEW_REMOVECONTENT: 'listview_removecontent',
  MANAGE_LIST_ITEM: 'manage_list_item',
  LIST_ITEM_MOVE: 'list_item_move',
  LIST_ITEM_DUPLICATE: 'list_item_duplicate',
  LIST_ITEM_REMOVE: 'list_item_remove',
  MANAGE_LISTITEM: 'manage_listitem',
  SAVEDITEMS_NEWSLISTS_ARTICLE: 'saveditems_newslists_article',
  NEWS_CARD: 'news_card',
  DEFAULTLIST_SETTINGS: 'defaultlist_settings',
  TOAST_CHANGE_NEWSLIST: 'toast_change_newslist',
  SAVEDITEMS_NEWSFILTERS: 'saveditems_newsfilters',
  SYNC_COMPANY_LIST_RADAR: 'sync_list_radar',
  SAVE_FILTER: 'save_filter',
  COMPANY_FEED: 'company_feed',
  COMPANY_CONNECTION_MAP: 'company_connection_map',
  TOAST_VIEW_FILTER: 'toast_view_filter',
  CHANGE_LIST: 'change_list',
  SAVE_LIST: 'save_list',
  TOAST_CHANGE_LIST: 'toast_change_list',
  TOAST_VIEW_LIST: 'toast_view_list',
  COMPANY_CARD: 'company_card',
  TOAST_VIEW_RADAR: 'toast_view_radar',
  MONITOR_FILTER: 'monitor_filter',
  MONITOR_LIST: 'monitor_list',
  ADD_LIST: 'add_list',
  // homepage
  ALLNEWS_MYNEWS_TOGGLE: 'allnews_mynews_toggle',
  HOMEPAGE_ALLNEWS_ARTICLE: 'homepage_allnews_article',
  HOMEPAGE_MYNEWS_ARTICLE: 'homepage_mynews_article',
  SAVEDITEMS_COMPANYFILTERS: 'saveditems_companyfilters',
  COUNTRY_FILTER: 'country_filter',
  TOP_SCOUTAI: 'top_scoutai',
  // relationship ownership
  OS_OWNERSHIP: 'os_ownership',
  // relationship supplychain
  OS_SUPPLYCHAIN: 'os_supplychain',
  // relationship competitor
  OS_COMPETITOR: 'os_competitor',
  TARGETLISTS_COMPANY: 'targetlists_company',
  SECTOR_REPORT: 'sector_report',
  VIEW_ALL: 'view_all',
  QUICK_ACCESS_CARD: 'quick_access_card',
  CUSTOMISE_QUICK_ACCESS_WIDGET: 'customise_quick_access_widget',
  CUSTOMISE_CUSTOMIZE_NEWS_SAVED_ITEMS_WIDGET: 'customise_customize_news_saved_items_widget',
  CUSTOMISE_RADAR_WIDGET: 'customise_radar_widget',
  // search sidebar
  SEARCH_SIDEBAR: 'search_sidebar',
  // filter
  COUNTRIES_REGIONS_FILTER: 'countries&regions_filter',
  FINANCIALS_FILTER: 'financials_filter',
  OWNERSHIP_FILTER: 'ownership_filter',
  PUBLICATIONDATE_FILTER: 'publicationdate_filter',
  SOURCE_FILTER: 'source_filter',
  SCOUTAI_FILTER:'scoutai_filter',
  COMPANIES_FILTER: 'companies_filter',
  REVENUE_CARD: 'revenue_card',
  PROFIT_CARD: 'profit_card',
  MARKETCAP_CARD: 'marketcap_card',
  ASSETS_CARD: 'assets_card',
  SHAREHOLDERSFUNDS_CARD: 'shareholdersfunds_card',
  EMPLOYEES_FILTER: 'employees_filter',
  RELATIONSHIP_FILTER:'relationship_filter',

  // widget
  REGISTER_FORM: 'register_form',
  CONFIRM: 'confirm',
  ACTIVATION_FAILURE: 'activation_failure',
  ARTICLE_LIST: 'article_list',
  ARTICLE_CARD: 'article_card',
  ACTIVE_TOPICS: 'active_topics',
  ACTIVE_SECTORS: 'active_sectors',
  TOP_COMENTIONS: 'top_comentions',
  PUBLICATIONS: 'publications',
  TOP_COMAPNY_MENTION:'top_company_mention',

  // news from saved items
  NEWS_SAVED_ITEM_CARD: 'news_saved_item_card',
  NEWS_SAVED_ITEM: 'news_saved_item'
};

export const UI_ELEMENT_DETAILS = {
  // radar
  MANAGE: 'manage',
  // bookmark
  TOGGLE: 'toggle',
  BOOKMARK_SYMBOL: 'bookmark_symbol',
  CREATE_EMPTY_COMPANYFILTERS: 'create_empty_companyfilters',
  TOAST: 'toast',
  MAIN_FLOW: 'main_flow',
  TOGGLE_REGION: 'toggle_region',
  TOGGLE_GLOBAL: 'toggle_global',
  COUNTRY: 'country',
  SECTOR: 'sector',
  SECTOR_COUNTRY: 'sector_country'
};

export const OBJECT_NAME = {
  // filter new sector
  GO_HOMEPAGE: 'go_homepage',
  ENABLE_SECTOR: 'enable_sector',
  DISABLE_SECTOR: 'disable_sector',
  CLEAR_SECTOR: 'clear_sector',
  CLEAR_ALL: 'clear_all',
  SECTOR: 'sector',
  EXPAND_CHILD_SECTOR: 'expandto_childsector',
  RETURN_PARENT_SECTOR: 'returnto_parentsector',
  PARENT_SCOUTAI: 'parent_scoutai',
  CUSTOM_SCOUTAI_FILTER: 'custom_scoutai_filter',
  ALL_CUSTOM_SCOUTAI: 'all_custom_scoutai',
  ALL_SCOUTAI: 'all_scoutai',
  VIEW_ALL: 'view_all',
  TARGETLIST: 'targetlists',
  NEWSSEARCH: 'newssearch',
  COMPANYSEARCH: 'companysearch',
  SCOUTAI: 'scoutai',
  COMPANY_MENTIONS: 'company_mentions',
  ARTICLE: 'article',
  PUBLISHER: 'publisher',
  CONNECTIONS: 'connectionsplus',
  LANGUAGE: 'language',
  COMPANY: 'company',
  LIBRARY: 'library',
  RADAR: 'radar',
  BOOKMARK: 'bookmark',
  REMOVE_BUSINESS_LINES: 'remove_bizlines',
  FIND_BUSINESS_LINES: 'find_bizlines',
  SELECTED_BUSINESS_LINES: 'selected_bizlines',
  ENABLE_BUSINESS_LINES: 'enable_bizlines',
  DISABLE_BUSINESS_LINES: 'disable_bizlines',
  HIDE_KEYWORDS: 'hide_keywords',
  SHOW_KEYWORDS: 'show_keywords',
  EXPORT_COMPANIES: 'export_companies',
  EXTERNAL_VIEW_ORIGINAL: 'external_view_original',
  EXTERNAL_READ_MORE: 'external_read_more',
  EXTERNAL_CAMPAIGN_PAGE: 'external_campaign_page',
  // trending-topic
  CLUSTER: 'cluster',
  COUNTRIESREGIONS: 'countriesregions',
  SECTORS: 'sectors',
  ALERTSETTINGS: 'alertsettings',
  SAVEDSEARCHES: 'savedsearches',
  TARGETLISTS: 'targetlists',
  ACCOUNT: 'account',
  FAQ: 'faq',
  UPGRADE: 'upgrade',
  LOGOUT: 'logout',
  LOAD_HEATMAP: 'load heatmap',
  COUNTRIESREGIONS_MAP: 'countriesregions_map',
  COMPANIES_MAP: 'companies_map',
  NEWS_MAP: 'news_map',
  REPORTSFILINGS_MAP: 'reportsfilings_map',
  REGION_INFOBOX: 'region_infobox',
  SELECT_REGION: 'select_region',
  INFOBOX_COMPANYCOUNT: 'infobox_companycount',
  INFOBOX_NEWSCOUNT: 'infobox_newscount',
  INFOBOX_REPORTSCOUNT: 'infobox_reportscount',
  // onboarding
  STEP_NEXT: 'step_next',
  STEP_BACK: 'step_back',
  STEP_SKIPPED: 'step_skipped',
  CLOSE: 'close',
  LOGIN: 'login',

  REPORT_DOWNLOAD: 'report_download',
  REPORT_PRINT: 'report_print',
  // radar
  ADD_SEARCHFEED: 'add_searchfeed',
  EDIT_SEARCHFEED: 'edit_searchfeed',
  DELETE_SEARCHFEED: 'delete_searchfeed',
  ADD_COMPANIES: 'add_companies',
  DELETE_COMPANIES: 'delete_companies',
  VIEW_ARTICLE: 'view_article',
  VIEW_COMPANY: 'view_company',
  VIEW_MORE: 'view_more',
  SHOW_LESS: 'show_less',
  DEFAULT_VIEW: 'default_view',
  OPEN_DROPDOWN: 'open_dropdown',
  MY_ACCOUNT: 'my_account',
  ALL_NEWS: 'all_news',
  NEWS_INDIVIDUAL: 'news_individual',
  COMPANIES_INDIVIDUAL: 'companies_individual',
  NEWS_MATCHEDCOMPANIES: 'news_matchedcompanies',
  NEWS_STARREDCOMPANIES: 'news_starredcompanies',
  RUN_SEARCH: 'run_search',
  ADD_STARREDCOMPANIES: 'add_starredcompanies',
  ADD_MATCHEDCOMPANIES: 'add_matchedcompanies',
  VIEW_COMPANIES: 'view_companies',
  NEWS: 'news',
  COMPANIES: 'companies',
  REPORTSFILINGS: 'reportsfilings',
  ADD_INPUTBUTTON: 'add_inputbutton',
  SETTINGS: 'settings',
  INPUT_NEWS: 'input_news',
  INPUT_COMPANIES: 'input_companies',
  INPUT_REPORTS: 'input_reports',
  INPUT_NEWS_SEARCH: 'input_newssearch',
  INPUT_COMPANIES_SEARCH: 'input_companiessearch',
  INPUT_COMPANIES_STARRED: 'input_companiesstarred',
  MATCHEDCOMPANIES_SECTION: 'matchedcompanies_section',
  STARREDCOMPANIES_SECTION: 'starredcompanies_section',
  RELATED_ARTICLES: 'related_articles',
  RADAR_INDIVIDUAL: 'radar_individual',
  RADAR_LANDING: 'radar_landing',
  DATE_TOGGLE: 'date_toggle',
  UNREAD_NEWS: 'unread_news',
  RECENTMENTIONS_COMPANIES_FILTER: 'recentmentions_companies_filter',
  RECENTMENTIONS_COMPANIES_LIST: 'recentmentions_companies_list',
  DELETE: 'delete',
  RENAME: 'rename',
  LEARN_MORE: 'learn_more',
  SYNC: 'sync',
  VIEW_ALL_RADAR: 'view_all_radar',
  ADD_CARD: 'add_card',
  VIEW_NEWSLIST: 'view_newslist',
  VIEW_COMPANIESNEWS: 'view_companynews',
  RUN_NEWSFILTER: 'run_newsfilter',
  RUN_COMPANYFILTER: 'run_companyfilter',
  VIEW_NEWS: 'view_news',
  // radar-setting
  SYNC_COMPANYLISTS: 'sync_companylists',
  MODAL_SYNC_COMPANYLISTS: 'modal_sync_companylists',
  COMPANYLISTS: 'companylists',
  MODAL_REMOVE_COMPANYLISTS: 'modal_remove_companylists',
  SYNC_NEWSFILTERS: 'sync_newsfilters',
  NEWSFILTERS: 'newsfilters',
  EDIT_NEWSFILTERS: 'edit_newsfilters',
  MODAL_REMOVE_NEWSFILTERS: 'modal_remove_newsfilters',
  SYNC_COMPANYFILTERS: 'sync_companyfilters',
  MODAL_SYNC_COMPANYFILTERS: 'modal_sync_companyfilters',
  COMPANYFILTERS: 'companyfilters',
  EDIT_COMPANYFILTERS: 'edit_companyfilters',
  MODAL_REMOVE_COMPANYFILTERS: 'modal_remove_companyfilters',
  // quick search
  RECENT_SEARCHES: 'recent_searches',
  CLEAR_RECENT_SEARCHES: 'clear_recent_searches',
  SEARCH_BUTTON: 'search_button',
  SEARCH_DROPDOWN: 'search_dropdown',
  // filter news scout AI
  ENABLE_SCOUTAI: 'enable_scoutai',
  DISABLE_SCOUTAI: 'disable_scoutai',
  CLEAR_SCOUTAI: 'clear_scoutai',
  HIDE_DEFINITIONS: 'hide_definitions',
  SHOW_DEFINITIONS: 'show_definitions',
  // filter new source type 
  SOURCETYPE_FILTER: 'sourcetype_filter',
  ENABLE_SOURCE: 'enable_source',
  DISABLE_SOURCE: 'disable_source',
  // filter new country
  ENABLE_COUNTRIES_REGIONS: 'enable_countries & regions',
  DISABLE_COUNTRIES_REGIONS: 'disable_countries & regions',
  // filter companies
  FIND_COMPANIES: 'find_companies',
  SELECTED_COMPANIES: 'selected_companies',
  REMOVE_SELECTED_COMPANIES: 'remove_selected_companies',
  ADD_LIST: 'add_list',
  ADD_FROM_SEARCH:'add_from_search',
  // filter companies of the new pages
  ENABLE_COMPANIES: 'enable_companies',
  DISABLE_COMPANIES: 'disable_companies',
  SEARCH_COMPANIES: 'search_companies',
  // filter ownership
  ALL_OWNERSHIP: 'all_ownership',
  PRIVATE_OWNERSHIP: 'private_ownership',
  LISTED_OWNERSHIP: 'listed_ownership',
  // filter financials
  REVENUE_FROM_FILTER: 'revenue_from_filter',
  REVENUE_TO_FILTER: 'revenue_to_filter',
  PROFIT_FROM_FILTER: 'profit_from_filter',
  PROFIT_TO_FILTER: 'profit_to_filter',
  MARKETCAP_FROM_FILTER: 'marketcap_from_filter',
  MARKETCAP_TO_FILTER: 'marketcap_to_filter',
  ASSETS_FROM_FILTER: 'assets_from_filter',
  ASSETS_TO_FILTER: 'assets_to_filter',
  SHAREHOLDERFUNDS_FROM_FILTER: 'shareholderfunds_from_filter',
  SHAREHOLDERFUNDS_TO_FILTER: 'shareholdersfunds_to_filter',
  // filter employees 
  EMPLOYEES_FILTER: 'employees_filter',
  EMPLOYEES_TO_FILTER: 'employees_to_filter',
  EMPLOYEES_FROM_FILTER: 'employees_from_filter',
  // filter relationship
  RELATIONSHIP_FILTER:'relationship_filter',
  ENABLE_RELATIONSHIP: 'enable_relationship',


  // bookmark
  SAVE_CONTENT: 'save_content',
  REMOVE_CONTENT: 'remove_content',
  TOGGLE_LIST: 'toggle_list',
  TOGGLE_CARD: 'toggle_card',
  SAVE_ARTICLE: 'save_article',
  REMOVE_ARTICLE: 'remove_article',
  SAVED_ITEMS: 'saveditems',
  NEWS_LISTS: 'newslists',
  TARGETLIST_DRAWER: 'targetlist_drawer',
  SAVEDSEARCH_DRAWER: 'savedsearch_drawer',
  CREATE_NEW_BUTTON: 'create_new_button',
  MODAL_CREATE_NEW: 'modal_create_new',
  ALL: 'all',
  REPORT: 'report',
  EXCHANGE_FILING: 'exchange_filing',
  KEBAB: 'kebab',
  EDIT_DETAILS: 'edit_details',
  MODAL_EDIT_DETAILS: 'modal_edit_details',
  DELETELIST: 'deletelist',
  MODAL_DELETELIST: 'modal_deletelist',
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  CHANGE: 'change',
  MOVE_BUTTON: 'move_button',
  MODAL_MOVECONTENT: 'modal_movecontent',
  DUPLICATE_BUTTON: 'duplicate_button',
  MODAL_DUPLICATECONTENT: 'modal_duplicatecontent',
  REMOVE_BUTTON: 'remove_button',
  MODAL_REMOVECONTENT: 'modal_removecontent',
  TICK_UNCHECKED: 'tick_unchecked',
  UNTICK_CHECKED: 'untick_checked',
  TICK_SELECTALL: 'tick_selectall',
  UNTICK_SELECTALL: 'untick_selectall',
  EDIT_DEFAULTLIST: 'edit_defaultlist',
  BOOKMARK_SYMBOL_UNFILLED: 'bookmark_symbol_unfilled',
  BOOKMARK_SYMBOL_FILLED: 'bookmark_symbol_filled',
  CHANGELIST_BUTTON: 'changelist_button',
  MODAL_SYNC_NEWSFILTERS: 'modal_sync_newsfilters',
  TOOGLE_SAVEDITEMS_COMPANYLISTS: 'toggle_saveditems_companylists',
  CLICK_COMPANYLIST: 'click_companylist',
  CLICK_NEWSFILTERS: 'click_newsfilters',
  CLICK_COMPANYFILTERS: 'click_companyfilters',
  CREATE_COMPANYLIST: 'create_companylist',
  SAVE: 'save',
  SYNC_COMPANYLIST: 'sync_companylist',
  CHANGE_COMPANYLIST: 'change_companylist',
  MODAL_SYNC_RADAR: 'modal_sync_radar',
  MODAL_CHANGE_RADAR: 'modal_change_radar',
  SETTINGS_COMPANYLIST: 'settings_companylist',
  EXPORT: 'export',
  TICK_COMPANIES: 'tick_companies',
  UNTICK_COMPANIES: 'untick_companies',
  MOVE_COMPANYLIST: 'move_companylist',
  DUPLICATE_COMPANYLIST: 'duplicate_companylist',
  REMOVE_COMPANYLIST: 'remove_companylist',
  TOOGLE_SAVEDITEMS_NEWSFILTERS: 'toggle_saveditems_newsfilters',
  TOOGLE_SAVEDITEMS_COMPANYFILTERS: 'toggle_saveditems_companyfilters',
  CREATE_EMPTY_NEWSFILTERS: 'create_empty_newsfilters',
  CREATE_COMPANYFILTERS: 'create_companyfilters',
  CREATE_NEWSFILTERS: 'create_news_filter',
  SYNC_RADAR: 'sync_radar',
  UNSYNC_RADAR: 'unsync_radar',
  RENAME_FILTER: 'rename_filter',
  DELETE_FILTER: 'delete_filter',
  TOAST_VIEW_RADAR: 'toast_view_radar',
  TOAST_VIEW_FILTER: 'toast_view_filter',
  MODAL_RENAME_FILTER: 'modal_rename_filter',
  MODAL_DELETE_FILTER: 'modal_delete_filter',
  SAVE_FILTER: 'save_filter',
  MODAL_SAVED_FILTER: 'modal_saved_filter',
  COMPANYFILTERS_KEBAB: 'companyfilters_kebab',
  NEWSFILTERS_KEBAB: 'newsfilters_kebab',
  MODAL_CHANGR_LIST: 'modal_change_list',
  VIEW_LIST: 'view_list',
  CHANGE_LIST: 'change_list',
  MODAL_SAVE_LIST: 'modal_save_list',
  CREATE_EMPTY_COMPANYFILTERS: 'create_empty_companyfilters',
  SELECTED_ORIGINAL: 'selected_original',
  SELECTED_ENGLISH: 'selected_English',
  ORIGINAL_ARTICLE: 'original_article',
  MODAL_MONITOR_LIST: 'modal_monitor_list',
  MODAL_MONITOR_FILTER: 'modal_monitor_filter',
  MODAL_MAX_RADAR: 'modal_max_radar',
  // homepage
  HOMEPAGE_ALLNEWS: 'homepage_allnews',
  HOMEPAGE_MYNEWS: 'homepage_mynews',
  SELECT_COUNTRY: 'select_country',
  SELECT_SECTOR: 'select_sector',
  GO_MACRO: 'go_macro',
  // relationship  ownership
  OWNERSHIP_TAB: 'ownership_tab',
  OWNERSHIP_HOLDER_SORT: 'ownership_holder_sort',
  OWNERSHIP_COUNTRY: 'ownership_country',
  OWNERSHIP_OWNERSHIP_SORT: 'ownership_ownership_sort',
  OWNERSHIP_POSITION_SORT: 'ownership_position_sort',
  OWNERSHIP_POSITIONCHANGE_SORT: 'ownership_positionchange_sort',
  OWNERSHIP_REPORTDATE_SORT: 'ownership_reportdate_sort',
  OWNERSHIP_COMPANY: 'ownership_company',
  OWNERSHIP_PAGES: 'ownership_pages',
  OWNERSHIP_RESULTS: 'ownership_results',
  OWNERSHIP_RESULTSOPTION: 'ownership_resultsoption',
  OWNERSHIP_DOWNLOAD: 'ownership_download',
  // relationship supplychain
  SUPPLYCHAIN_TAB: 'supplychain_tab',
  FILTER_ALL: 'filter_all',
  FILTER_SUPPLIER: 'filter_supplier',
  FILTER_CUSTOMER: 'filter_customer',
  FILTER_PARTNER: 'filter_partner',
  SUPPLYCHAIN_COMPANY_SORT: 'supplychain_company_sort',
  SUPPLYCHAIN_REVENUE_SORT: 'supplychain_revenue_sort',
  SUPPLYCHAIN_COUNTRY_SORT: 'supplychain_country_sort',
  SUPPLYCHAIN_SECTOR_SORT: 'supplychain_sector_sort',
  SUPPLYCHAIN_RELATIONSHIPTYPE_SORT: 'supplychain_relationshiptype_sort',
  SUPPLYCHAIN_COMPANY: 'supplychain_company',
  SUPPLYCHAIN_PAGES: 'supplychain_pages',
  SUPPLYCHAIN_RESULTS: 'supplychain_results',
  SUPPLYCHAIN_RESULTSOPTION: 'supplychain_resultsoption',
  SUPPLYCHAIN_DOWNLOAD: 'supplychain_download',
  // relationship competitor
  COMPETITOR_TAB: 'competitor_tab',
  COMPETITOR_COMPANY_SORT: 'competitor_company_sort',
  COMPETITOR_REVENUE_SORT: 'competitor_revenue_sort',
  COMPETITOR_COUNTRY_SORT: 'competitor_country_sort',
  COMPETITOR_SECTOR_SORT: 'competitor_sector_sort',
  COMPETITOR_COMPANY: 'competitor_company',
  COMPETITOR_PAGES: 'competitor_pages',
  COMPETITOR_RESULTS: 'competitor_results',
  COMPETITOR_RESULTSOPTION: 'competitor_resultsoption',
  COMPETITOR_DOWNLOAD: 'competitor_download',
  NEWSSEARCH_RESULT: 'newssearch_result',
  COMPANYSEARCH_RESULT: 'companysearch_result',
  REPORTSEARCH_RESULT: 'reportsearch_result',
  INTO_RADAR: 'into_radar',
  CREATE_RADAR: 'create_radar',
  MODAL_CREATE_RADAR: 'modal_create_radar',
  CUSTOMISE_RADAR: 'customise_radar',
  MODAL_CUSTOMISE_RADAR: 'modal_customise_radar',
  // emailAlerts
  EMAIL_ALERTS: 'email_alerts',
  TOGGLE_ALERTS: 'toggle_alerts',
  RADAR_BELL: 'radar_bell',
  // Quick_Access
  SAVEDITEMS_NEWSLIST: 'saveitems_newslist',
  SAVEDITEMS_COMPANYLIST: 'saveitems_companylist',
  SAVEDITEMS_NEWSFILTER: 'saveitems_newsfilter',
  SAVEDITEMS_COMPANYFILTER: 'saveitems_companyfilter',
  CUSTOMISE_QUICK_ACCESS: 'customise_quick_access',
  MODAL_CUSTOMISE_QUICK_ACCESS: 'modal_customise_quick_access',

  // News Saved Items
  CUSTOMISE_NEWS_SAVED_ITEMS: 'customise_news_saved_items',
  MODAL_CUSTOMISE_NEWS_SAVED_ITEMS: 'modal_customise_news_saved_items',

  // filter
  SELECT_KEYWORDTYPE: 'select_keywordtype',
  SECTOR_FILTER: 'sector_filter',
  BUSINESS_LINES_FILTER: 'bizlines_filter',
  COUNTRIES_REGIONS_FILTER: 'countries&regions_filter',
  FINANCIALS_FILTER: 'financials_filter',
  OWNERSHIP_FILTER: 'ownership_filter',
  PUBLICATIONDATE_FILTER: 'publicationdate_filter',
  SOURCE_FILTER: 'source_filter',
  SCOUTAI_FILTER:'scoutai_filter',
  COMPANIES_FILTER: 'companies_filter',
  REMOVE_COUNTRIES_REGIONS: 'remove_countries&regions',
  REMOVE_FINANCIALS: 'remove_financials',
  REMOVE_OWNERSHIP: 'remove_ownership',
  REMOVE_PUBLICATIONDATE: 'remove_publicationdate',
  REMOVE_SOURCE: 'remove_source',
  REMOVE_SCOUTAI: 'remove_scoutai',
  REMOVE_COMPANIES: 'remove_companies',
  PUBLICATION_STARTDATE: 'publication_startdate',
  PUBLICATION_ENDDATE: 'publication_enddate',

  // widget
  REGISTER_BUTTON: 'register_button',
  CONTENT_LINK: 'content_link',
  GET_SUPPORT: 'get_support',
  TOGGLE: 'toggle',
};

export const OBJECT_NAME_DETAILS = {
  // onboarding
  SKIPPED_TO_USERGOAL: 'Step that user skipped to usergoal',
  SKIPPED_TO_REGIONS: 'Step that user skipped to regions',
  SKIPPED_TO_SECTORS: 'Step that user skipped to sectors',
  CREATE_RADAR_NEWS: 'Create Radar > News about a particular topic',
  CREATE_RADAR_COMPANIES: 'Create Radar > Companies',
  CREATE_RADAR_REPORTS: 'Create Radar > Research reports & exchange filings',
  INTO_RADAR: 'into_radar',
  MANAGE: 'manage',
  EDIT: 'edit',
  DELETE: 'delete',
  SAVE: 'save',
  CANCEL: 'cancel',
  REMOVE: 'remove',
  MOVE: 'move',
  DUPLICATE: 'duplicate',
  UNTICK_CHECKED: 'untick_checked',
  TICK_UNCHECKED: 'tick_unchecked',
  CONFIRM: 'confirm',
  TOGGLE_ON: 'toggle_on ',
  TOGGLE_OFF: 'toggle_off',
  BELL_ON: 'bell_on ',
  BELL_OFF: 'bell_off',
  ALERT_ON: 'alert_on',
  ALERT_OFF: 'alert_off',
  CREATE: 'create',
  CUSTOMISE: 'customise',
  CLEAR_ALL: 'clear_all',
  SYNC: 'sync',
  RENAME: 'rename',
  CHANGE: 'change',
  YES: 'yes',
  NO: 'no',
  LEARN_MORE: 'learn_more',
  TOGGLE_REGION: 'toggle_region',
};

export const ALERT_SETTING_TAB = {
  SCOUTAI_LIST: 'SCOUTAI_LIST',
  CUSTOM_SCOUTAI_LIST: 'CUSTOM_SCOUTAI_LIST',
  SAVED_SEARCH_LIST: 'SAVED_SEARCH_LIST',
  TARGE_LIST: 'TARGE_LIST',
};

export const ALERT_TYPE = {
  TARGET_LIST: 'TARGET_LIST',
  SAVED_SEARCH: 'SAVED_SEARCH',
};

export const SCOUTASIA_ACTIVATE_TYPE = {
  MARKER_ENTRY_RESEARCH: 'Market Entry Research',
  MONITORING_INDUSTRY_DEVELOPMENTS: 'Monitoring Industry Developments',
  OTHER: 'Other...',
};

export enum SAVE_TO_RADAR_TYPE {
  SEARCH = 'SEARCH',
  COMPANY = 'COMPANY',
  REPORT = 'REPORTFILE',
  NEWS = 'NEWS',
  NONE = 'NONE',
}

export enum DELETED_STATUS {
  BEEN_DELETED = 1,
  NOT_DELETED = 0,
}

export enum BELL_STATUS {
  DEACTIVATED = 'deactivated',
  ACTIVATED = 'activated',
}

export enum RADAR_SEARCH_TYPE {
  ARTICLE = 'ARTICLE',
  COMPANY = 'COMPANY',
  REPORTFILE = 'REPORTFILE',
  FAVORITE_COMPANY = 'FAVORITECOMPANY',
  MATCHED_COMPANY = 'MATCHEDCOMPANY',
}

export const RADAR_COMPANY_TYPE = {
  MATCHED_COMPANIES: 'MATCHED_COMPANIES',
  STARRED_COMPANIES: 'STARRED_COMPANIES',
};

export const RADAR_ARTICLE_TYPE = {
  ALL: 'ALL',
  INDIVIDUAL: 'INDIVIDUAL',
  TOP500: 'TOP500',
  RADARCOMPANIES: 'RADARCOMPANIES',
};

export const RADAR_COMPANY_OVERVIEW_TYPE = {
  MATCHED: 'MATCHED',
  FAVORITE: 'FAVORITE',
};

export const RADAR_ARTICLE_SELECT_OPTIONS = {
  COMPANY_FILTER: 'COMPANY FILTER',
  TOP500: 'MATCHED COMPANIES: TOP 500 BY REVENUE',
  STARRED_COMPANIES: 'STARRED COMPANIES',
  COMPANY_LIST: 'COMPANY LIST',
};

export const SEARCH_CRITERIA_COLOR = {
  BLUE: '#2475d0',
  PURPLE: '#b940cf',
  ORANGE: '#e67107',
  RED: '#e23037',
  GREEN: '#49926e',
  TEAL: '#018da6',
};

export const COMPANY_CRITERIA_COLOR = {
  PURPLE: '#a78ff9',
  GREEN: '#0eb0a5',
};

export const SEARCH_NAVBAR_TAB = {
  ALL: 'All',
  COMPANY: 'Company',
  NEWS: 'News',
};

export const ENTITY_NAVBAR_TAB = {
  OVERVIEW: 'Overview',
  COMPANIES: 'Companies',
  NEWS: 'News',
  REPORTFILINGS: 'Reports & Filings',
  SCOUT_AI: 'Scout AI',
};

export const RADAR_LEFT_DRAWER = {
  ADDED_BY_YOU: 'Added by you',
  SEARCH_FEEDS: 'Search Feeds',
};

export const LIBRARY_OVERVIEW_TYPE = {
  OVERVIEW: 'Overview',
  COUNTRIESREGIONS: 'Countries & Regions',
  COMPANIES: 'Companies',
  NEWS: 'News',
  REPORTFILINGS: 'Reports & Filings',
};

export const TITLE_LOAD_TIME = 3000;

export const TTFS_GLOBAL = 'GLOBAL';

export const MENTIONED_COUNTRY = [
  {
    name: 'Global',
    alpha3: TTFS_GLOBAL,
  },
  {
    name: 'China',
    alpha3: 'CHN',
  },
  {
    name: 'Hong Kong',
    alpha3: 'HKG',
  },
  {
    name: 'India',
    alpha3: 'IND',
  },
  {
    name: 'Japan',
    alpha3: 'JPN',
  },
  {
    name: 'Malaysia',
    alpha3: 'MYS',
  },
  {
    name: 'Singapore',
    alpha3: 'SGP',
  },
  {
    name: 'Vietnam',
    alpha3: 'VNM',
  },
];

export const NEWS_REPORT_FILINGS = ['SGX', 'HKEX', 'Emis', 'ARAI', 'ExpertConnectAsia', 'GLiN'];

export const NEWS_REPORT_PUBLISHER = ['Emis', 'ARAI', 'ExpertConnectAsia', 'GLiN'];

export const GRAPH_HEIGHT = {
  NORMAL: 260,
  DOWNLOAD: 220,
};

export const COMPANY_SEARCH = {
  KEYWORD_IN: 'Search keyword in',
  SECTORS: 'Sectors',
  BUSINESS_LINES: 'Business Lines',
  COUNTRIES_AND_REGIONS: 'Countries & regions',
  FINANCIAL: 'Financials',
  OWNERSHIP: 'Ownership',
  NO_EMPLOYEES: 'No. Employees',
  RELATIONSHIP_SEARCH: 'Relationship Search',
};

export const NEWS_SEARCH = {
  PUBLICATION_DATE: 'Publication Date',
  SOURCE: 'Source',
  SECTORS: 'Sectors',
  SCOUT_AI: 'Scout AI',
  SCOUT_AI_TOPICS: 'Scout AI Topics',
  COMPANIES: 'Companies',
  COUNTRIES_AND_REGIONS: 'Countries & regions',
};

export const STORE_QUERY = {
  SEARCH_TEXT: 'searchStr',
  COMPANY_QUERY: 'company-query',
  NEWS_QUERY: 'news-query',
};

export const SPECIAL_NEWS_OPTIONS = {
  MATCHED_COMPANY: 'matched-company',
};

export enum DRAWER_ALERT_CONTROL {
  none,
  targetList,
  savedSearch,
}

export const ARTICLE_CARD_VIEW = {
  COMPACT: 'COMPACT',
  DETAILED: 'DETAILED',
};

export enum BOOKMARK_TYPE {
  COMPANY_LIST = 'COMPANY_LIST',
  COMPANY_FILTER = 'COMPANY_FILTER',
  CONTENT_LIST = 'CONTENT_LIST',
  CONTENT_FILTER = 'CONTENT_FILTER',
}

export const BOOKMARK_LISTS_TYPE = {
  CONTENT_LIST: 'CONTENTLIST',
  TARGET_LIST: 'TARGETLIST',
};

export const CONTENT_LIST_ITEM_TYPE = {
  ARTICLE: 'ARTICLE',
  REPORT: 'REPORT',
  FILLING: 'FILLING',
};

export const BOOKMARK_ENTITY_TYPE = {
  ARTICLE: 'ARTICLE',
  COMPANY: 'COMPANY',
};

export const BOOKMARK_DETAIL_LIST_TAB = [
  {
    name: 'ARTICLE',
    count: 0,
    checked: false,
  },
  {
    name: 'REPORT',
    count: 0,
    checked: false,
  },
  {
    name: 'EXCHANGEFILING',
    count: 0,
    checked: false,
  },
];

export const BOOKMARK_FILTER_TAB = {
  ALL: 'All',
  NEWS: 'News',
  REPORT: 'Report',
  EXCHANGEFILING: 'Exchange Filing',
};

export enum BOOKMARK_NAVBAR_TAB {
  NEWS = 'News Lists',
  COMPANIES = 'Company Lists',
  NEWSFILTERS = 'News Filters',
  COMPANIESFILTER = 'Company Filters',
}

export const BOOKMARK_LIST_ITEM_TYPE = {
  NEWS: 'news',
  COMPANIES: 'companies',
  NEWS_FILTERS: 'newsFilters',
  COMPANIES_FILTERS: 'companiesFilters',
};

export const RADAR_TEMPLATE_TAB = {
  COMPANY_LIST: 'Company List',
  NEWS_FILTER: 'News Filter',
  COMPANY_FILTER: 'Company Filter',
};

export enum IBookmarkModalOptionType {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  INPUT = 'input',
  REMOVE = 'remove',
  SELECT_RADAR = 'selectRadar',
}

export enum RADAR_COUNTS {
  ARTICLE_SEARCH_CRITERIA = 'articleSearchCriteria',
  COMPANY_SEARCH_CRITERIA = 'companySearchCriteria',
}

export enum PILL_SHAPED {
  ALL = 'ALL',
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
}

export const RELATIONSHIP_TYPES = {
  OWNER: 'OWNER',
  SUPPLIER: 'SUPPLIER',
  CUSTOMER: 'CUSTOMER',
  PARTNER: 'PARTNER',
  COMPETITOR: 'COMPETITOR',
};

export enum OWNERSHIP_TABLE {
  OWNERSHIP = 0,
  SUPPLYCHAIN = 1,
  COMPETITORS = 2,
}

export enum SEER_OWNERSHIP_TABLE {
  OWNERSHIP = 0,
  ALLSUPPLYCHAIN = 1,
  SUPPLYCHAIN = 2,
  SUPPLIER = 3,
  CUSTOMER = 4,
}

export const NO_RESULT_PER_PAGE = {
  TEN: 10,
  THIRTY: 30,
  FIFTY: 50,
};

export const CREATE_NEW_TYPE = {
  LIST: 'List',
  RADAR: 'Radar',
  FILTER: 'Filter',
};

export const DASHBOARD_ALERT_TYPE = {
  NEWS_SEARCH: 'newssearch',
  COMPANY_SEARCH: 'companysearch',
  SCOUTAI: 'scoutai',
};

export const RADAR_TAB_BAR = {
  NEWS_FEED: 'News Feed',
  COMPANY_FEED: 'Company Feed',
};

export const MAX_QUICK_ACCESS_COUNT = 12;

export const INIT_RADAR_NAME = {
  MY_REGIONS_RADAR: 'My Regions Radar',
  MY_SECTORS_RADAR: 'My Sectors Radar',
  ALL: 'My Regions and Sectors Radar',
};

export const DEFAULT_CRON = '0 23 * * *';

export const RADAR_COMPANY_FEED = {
  FILTER: 'filter',
  COMPANY: 'company',
};

export enum SOURCE_TYPE {
  FACTSET = 'FACTSET',
  SCOUTASIA = 'SCOUTASIA',
}

export enum RADAR_MODAL_TRACKING_FROM_TYPE {
  SEARCH_SIDEBAR = 'SEARCH_SIDEBAR',
  COMPANY_PAGE = 'COMPANY_PAGE',
  COMPAY_SEARCH_RESULT = 'COMPANYSEARCH_RESULT',
  RADAR_INDIVIDUAL = 'RADAR_INDIVIDUAL',
  REGIONSECTOR_PAGE = 'REGIONSECTOR_PAGE',
  CONNECTIONS_PAGE = 'CONNECTIONS_PAGE'
}

export enum SAVECOMPANIESTYPE {
  COMPANYSEARCHRESULT = 'companysearch_result',
  REGIONSECTORPAGE = 'regionsector_page',
}

export enum CHANGECOMPANYLISTTYPE {
  COMPANYSEARCHRESULT = 'companysearch_result',
  COMPANYPAGE = 'company_page',
  RADAR_INDIVIDUAL = 'radar_individual',
  REGIONSECTORPAGE = 'regionsector_page',
}

export const FINANCIAL_ADDITIONAL_INFO_LABEL = {
  OPERATING_REVENUE: 'Revenue',
  PL_BEFORE_TAX: 'Profit Before Tax',
  MARKET_CAPITALIZATION: 'Market Cap',
  SHAREHOLDERS_FUNDS: "Shareholders' Funds",
  TOTAL_ASSETS: 'Total Assets'
};

export const RADAR_COMPANY_FILTER_BAR = {
  TODAY: 'Today',
  SINCE_YESTERDAY: 'Since Yesterday',
  LAST_1_WEEK: 'Last 1 week',
  LAST_1_MONTH: 'Last 1 month',
  LAST_1_YEAR: 'Last 1 year',
};

export enum LIMIT_COUNT {
  FOUR = 4,
  TWELVE = 12
}

export enum RELATIONSHIP_SEARCH_SELECT {
  SELECT_RELATIONSHIP = 'SELECT_RELATIONSHIP',
  SELECT_COUNTRY_REGION = 'SELECT_COUNTRY_REGION'
}

export enum SEARCH_FILTER_COMPANIES_ACCORDION {
  ACCORDION_ADD_COMPANY_LIST = 'accordion-add-company-list',
  ACCORDION_ADD_FROM_SEARCH = 'accordion-add-from-search'
}

export enum IMonitorValue {
  NO = 'No',
  YES = 'Yes',
}

export enum FAQ_TOPIC_SECTION {
  RADAR = 'Radar'
}

export enum ACTIVATE_STATUS {
  PENDING,
  SUCCESSFUL,
  FAILED,
}

export const ERROR_TYPE = {
  BAD_TOKEN: 'BAD_TOKEN',
  NO_ACCOUNT: 'NO_ACCOUNT',
  NOT_VERIFIED: 'NOT_VERIFIED',
  NOT_ACTIVATED: 'NOT_ACTIVATED'
};

export const WIDGET_INPUT_FIELDS = {
  GIVEN_NAME: 'GIVEN_NAME',
  FAMILY_NAME: 'FAMILY_NAME',
  WORK_EMAIL: 'WORK_EMAIL',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  COMPANY_NAME: 'COMPANY_NAME'
};

export const SWAGGER_ENV_MAP = {
  localhost: 'developer-',
  app: EMPTY_STRING
};

export const SCOUT_AI_CUSTOM_CODE = '-1';
export const SCOUT_AI_TOPICS_CREATE_ITEM = {
  code: SCOUT_AI_CUSTOM_CODE,
  parent: null,
  level: 1,
  label: 'Custom Scout AI Topics',
  count: 0
};

export const ARAIRegx = /ARAI/;
export const ExpertConnectAsiaRegx = /ExpertConnectAsia/;
export const GLINRegx = /GLiN/;
export const getUrlForGlinRegex = /GLiN\/(.*?)\/[^\/]+\.pdf/;

export const SEARCH_QUERY_MAP = {
  scoutAIs: NEWS_SEARCH.SCOUT_AI_TOPICS,
}

export enum FilterProgressStatus {
  None = 1,
  Pending = 2,
  Successful = 3
}

export const WidgetRetryMessage = 'Pending widget result. Please retry.';

export const COUNTRIES = {
  CHINA: 'CHINA'
};

export const CHINA_REGIONS = {
  HongKong: 'Hong Kong',
  Macau: 'Macau',
  Taiwan: 'Taiwan'
};

export const CLICK_TRACKING_CATEGORIES = {
  RADER_CARD: 'Radar_Card',
  News_FROM_SAVED_ITEMS_CARD: 'News_From_Saved_Items_Card',
};

export const SAVED_ITEM_TYPE = {
  COMPANY_LIST: 'Company List',
  COMPANY_FILTER: 'Company Search',
  CONTENT_FILTER: 'News Search'
}

export const DAILY_HEADLINES_TYPE = {
  companies: 'Company List',
  newsFilters: 'News Search',
  companiesFilters: 'Company Search'
}

export const SCROLL_POSITION = {
  DASHBOARD_SCROLL_TOP: 'dashboardScrollTop',
};
export const ARTICLE_CARD_ATLAS_TRACKING_TYPE = {
  RECOMMENDED_ARTICLES: 'recommended-articles',
  YOU_MIGHT_ALSO_LIKE_ARTICLES: 'you-might-also-like-articles',
  CUSTOM_SCOUT_ARTICLE_WINDOW_RA: 'customscout—articlewindow—ra',
  BOOKMARK_CONTENT_LIST_DETAIL_PAGE: 'bookmark-content-list-detail-page',
  COMPANY_DEATIL_PAGE: 'company-detail-page',
  SEARCH_RESULT_PAGE: 'search-result-page',
  REGION_SECTOR_PAGE: 'region-sector-page',
  HOMEPAGE_ALL_NEWS: 'homepage-all-news',
  HOMEPAGE_MY_NEWS: 'homepage-my-news',
  TARGET_LIST_PAGE: 'target-list-page',
  SCOUT_TRAINING_PAGE: 'scout-training-page',
  LATEST_NEWS_HOMEPAGE: 'latest-news-homepage'
}
