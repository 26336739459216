import React, { useContext, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Box } from '@mui/material';
import { authValidSelector } from '@redux/selectors/auth.selector';
import { isLoadingUserSelector, oidcUserSelector } from '@redux/selectors/oidc.selector';
import { isLoggingOutSelector } from '@redux/selectors/account.selector';
import { authValidateUser } from '@redux/actions/auth.action';
import { getLoginToken, initAccountInfo as initAccountInfoAction } from '@redux/actions/account.actions';
import { isValidUserWidget } from '@react/utils/auth.util';
import BlankPage from '@react/components/blank/blank.component';
import { getHostDomain, useQuery } from '@react/utils/common.util';
import { USER_SESSION_STATUS } from '@constants/state';
import { partnerOrganisationAccountCreateMode, partnerOrganisationInitialEmailMode } from 'skyeye-common-const/dist/utils/appConst';
import { SITE_URLS } from '@constants/routes';
import { useIntl } from 'react-intl';
import { useCustomOidc } from '../oidc/customOidcProvider.component';
import ArticlesWidget from './articlesWidget.component';
import StatisticWidget from './statisticWidget.component';
import useStyles from './styles';
import { WidgetContext } from './widgetApp.component';
import PrimaryContainedButtonBase from '../button/base/primaryContainedButtonBase.component';
import messages from './messages';
import WidgetAccountErrorPage from './widgetAccountErrorPage.component';
import { LOCAL_STORAGE } from '@constants/common';
import AgreementDialog from './agreementDialog.component';
import { initScoutAis as initScoutAisAction } from '@redux/actions/scoutAI.actions';
import { WIDGET_TYPE } from '@constants/enum';
import WidgetHeader from './widgetHeader.component';

interface AuthWidgetWrapperState {
  isValid: any;
  oidcUser: any;
  isLoadingUser: boolean;
  isLoggingOut: boolean;
}

interface AuthWidgetWrapperDispatch {
  validate: (boolean) => void;
  initAccountInfo;
  initScoutAis;
}
  
type AuthWidgetWrapperProps = 
AuthWidgetWrapperState &
AuthWidgetWrapperDispatch;

const widgetMapping = {
    [WIDGET_TYPE.NEWS]: ArticlesWidget,
    [WIDGET_TYPE.STATISTIC]: StatisticWidget
};

const updateAndSubmitSignInForm = (token) => {
  (document.getElementById('validate-token') as HTMLInputElement).value = token;
  (document.getElementById('signin-form') as HTMLFormElement).submit();
};

const WidgetWrapper = (props: AuthWidgetWrapperProps) => {
  const { isValid, validate, oidcUser, isLoadingUser, isLoggingOut, initAccountInfo, initScoutAis } = props;
  const classes = useStyles();
  const location = useLocation();
  const query = useQuery();
  const prevUserRef = useRef<any>(null);
  const intl = useIntl();
  const { partnerInfo } = useContext(WidgetContext);
  const [partnerLinkedUserGuid, setPartnerLinkedUserGuid] = useState(partnerInfo.userGuid);

  const WidgetComponent = widgetMapping[query.get('type')];
  const oidc = useCustomOidc();

  useEffect(() => {
    if (!isLoggingOut && !isLoadingUser && partnerLinkedUserGuid !== null && navigator.onLine) {
      const currentPathAndSearch = location.pathname + location.search;

      const validUserStatus = isValidUserWidget(oidcUser, partnerLinkedUserGuid);
      validate(validUserStatus);
      if (validUserStatus === USER_SESSION_STATUS.MISMATCH) { // different user with the session
        sessionStorage.removeItem(LOCAL_STORAGE.WIDGET_DATA);
        oidc.userManager.removeUser();
      }
        
      if (validUserStatus !== USER_SESSION_STATUS.VALID) {
        getLoginToken(oidc.userManager, currentPathAndSearch, partnerInfo).then(tokenRes => {
          updateAndSubmitSignInForm(tokenRes.data);
        });
      }
    }
    if (!prevUserRef.current && oidcUser) {
      prevUserRef.current = oidcUser;
      initAccountInfo();
    }

  }, [isLoadingUser]);

  useEffect(() => {
    if (isValid === USER_SESSION_STATUS.VALID) {
      initScoutAis();
    }
  }, isValid);
    
  if (isValid !== USER_SESSION_STATUS.VALID) {
    // if user has no SA account
    if (partnerLinkedUserGuid === null) {
      if (partnerInfo.createMode === partnerOrganisationAccountCreateMode.WIDGET_USER_MANUAL) {
        return (
          <>
            <p>Click here to register a ScoutAsia account</p>
            <PrimaryContainedButtonBase
              onClick={() => {
                window.open(`${SITE_URLS.ACCOUNT_REGISTRATION}?partnerUserGuid=${partnerInfo.partnerUserId}&partnerCode=${partnerInfo.partnerCode}`, '_parent');
              }}
            >
              Register
            </PrimaryContainedButtonBase>
          </>
        );
      }
      if (partnerInfo.createMode === partnerOrganisationAccountCreateMode.USER_MANUAL) {
        window.open(`${SITE_URLS.ACCOUNT_REGISTRATION}?partnerUserGuid=${partnerInfo.partnerUserId}&partnerCode=${partnerInfo.partnerCode}`, '_parent');
        return;
      }

      return <WidgetAccountErrorPage errorHeader={intl.formatMessage(messages.accountNotYetCreated)} errorMessage={intl.formatMessage(messages.contactSupport)} showSupportButton={true} supportEmail={`support@scout.asia?subject=${partnerInfo.partnerCode}-Account%20not%20created`} />;
        
    }
      
    // if user has SA account but not yet activate
    if (partnerLinkedUserGuid !== null && partnerInfo.verifiedDate === null) {
      if (partnerInfo.emailMode === partnerOrganisationInitialEmailMode.EMAIL_VERIFICATION) {
        return <WidgetAccountErrorPage errorHeader={intl.formatMessage(messages.confirmEmailAddress)} errorMessage={intl.formatMessage(messages.checkEmailForVerification, {partnerService: partnerInfo.partnerCode})} showSupportButton={true} supportEmail={`support@scout.asia?subject=${partnerInfo.partnerCode}-Account%20not%20activated`} />;
      } 
      return <WidgetAccountErrorPage errorHeader={intl.formatMessage(messages.userAccountNotFound)} errorMessage={intl.formatMessage(messages.contactForAssistance)} showSupportButton={true} supportEmail={`support@scout.asia?subject=${partnerInfo.partnerCode}-User%20Account%20not%20found`} />;
        
    }

    return <>
      <form action={process.env.LOGIN_BY_PARTNER_SSO_ENDPOINT} method="POST" id="signin-form">
        <input type="hidden" id='validate-token' name="validateToken" />
      </form>
      <BlankPage isWidget={true}/>
    </>;
  }

  return (
    <Box height="100%">
        <WidgetHeader/>
        {WidgetComponent && <WidgetComponent />}
        <AgreementDialog />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isValid: authValidSelector(state),
  oidcUser: oidcUserSelector(state),
  isLoadingUser: isLoadingUserSelector(state),
  isLoggingOut: isLoggingOutSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      validate: authValidateUser,
      initAccountInfo: initAccountInfoAction,
      initScoutAis: initScoutAisAction
    },
    dispatch,
  ),
});

const connectedWrapper = connect<
AuthWidgetWrapperState,
AuthWidgetWrapperDispatch>(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetWrapper);

export default connectedWrapper;