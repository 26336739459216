import { useState } from 'react';
import { COMPANY_CRITERIA_COLOR, RADAR_SEARCH_TYPE, SEARCH_CRITERIA_COLOR, RADAR_ARTICLE_SELECT_OPTIONS, LOCAL_STORAGE, RADAR_ARTICLE_TYPE, EMPTY_STRING } from '@constants/common';
import { RadarSearchCriteria, STATUS_FOR_RADAR } from '@models/radar';
import { isTheSameString } from '@react/utils/common.util';
import { isEmpty } from 'lodash';
import { generateColorHexFromGUID } from '@react/utils/component.util';

interface IIsInProgressParams {
  activeFunc,
  successCb?,
  failCb?,
}

export function useIsInProgress(params: IIsInProgressParams) {
  const [inProgress, setInProgress] = useState(false);
    
  const handleConfirm = (req?: any, otherParam?: any) => {
    setInProgress(true);
    params.activeFunc(req)
      .then((res) => params.successCb && params.successCb(res, req, otherParam))
      .catch((res) => params.failCb && params.failCb(res, req, otherParam))
      .finally(() => setInProgress(false));
  };

  return { inProgress, handleConfirm };
}

export const isRadarFeatureDisabled = !!process.env.RADAR_FEATURE_DISABLED;

export const transformSearchType = (type: string) => {
  switch (type) {
    case RADAR_SEARCH_TYPE.ARTICLE:
      return 'News';
    case RADAR_SEARCH_TYPE.COMPANY:
      return 'Companies';
    case RADAR_SEARCH_TYPE.REPORTFILE:
      return 'Reports';
    default:
      return type;
  }
};

const isDeletedGuid = (guid: string, guidList: string[]) => {
  return guid && !guidList.includes(guid.toUpperCase());
};

const syncExistingRadar = (localRadarSc, searchCriteriaObj, searchCriteriaGuidList: string[], colorArray: string[]) => {
  const newLocalRadarSc = localRadarSc;
  // Clear all the deleted search criterias
  Object.entries(newLocalRadarSc.News).forEach(entry => {
    const [color, guid] = entry as  [string, string];
    if (isDeletedGuid(guid, searchCriteriaGuidList)) {
      newLocalRadarSc.News[color] = null;
    }
  });
  Object.entries(newLocalRadarSc.Companies).forEach(entry => {
    const [color, guid] = entry as  [string, string];
    if (isDeletedGuid(guid, searchCriteriaGuidList)) {
      newLocalRadarSc.Companies[color] = null;
    }
  });
  Object.entries(newLocalRadarSc.Reports).forEach(entry => {
    const [color, guid] = entry as  [string, string];
    if (isDeletedGuid(guid, searchCriteriaGuidList)) {
      newLocalRadarSc.Reports[color] = null;
    }
  });

  // Add all the new search criterias
  searchCriteriaObj[RADAR_SEARCH_TYPE.ARTICLE].forEach(item => {
    const existingSC = Object.values(newLocalRadarSc.News);
    const colorIndex = existingSC.indexOf(null);
    if (!existingSC.includes(item.guid)) {
      newLocalRadarSc.News[colorArray[colorIndex]] = item.guid;
    }
  });
  searchCriteriaObj[RADAR_SEARCH_TYPE.COMPANY].forEach(item => {
    const existingSC = Object.values(newLocalRadarSc.Companies);
    const colorIndex = existingSC.indexOf(null);
    if (!existingSC.includes(item.guid)) {
      newLocalRadarSc.Companies[colorArray[colorIndex]] = item.guid;
    }
  });
  searchCriteriaObj[RADAR_SEARCH_TYPE.REPORTFILE].forEach(item => {
    const existingSC = Object.values(newLocalRadarSc.Reports);
    const colorIndex = existingSC.indexOf(null);
    if (!existingSC.includes(item.guid)) {
      newLocalRadarSc.Reports[colorArray[colorIndex]] = item.guid;
    }
  });

  return newLocalRadarSc;
};

export const setSearchCriteriaToLocalStorage = (radarGuid: string, searchCriteria: RadarSearchCriteria[]) => {
  const localSearchCriteria = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SEARCH_CRITERIA)) || {}; 
  const colorArray = Object.values(SEARCH_CRITERIA_COLOR);
  const searchCriteriaObj = {
    [RADAR_SEARCH_TYPE.ARTICLE]: [],
    [RADAR_SEARCH_TYPE.COMPANY]: [],
    [RADAR_SEARCH_TYPE.REPORTFILE]: [],
  };
  const searchCriteriaGuidList = [];
  searchCriteria.forEach((feed) => {
    searchCriteriaObj[feed.type] && searchCriteriaObj[feed.type].push(feed);
    searchCriteriaGuidList.push(feed.guid.toUpperCase());
  });

  const localRadarSc = localSearchCriteria[radarGuid];
  if (localRadarSc) { // if radar is already existed, we need to sync
    localSearchCriteria[radarGuid] = syncExistingRadar(localRadarSc, searchCriteriaObj, searchCriteriaGuidList, colorArray);
  } else {
    const finalSearchCriteria = {
      News: {},
      Companies: {},
      Reports: {},
    };
    Object.keys(finalSearchCriteria).forEach(t => {
      colorArray.forEach(color => {
        finalSearchCriteria[t][color] = null;
      });
    });
    searchCriteriaObj[RADAR_SEARCH_TYPE.ARTICLE].forEach((item, index) => {
      finalSearchCriteria.News[colorArray[index]] = item.guid;
    });
    searchCriteriaObj[RADAR_SEARCH_TYPE.COMPANY].forEach((item, index) => {
      finalSearchCriteria.Companies[colorArray[index]] = item.guid;
    });
    searchCriteriaObj[RADAR_SEARCH_TYPE.REPORTFILE].forEach((item, index) => {
      finalSearchCriteria.Reports[colorArray[index]] = item.guid;
    });
    localSearchCriteria[radarGuid] = finalSearchCriteria;
  }

  localStorage.setItem(LOCAL_STORAGE.SEARCH_CRITERIA, JSON.stringify(localSearchCriteria));
};

export const operateSearchCriteriaToLocalStorage = (radarGuid: string, type: string, searchCriteriaGuid: string, operation: string) => {
  const localSearchCriteria = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SEARCH_CRITERIA));
  if (!localSearchCriteria || !localSearchCriteria[radarGuid]) return;
  const colorArray = Object.values(SEARCH_CRITERIA_COLOR);
  const currentSearchCriteria = localSearchCriteria[radarGuid][transformSearchType(type)];
  for (const color of colorArray) {
    if (operation === 'add' && !currentSearchCriteria[color]) {
      currentSearchCriteria[color] = searchCriteriaGuid.toUpperCase();
      break;
    }
    if (operation === 'delete' && currentSearchCriteria[color] && isTheSameString(currentSearchCriteria[color], searchCriteriaGuid)) {
      currentSearchCriteria[color] = null;
      break;
    }
  }
  localStorage.setItem(LOCAL_STORAGE.SEARCH_CRITERIA, JSON.stringify(localSearchCriteria));
};

interface ICompanyItems {
  companyFilterName?: string;
  companyListName?: string;
}

export const combineSearchCriteriaWithColor = (searchCriteriaList: RadarSearchCriteria[], radarGuid: string, searchCriteriaType: string, companyItems?: ICompanyItems) => {
  const finalSearchCriterias = [];
  searchCriteriaList.forEach((sc) => {
    if (sc.type === RADAR_SEARCH_TYPE.ARTICLE ) {
      const generatedSearchCriteria = { 
        guid: sc.guid,
        color: generateColorHexFromGUID(sc.guid),
        name: sc.name,
        selectType: RADAR_ARTICLE_TYPE.INDIVIDUAL,
      };
      finalSearchCriterias.push(generatedSearchCriteria);
    }
  })
  if (transformSearchType(searchCriteriaType) === 'News') {
    const matchedCompanies = {
      guid: searchCriteriaList.find(feed => feed.type === RADAR_SEARCH_TYPE.MATCHED_COMPANY)?.guid,
      color: COMPANY_CRITERIA_COLOR.PURPLE,
      name: `${RADAR_ARTICLE_SELECT_OPTIONS.COMPANY_FILTER} ${companyItems && !isEmpty(companyItems.companyFilterName) ? (`: ${  companyItems.companyFilterName}`) : EMPTY_STRING}`,
      selectType: RADAR_ARTICLE_SELECT_OPTIONS.COMPANY_FILTER,
    };
    const radarCompanies = {
      guid: searchCriteriaList.find(
        (feed) => feed.type === RADAR_SEARCH_TYPE.FAVORITE_COMPANY,
      )?.guid,
      color: COMPANY_CRITERIA_COLOR.GREEN,
      name: `${RADAR_ARTICLE_SELECT_OPTIONS.COMPANY_LIST} ${companyItems && !isEmpty(companyItems.companyListName) ? (`: ${  companyItems.companyListName}`) : EMPTY_STRING}`,
      selectType: RADAR_ARTICLE_SELECT_OPTIONS.STARRED_COMPANIES,
    };
    finalSearchCriterias.push(matchedCompanies, radarCompanies);
  }
  return finalSearchCriterias;
};

export const hasPendingOrInitialStatus = (feedList) => {
  return feedList?.find(feed => feed.status === STATUS_FOR_RADAR.PENDING || feed.status === STATUS_FOR_RADAR.INITIAL);
};