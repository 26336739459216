import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { Radar, RadarSearchCriteria, RadarStats } from '@models/radar';
import { fromSearchTerm } from '@react/utils/filter.util';
import moment from 'moment';
import { BOOKMARK_NAVBAR_TAB, FEATURE_CODE, RADAR_MATCHED_COMPANY_LIMIT, RADAR_SEARCH_LIMIT, RADAR_SEARCH_TYPE, RADAR_SEARCH_UPPER_LIMIT, RADAR_SHOWING_LIMIT, ZERO } from '@constants/common';
import { subscriptionFeaturesObjectSelector } from './account.selector';

const getActiveInitRadar = (state) => state.getIn(['Radar', 'activeInitRadar']);
const getRadarList = (state) => state.getIn(['Radar', 'radarList']);
const getRadarStats = (state) => state.getIn(['Radar', 'radarStatsList']);
const searchCriteria = (state) => state.getIn(['SearchCriteria', 'list']);

export const activeInitRadarSelector = createSelector([getActiveInitRadar], (activeInitRadar) => activeInitRadar);
export const radarListSelector = createSelector([getRadarList], (radarList) => radarList);
export const radarListMapSelector = createSelector([getRadarList], (radarList) => {
  return keyBy(radarList, 'guid');
});

export const radarGuidListSelector = createSelector([getRadarList], (radarList: Radar[]) => radarList.map(radar => radar.guid));

export const radarListWithStatsSelector = createSelector([getRadarList, getRadarStats], (radarList: Radar[], radarStats: RadarStats) => {
  const emptyStat = {
    activeCompanyListCount: 0,
    activeMatchedCompanyCount: 0,
    articleCount: 0,
    companyListCount: 0,
    matchedCompanyListCount: 0,
  };
  return radarList.map(radar => {
    const radarStat = radarStats[radar.guid] ? radarStats[radar.guid] : { radarGuid: radar.guid, ...emptyStat };
    return {
      ...radar,
      ...radarStat,
    };
  });
});

export const radarSearchCriteriaSelector = createSelector([searchCriteria], (searches) => (
  searches.map((s: RadarSearchCriteria) => {
    const searchTermObject = JSON.parse(s.searchCriteria);
    if (s.isAllPublishers) {
      delete searchTermObject.publishers;
    }
    if (s.isAllRegions) {
      delete searchTermObject.countries;
    }
    const { term, filters } = fromSearchTerm(JSON.stringify(searchTermObject));
    const filterString = filters.join(', ');

    return {
      ...s,
      searchTerm: term,
      filter: filterString,
      updated: moment(s.lastModifiedDate).format('D MMM YYYY'),
    };
  })
));

export const getRadarLimitFromFeature = createSelector([subscriptionFeaturesObjectSelector], (featuresObject) => {
  if (featuresObject[FEATURE_CODE.RADAR_COUNT]) {
    return Number(featuresObject[FEATURE_CODE.RADAR_COUNT]) || ZERO;
  }
  return ZERO;
});

export const getRadarArticleSCLimitFromFeature = createSelector([subscriptionFeaturesObjectSelector], (featuresObject) => {
  if (featuresObject[FEATURE_CODE.RADAR_ARTICLE_SEARCH_CRITERIA_COUNT]) {
    return Math.min(
      Math.max(Number(featuresObject[FEATURE_CODE.RADAR_ARTICLE_SEARCH_CRITERIA_COUNT]) || RADAR_SEARCH_LIMIT, RADAR_SEARCH_LIMIT)
      , RADAR_SEARCH_UPPER_LIMIT);
  }
  return RADAR_SEARCH_LIMIT;
});

export const checkAllRadarIsFull = createSelector([
  getRadarList,
  getRadarLimitFromFeature,
  getRadarArticleSCLimitFromFeature,
  (state, scType) => scType],
  (radarList: Radar[], radarLimit: number, radarArticleSCLimit: number, scType) => {
  const syncLimit = scType === BOOKMARK_NAVBAR_TAB.NEWSFILTERS
    ? radarArticleSCLimit
    : RADAR_MATCHED_COMPANY_LIMIT;
  const hasSlotForSync = radarList.some((radar) => {
    return getRadarSearchCriteriaCount(radar, scType) < syncLimit;
  });
  
  return !hasSlotForSync && radarList.length >= radarLimit;
});

export const getRadarSearchCriteriaCount = (item: Radar, filterType: string) => {
  if (!item?.searchCriteria) {
    return 0;
  }

  const typeMap = {
    [BOOKMARK_NAVBAR_TAB.NEWSFILTERS]: RADAR_SEARCH_TYPE.ARTICLE,
    [BOOKMARK_NAVBAR_TAB.COMPANIESFILTER]: RADAR_SEARCH_TYPE.COMPANY,
    [BOOKMARK_NAVBAR_TAB.COMPANIES]: RADAR_SEARCH_TYPE.FAVORITE_COMPANY,
  };

  return item?.searchCriteria.filter((x) => x.type === typeMap[filterType]).length;
};