import { FONT } from '@constants/style';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageBox: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(1.5),
    },
    infoBox: {
      backgroundColor: '#eef7fe',
    },
    errorBox: {
      backgroundColor: '#ffeef0',
    },
    errorLabel: {
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    messageIcon: {
      fontSize: theme.spacing(3),
      marginRight: theme.spacing(1.75),
    },
    renameError: {
      padding: theme.spacing(1.5),
      marginBottom: '32px',
    },
    errorTop: {
      padding: theme.spacing(1.5), 
      marginBottom: '12px',
    },
    saveFilterError: {
      padding: theme.spacing(1.5),
      marginTop: '12px',
    },
    quickAccess: {
      border: '1px solid #b51225',
      marginTop: '10px',
      position: 'absolute',
      width: '100%',
      bottom: '16px',
      '& > div': {
        height: '50px',
        [theme.breakpoints.down('sm')]: {
          height: 'inherit',
        },
        '& > div:nth-child(1)': {
          width: '44px',
          height: 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.error.dark,
          '& > svg': {
            fontSize: '20px',
            margin: '0',
          },
        },
        '& > div:nth-child(2)': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          margin: '8px 10px',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          '& > p:nth-child(1)': {
            color: '#b51225',
            fontWeight: FONT.WEIGHT.SEMIBOLD,
            marginRight: '5px',
          },
        },
      },
    },
    maximumRadarMonitor: {
      padding: '12px',
    }
  }),
);

export default useStyles;