import React, { useEffect } from 'react';
import { Route, withRouter, RouteComponentProps, Redirect } from 'react-router';
import { authValidateUser } from '@redux/actions/auth.action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ROUTE_PATHS } from '@constants/routes';
import { isValidUserWidget } from '@react/utils/auth.util';
import BlankPage from '@react/components/blank/blank.component';
import { authValidSelector } from '@redux/selectors/auth.selector';
import { isLoadingUserSelector, oidcUserSelector } from '@redux/selectors/oidc.selector';
import { isLoggingOutSelector } from '@redux/selectors/account.selector';
import { LOCAL_STORAGE } from '@constants/common';
import { USER_SESSION_STATUS } from '@constants/state';

interface CallbackRouteState {
  isValid: string;
  oidcUser: any;
  isLoadingUser: boolean;
  isLoggingOut: boolean;
}

interface CallbackRouteDispatch {
  validate: (user) => void;
}

interface CallbackRouteOwnProps extends RouteComponentProps {
  path: string;
  exact?: boolean;
  component?;
  render?;
}

type CallbackRouteProps = 
  CallbackRouteState &
  CallbackRouteDispatch &
  CallbackRouteOwnProps;
 
const WidgetCallbackRoute: React.FC<CallbackRouteProps> = (props) => {
  const { isValid, validate, oidcUser, isLoadingUser, isLoggingOut } = props;

  useEffect(() => {
    if (!isLoggingOut && !isLoadingUser) {
      validate(isValidUserWidget(oidcUser));
    } 
  }, [oidcUser, isLoadingUser]);
  
  if (isValid === undefined) {
    return <BlankPage isWidget={true}/>;
  }
  if (isValid === USER_SESSION_STATUS.EMPTY ||
    isValid === USER_SESSION_STATUS.EXPIRED ||
    isValid === USER_SESSION_STATUS.MISMATCH) {
    return (
      <Route
        {...props}
      />
    );
  }
  //   // TODO: if oidc logged in then go to redirectUrl. (this fix when start from p225 delivery page, but is not good practice.)
  //   let redirectUrl = sessionStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) ? decodeURIComponent(sessionStorage.getItem(LOCAL_STORAGE.REDIRECT_URL)) : ROUTE_PATHS.MAIN;
  //   const stateRedirectUrl = localStorage.getItem(`${stateQuery}-redirectUrl`);
  //   if (stateRedirectUrl) {
  //     redirectUrl = stateRedirectUrl;
  //     localStorage.removeItem(`${stateQuery}-redirectUrl`);
  //   }
  
//   return <Redirect to={{ pathname: redirectUrl }} />;
};
 
const mapStateToProps = (state) => ({
  isValid: authValidSelector(state),
  oidcUser: oidcUserSelector(state),
  isLoadingUser: isLoadingUserSelector(state),
  isLoggingOut: isLoggingOutSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      validate: authValidateUser,
    },
    dispatch,
  ),
});

const connected = connect<
CallbackRouteState,
CallbackRouteDispatch,
CallbackRouteOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetCallbackRoute);

export default withRouter(connected);

