import React from 'react';
import { Box, Typography } from '@mui/material';
import ScoutAsiaIcon from '../icons/scoutAsiaIcon.component';
import useStyles from './styles';

const WidgetHeader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapperHeaderBox}>
            <Typography className={classes.wrapperHeaderText}>Powered by</Typography>
            <ScoutAsiaIcon className={classes.headerSAIcon}/>
        </Box>
    )
}

export default WidgetHeader;