export const COMPANY_STATUS = {
  COMPANY_LIST: 'COMPANY_LIST',
};

export const USER_SESSION_STATUS = {
  VALID: 'VALID',
  EXPIRED: 'EXPIRED',
  MISMATCH: 'MISMATCH',
  EMPTY: 'EMPTY',
};
