import { ARTICLE, DRAWER, COUNTRY, DASHBOARD } from '@constants/actions';
import { API_ENDPOINT } from '@constants/api';
import { sendPostRequestWithState, sendPostRequestWithKey } from 'skyeye-fe-common-util';
import { EMPTY_STRING } from '@constants/common';
import { DetailAnalyticModel } from '@react/utils/analytics/models/detailanalytics.model';
import ArticleListReq from '@models/articleListReq';
import { IRecommendNewsReq } from '@models/recommendNews';
import { TargetListMyNewsReq } from '@models/targetListMyNewsReq';
import { isEmpty } from 'lodash';
import { IArticle } from '@models/article';
import ContentAggregationReq from '@models/contentAggregationReq';
import { formatUrl, formatUrls } from '@react/utils/common.util';
import { IListProps } from '@interface/tableProps';
import { getUserGuid } from '../selectors/account.selector';
import createAction from './actionCreator';
import { increaseProgress, decreaseProgress } from './progress.actions';

export const downloadEmiReport = (fileName: string) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(ARTICLE.EMI.DOWNLOAD.IN_PROGRESS));

    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_EMIS_DOWNLOAD, { fileName }, getState);

    return new Promise((resolve, reject) => {
      dispatch(createAction(ARTICLE.EMI.DOWNLOAD.NOT_IN_PROGRESS));

      if (response && response.isSuccess) {
        dispatch(createAction(ARTICLE.EMI.DOWNLOAD.RESULT, { data: response.data }));
        // `response.data` is the link string
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  };
};

const selectedArticleAction = (selectedArticle) => {
  return createAction(ARTICLE.ARTICLE_SELECTED, { selectedArticle: formatUrl(selectedArticle) });
};

export const getContentAggregation = (contentAggregationReq: ContentAggregationReq = {}) => {
  let request: ContentAggregationReq = {
    ...contentAggregationReq,
  };

  if (isEmpty(request)) {
    request = {
      searchStr: EMPTY_STRING,
    };
  }
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    const response = await sendPostRequestWithState(API_ENDPOINT.AGGREGATION_CONTENT, request, getState);
    return new Promise<void>((resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        dispatch(createAction(DRAWER.ARTICLE.AGGREGATE.UPDATE, { aggregation: response.aggregation }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const searchNews =
  (newsListReq: ArticleListReq = {}, withAPIKey: boolean = false) => {
    const request: ArticleListReq = {
      ...newsListReq,
      aggregation: false,
    };
    return async function (dispatch: any, getState: any) {
      dispatch(createAction(ARTICLE.SEARCH_IN_PROGRESS));
      const response = withAPIKey ? await sendPostRequestWithKey(API_ENDPOINT.SEARCH_NEWS, request, process.env.API_KEY) :
        await sendPostRequestWithState(API_ENDPOINT.SEARCH_NEWS, request, getState);
      return new Promise((resolve, reject) => {
        dispatch(createAction(ARTICLE.SEARCH_NOT_IN_PROGRESS));
        if (response && response.isSuccess) {
          dispatch(createAction(ARTICLE.ARTICLE_LIST, { data: response.data, totalCount: response.totalCount }));
          dispatch(createAction(ARTICLE.STORE_ARTICLE_SEARCH_STR, { searchStr: newsListReq.searchStr }));
          resolve(response);
        } else {
          reject(response);
        }
      });
    };
  };

export const searchCountrySectorDetailArticles = (newsListReq: ArticleListReq = {}) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(COUNTRY.DETAILS.ARTICLE.LIST.IN_PROGRESS));

    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_NEWS, newsListReq, getState);
    return new Promise<void>((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(COUNTRY.DETAILS.ARTICLE.LIST.NOT_IN_PROGRESS));

      if (response && response.isSuccess) {
        dispatch(createAction(COUNTRY.DETAILS.ARTICLE.LIST.RESULT, { data: response.data }));
        resolve();
      } else {
        reject(response);
      }
    });
  };
};

export const searchTrendingTopicsByCountry = (country: string) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(createAction(DASHBOARD.TRENDING_TOPICS.LIST.IN_PROGRESS));

    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_TRENDING_TOPICS, { country }, getState);
    return new Promise((resolve, reject) => {
      dispatch(decreaseProgress());
      dispatch(createAction(DASHBOARD.TRENDING_TOPICS.LIST.NOT_IN_PROGRESS));

      if (response && response.isSuccess) {
        dispatch(createAction(DASHBOARD.TRENDING_TOPICS.LIST.RESULT, { trending: formatUrls(response.data) }));
        resolve(null);
      } else {
        dispatch(createAction(DASHBOARD.TRENDING_TOPICS.LIST.RESULT, { trending: [] }));
        reject(response);
      }
    });
  };
};

export const searchTrendingTopicsByTopPage = (country: string, fromTopPage = true) => {
  return async function (dispatch: any, getState: any) {
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_TRENDING_TOPICS, { country: country || 'ALL' }, getState);
    return new Promise((resolve, reject) => {
      if (response && response.isSuccess) {
        let articleIds = response.data.reduce((data, current) => {
          data = data.concat(current?.articles.map((x) => x.article_id) || []);
          return data;
        }, []);
        fromTopPage ? dispatch(createAction(DASHBOARD.TOP_PAGE_TRENDING_TOPICS.ALL, { all: articleIds })):
        dispatch(createAction(DASHBOARD.TOP_PAGE_TRENDING_TOPICS.LATEST_NEWS, { latestNews: articleIds }));
        resolve(null);
      } else {
        reject(response);
      }
    });
  };
};

export const searchDashboardNews = () => {
  return async function (dispatch: any, getState: any) {
    return new Promise((_resolve, reject) => {
      dispatch(increaseProgress());
      dispatch(createAction(DASHBOARD.ARTICLES.LIST.IN_PROGRESS));
      const promiseList = [
        new Promise(async (resolve1, reject1) => {
          const newsListReq: ArticleListReq = { select: 10 };
          const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_NEWS, newsListReq, getState);
          if (response && response.isSuccess) {
            resolve1({ news: response.data });
          } else {
            reject1();
          }
        })
      ];
      Promise.all(promiseList).then((values: any) => {
        let data = {};
        values.forEach((value) => {
          data = {
            ...data,
            ...value,
          };
        });
        dispatch(createAction(DASHBOARD.ARTICLES.LIST.RESULT, data));
        dispatch(decreaseProgress());
        dispatch(createAction(DASHBOARD.ARTICLES.LIST.NOT_IN_PROGRESS));
      }).catch(() => {
        reject();
        dispatch(createAction(DASHBOARD.ARTICLES.LIST.NOT_IN_PROGRESS));
      });
    });
  };
};

export const getArticle = (getNewsReq) => {
  return async function (dispatch: any, getState: any) {
    dispatch(increaseProgress());
    dispatch(selectedArticleAction({}));
    dispatch(createAction(ARTICLE.RETRIEVE_IN_PROGRESS));

    const response = await sendPostRequestWithState(API_ENDPOINT.RETRIEVE_NEWS, getNewsReq, getState);
    return new Promise(async (resolve, reject) => {
      dispatch(decreaseProgress());
      if (response && response.isSuccess) {
        const article: IArticle = response.data[0];

        dispatch(selectedArticleAction(article));

        const detailAnalyticModel: DetailAnalyticModel = new DetailAnalyticModel(article.publisher, article.entityId, '', article.status !== 'ACTIVE', article.source, article.sourceKey);

        const pageTrackingData = {
          
          ...detailAnalyticModel,
          content: { asset_type: 'story' },
        };

        dispatch(createAction(ARTICLE.RETRIEVE_NOT_IN_PROGRESS));
        resolve({
          // data: article,
          // message: NOTIFICATION.TARGET_LIST.RETRIEVE_SUCCESSFUL
        });
      } else {
        dispatch(createAction(ARTICLE.RETRIEVE_NOT_IN_PROGRESS));
        reject(response);
      }
    });
  };
};

export const searchRecommend = (guid) => {
  return async function (dispatch: any, getState: any) {
    dispatch(createAction(ARTICLE.RETRIEVE_RECOMMEND_IN_PROGRESS));
    const request: IRecommendNewsReq = {
      guid,
    };
    const response = await sendPostRequestWithState(API_ENDPOINT.SEARCH_RECOMMEND_NEWS, request, getState);
    return new Promise<void>(async (resolve, reject) => {
      if (response && response.isSuccess) {
        const { recommend, mightLike } = response?.data;
        dispatch(createAction(ARTICLE.RECOMMEND, { recommend }));
        dispatch(createAction(ARTICLE.MIGHT_LIKE, { mightLike }));
        dispatch(createAction(ARTICLE.RETRIEVE_RECOMMEND_NOT_IN_PROGRESS));
        resolve(null);
      } else {
        dispatch(createAction(ARTICLE.RETRIEVE_RECOMMEND_NOT_IN_PROGRESS));
        reject(response);
      }
    });
  };
};

export const updateArticleListProps = (props: IListProps) => {
  return function (dispatch) {
    dispatch(createAction(ARTICLE.UPDATE_LIST_PROPS, { ...props }));
  };
};

export const updateTrendingTopicFilteredCountry = (filteredCountry) => {
  return function (dispatch) {
    dispatch(createAction(DASHBOARD.TRENDING_TOPICS.FILTERED_COUNTRY, { filteredCountry }));
  };
};

export const updateTrendingTopicSelectedCluster = (clusterId) => {
  return function (dispatch) {
    dispatch(createAction(DASHBOARD.TRENDING_TOPICS.SELECTED_CLUSTER, { clusterId }));
  };
};

export const getTrendingTopicScrollHeight = (scrollHeight) =>{
  return function (dispatch) {
    dispatch(createAction(DASHBOARD.TRENDING_TOPICS.SELECTED_CLUSTER_SCROLL_HEIGHT, { scrollHeight }));
  };
};

export const updateDefaultLanguage = (language) => {
  return function (dispatch) {
    dispatch(createAction(DASHBOARD.DEFAULT_LANGUAGE, { language }));
  };
};