import React, { ReactNode } from 'react';
import { Sector } from 'skyeye-common-const';
import { keyBy, Dictionary, isEmpty, capitalize } from 'lodash';
import { Typography } from '@mui/material';
import CustomLink from '@react/components/customLink.component';
import { SITE_URLS } from '@constants/routes';
import { FONT } from '@constants/style';
import { COMPANY_CONSTANT, EMPTY_STRING, ENTITY_TYPE_TEXT, OWNERSHIP_TABLE, SCOUTASIA_SOURCE_UNDEFINED, TRANSLATION_PUBLISHER, TTFS_GLOBAL } from '@constants/common';
import { LOCALIZATION } from '@constants/localization';
import { ICompanyExportData, ICompanyOfficer } from '@models/company';
import { executive, board, founder } from '@constants/peopleGroupTemplate';
import { formatUnixToDate } from 'skyeye-fe-common-util';
import { seerBoard, seerExecutive } from '@constants/peopleGroupScoutAsiaTemplate';
import { CompanyDetailTableHeaderFactsetOwnershipDisplay, CompanyDetailHeaderFactsetSupplyChainDisplay, CompanyDetailHeaderFactsetCompetitorsDisplay } from '@models/relationship';
import { formatCurrency, getNumValue, orderBy } from './common.util';

const omitLevel2SectorCodeArticle = ['0500', '1300', '4500', '6500', '7500', '9500'];
const omitLevel2SectorCodeCompany = ['0500', '1300', '4500', '5500', '6500', '7500', '9500', '3300', '5700', '8300'];

const omitLevel3SectorCodeArticle = ['2350', '3350', '5550', '5750', '8350', '8770'];

const getDirectParentCode = (sectorCode: string, parentLevel: number) => {
  let parentCode;
  
  if (sectorCode.substring(0, parentLevel) === '89') {
    // Financial Services
    parentCode = '8700';
  } else if (sectorCode.length === 4) {
    parentCode = `${sectorCode.substring(0, parentLevel)}${sectorCode.substring(parentLevel).replace(/\d/gi, '0')}`;
  } else if (sectorCode.length === 5) {
    // Government Sector
    parentCode = `${sectorCode.substring(0, 3)}${sectorCode.substring(3, 5).replace(/\d/gi, '0')}`;
  }
  return parentCode;
};

export const getSectorComponents = (
  sectorCodes: string[],
  sectorDetails: Sector[],
  entityType: string,
  sectorLinkComponent: (detail: Sector) => ReactNode,
) => {
  const filteredSectorDetails: Sector[] = [];
  const sectorComponents = [];
  const sectorParentList = [];
  const sectorDetailsToBeDisplayed = [];
  const omitLevel2SectorCode = (entityType === ENTITY_TYPE_TEXT.ARTICLE) ? omitLevel2SectorCodeArticle : omitLevel2SectorCodeCompany;
  const omitLevel3SectorCode = (entityType === ENTITY_TYPE_TEXT.ARTICLE) ? omitLevel3SectorCodeArticle : [];

  for (const sector of sectorDetails) {
    const tempSector = sector;
    if (sector.level === 1 || sector.level === 4) {
      sectorDetailsToBeDisplayed.push(tempSector);
    }
    if (sector.level === 2 && !omitLevel2SectorCode.includes(sector.code)) {
      sectorDetailsToBeDisplayed.push(tempSector);
    }
    if (sector.level === 3 && !omitLevel3SectorCode.includes(sector.code)) {
      sectorDetailsToBeDisplayed.push(tempSector);
    }
  }
  
  for (const sector of sectorDetailsToBeDisplayed) {
    const newSector = sector;
    if (sector.level !== 1) {
      let parentLevel = sector.level - 1;
      if (parentLevel !== 1) {
        let newParentCode = getDirectParentCode(sector.code, parentLevel);
        // need to make sure the parent code is part of the sector code in the list
        if (sectorDetailsToBeDisplayed.filter(item => item.code === newParentCode).length === 0) {
          parentLevel -= 1;
          
          if (parentLevel === 1 && newParentCode === '0500') { // Oil & Gas
            newParentCode = newSector.parent;
          } else {
            newParentCode = getDirectParentCode(sector.code, parentLevel);
          }
        }
        newSector.parent = newParentCode;
      }
      sectorParentList.push(newSector.parent);
    }
    filteredSectorDetails.push(newSector);
  }

  const filteredSectorMap = keyBy(filteredSectorDetails, 'code');
  const sortSectorCodes = [...sectorCodes].sort();
  sortSectorCodes.forEach((sectorCode) => {
    // no need to render parent separately if it's already showed by child
    if (sectorParentList.includes(sectorCode)) {
      return;
    }

    // create sector item 
    const sectorItem = generateSectorItem(filteredSectorMap[sectorCode], filteredSectorMap, sectorLinkComponent);
    if (sectorItem) {
      sectorComponents.push(sectorItem);
    }
  });

  return sectorComponents;
};

const generateSectorItem = (
  sectorDetail: Sector,
  sectorMap: Dictionary<Sector>,
  sectorLinkComponent: (detail: Sector) => ReactNode,
) => {
  if (!sectorDetail) {
    return null;
  }
  if (!sectorDetail.parent) {
    return sectorLinkComponent(sectorDetail);
  }
  const parentSector: Sector = sectorMap[sectorDetail.parent];
  if (!parentSector) {
    return sectorLinkComponent(sectorDetail);
  }
  return (
    <React.Fragment>
      {generateSectorItem(parentSector, sectorMap, sectorLinkComponent)}
      <Typography component="span">{' > '}</Typography>
      {sectorLinkComponent(sectorDetail)}
    </React.Fragment>
  );
};


const getSectorLinkComponent = (sectorDetail: Sector) => {
  return (
    <CustomLink
      style={!sectorDetail.parent ? { fontWeight: FONT.WEIGHT.SEMIBOLD } : {}}
      to={`${SITE_URLS.SECTORS_OVERVIEW}/${sectorDetail.code}`}
    >
      {sectorDetail.label}
    </CustomLink>
  );
};

export const transformSector = (sectors: string[], sectorDetails: Sector[], entityType: string) => {
  const sectorComponents = getSectorComponents(sectors, sectorDetails, entityType, getSectorLinkComponent);
  return sectorComponents.map((sectorComponent, index) => [
    sectorComponent,
    index < sectorComponents.length - 1 ? ', ' : null,
  ],
  );
};

export const transformSectorToString = (sector: string[], sectorDetails: Sector[], delimiter: string = ' > ') => {
  return (sector.map((code) => {
    const sectorDetail = sectorDetails.find(detail => code === detail.code);
    const display = [];
    if (sectorDetail !== undefined && sectorDetail.label.length > 0) {
      display.push(sectorDetail.label);
    }
    return display;
  })).join(delimiter);
};

export const transformNaics = (naics: string[], naicsDetails: any[]) => {
  return (naics.map((code) => {
    const naicsDetail = naicsDetails.find(detail => code === detail.code);
    const display = [code];
    if (naicsDetail !== undefined && naicsDetail.label.length > 0) {
      display.push(naicsDetail.label);
    }
    return display.join(': ');
  })).join(' > ');
};

export const renderMultipleComp  = (keyPrefix, Comp) => {
  const multipleComps = [];
  for (let i = 0; i < 5; i++ ) {
    multipleComps.push(
      <Comp key={`${keyPrefix}_${i}`} />,
    );
  }
  return (
    <>{multipleComps}</>
  );
};


const getAddressesField = (addresses) => {
  if (addresses.length === 0) {
    return {
      'Addresses': EMPTY_STRING,
      'Address Type': EMPTY_STRING,
      'City': EMPTY_STRING,
      'Postal Code': EMPTY_STRING,
    };
  }
  const address = [[addresses[0].addressLine1, addresses[0].addressLine2, addresses[0].addressLine3, addresses[0].addressLine4].join(', '),
    `city: ${addresses[0].city}`, `postal code ${addresses[0].postalCode}`].join('; ');
  return {
    'Addresses': address,
    'Address Type': EMPTY_STRING,
    'City': addresses[0].city,
    'Postal Code': addresses[0].postalCode,
  };
};

const getPeopleFields = (people: ICompanyOfficer[], source) => {
  let executives = EMPTY_STRING;
  let boards = EMPTY_STRING;
  let founders = EMPTY_STRING;

  const executiveTemplate = source === 'FACTSET' ? executive : seerExecutive;
  const boardTemplate = source === 'FACTSET' ? board : seerBoard;

  if (!isEmpty(people)) {
    for (const person of people) {
      if (executiveTemplate[person.type]) {
        executives += `${executiveTemplate[person.type]}:${person.name}`;
      } else if (boardTemplate[person.type]) {
        boards += `${boardTemplate[person.type]}:${person.name}`;
      } else if (founder[person.type]) {
        founders += `${founder[person.type]}:${person.name}`;
      }
    }
  }

  return { executives, boards, founders };
};

const extractTradeOrLocalName = (nameArray, nameType) => {
  if (nameArray.length === 0) {
    return EMPTY_STRING;
  }

  const nameObj = nameArray.find((nameItem) => {
    if (nameItem.nameType.toLowerCase() === nameType) {
      return nameItem.name;
    }
  });
  return nameObj ? nameObj.name : EMPTY_STRING;
};

export const constructCompanyExportObject = (companiesData: ICompanyExportData[]) => {
  return companiesData.reduce((companyExport, companyData) => {
    const commonCompanyData = {
      'Company Name': companyData.name,
      'Trade Name': extractTradeOrLocalName(companyData.names, COMPANY_CONSTANT.NAME.TYPE.TRADE),
      'Local Name': extractTradeOrLocalName(companyData.names, COMPANY_CONSTANT.NAME.TYPE.LOCAL),
      'Listed?': companyData.listingStatus,
      'Primary Equity': companyData.tickerExchange,
      'Country or region': companyData.country,
      ...getAddressesField(companyData.addresses),
      'Phone Number': companyData.telephone === SCOUTASIA_SOURCE_UNDEFINED ? EMPTY_STRING : companyData.telephone,
      'Email': companyData.email === SCOUTASIA_SOURCE_UNDEFINED ? EMPTY_STRING : companyData.email,
      'Company Website': companyData.url,
      'Industry and Sector': transformSectorToString(companyData.sector, companyData.sectorDetails),
      'Sector: NAICS 5 Primary Code and Label': transformNaics(companyData.naics, companyData.naicsDetails),
    };

    const currencyData = {
      'Local Currency': companyData.currency,
      'Currency Exchange Rate': companyData.usdExchangeRate,
      'Reference Currency': companyData.referenceCurrency,
    };

    const { executives, boards, founders } = getPeopleFields(companyData.officers, companyData.officersSource);

    const companyMemberData = {
      'CEO and Executives': executives,
      'Board Members': boards,
      'Founders': founders,
    };

    if (isEmpty(companyData.financialData)) {
      companyExport.push({
        ...commonCompanyData,
        'Year': EMPTY_STRING,
        ...currencyData,
        'Revenue': EMPTY_STRING,
        'Total Assets': EMPTY_STRING,
        'Shareholders Funds': EMPTY_STRING,
        'Profit Before Tax': EMPTY_STRING,
        'Market Cap': EMPTY_STRING,
        'Market Price': EMPTY_STRING,
        'Sales / Revenue': EMPTY_STRING,
        'Gross Income': EMPTY_STRING,
        'Gross Margin (%)': EMPTY_STRING,
        'EBIT': EMPTY_STRING,
        'EBIT Margin (%)': EMPTY_STRING,
        'EBITDA': EMPTY_STRING,
        'EBITDA Margin (%)': EMPTY_STRING,
        'Net Income': EMPTY_STRING,
        'Net Profit Margin (%)': EMPTY_STRING,
        'Cash & Short Term Investments': EMPTY_STRING,
        'Cash & Short Term Investments / Total Assets (%)': EMPTY_STRING,
        'Asset Turnover': EMPTY_STRING,
        'Return on Assets (%)': EMPTY_STRING,
        'Total Debt': EMPTY_STRING,
        'Total Debt / Total Assets (%)': EMPTY_STRING,
        'Total Debt / Total Equity (%)': EMPTY_STRING,
        'Net Debt': EMPTY_STRING,
        'Net Debt / Total Equity (%)': EMPTY_STRING,
        'Total Liabilities': EMPTY_STRING,
        "Total Shareholders' Equity": EMPTY_STRING,
        "Total Shareholders' Equity / Total Assets (%)": EMPTY_STRING,
        'Return on Equity (%)': EMPTY_STRING,
        'Net Assets': EMPTY_STRING,
        'Contributed Capital': EMPTY_STRING,
        'Net Operating Cash Flow': EMPTY_STRING,
        'Cash Flow Return on Invested Capital (%)': EMPTY_STRING,
        'Capital Expenditures (Total)': EMPTY_STRING,
        'Net Investing Cash Flow': EMPTY_STRING,
        'Net Financing Cash Flow': EMPTY_STRING,
        'Free Cash Flow': EMPTY_STRING,
        'Free Cash Flow Yield (%)': EMPTY_STRING,
        ...companyMemberData,
        'Number of Employees': getNumValue(companyData.numOfEmployees),
        'scoutAsia Url': `${window.location.origin}${SITE_URLS.COMPANY_DETAIL}/${companyData.entityId}`,
      });
    } else {
      for (const financialData of companyData.financialData) {
        companyExport.push({
          ...commonCompanyData,
          'Year': formatUnixToDate(financialData.closingDate, 'YYYY'),
          ...currencyData,
          'Revenue': getNumValue(financialData.revenueUsd),
          'Total Assets': getNumValue(financialData.totalAssetsUsd),
          'Shareholders Funds': getNumValue(financialData.shareholdersFundsUsd),
          'Profit Before Tax': getNumValue(financialData.profitLossUsd),
          'Market Cap': getNumValue(companyData.marketCapitalization),
          'Market Price': getNumValue(companyData.marketPrice),
          'Sales / Revenue': financialData.revenueUsd,
          'Gross Income': financialData.grossIncomeUsd,
          'Gross Margin (%)': financialData.grossIncomeMarginUsd,
          'EBIT': financialData.ebitUsd,
          'EBIT Margin (%)': financialData.ebitMarginUsd,
          'EBITDA': financialData.ebitdaUsd,
          'EBITDA Margin (%)': financialData.ebitdaMarginUsd,
          'Net Income': financialData.netIncomeUsd,
          'Net Profit Margin (%)': financialData.netIncomeMarginUsd,
          'Cash & Short Term Investments': financialData.cashAndShortTermInvestmentUsd,
          'Cash & Short Term Investments / Total Assets (%)': financialData.cashAndShortTermInvestmentOverTotalAssestsUsd,
          'Asset Turnover': financialData.assetTurnoverUsd,
          'Return on Assets (%)': financialData.returnOnAssetsPercentageUsd,
          'Total Debt': financialData.totalDebtUsd,
          'Total Debt / Total Assets (%)': financialData.totalDebtOverTotalAssetsUsd,
          'Total Debt / Total Equity (%)': financialData.totalDebtOverTotalEquityUsd,
          'Net Debt': financialData.netDebtUsd,
          'Net Debt / Total Equity (%)': financialData.netDebtOverTotalEquityUsd,
          'Total Liabilities': financialData.totalLiabilitiesUsd,
          "Total Shareholders' Equity": financialData.shareholdersFundsUsd,
          "Total Shareholders' Equity / Total Assets (%)": financialData.shareholdersFundsOverTotalAssetsUsd,
          'Return on Equity (%)': financialData.returnOnEquityPercentageUsd,
          'Net Assets': financialData.netAssetsUsd,
          'Contributed Capital': financialData.contributedCapitalUsd,
          'Net Operating Cash Flow': financialData.netOperatingCashFlowUsd,
          'Cash Flow Return on Invested Capital (%)': financialData.cashFlowReturnOnInvestedCapitalUsd,
          'Capital Expenditures (Total)': financialData.totalCapitalExpendituresUsd,
          'Net Investing Cash Flow': financialData.netInvestingCashFlowUsd,
          'Net Financing Cash Flow': financialData.netFinancingCashFlowUsd,
          'Free Cash Flow': financialData.freeCashFlowUsd,
          'Free Cash Flow Yield (%)': financialData.freeCashFlowYieldUsd,
          ...companyMemberData,
          'Number of Employees': getNumValue(companyData.numOfEmployees),
          'scoutAsia Url': `${window.location.origin}${SITE_URLS.COMPANY_DETAIL}/${companyData.entityId}`,
        });
      }
    }
    
    return companyExport;
  }, []);
};

/* eslint-disable */
export const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += (`00${  value.toString(16)}`).slice(-2);
  }
  return colour;
};
/* eslint-enable */


export const checkMentionedCountryValue = (value?: string) => {
  if (value === TTFS_GLOBAL) {
    return EMPTY_STRING;
  }

  return value;
};

const getFormattedRevenue = (intl, revenue) => {
  return revenue !== null ? formatCurrency(intl, 'USD', revenue) : '-';
};

export const constructCompanyOwnershipExportObject = (ownershipData: any[], tab: OWNERSHIP_TABLE, intl) => {
  return ownershipData.reduce((ownershipExport, data) => {
    let exportData = {};
    if (tab === OWNERSHIP_TABLE.OWNERSHIP) {
      exportData = {
        [CompanyDetailTableHeaderFactsetOwnershipDisplay.Holder]: data.fromName,
        [CompanyDetailTableHeaderFactsetOwnershipDisplay.Country]: !isEmpty(data.fromCountry) ? data.fromCountry : '-',
        [CompanyDetailTableHeaderFactsetOwnershipDisplay.Ownership]: data.ownershipPercentageOwnership,
        [CompanyDetailTableHeaderFactsetOwnershipDisplay.Position]: data.ownershipPosition,
        [CompanyDetailTableHeaderFactsetOwnershipDisplay.PositionChange]: typeof data.ownershipPositionChangeSixMonth == 'number' ? data.ownershipPositionChangeSixMonth : '-',
        [CompanyDetailTableHeaderFactsetOwnershipDisplay.ReportDate]: formatUnixToDate(data.ownershipReportDate, 'DD MMM YYYY'),
      };
    }
    if (tab === OWNERSHIP_TABLE.SUPPLYCHAIN) {
      const sortedRelationship = orderBy(data.types, ['SUPPLIER', 'CUSTOMER', 'PARTNER']);
      const formatRelationship = sortedRelationship.map((item) => {
        return capitalize(item);
      });

      exportData = {
        [CompanyDetailHeaderFactsetSupplyChainDisplay.Company]: data.fromName,
        [CompanyDetailHeaderFactsetSupplyChainDisplay.Revenue]: getFormattedRevenue(intl, data.fromRevenue),
        [CompanyDetailHeaderFactsetSupplyChainDisplay.Country]: !isEmpty(data.fromCountry) ? data.fromCountry : '-',
        [CompanyDetailHeaderFactsetSupplyChainDisplay.Sector]: !isEmpty(data.fromSector) ? data.fromSector : '-',
        [CompanyDetailHeaderFactsetSupplyChainDisplay.Relationship]: formatRelationship.toString(),
      };
    }
    if (tab === OWNERSHIP_TABLE.COMPETITORS) {
      exportData = {
        [CompanyDetailHeaderFactsetCompetitorsDisplay.Company]: data.fromName,
        [CompanyDetailHeaderFactsetCompetitorsDisplay.Revenue]: getFormattedRevenue(intl, data.fromRevenue),
        [CompanyDetailHeaderFactsetCompetitorsDisplay.Country]: !isEmpty(data.fromCountry) ? data.fromCountry : '-',
        [CompanyDetailHeaderFactsetCompetitorsDisplay.Sector]: !isEmpty(data.fromSector) ? data.fromSector : '-'
      };
    }

    ownershipExport.push({
      ...exportData,
    });
    
    return ownershipExport;
  }, []);
};

export const generateColorHexFromGUID = (guid: string, minHue: number = 240, maxHue: number = 300): string => {
  if (!guid) {
    return '#FFFFFF';
  }
    // Calculate a numeric value from the GUID
  let hash = 0;
  for (let i = 0; i < guid.length; i++) {
    hash = (hash << 5) - hash + guid.charCodeAt(i);
  }

  // Ensure the hue is within the specified range
  const hueRange = maxHue - minHue;
  const hue = (Math.abs(hash) % hueRange + minHue) % 360;

  // Convert the hue to an HSL color string
  const hslColor = `hsl(${hue}, 50%, 50%)`;

  // Convert HSL to RGB
  const rgbColor = hslToRgb(hslColor);

  return rgbColor;
}

// Convert HSL to RGB
function hslToRgb(hsl: string): string {
  const match = hsl.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
  if (!match) return '';

  const h = parseInt(match[1]) / 360;
  const s = parseInt(match[2]) / 100;
  const l = parseInt(match[3]) / 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l; // Achromatic (gray)
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}