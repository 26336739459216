import { NOTIFICATIONSETTING } from '@constants/actions';
import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import { NotificationResourceType } from '@models/notificationSettingReq';
import { SAVED_SEARCH_TYPE } from '@constants/common';

const initialState = Map({
  [NotificationResourceType.SAVED_SEARCH]: [],
  [NotificationResourceType.TARGET_LIST]: [],
  [NotificationResourceType.SCOUTAI]: [],
  alerts: [],
  rightDrawer: [],
});

const formatArrayByType = (items: any[], type: string) => {
  return items.map(item => {
    return {
      ...item,
      type,
    };
  });
};

const updateAlertDetails = (alertsArray, payload) => {
  const { name, guid, showOnSite } = payload;
  let updatedArray = cloneDeep(alertsArray);
  const index = alertsArray.findIndex(item => item.guid === guid);
  if (index !== -1) {
    updatedArray = cloneDeep(alertsArray);
    if (name) {
      updatedArray[index].name = name;
    } else {
      updatedArray[index].alertEnabled = showOnSite;
    }
  }
  
  return updatedArray;
};

const NotificationSetting = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONSETTING.LIST: {
      const { resourceType, data } = action.payload;
      return state.set(resourceType, data);
    }
    case NOTIFICATIONSETTING.CREATE: {
      const { resourceType, data } = action.payload;
      const { guid } = data;
      const updatedData = cloneDeep(state.get(resourceType));
      const index = updatedData.findIndex((item) => (item.guid.toLowerCase() === guid.toLowerCase()));
      if (index > -1) {
        updatedData[index] = data;
      } else {
        updatedData.push(data);
      }
      return state.set(resourceType, updatedData);
    }
    case NOTIFICATIONSETTING.RIGHT_DRAWER: {
      const { savedSearch, targetList } = action.payload.data;  
      return state.set('rightDrawer', [
        ...formatArrayByType(savedSearch, NotificationResourceType.SAVED_SEARCH),
        ...formatArrayByType(targetList, NotificationResourceType.TARGET_LIST),
      ]);
    }
    case NOTIFICATIONSETTING.ALERT: {
      const { savedSearch, targetList, scoutAI } = action.payload.data;  
      return state.set('alerts', [
        ...formatArrayByType(savedSearch, NotificationResourceType.SAVED_SEARCH),
        ...formatArrayByType(targetList, NotificationResourceType.TARGET_LIST),
        ...formatArrayByType(scoutAI, NotificationResourceType.SCOUTAI),
      ]);
    }

    case NOTIFICATIONSETTING.UPDATE_COMPANY_ALERTS: { 
      const arrayFromResponse = action.payload.data;
      const arrayFromRedux = state.get('alerts') as any[];
      const mergedArray = [];

      arrayFromRedux.forEach((itemFromRedux) => {
        if (itemFromRedux.searchType !== SAVED_SEARCH_TYPE.COMPANY) {
          mergedArray.push(itemFromRedux);
        } else {
          const index = arrayFromResponse.findIndex(item => item.guid === itemFromRedux.guid);
          if (index !== -1) {
            mergedArray.push({
              ...itemFromRedux,
              name: arrayFromResponse[index].name,
              companies: arrayFromResponse[index].companies,
            });
          }
        }
      });

      return state.set('alerts', mergedArray);
    }

    case NOTIFICATIONSETTING.UPDATE: {
      const myAlerts = state.get('alerts') as any[];
      const myAlertsinRightDrawer = state.get('rightDrawer') as any[];

      return state.set('alerts', updateAlertDetails(myAlerts, action.payload))
        .set('rightDrawer', updateAlertDetails(myAlertsinRightDrawer, action.payload));
    }
    default:
      return state;
  }
};

export default NotificationSetting;