import { Map } from 'immutable';
import { ANNOUNCEMENT } from '@constants/actions';

const initialState = Map({
  inProgress: false,
  list: [],
});

const Announcement = (state = initialState, action) => {
  switch (action.type) {
    case ANNOUNCEMENT.INIT.IN_PROGRESS: {
      return state.setIn(['inProgress'], true);
    }
    case ANNOUNCEMENT.INIT.NOT_IN_PROGRESS: {
      return state.setIn(['inProgress'], false);
    }
    case ANNOUNCEMENT.INIT.RESULT: {
      const announcements = action.payload.data;
      return state.setIn(['list'], announcements);
    } 
    default:
      return state;
  }
};

export default Announcement;