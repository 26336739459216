import React, { useState, useEffect,  FC, ReactNode, useRef } from 'react';
import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { FONT, TOOLTIP } from '@constants/style';
import AtlasDataTrackable, { IAtlasDataTrackable } from '@react/components/atlas/atlas.component';
import classNames from 'classnames';
import useStyles from './style';

const StyledTooltip = withStyles(() => ({
  tooltip: {
    padding: 16,
    marginTop: 8,
    fontSize: '16px',
    backgroundColor: TOOLTIP.BACKGROUND_COLOR,
    fontWeight: FONT.WEIGHT.REGULAR,
    '@media (max-width: 600px)': {
      padding: 8,
    },
  },
  arrow: {
    color: TOOLTIP.BACKGROUND_COLOR, 
  },
  popper: {},
}))(Tooltip);

const HiddenButton = withStyles(() => ({
  root: {
    background: 'transparent',
    border: 'none',
    fontSize: 0,
    padding: '0',
    position: 'absolute'
  }
}))(Button);

export interface IProps {
  tooltipText: ReactNode,
  arrow?: boolean,
  customClasses?: {
    tooltip?: string,
    arrow?: string,
    popper?: string
    wrapper?: string;
  },
  placement?: 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start';
  containerClass?: string,
  atlastTrackingObj?: IAtlasDataTrackable,
  type?: 'default' | 'light',
  children?: any;
  value?: boolean;
  onChange?: () => void;
  onTouchStart?:() => void;
  onMouseOver?:() => void;
  onMouseLeave?:() => void;
}

const HsTooltip:FC<IProps> = (props) => {
  const { tooltipText, children, arrow, customClasses, containerClass, atlastTrackingObj, placement = 'bottom', type = 'default', value= false, onChange, onTouchStart, onMouseOver, onMouseLeave  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(value);
  const clickRef = useRef(null);
  
  useEffect(() => {
    setOpen(value);
  }, [value]);

  const handleOpen = () => {
    if (onChange) {
      onChange();
    } else {
      setOpen(true);
    }
    atlastTrackingObj && clickRef.current.click();
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  if (!tooltipText) {
    return <>{children}</>;
  }
  
  const getAtlasTrackingObj = () => {
    return atlastTrackingObj;
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={customClasses && customClasses.wrapper}>
        <StyledTooltip
          title={tooltipText}
          placement={placement}
          onClose={handleClose}
          onOpen={handleOpen}
          onClick={handleOpen}
          enterTouchDelay={1}
          leaveTouchDelay={10000}
          open={open}
          arrow={arrow}
          classes={{
            tooltip: customClasses && customClasses.tooltip,
            arrow: customClasses && customClasses.arrow,
            popper: classNames(type ==='light' && classes.lightTooltip, customClasses && customClasses.popper)
          }}
        >
          <div
            onFocus={()=>{}}
            onTouchStart={() => onTouchStart?.()}
            onMouseOver={() => onMouseOver?.()}
            onMouseLeave={() => onMouseLeave?.()}
            aria-haspopup="true"
            role='generic'
            className={containerClass}
          >
            {children}
          </div>
        </StyledTooltip>
        {
          atlastTrackingObj && 
          <HiddenButton 
            variant='text' 
            ref={clickRef}
            {...atlastTrackingObj ? AtlasDataTrackable(getAtlasTrackingObj()) : {}}
          />
        }
      </div>
    </ClickAwayListener>
  );
};

export default HsTooltip;

export const generateMultilineTooltipText = (stringArray: string[]) => {
  return stringArray.map((string, index) => (
    <div key={`tooltip-item-${index}-${string}`} dangerouslySetInnerHTML={{ __html: string }} />));
};