import { IAtlasTracking } from '@models/atlasTracking';

export interface IAtlasDataTrackable {
  targetAttribute?: string;
  articleGuid?: string;
  articleSource?: string;
  articleIsLocked?: boolean;
  role?: string;
  filterTitle?: string;
  articleIdContext?: string;
  companyScoutIdContext?: string;
  countryContext?: string;
  sectorContext?: string;
  subsectorContext?: string;
  dataqa?: string;
  dataContext?: string;
  companyContextCount?: string;
  atlastracking?: IAtlasTracking;
}

const transfromJson = (data: any) => {
  return typeof data === 'string' ? data : JSON.stringify(data);
};

const AtlasDataTrackable = (props: IAtlasDataTrackable) => {

  const dataTrackableObj = {
    'data-trackable': props.targetAttribute,
  };

  if (props.companyContextCount) {
    dataTrackableObj['data-trackable-context-company_count'] = props.companyContextCount;
  }

  if (props.dataContext) {
    dataTrackableObj.dataTrackableContext = props.dataContext;
  }

  if (props.dataqa) {
    dataTrackableObj['data-qa'] = props.dataqa;
  }

  if (props.filterTitle) {
    dataTrackableObj['data-trackable-context-filter_title'] = props.filterTitle;
  }

  if (props.articleIdContext) {
    dataTrackableObj['data-trackable-context-article_id'] = props.articleIdContext;
  }

  if (props.companyScoutIdContext) {
    dataTrackableObj['data-trackable-context-company_scout_id'] = props.companyScoutIdContext;
  }

  if (props.countryContext) {
    dataTrackableObj['data-trackable-context-country'] = props.countryContext;
  }

  if (props.sectorContext) {
    dataTrackableObj['data-trackable-context-sector'] = props.sectorContext;
  }

  if (props.subsectorContext) {
    dataTrackableObj['data-trackable-context-sub_sector'] = props.subsectorContext;
  }

  if (props.articleGuid) {
    dataTrackableObj['data-trackable-context-article_guid'] = props.articleGuid;
    dataTrackableObj['data-trackable-context-article_source'] = props.articleSource;
    dataTrackableObj['data-trackable-context-article_is_locked'] = props.articleIsLocked;
  }

  if (props.role) {
    dataTrackableObj.role = props.role;
  }

  if (props.atlastracking) {
    if (props.atlastracking.uiElement) {
      dataTrackableObj['data-trackable-ui-element'] = props.atlastracking.uiElement;
      dataTrackableObj['data-trackable-ui-element-group'] = props.atlastracking.uiElementGroup;
    }
    if (props.atlastracking.uiElementDetails) {
      dataTrackableObj['data-trackable-ui-element-details'] = transfromJson(props.atlastracking.uiElementDetails);
    }
    if (props.atlastracking.objectName) {
      dataTrackableObj['data-trackable-object-name'] = props.atlastracking.objectName;
    }
    if (props.atlastracking.objectNameDetails) {
      dataTrackableObj['data-trackable-object-name-details'] = transfromJson(props.atlastracking.objectNameDetails);
    }
    if (props.atlastracking.ig_ctx_url) {
      dataTrackableObj['data-trackable-ig-ctx-url'] = transfromJson(props.atlastracking.ig_ctx_url);
    }
  }

  return dataTrackableObj;
};



export default AtlasDataTrackable;