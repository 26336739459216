import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { QUICK_SEARCH } from '@constants/style';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ExternalsTagContent: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '12px',
      cursor: 'pointer',
      fontSize: '10px',
      marginTop: '4px',
      color: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
      '&:hover >span, &:hover > svg': {
        color: QUICK_SEARCH.COLOR.FONT.DARK,
        fill: QUICK_SEARCH.COLOR.FONT.DARK,
      }
    },
    FTexternalLink: {
      marginRight: '6px',
    },
    openInNewIcon: {
      width: '12px',
      height: '12px',
      fill: QUICK_SEARCH.COLOR.FONT.MAIN_DARK,
    },

  })
);

export default useStyles;