import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FONT } from '@constants/style';

const useStyles = makeStyles(() =>
  createStyles({
    font:{
      fontFamily: 'Lato,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif !important',
      fontWeight: FONT.WEIGHT.REGULAR,
    },
    translation:{
      fontSize:'12px',
      marginLeft: 5,
    },
    darkTranslation:{
      fontSize:'12px',
      color: '#FFFFFFDE !important',
      marginLeft: 5,
    },
    list:{
      paddingTop: '0 !important',
      paddingBottom: '76px !important',
    },
    darkThemeList:{
      paddingTop: '0 !important',
      paddingBottom: '76px !important',
      background: '#303031 !important',
    },
    listItem:{
      borderBottom: '1px solid hsla(0,100%,0%,.09)',
      paddingLeft: 0,
      paddingRight: 0,
    },
    listItemDark:{
      borderBottom: '1px solid hsla(0,100%,100%,.09)',
      paddingLeft: 0,
      paddingRight: 0,
    },
    leftPart:{
      width: 'calc(100% - 110px)',
    },
    rightPart:{
      minWidth:'110px',
    },
    aTag:{
      textDecoration: 'none',
    },
    icon:{
      verticalAlign: 'sub',
      marginLeft: '5px',
    },
    loading:{
      position: 'absolute',
      bottom: 0,
      paddingTop: 900,
    },
    title: {
      fontSize: '14px',
      color: 'hsla(0,100%,0%,0.87) !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    darkTitle:{
      fontSize: '14px',
      color: 'hsla(0,100%,100%,0.87) !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    nikkeiTitle:{
      maxWidth: 'calc(100% - 25px)',
    },
    darkPublisher:{
      paddingTop: 4,
      fontSize: '10px',
      color: 'hsla(0,100%,100%,0.6) !important',
    },
    publisher:{
      paddingTop: 4,
      fontSize: '10px',
      color: 'hsla(0,100%,0%,0.6) !important',
    },
    darkTime:{
      textAlign:'end',
      paddingTop: 4,
      fontSize: '10px',
      color: '#FFFFFF99 !important',
    },
    time:{
      textAlign:'end',
      paddingTop: 4,
      fontSize: '10px',
      color: '#878787',
    },
    legaleseBanner:{
      position:'fixed',
      backgroundColor: '#707070',
      color: FONT.COLOR.SECONDARY.INVERTED.MAIN,
      top: 0,
      zIndex: 1,
      padding: '8px 16px',
      fontSize: 12,
      lineHeight: 1.5,
    },
    crossButton:{
      padding: '0 8px',
    },
    BannerText: {
      maxWidth: 'calc(100% - 34px)',
    },
    noContentBox: {
      margin: '20px 0',
    },
    paperRoot:{
      background: 'transparent',
      padding: 20,
    },
    paperOutlined:{
      border: `solid 1px ${FONT.COLOR.ERROR.DARK}`,
    },
    darkAlertText: {
      color: FONT.COLOR.SECONDARY.INVERTED.MAIN,
    },
    legaleseLink:{
      color: 'inherit',
    },
  }),
);

export default useStyles;
