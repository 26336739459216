import { ONBOARDING } from '@constants/actions';
import { Map } from 'immutable';

const initialState = Map({
  sectors: {
    selected: {},
  },
  goals: {
    selected: [],
  },
  countries: {
    selected: [],
  },
});

const Onboarding = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING.SECTOR.SELECTED.UPDATE: {
      return state.setIn(['sectors', 'selected'], action.payload.selected.sectors);
    }
    case ONBOARDING.GOAL.SELECTED.UPDATE: {
      return state.setIn(['goals', 'selected'], action.payload.goals);
    }
    case ONBOARDING.COUNTRY.SELECTED.UPDATE: {
      return state.setIn(['countries', 'selected'], action.payload.countries);
    }
    default:
      return state;
  }
};
  
export default Onboarding;